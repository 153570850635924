import { Card, CardContent, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import apiClient from 'src/utils/apiClient';
import CheckOutTable from './CheckOutTable';
import TicketCard from './TicketCard';

const useStyles = makeStyles((theme)=>({
    cardContent:{
        padding:'16px 0'
    },
    cardRow:{
        padding:'4px 8px'
    },
    rowTot:{
        backgroundColor:'#c9ddff',
        padding:'4px 8px'
    }
}))

const CheckOutAnalisi = (props)=>{

    const {righe,user,analisi_extra,handleModify,handleDeleteRiga}=props;
    const classes= useStyles();
    //const [tickets,setTickets] = useState([]);
    const [numero_analisi, setNumeroAnalisi] = useState(0);
    const tot = numero_analisi * parseFloat(user.prezzo_base_analisi);
    const iva = tot *0.22;

    

    useEffect(()=>{
        //setNumeroAnalisi(righe.length);
        var n_a = righe.length;
        
        setNumeroAnalisi(n_a);
        //setTickets(ticket_list);
    },[righe])
    
    return (
        <Paper elevation={8} style={{padding:"16px 8px"}}>
        {/* <TicketCard tickets={tickets} /> */}
            <Grid container spacing={2}>
                
                <Grid item xs={12}>
                    <Typography variant="h4">Lista campioni da analizzare</Typography>
                    <Typography variant="body2" color="textSecondary">Qui verranno mostrati i campioni inseriti nell'ordine. <br/> controlla di aver inserito <b>tutti</b> i campioni prima di inviare l'ordine di analisi</Typography>
                    
                </Grid>
                <Grid item md={12} xs>
                    <CheckOutTable righe={righe} user={user}  handleModify = {handleModify} handleDeleteRiga = {handleDeleteRiga}/>
                </Grid>

                
                
                
            </Grid>
        </Paper>
    );
}

export default CheckOutAnalisi;