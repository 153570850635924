import React, { useEffect, useState } from 'react';
import { AppBar, Button, Dialog, DialogContent, Divider, Grid, IconButton, makeStyles, Slide, Table, TableCell, TableContainer, TableRow, TableBody, TableHead ,Toolbar, Typography } from '@material-ui/core';
import InputText from 'src/components/forms/InputText';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import CheckBox from 'src/components/forms/CheckBox';
import { CircularProgress } from '@material-ui/core';
import { Icon } from '@material-ui/core';


const StyledTableContainer = withStyles((theme) => ({
    root: {
      width: "max-content"
    }
  }))(TableContainer);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const styles = {
    appBar:{
      position: 'relative',
      backgroundColor:"#5AB66D"
    }
  }
  
  const useStyles = makeStyles((theme) => ({
    dialog:{
      paddingBottom:40
    },
    bottomFixed:{
      position:"fixed",
      bottom:0,
      maxHeight:40,
      left:0,
      right:0,
      backgroundColor:"#ffffff",
      padding:'0px 8px',
      display: 'flex',
      alignItems: 'center',
      marginBottom:8
    },
    circular:{
      width:'30px !important',
      height:'30px !important',
      marginRight:8
    },
    recapRow:{
       
        padding:'4px 8px'
    },
    rowTot:{
        backgroundColor:'#c9ddff',
        padding:'4px 8px'
    },
    btnGroup:{
        marginTop:16
    },
    margin0:{
        marginTop:0,
        marginBottom:0
    }
  }))

const ModaleProformaDeo = (props)=>{
    const {open, handleClose, m, user,save,isSaving,errore} = props;
    const [materie,setMaterie] = useState([]);
    const classes = useStyles();
    const [saveOfferta, setSaveOfferta]= useState(false);
    const [tot,setTot]= useState(0);
    //var ticket_usati = 0;
    //var n_analisi = righe.length;
    //var toPay = payment ? payment.usati : 0;

    
    
    useEffect(()=>{
         if(open){
            setMaterie([...m]);
            //calcolaTotale();
         }else{
             setMaterie([]);
             //calcolaTotale();
         }
    },[open,m])

    useEffect(()=>{
        calcolaTotale()
    },[materie])
    

    const onPriceChange = (e,i) =>{
        setMaterie([
            ...materie.slice(0,i),
            {...materie[i], prezzo:e},
            ...materie.slice(i+1)
        ]);
    }

    const calcolaTotale = () =>{
        
        var tot=materie.reduce((acc,materia)=>acc+(materia.prezzo * materia.peso_rilevato),0);
            
        
        setTot(tot);
    }

    // const renderPriceCol= (riga)=>{
        
    //     if(riga.with_ticket){
    //         return <Icon style={{fontSize:16}}>confirmation_number</Icon>
    //     }else if(price.length){
    //         let offerta = price.find(obj=> obj.id===riga.extras[0].id)
    //         return offerta? offerta.prezzo : riga.extras[0].pivot.prezzo;
    //     }else{
    //         return 0;
    //     }
    // }

    // const filterPrice = (id) =>{
    //     for (let i = 0; i < righe.length; i++) {
    //         const riga = righe[i];
    //         if(riga.extras[0].id === id && !riga.with_ticket) return true;
    //     }
    //     return false;
    // }
    
    return(
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} PaperProps={{className:classes.dialog}}>
            
            <AppBar style={styles.appBar}>
                <Toolbar>
                <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" style={{display:'flex'}}>
                    Genera Proforma
                </Typography>
                </Toolbar>
            </AppBar>

            <DialogContent>
                <Grid container spacing={0}>
                    <Grid item xs={12} className={classes.recapRow}>
                        <StyledTableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        
                                        <TableCell>Nome materia</TableCell>
                                        <TableCell>Codice interno</TableCell>
                                        <TableCell>Peso rilevato</TableCell>
                                        <TableCell>Prezzo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {materie.map((m,i)=>(
                                        <TableRow key={m.id}>
                                            
                                            <TableCell>{m.nome_materia}</TableCell>
                                            <TableCell>{m.codice}</TableCell>
                                            <TableCell>{m.peso_rilevato}</TableCell>
                                            <TableCell>
                                                <InputText value={m.prezzo} handleChange={(e)=>onPriceChange(e,i)} variant="outlined" className={classes.margin0} size="small"/>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    <TableRow>
                                        <TableCell colSpan={2} rowSpan={3}></TableCell>
                                        <TableCell variant="head">Imponibile</TableCell>
                                        <TableCell>{tot.toFixed(2)} €</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">IVA</TableCell>
                                        <TableCell>{(tot*0.22).toFixed(2)} €</TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell variant="head">Totale</TableCell>
                                        <TableCell>{(tot*1.22).toFixed(2)} €</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </StyledTableContainer>
                        
                    </Grid>
                    

                    <Grid item xs={12}>
                        <Divider style={{backgroundColor:"#274AA7"}}/>
                    </Grid>

                    

                    <Grid item xs={12} container spacing={0} className={classes.btnGroup}>
                        <Button variant="outlined" color="primary" onClick={()=>save(materie,tot)}>Salva</Button>
                        
                        {isSaving &&
                            <>
                            <CircularProgress className={classes.circular}/>
                            <Typography variant="overline" style={{color:"#aaa"}}>Salvataggio in corso</Typography>
                            </>
                        }
                        {errore !== "" && <Typography variant="overline" color="error">{errore}</Typography>}

                    </Grid>

                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ModaleProformaDeo;