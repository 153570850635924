import { makeStyles, Table } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { arrayCompare } from 'src/utils/const';

const useStyles = makeStyles(()=>({
    thead:{
        backgroundColor:"rgba(39, 167, 74, 0.25)"
    },
    rowWhite: {
        backgroundColor:"#fff"
    },
    rowGrey:{
        backgroundColor:"#DAE4F0"
    }
}))


const IncludiAnalisi = (props) =>{
    const {payments,selected,setSelected} =  props;
    const [rows,setRows]= useState([]);
    const classes = useStyles();

    useEffect(()=>{
        var list = [];
        payments.map(payment => {
            var ordine = payment.refer;
            var righe = ordine.righe;
            var elem = {
                codice:ordine.codice,
                id: ordine.id,
                analisi:[],
            }
            righe.map(riga=>{
                if(!riga.with_ticket){
                    var i  = elem.analisi.findIndex(obj => obj.id===riga.extras[0].id)
                    if(i!==-1){
                        elem.analisi[i].quantita +=1;
                    }else{
                        elem.analisi.push({
                        id:riga.extras[0].id,
                        nome:riga.extras[0].nome,
                        quantita:1
                      })
                    }
                }
            });

            list.push(elem);
            
        })
        setRows(list)
        console.log(list);
    },[payments])

    const isChecked = (id_ordine)=>{
        if(selected.hasOwnProperty(id_ordine)){
            var ordine = payments.find(p => p.refer.id === id_ordine);
            var righe_ordine = ordine.refer.righe.map(r => r.id);
            return arrayCompare(righe_ordine,selected[id_ordine]);
        }
        return false;
    }

    const isAnalisiChecked = (id_ordine,id_analisi) =>{
        if(selected.hasOwnProperty(id_ordine)){
            var righe_ids = selected[id_ordine];
            var ordine = payments.find(p => p.refer.id === id_ordine).refer;
            var righe_ordine = ordine.righe.filter(r => r.extras[0].id==id_analisi).map(r=>r.id);
            console.log(righe_ordine,selected[id_ordine]);
            // return arrayCompare(righe_ordine,selected[id_ordine]);
            return righe_ordine.some(r=>selected[id_ordine].includes(r));
        }
        return false;
    }

    const onSelectAllClick = (id_ordine) =>{
        if(isChecked(id_ordine)){
            const {[id_ordine]:[],...other}= selected;
            
            setSelected(other);
        }else{
            var ordine = payments.find(p => p.refer.id == id_ordine);
            
            var righe_ordine = ordine.refer.righe.filter(r=>!r.with_ticket).map(r => r.id);
            setSelected({
                ...selected,
                [id_ordine]: righe_ordine
            });    
        }
        
    }

    const onSelectAnalisiClick = (id_ordine,id_analisi) =>{
        var ordine = payments.find(p => p.refer.id === id_ordine).refer;
        if(isAnalisiChecked(id_ordine,id_analisi)){
            var righe_ordine = ordine.righe.filter(r => r.extras[0].id===id_analisi).map(r=>r.id);
            righe_ordine = selected[id_ordine].filter(id => !righe_ordine.includes(id))
            if(righe_ordine.length){
                setSelected({
                    ...selected,
                    [id_ordine]: righe_ordine
                })    
            }else{
                const {[id_ordine]:[],...other}= selected;
                setSelected(other);
            }
            
        }else{
            var righe_ordine = ordine.righe.filter(r => r.extras[0].id===id_analisi).map(r=>r.id);
            var selected_old = selected[id_ordine]? selected[id_ordine] : [];
            setSelected({
                ...selected,
                [id_ordine]: [...selected_old,...righe_ordine]
            })
        }

    }
    return(

        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow className={classes.thead}>
                        <TableCell variant="head" colSpan={2}>Ordine</TableCell>
                        <TableCell variant="head" colSpan={2}>Analisi richieste</TableCell>
                        <TableCell variant="head" >Quantità</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((ordine,i) =>{
                        var rowSpan = ordine.analisi.length;
                        var cN = i%2==0 ? classes.rowWhite : classes.rowGrey;
                        return (
                            <Fragment key={ordine.id}>
                            <TableRow className={cN}>
                                <TableCell padding="checkbox" rowSpan={rowSpan}>
                                    <Checkbox
                                    checked={isChecked(ordine.id)}
                                    onChange={()=>onSelectAllClick(ordine.id)}
                                    inputProps={{ 'aria-label': 'seleziona intero ordine' }}
                                    />
                                </TableCell>
                               <TableCell rowSpan={rowSpan}>{ordine.codice}</TableCell>
                               {ordine.analisi.map((a,i)=>{
                                   if(i==0){
                                       return(
                                            <Fragment key={i}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                    checked={isAnalisiChecked(ordine.id,a.id)}
                                                    onChange={()=>onSelectAnalisiClick(ordine.id,a.id)}
                                                    inputProps={{ 'aria-label': 'seleziona intero ordine' }}
                                                    />
                                                </TableCell>
                                                <TableCell>{a.nome}</TableCell>
                                                <TableCell>{a.quantita}</TableCell>
                                            </Fragment>
                                       )
                                   }
                               })}
                            </TableRow>
                            {ordine.analisi.map((a,i)=>{
                                if(i!=0){
                                return(
                                    <TableRow key={i} className={cN}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                            checked={isAnalisiChecked(ordine.id,a.id)}
                                            onChange={()=>onSelectAnalisiClick(ordine.id,a.id)}
                                            inputProps={{ 'aria-label': 'seleziona intero ordine' }}
                                            />
                                        </TableCell>
                                        <TableCell>{a.nome}</TableCell>
                                        <TableCell>{a.quantita}</TableCell>
                                    </TableRow>
                                )}
                            })}
                            </Fragment>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default IncludiAnalisi;