import { Checkbox, FormControlLabel} from "@material-ui/core";
import React from "react";


const CheckBox = (props)=>{
    const {value,label,handleChange, ...other} = props;

    return (
        <FormControlLabel 
        control={
            <Checkbox 
            checked={value}
            onChange={e=>handleChange(e.target.checked)}
            color="primary"
            />
        }
        label={label}
        {...other}
        />
    );
}
export default CheckBox;