import React, { useEffect, useState } from 'react';
import { AppBar, Button, Dialog, DialogContent, Divider, Grid, IconButton, makeStyles, Slide, Table, TableCell, TableContainer, TableRow, TableBody, TableHead ,Toolbar, Typography, Chip } from '@material-ui/core';
import InputText from 'src/components/forms/InputText';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import CheckBox from 'src/components/forms/CheckBox';
import { CircularProgress } from '@material-ui/core';
import { Icon } from '@material-ui/core';


const StyledTableContainer = withStyles((theme) => ({
    root: {
      width: "max-content"
    }
  }))(TableContainer);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const styles = {
    appBar:{
      position: 'relative',
      backgroundColor:"#5AB66D"
    }
  }
  
  const useStyles = makeStyles((theme) => ({
    dialog:{
      paddingBottom:40
    },
    bottomFixed:{
      position:"fixed",
      bottom:0,
      maxHeight:40,
      left:0,
      right:0,
      backgroundColor:"#ffffff",
      padding:'0px 8px',
      display: 'flex',
      alignItems: 'center',
      marginBottom:8
    },
    circular:{
      width:'30px !important',
      height:'30px !important',
      marginRight:8
    },
    recapRow:{
       
        padding:'4px 8px'
    },
    rowTot:{
        backgroundColor:'#c9ddff',
        padding:'4px 8px'
    },
    btnGroup:{
        marginTop:16
    },
    margin0:{
        marginTop:0,
        marginBottom:0
    }
  }))

const ModaleProforma = (props)=>{
    const {open, handleClose, righe,user,proformaSave,simpleSave,codice,isSaving,errore,convenzione} = props;
    const [price,setPrice] = useState([]);
    const classes = useStyles();
    const [saveOfferta, setSaveOfferta]= useState(false);
    const [tot,setTot]= useState(0);
    //var ticket_usati = 0;
    //var n_analisi = righe.length;
    //var toPay = payment ? payment.usati : 0;

    
    
    useEffect(()=>{
        
        // setPrice(user? user.offerta : []);
        setUpPrice(user,convenzione);
        setSaveOfferta(false);
        
    },[righe])
    
    useEffect(()=>{
        // setPrice(user? user.offerta : [])
        setUpPrice(user,convenzione);
    },[user])

    useEffect(()=>{
        calcolaTotale()
    },[price])

    const onPriceChange = (e,i) =>{
        setPrice([
            ...price.slice(0,i),
            {
                ...price[i],
                prezzo:e
            },
            ...price.slice(i+1)
        ]);
    }

    const setUpPrice = (user,convenzione) =>{
        if(convenzione){
            var price  = user ? 
                user.offerta.map(el=>{
                    var p = convenzione.extras.find(e => e.id ==el.id);

                    return {
                        ...el,
                        'prezzo':p? p.pivot.prezzo: el.prezzo,
                        'old': p? el.prezzo : p
                    }
                }) 
            : 
                [];
            //console.log(price);
            setPrice(price);
        }else{
            setPrice(user? user.offerta : []);
            // console.log(user? user.offerta : []);
        }
    }

    const calcolaTotale = () =>{
        var tot=0;
        righe.map(riga=>{
            if(!riga.with_ticket){
                let offerta = price.find(obj=> obj.id===riga.extras[0].id);

                tot += parseFloat(offerta  ? offerta.prezzo : riga.extras[0].pivot.prezzo);
            }
            return riga;
        });
        setTot(tot);
    }

    const renderPriceCol= (riga)=>{
        
        if(riga.with_ticket){
            return <Icon style={{fontSize:16}}>confirmation_number</Icon>
        }else if(price.length){
            let offerta = price.find(obj=> obj.id===riga.extras[0].id)
            return offerta? offerta.prezzo : riga.extras[0].pivot.prezzo;
        }else{
            return 0;
        }
    }

    const filterPrice = (id) =>{
        for (let i = 0; i < righe.length; i++) {
            const riga = righe[i];
            if(riga.extras[0].id === id && !riga.with_ticket) return true;
        }
        return false;
    }
    
    return(
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} PaperProps={{className:classes.dialog}}>
            
            <AppBar style={styles.appBar}>
                <Toolbar>
                <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" style={{display:'flex'}}>
                    Genera Proforma
                </Typography>
                </Toolbar>
            </AppBar>

            <DialogContent>
                <Grid container spacing={0}>
                    <Grid item xs={6} className={classes.recapRow}>
                        <StyledTableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        
                                        <TableCell>Nome</TableCell>
                                        
                                        <TableCell>Prezzo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {price.filter(el=>filterPrice(el.id)).map((tipo_analisi,i)=>(
                                        <TableRow key={tipo_analisi.id}>
                                            
                                            <TableCell>{tipo_analisi.nome}</TableCell>
                                            
                                            <TableCell>
                                                <InputText value={tipo_analisi.prezzo} handleChange={(e)=>onPriceChange(e,i)} variant="outlined" inputProps={{pattern: '[0-9][0-9]*'}} className={classes.margin0} size="small"/>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </StyledTableContainer>
                        <CheckBox value={saveOfferta} label="salva come offerta base cliente" handleChange={setSaveOfferta}/>
                        {convenzione && 
                        <>
                        <Typography>Ordine Convenzionato</Typography>
                        <Chip label={convenzione.nome_convenzione} color="primary"/>
                        </>
                        }
                    </Grid>
                    <Grid item xs={6} className={classes.recapRow}>
                        <StyledTableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Codice</TableCell>
                                        <TableCell>Nome</TableCell>
                                        <TableCell>Analisi</TableCell>
                                        <TableCell>Prezzo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {righe.map((riga,i)=>(
                                        <TableRow key={i}>
                                            <TableCell>{codice+'.'+(riga.posizione+1)}</TableCell>
                                            <TableCell>{riga.nome_campione}</TableCell>
                                            <TableCell>{riga.extras[0].nome}</TableCell>
                                            <TableCell>{renderPriceCol(riga)}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={2} rowSpan={3}></TableCell>
                                        <TableCell variant="head">Imponibile</TableCell>
                                        <TableCell>{tot}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">Iva</TableCell>
                                        <TableCell>{(tot * 0.22).toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">Totale</TableCell>
                                        <TableCell>{(tot * 1.22).toFixed(2)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </StyledTableContainer>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider style={{backgroundColor:"#274AA7"}}/>
                    </Grid>

                    

                    <Grid item xs={12} container spacing={0} justify="flex-start" className={classes.btnGroup}>
                        <Button style={{marginRight:8}} variant="outlined" color="primary" onClick={simpleSave}>Salva senza Proforma</Button>
                        {tot > 0 && <Button variant="contained" style={{marginRight:8}} color="primary" onClick={()=>proformaSave(tot,price,saveOfferta)}>Salva con Proforma</Button>}

                        {isSaving &&
                            <>
                            <CircularProgress className={classes.circular}/>
                            <Typography variant="overline" style={{color:"#aaa"}}>Salvataggio in corso</Typography>
                            </>
                        }
                        {errore !== "" && <Typography variant="overline" color="error">{errore}</Typography>}

                        
                    </Grid>

                </Grid>
            </DialogContent>
        </Dialog>
    );
}

ModaleProforma.defaultProps = {
    convenzione:null,
  }

export default ModaleProforma;