import React,{useState,useEffect, useRef} from "react";
import Page from 'src/components/Page';
import {Button, makeStyles,Menu,MenuItem,Typography,IconButton,Icon, Divider, Card, Grid} from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import makeCarousel from "react-reveal/makeCarousel";
import Slide from "react-reveal/Slide";
import "src/home.css";
import TopBar from "./TopBar";
import MoreIcon from '@material-ui/icons/MoreVert';
import clsx from "clsx";
import ContactForm from "./ContactForm";
import { connect } from "react-redux";
import { setSettings, updateSettings } from "src/redux/actions";
import { saveState } from "src/redux/localStorage";
import { Link } from "react-router-dom";
import { Facebook, Instagram } from "react-feather";
import { SITE_URL } from "src/utils/const";

const useStyles = makeStyles((theme) => ({
    root: {
    //   backgroundColor: theme.palette.background.dark,
      height: '100%',
      flex: '1 1 auto',
      overflow: 'auto',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
        
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
    },
    headline:{
        backgroundImage:"url('/static/images/seeds.jpeg')",
        backgroundPositionY:"bottom",
        backgroundSize:"cover",
        position: "relative",
        height: "100vh",
        color: "#fff",
        textAlign: "center"
    },
    inner:{
        position:"absolute",
        top:"50%",
        left:"50%",
        zIndex:7,
        transform:"translateX(-50%) translateY(-50%)",
        [theme.breakpoints.down('sm')]: {
            width:300
        },
    },
    logoHeadline:{
        width:"100%",
        maxWidth:400,
    },
    logoFooter:{
        width:250
    },
    sl:{
        height:"100vh",
        [theme.breakpoints.down('sm')]: {
            height:"100%",
          },
    },
    
    title:{
        fontFamily:"'Poppins', sans-serif",
        textTransform:"uppercase",
        color:"#fff",
        color:"#fff",
        fontWeight:"bold",
        letterSpacing:"-1px",
        wordSpacing:5,
        fontSize:"1.5em",
        
        [theme.breakpoints.up('lg')]: {
            fontSize:"2em",
        },
    },

    formContainer:{
        width:"50%",
        [theme.breakpoints.down('sm')]: {
            width:"100%",
        },
    },
    infoContainer:{
        width:"50%",
        [theme.breakpoints.down('sm')]: {
            width:"100%",
        },
    },
    slAnalisi:{
        width:850,
        [theme.breakpoints.down('sm')]: {
            width:"auto",
        },
    },
    slCard:{
        [theme.breakpoints.down('sm')]: {
            flexDirection:"column",
        },
    },
    slCardContent:{
        width:"70%",
        [theme.breakpoints.down('sm')]: {
            width:"100%",
        },
    },
    slideBg:{
        backgroundImage:"url('/static/images/mol_bg_grey.png')",
        backgroundSize:"cover"
    },
    sl1_card_media:{
        backgroundImage:"url('/static/images/analisi_portrait.jpeg')",
        backgroundSize:"cover",
        backgroundRepeat:"no-repeat",
        height:622,
        backgroundPositionY:"40%",
        width:350,
        [theme.breakpoints.down('sm')]: {
            width:"100%",
            height:"calc(0.5625 * 100vw)",
            backgroundImage:"url('/static/images/analisi_landscape.jpeg')",
        },
    },
    sl3_card_media:{
        backgroundImage:"url('/static/images/Heavy_Metals.jpeg')",
        backgroundSize:"cover",
        backgroundRepeat:"no-repeat",
        height:"calc(900px / 3.26)",
        backgroundPositionY:"40%",
        width:"100%"
    },
    footerContainer:{
        width:"100%",
        maxWidth:1200,
        [theme.breakpoints.down(1200)]: {
            maxWidth:"100%",
        },
    },
    /////////////////////
    container:{
        maxWidth:1200
    },
    bannerServizi:{
        backgroundImage:"url('/static/images/bannerServizi.jpeg')",
        backgroundPositionY:"25%",
        backgroundSize:"cover",
        position: "relative",
        height: "calc(0.25 * 100vw)",
        color: "#fff",
        textAlign: "center",
        backgroundRepeat:"no-repeat",
        width:"100%",
        [theme.breakpoints.down('sm')]: {
            height: "calc(0.5 * 100vw)",
            backgroundPositionX:"center",
        },
    },
    bannerInner:{
        
            position:"absolute",
            top:"50%",
            left:"50%",
            zIndex:7,
            transform:"translateX(-50%) translateY(-50%)"
        
    },
    serviziContainer:{
        width:"100%",
        display:"flex",
        padding:"16px 32px",
        [theme.breakpoints.down('sm')]: {
            flexDirection:"column"
        },
        
    },
    margin_V8:{
        margin:"8px 0px"
    },
    media:{
        // backgroundImage:"url('/static/images/analisi_standard.jpeg')",
        // backgroundSize:"cover",
        // backgroundRepeat:"no-repeat",
        
        // backgroundPositionY:"40%",
        fontFamily:"'Poppins', sans-serif",
        width:"30%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        [theme.breakpoints.down('sm')]: {
            width:"100%",
            height:"calc(0.5625 * 100vw)",
            // backgroundImage:"url('/static/images/analisi_landscape.jpeg')",
        },
    },
    mediaImg:{
        height:200,
        width:200,
        borderRadius:"50%",
        border:"4px solid #27a"
    },
    servitiTextContainer:{
        width:"70%",
        [theme.breakpoints.down('sm')]: {
            width:"100%",
        },
    },
    contacts:{
        backgroundColor:"#e5f5ff",
    },
    contact_info:{
        [theme.breakpoints.up('sm')]: {
            padding:"0px 8px"
        },
    },
    bodyBg:{
        backgroundImage:"url('/static/images/mol_bg_grey.png')",
        backgroundSize:"contain"
    }
}));



const Landing = (props) =>{
    const {settings,setSettings,updateSettings,lang}=props;
    const classes = useStyles();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const navRef = useRef(null);
    const headerRef= useRef(null);
    const innerRef= useRef(null);
    const carouselRef = useRef(null);
    const bannerRef= useRef(null);
    const AboutUsRef= useRef(null);
    const contactRef = useRef(null);

    const [headerOpacity,setHeaderOpacity]=useState(0);
    const [scrollTop,setScrollTop]=useState(0);
    const [scrolled,setScrolled]=useState(false);
    const [scrollBanner,setScrollBanner]=useState(0);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleScroll = e =>{
        let element = e.target;
        let scrollTop = element.scrollTop;
        let headLineHeight = headerRef.current.clientHeight -75;
        let bannerOffsetTop = bannerRef.current.offsetTop;
        // console.log("******************************************");
        // console.log("scrollHeight -> "+element.scrollHeight);
        // console.log("scrollTop -> "+element.scrollTop);
        // console.log("clientHeight -> "+element.clientHeight);
        // console.log("******************************************");
        // console.log("nav -> "+navRef.current.clientHeight);
        // console.log("header -> "+headerRef.current.clientHeight);
        // console.log("innerRef -> "+innerRef.current.clientHeight);
        setHeaderOpacity(scrollTop / headLineHeight);
        setScrollTop(scrollTop);
        setScrollBanner(scrollTop<bannerOffsetTop ? 0 :scrollTop - bannerOffsetTop)
    }

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
         
    };

    const handleMenuClose = (ref) => {
        // goTo(i);
        handleMobileMenuClose()
        // setAnchorEl(null);
        scrollToElem(ref)
        ;
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const goTo = (i)=>{
        var promise = new Promise((resolve,reject)=>{
            setTimeout(() => {
                carouselRef.current.listRef.scrollIntoView({ behavior: 'smooth',block:"center"});
                resolve();
            }, 300);
        })
        promise.then(()=>{
            console.log(i);
            setCurrentSlide(i);
        });
        
    }
    const scrollHome = ()=>{
        headerRef.current.scrollIntoView({ behavior: 'smooth'});
    }

    const changeLang=()=>{
        let obj = {
            ...settings,
            lang:settings.lang=="it"? "en" : "it"
        };
        console.log(obj)

        // if(saveState(obj,'settings')){
        //     setSettings(obj);
        // }
        updateSettings(obj);
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isMenuOpen}
        getContentAnchorEl={null}
        onClose={handleMenuClose}
        >
            <MenuItem onClick={()=>handleMenuClose(1)}>{lang.landing.analisiCannabMenu}</MenuItem>
            <MenuItem onClick={()=>handleMenuClose(2)}>{lang.landing.analisiSpecialMenu}</MenuItem>
            <MenuItem onClick={()=>handleMenuClose(3)}>{lang.landing.heavyMetalMenu}</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        >   
            <MenuItem onClick={scrollHome}>{lang.landing.homeMenu}</MenuItem>
            <MenuItem onClick={()=>handleMenuClose(AboutUsRef)}>{lang.landing.aboutUsMenu}</MenuItem>
            <MenuItem onClick={()=>handleMenuClose(bannerRef)}>{lang.landing.analisiMenu}</MenuItem>
            <MenuItem onClick={()=>handleMenuClose(contactRef)}>{lang.landing.contactMenu}</MenuItem>
            <MenuItem onClick={changeLang}>{settings.lang=="it"?"EN":"IT"}</MenuItem>
        </Menu>
    );

    const  scrollToElem= (ref)=>{
        //ref.current.scrollIntoView({ behavior: 'smooth',block:"center"})
        var promise = new Promise((resolve,reject)=>{
            setTimeout(() => {
                ref.current.scrollIntoView({ behavior: 'smooth',block:"center"});
                resolve();
            }, 300);
        })
        return promise;
    }

    return (
        <>
            <TopBar navStyle={{backgroundColor:'rgba(39, 167, 74, '+(0+headerOpacity)+')'}} navRef={navRef}>

                <div className={classes.sectionDesktop}>
                    <Button color="inherit" onClick={scrollHome}>{lang.landing.homeMenu}</Button>
                    <Button color="inherit" onClick={()=>scrollToElem(AboutUsRef)}>{lang.landing.aboutUsMenu} </Button>
                    <Button color="inherit" onClick={()=>scrollToElem(bannerRef)}> {lang.landing.analisiMenu}</Button>
                    <Button color="inherit" onClick={()=>scrollToElem(contactRef)}>{lang.landing.contactMenu}</Button>
                    <Button color="inherit" component={Link} to="/auth/login">{lang.landing.loginMenu}</Button>
                    <Divider orientation="vertical" flexItem className="topBar-divider"/>
                    <Button color="inherit" onClick={changeLang}>{settings.lang=="it"?"EN":"IT"}</Button>

                </div>
                <div className={classes.sectionMobile}>
                <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
                >
                    <MoreIcon />
                </IconButton>
                </div>
            </TopBar>

            <Page
            title="Scienza In Fiore"
            className={classes.root}
            onScroll={handleScroll}
            >
                <div className="header" >
                    <div style={{opacity:(headerOpacity<=0.1? 0.9 : 1-headerOpacity)}} ref={headerRef} className={classes.headline}>
                        <div className={classes.inner} ref={innerRef}>
                            <div style={{transform:'translateY('+ scrollTop * 0.4 +'px)'}}>
                                <img src="static/siflab.png" className={classes.logoHeadline}/>
                                <p className={classes.title}>{lang.landing.homeHeader} </p>
                                <Button size="large" variant="contained" color="primary" component={Link} to="/auth/login">{lang.landing.loginMenu}</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={clsx(classes.bodyBg,"body")}>
                    <div className={clsx(classes.container,"container")} ref={AboutUsRef}>
                            <div className={clsx(classes.slCardContent,"sl1-card-content")}>
                                <h1 className="aboutUs-title">{lang.landing.slide1_title}</h1>
                                <hr className="divider"/>
                                <div className="aboutUs-text">
                                    <p>{lang.landing.about_p_1}</p>
                                    <p>{lang.landing.about_p_2}</p>
                                    <p>{lang.landing.about_p_3}</p>
                                    <p>{lang.landing.about_p_4}</p>
                                    <p>{lang.landing.about_p_5}</p>
                                    <br/>
                                    <Button color="primary" variant="outlined" onClick={()=>scrollToElem(contactRef)} style={{marginBottom:16}}>{lang.landing.cannabinoids_link}</Button>
                                </div>
                            </div>                            
                    </div>

                    <div className={clsx(classes.bannerServizi,"bannerServizi")} ref={bannerRef}>
                        <div className="layer"></div>
                        <div className={classes.bannerInner}>
                            <div style={{transform:'translateY('+ scrollBanner * 0.4 +'px)'}}>
                                <p className={classes.title}>{lang.landing.analisiMenu} </p>
                            </div>
                        </div>
                    </div>
                    

                    <div className={clsx(classes.container,"container","column-flex")}>
                        <Slide left>
                            <div className={clsx(classes.serviziContainer,classes.margin_V8)}>
                                
                                <div className={classes.media}>
                                    <img src="/static/images/analisi_standard.jpeg" className={classes.mediaImg}/>
                                </div>
                                <div className={classes.servitiTextContainer}>
                                    <h1 className="aboutUs-title">{lang.landing.slide2_title}</h1>
                                    <hr className="divider"/>
                                    <div className="aboutUs-text">
                                        <p>{lang.landing.cannabinoids_p_1}</p>
                                        <p>{lang.landing.cannabinoids_p_2}</p>
                                        <p>{lang.landing.cannabinoids_p_3}</p>
                                        <Button color="primary" variant="outlined" onClick={()=>scrollToElem(contactRef)} style={{marginBottom:16}}>{lang.landing.cannabinoids_link}</Button>
                                        <p>{lang.landing.cannabinoids_p_4}</p>
                                        <p>{lang.landing.cannabinoids_p_5}</p>
                                    </div>
                                </div>
                            </div> 
                        </Slide>
                        <Slide right>
                            <div className={clsx(classes.serviziContainer,classes.margin_V8)}>
                                
                                <div className={classes.media}>
                                    <img src="/static/images/analisi_advanced.jpeg" className={classes.mediaImg}/>
                                </div>
                                <div className={classes.servitiTextContainer}>
                                    <h1 className="aboutUs-title">{lang.landing.slide3_title}</h1>
                                    <hr className="divider"/>
                                    <div className="aboutUs-text">
                                        <p>{lang.landing.advanced_p_1}</p>
                                    </div>
                                </div>
                            </div>  
                        </Slide>
                        <Slide left>
                            <div className={clsx(classes.serviziContainer,classes.margin_V8)}>
                                
                                <div className={classes.media}>
                                    <img src="/static/images/metals.jpeg" className={classes.mediaImg}/>
                                </div>
                                <div className={classes.servitiTextContainer}>
                                    <h1 className="aboutUs-title">{lang.landing.slide4_title}</h1>
                                    <hr className="divider"/>
                                    <div className="aboutUs-text">
                                        <p>{lang.landing.metals_p_1}</p>
                                    </div>
                                </div>
                            </div> 
                        </Slide>

                        <div className={clsx(classes.serviziContainer,classes.margin_V8)} style={{justifyContent:"center"}}>
                            <Button size="large" variant="contained" color="primary" href={SITE_URL+"/storage/asset/"+lang.landing.listino} target="_blank">{lang.landing.listino_link}</Button>
                        </div>
                            

                            <div className={clsx(classes.serviziContainer,classes.contacts)} ref={contactRef}>
                                
                                
                                <div className={classes.servitiTextContainer}>
                                    <ContactForm />
                                </div>

                                <div className={classes.media}>
                                    <div className={classes.contact_info}>
                                        <p style={{fontWeight:"500"}}>Scienza In Fiore S.r.l.</p>
                                        <p>Via Mauro Bolognini 11, Villa Fumosa - 64026 - Pineto (TE)</p>
                                        <br/>
                                        <ul className="contact-list">
                                            <li className="contact-item">
                                                <Icon style={{fontSize:20,color:"#666"}}>phone</Icon>
                                                <p className="contact-text">+39 320.3806298 <br/> +39 328.8732223</p>
                                            </li>
                                            <li className="contact-item">
                                                <Icon style={{fontSize:20,color:"#666"}}>email</Icon>
                                                <p className="contact-text">info@scienzainfiore.it</p>
                                            </li>
                                            <li className="contact-item">
                                                <Icon style={{fontSize:20,color:"#666"}}>email</Icon>
                                                <p className="contact-text">scienzainfiore@pec.it</p>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>                      
                    </div>

                    
                </div>

                <div className="footer">
                    <Grid container className={classes.footerContainer} spacing={0}>
                        <Grid item sm={4} xs={12} className="footerCol">
                            <img src="static/siflab.png" className={classes.logoFooter}/>
                        </Grid>
                        <Grid item sm={4} xs={12} className="footerCol">
                            <div className="contact-info">
                                <p style={{fontWeight:"500"}}>Scienza In Fiore S.r.l.</p>
                                <p>Via Mauro Bolognini 11, Villa Fumosa - 64026 - Pineto (TE)</p>
                                <br/>
                                <ul className="contact-list">
                                    <li className="contact-item">
                                        <Icon style={{fontSize:20,color:"#eee"}}>phone</Icon>
                                        <p className="text-footer">+39 320.3806298 <br/> +39 328.8732223</p>
                                    </li>
                                    <li className="contact-item">
                                        <Icon style={{fontSize:20,color:"#eee"}}>email</Icon>
                                        <p className="text-footer">info@scienzainfiore.it</p>
                                    </li>
                                    <li className="contact-item">
                                        <Icon style={{fontSize:20,color:"#eee"}}>email</Icon>
                                        <p className="text-footer">scienzainfiore@pec.it</p>
                                    </li>
                                </ul>
                                
                                
                                
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12} className="footerCol">
                            <div className="contact-info">
                                <p style={{fontWeight:"500"}}>Social</p>

                                <ul className="soclial-list">
                                    <li className="soclial-item">
                                        <IconButton target="_blank" href="https://www.facebook.com/pages/category/Consulting-Agency/Scienza-In-Fiore-2161347094077559/">
                                            <Facebook color="#eee"/>
                                        </IconButton>
                                    </li>
                                    <li className="soclial-item">
                                    <IconButton target="_blank" href="https://www.instagram.com/scienza_in_fiore">
                                        <Instagram color="#eee"/>
                                    </IconButton>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <p className="footer-last">
                                © 2021 Scienza in fiore s.r.l.  |  p.iva: 02024020675  |  sede legale: Via Sabotino 56, 64021 Giulianova (TE)
                            </p>
                        </Grid>
                    </Grid>   
                </div>
                {renderMenu}
                {renderMobileMenu}
            </Page>    
        
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    setSettings:(settings)=>dispatch(setSettings(settings)),
    updateSettings:(settings)=>dispatch(updateSettings(settings))
});

const mapStateToProps = function(state){
    return {
        settings:state.settings,
        lang:state.lang
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Landing);