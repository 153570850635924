import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import InputText from 'src/components/forms/InputText';
import { useNavigate } from 'react-router-dom';

function ModaleResponse(props) {
  //const [open, setOpen] = React.useState(false);
  const {open,toggle,response}=props
  const navigate = useNavigate();

  return (
      <Dialog
        open={open}
        onClose={toggle}
      >
        <DialogTitle id="alert-dialog-title">{"Ordine Inserito"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {response?.esito==="OK" ? "Ordine/i importato/i con successo" : "Ops si è verificato un errore"}
          </DialogContentText>
          
          {response?.esito==="OK" ?
            <>
            <div style={{marginBottom:24}}>
                <Typography variant="subtitle2" display="inline" style={{marginRight:16}}>Cliente:</Typography>
                <Typography display="inline">{response.utente.azienda}</Typography>
                {response.nuovo && <Typography display="inline"><em>(nuovo)</em></Typography>}    
            </div>
            <div>
                <Typography variant="overline" display="block">Analisi importate:</Typography>
                {Object.keys(response.ordini).map(cod => <Button key={cod} onClick={()=>navigate('/analisi/'+response.ordini[cod])}>{cod}</Button>)}
            </div>
            </>
           :
           <Typography>{response?.errore? response.errore : "Controllare log errori"}</Typography>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle}>
            Chiudi
          </Button>
          
        </DialogActions>
      </Dialog>
  );
}
export default ModaleResponse;