import { CardContent, Chip } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Card, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { Fragment } from "react";
import { LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { CardActions } from "@material-ui/core";
import { Button } from "@material-ui/core";
import ConvenzioneTooltip from "./ConvenzioneTooltip";

const useStyles = makeStyles({
    root: {
        position: "sticky",
        top: "1rem",
    },
    title: {
        marginLeft:12
    },
    header:{
        display:"flex",
        alignItems:"center",
        marginBottom:6
    },
    details:{
        marginTop: 16,
        marginBottom: 16
    },
    convenzione:{
        flexGrow:2,
        display:"flex",
        justifyContent:"flex-end"
    }
});

const BorderLinearProgress = withStyles((theme) => ({
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);


const PaymentsCard = (props)=> {
    const classes = useStyles();
    const {pay} = props;

    const renderDetails=(pay)=>{
        var details = [];
        var iter =pay.refer_type==="analisi" ?  
            pay.refer.righe.filter(el=>!el.with_ticket) 
            : 
            (pay.refer_type=="deodorazione"? 
            pay.refer.materie
            :
            pay.refer.extras
            );
            
        var kind="";

        for (let i = 0; i < iter.length; i++) {
            const item = iter[i];
            if(pay.refer_type==="analisi"){
                kind=item.extras[0].nome
                let elem = details.find((e,i)=>{
                    if(e.kind === kind){
                        details[i]={ 
                            n: details[i].n+1,
                            prezzo: details[i].prezzo
                        }
                        return true;
                    }
                })
                if(!elem){
                
                    details.push({
                        kind: kind,
                        n: 1,
                        prezzo: item.extras[0].pivot.prezzo
                    })
                }
            }else if(pay.refer_type==="deodorazione"){
                details.push({
                    kind: item.nome_materia,
                    n: item.peso_rilevato ? item.peso_rilevato : item.peso_dichiarato,
                    prezzo: item.prezzo
                })
            }else{
                details.push({
                    kind: item.nome,
                    n: item.pivot.numero_ticket,
                    prezzo: item.pivot.prezzo
                })
            }
        }
        
        console.log(details);
        return details;
    }

    return (
        <Card className={classes.root} elevation={8}>
            <CardContent>
                <div className={classes.header}>
                    <Avatar className={pay.refer_type==="analisi" ? "science" :(pay.refer_type==="deodorazione"? "deo": "ticket" ) }>
                        {pay.refer_type==="analisi" ?
                        <Icon>science</Icon>
                        :
                        (pay.refer_type==="deodorazione" ?
                        <Icon>trending_down</Icon>
                        :
                        <Icon>confirmation_number</Icon>
                        )
                        
                        }
                    </Avatar>
                    <div className={classes.title}>
                        <Typography variant="h5">{pay.refer.codice}</Typography>
                        <Typography color="textSecondary" variant="caption"><i>{pay.refer_type}</i></Typography>
                    </div>

                    {pay.refer.convenzione && 
                    <div className={classes.convenzione}>
                        <ConvenzioneTooltip convenzione={pay.refer.convenzione} />
                    </div>
                    }
                </div>

                <Divider color="secondary"/>

                <Grid container spacing={0} justify="space-between">
                    <Grid item xs={6}>
                        <Typography color="textSecondary" variant="overline">Azienda</Typography>
                        <Typography>{pay.user.azienda}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography color="textSecondary" align="right" variant="overline" component="p">Data acquisto</Typography>
                        <Typography align="right">{moment(pay.refer.data_acquisto).format('DD/MM/YYYY')}</Typography>
                    </Grid>
                    
                    <Grid container item xs={12} className={classes.details}>

                        <Grid item xs={12}><Typography color="textSecondary" variant="overline" component="p">Dettaglio</Typography></Grid>
                        {renderDetails(pay).map(el=>
                        <Fragment key={el.kind}>
                            <Grid item xs={6}>
                                <Typography variant="caption" >{el.kind}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption" >{el.n + " x " + el.prezzo+(pay.refer_type==="deodorazione"?"€/Kg":"€/cad")}</Typography>
                            </Grid>
                        </Fragment>
                        )}
                        <Divider className={classes.details}/>
                        <Grid item xs={6}>
                            <Typography variant="button" >Imponibile</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" >{pay.totale}€</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="button" >IVA</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" >{(pay.totale*0.22).toFixed(2)}€</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="button" >Totale</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" >{(pay.totale*1.22).toFixed(2)}€</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="button" >Saldo</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="caption" >{pay.saldo.toFixed(2)}€</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography align="center" variant="caption" color="textSecondary">{pay.perc}%</Typography>
                            <BorderLinearProgress variant="determinate" value={pay.perc} style={{width:"100%"}}/>
                        
                        </Grid>
                    </Grid>
                </Grid>

            </CardContent>
            <CardActions>
                <Button size="small" color="primary" component={Link} to={"/pagamento/"+pay.id}>VEDI</Button>
            </CardActions>
        </Card>
    );
}

export default PaymentsCard;