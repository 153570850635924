import { AppBar, makeStyles } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Toolbar } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import InputSelect from 'src/components/forms/InputSelect';
import InputText from 'src/components/forms/InputText';
import FormLabel from '@material-ui/core/FormLabel';
import { Slide } from '@material-ui/core';
import { Switch } from '@material-ui/core';
import CheckBox from 'src/components/forms/CheckBox';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import apiClient from 'src/utils/apiClient';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const styles = {
    appBar:{
      position: 'relative',
      backgroundColor:"#5AB66D"
    }
}
const useStyles = makeStyles((theme) => ({
    
    dialog:{
      paddingBottom:40
    },
    bottomFixed:{
      position:"fixed",
      bottom:0,
      maxHeight:40,
      left:0,
      right:0,
      backgroundColor:"#ffffff",
      padding:'0px 8px',
      display: 'flex',
      alignItems: 'center',
      marginBottom:8
    },
    content:{
        padding: 16
    },
    circular:{
      width:'30px !important',
      height:'30px !important',
      marginRight:8
    }
}))

const ModaleRefertoEsterno = (props)=>{
    const {open,close,riga,ordine,setAnalisi}=props
    const classes = useStyles();
    const [committente,setCommittente] = useState('');
    const [accettazione, setAccettazione] = useState('');
    const [data_analisi, setDataAnalisi] = useState('');
    const [codice, setCodice] = useState('');
    const [etichetta, setEtichetta] = useState('');
    const [descrizione, setDescrizione] = useState('');
    const [varieta, setVarieta] = useState('');
    const [cartellino, setCartellino] = useState('');
    const [escludiTHC, setEscludiTHC] = useState(false);
    const [lotto, setLotto] = useState('');
    const [umidita,setUmidita]=useState('');
    const [um, setUm] = useState(false);
    const [isSaving , setIsSaving] = useState(false);
    const [errore,setErrore] = useState("");
    //const [isEng, setIsEng] = useState(false);
    const [lingua,setLingua]= useState('it');
    const [isRefV2, setRefV2] = useState(false);
    const [cartellinoQR, setCartellinoQR] = useState(false);

    useEffect(()=>{
        setCommittente("");
        setAccettazione(ordine.data_accettazione? moment(ordine.data_accettazione).format('DD/MM/YYYY'):"");
        setDataAnalisi(riga?.referto?.data_referto? moment(riga.referto.data_referto).format('DD/MM/YYYY'): moment().format('DD/MM/YYYY'));
        setCodice(riga? ordine.codice+"."+(riga.posizione+1):"");
        setEtichetta(riga? riga.nome_campione:"");
        setDescrizione(riga? riga.descrizione:"");
        setVarieta(riga? riga.cartellino.varieta.nome:"");
        setCartellino(riga? riga.cartellino.codice:"");
        setEscludiTHC(riga?.referto? riga.referto.escludiTHC : false);
        setLotto(riga? riga.lotto_coltivazione:"");
        setUm(false);
        //setIsEng(false);
        setLingua('it');
        setRefV2(false);
        setCartellinoQR(false);
        setUmidita('');
    },[riga])

    const handleSubmit = ()=>{
        setErrore("");
        setIsSaving(true);
        var post = new FormData();
        post.append('committente',committente);
        post.append('data_accettazione',accettazione);
        post.append('data_analisi', data_analisi);
        post.append('codice', codice);
        post.append('etichetta', etichetta);
        post.append('descrizione', descrizione);
        post.append('varieta', varieta);
        post.append('cartellino', cartellino);
        post.append('escludiTHC', escludiTHC);
        post.append('lotto', lotto);
        post.append('riga_id',riga.id);
        post.append('um', um);
        post.append('umidita',umidita);
        //post.append('isEng', isEng);
        post.append('lang',lingua);
        post.append('refV2',isRefV2);
        post.append('cartellinoQR',cartellinoQR);
        apiClient.post('api/referto_esterno',post)
        .then(response => {
            if(response.data.error){
                setErrore(response.data.error);
                setIsSaving(false);
            }else{
                setAnalisi(response.data.analisi);
                setIsSaving(false);
                close();
            }
        })
        .catch(err=>{
            setErrore("qualcosa è andato storto durante il salvataggio");
            setIsSaving(false)
        });
    }

    return(
        <Dialog fullScreen open={open} onClose={close} TransitionComponent={Transition} PaperProps={{className:classes.dialog}}>
            <AppBar style={styles.appBar}>
                <Toolbar>
                <IconButton color="inherit" onClick={close} aria-label="Close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" style={{display:'flex'}}>
                    Referto con intestazione
                </Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={0} className={classes.content}> 
                <Grid item xs={12} md={4}>
                    <InputText 
                    label = "Committente"
                    value= {committente}
                    handleChange = {setCommittente}
                    />    
                </Grid>

                <Grid item xs={12} md={4}>
                    <InputText 
                    label = "Data accettazione"
                    value= {accettazione}
                    handleChange = {setAccettazione}
                    />    
                </Grid>

                <Grid item xs={12} md={4}>
                    <InputText 
                    label = "Data analisi"
                    value= {data_analisi}
                    handleChange = {setDataAnalisi}
                    />    
                </Grid>

                <Grid item xs={12} md={4}>
                    <InputText 
                    label = "Codice"
                    value= {codice}
                    handleChange = {setCodice}
                    />    
                </Grid>

                <Grid item xs={12} md={4}>
                    <InputText 
                    label = "Nome campione"
                    value= {etichetta}
                    handleChange = {setEtichetta}
                    />    
                </Grid>

                <Grid item xs={12} md={4}>
                    <InputSelect
                    value={descrizione} 
                    handleChange={setDescrizione} 
                    label="Tipologia" 
                    items={[
                        {label:"Infiorescenza", value:"infiorescenza"},
                        {label:"Semilavorato",value:"semilavorato"},
                        {label:"Bio massa",value:"bio massa"}
                    ]} 
                    />  
                </Grid>


                <Grid item xs={12} md={4}>
                    <InputText 
                    label = "Varietà"
                    value= {varieta}
                    handleChange = {setVarieta}
                    />    
                </Grid>

                <Grid item xs={12} md={4}>
                    <InputText 
                    label = "Cartellino"
                    value= {cartellino}
                    handleChange = {setCartellino}
                    />    
                </Grid>

                <Grid item xs={12} md={4}>
                    <InputText 
                    label = "Lotto coltivarione"
                    value= {lotto}
                    handleChange = {setLotto}
                    />    
                </Grid>

                <Grid component="label" item container sm={4} xs={12} alignItems="center" spacing={0} style={{width:"auto"}}>
                    <Grid item xs={12}><Typography variant="body1"> Unità di misura</Typography></Grid>
                    <Grid item component={FormLabel}>p/p</Grid>
                    <Grid item>
                        <Switch
                        checked={um}
                        onChange={(event)=>setUm(event.target.checked)}
                        color="primary"
                        />
                    </Grid>
                    <Grid item component={FormLabel}>mg/ml</Grid>
                </Grid>

                <Grid item xs={12} sm={4} style={{alignSelf:"flex-end"}}>
                    <InputText value={umidita} handleChange={setUmidita} label="Tasso umidità" />
                </Grid>

                <Grid item xs={12} sm={4} style={{alignSelf:"flex-end"}}>
                    <CheckBox label="Escludi THC" value={escludiTHC} handleChange={setEscludiTHC}/>
                </Grid>

                <Grid item xs={12} sm={4} style={{alignSelf:"flex-end"}}>
                    {/* <CheckBox label="Crea in Inglese" value={isEng} handleChange={setIsEng}/> */}
                    <InputSelect 
                    label="Lingua Referto" 
                    value={lingua} 
                    handleChange={setLingua} 
                    items={[
                    {value:"it",label:"Italiano"},
                    {value:"eng",label:"Inglese"},
                    {value:"de",label:"Tedesco"}]} 
                    />
                </Grid>

                <Grid item xs={12}>
                    <CheckBox label="Versione 2" value={isRefV2} handleChange={setRefV2}/>
                </Grid>

                {/* <Grid item xs={12}>
                    <CheckBox label="QrCode cartellino" value={cartellinoQR} handleChange={setCartellinoQR} disabled={riga?.cartellino?.path? false:true }/>
                </Grid> */}
                
            </Grid>

            <div className={classes.bottomFixed}>
                <Button variant="contained" color="primary" onClick={handleSubmit} >SALVA</Button>
                {isSaving &&
                <>
                    <CircularProgress className={classes.circular}/>
                    <Typography variant="overline" style={{color:"#aaa"}}>Salvataggio in corso</Typography>
                </>
                }
                {errore != "" && <Typography variant="overline" color="error">{errore}</Typography>}
            </div>
        </Dialog>
    );
}

export default ModaleRefertoEsterno;