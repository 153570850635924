import React, {useEffect, useState} from 'react';
import {
    Button,
    Container,
    Divider,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from 'react-redux';
import AddRigheStep from 'src/views/analisi/NuovaAnalisi/AddRigheStep'
import CheckOutAnalisi from './CheckOutAnalisi';
import apiClient from 'src/utils/apiClient';
import { useNavigate } from 'react-router-dom';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import TicketCard from './TicketCard';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    buttonGroup:{
        marginTop:24
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const mapStateToProps = function(state){
    return {
        user:state.user
    }
}

let cartellinoDefault = {
    id:null,
    codice:'',
    img:new Array(),
    varieta:{
        nome:"",
        id:null
    }  
}

let rigaDefault = {
    nome_campione:"",
    grammatura:0,
    lotto_coltivazione:"",
    descrizione:"",
    cartellinoText:"",
    extra:"",
    note:"",
    cartellino:{...cartellinoDefault}
}


const NuovaAnalisi = (props)=>{

    const [righe, setRighe]=useState([]);
    const [nuovaRiga, setNuovaRiga] = useState({...rigaDefault});
    const classes = useStyles();
    const [analisi_extra,setAnalisiExtra] = useState([]);
    const [modify_index,setModifyIndex] = useState(null);
    const [tickets,setTickets] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [cartellini,setCartellini] = useState([]);
    const [varieta, setVarieta] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        apiClient.get('api/extras')
        .then(response => setAnalisiExtra(response.data.extras));
    },[]);

    useEffect(()=>{
        apiClient.get('api/ticket/user/'+props.user.id)
        .then(response => {
            var ticket_list = response.data.tickets.map(el=>({...el,usati:0}))
            setTickets(ticket_list)
        })
    },[]);

    useEffect(()=>{
        axios.all([
            apiClient.get('api/cartellini/'+props.user.id),
            apiClient.get('api/varieta')
        ])
        .then(axios.spread((cartellini,varieta)=>{
            setCartellini(cartellini.data);
            setVarieta(varieta.data);
        } ))
        .catch(err=>console.log(err));
    },[]);


    const handleSubmit = ()=>{
        setIsSaving(true);
        var post=new FormData();
        //
        console.log(righe);
        var data = righe.map((el,i)=>{
            //var r={...el};

            //console.log(el,r);

            if(el.cartellino.img.length > 0){
                post.append('pics[]',el.cartellino.img[0]);
                el.cartellino = {...el.cartellino, img:el.cartellino.img[0].name}
            }else{
                el.cartellino = {...el.cartellino, img:null};
            }

            return el;
        });

        post.append("righe", JSON.stringify(data));


        apiClient.post('api/analisi/nuova',post)
        .then(response => {
            setIsSaving(false);
            if(response.data.error){
                console.log(response)
            }else{
                navigate('/analisi/'+response.data.analisi);
            }
        })
        .catch(err=>{
            console.log(err);
            setIsSaving(false);
        })
    }

    const handleChangeRiga = (event)=>{
        
        setNuovaRiga({...nuovaRiga, [event.target.name]: event.target.value });
    }



    const handleCartellinoTextChange = (event,val,r)=>{

        setNuovaRiga({
            ...nuovaRiga,
            cartellinoText:val,
        })
    }

    const handleBlur = ()=>{
        if(nuovaRiga.cartellinoText !== nuovaRiga.cartellino.codice){
            setNuovaRiga({
                ...nuovaRiga,
                cartellino:{
                    ...nuovaRiga.cartellino,
                    id:null,
                    codice:nuovaRiga.cartellinoText
                }
            })  
        }
        
    }

    const handleChangeCartellino = (e,val,r)=>{
        if(r!=="clear"){
            console.log("!!!!!!!CLEAR")
            if(typeof val ==="object" && val!==null){
                setNuovaRiga({
                    ...nuovaRiga,
                    cartellino:{
                        id:val.id,
                        codice:val.codice,
                        img:[],
                        varieta:{
                            nome:val.varieta.nome,
                            id:val.varieta.id
                        }
                    }
                });
            }else{
                console.log("aaaaaaaaaaaaaaaaaaaaaaaaa")
            }
        }else{
            console.log("CLEAR")
            setNuovaRiga({
                ...nuovaRiga,
                cartellinoText:"",
                cartellino:{
                    id:null,
                    codice:'',
                    img:[],
                    varieta:{
                        nome:'',
                        id:null
                    }
                }
            });
        }
        
    }

    const handleVarietaChange = (e,val,r)=>{
        if(r!=="clear"){
            if(val){
                setNuovaRiga({
                    ...nuovaRiga,
                    cartellino:{
                        ...nuovaRiga.cartellino,
                        //id: nuovaRiga.cartellinoText === nuovaRiga.cartellino.codice ? nuovaRiga.cartellinoText : null,
                        codice: nuovaRiga.cartellinoText,
                        varieta:{
                            id:val.id,
                            nome:val.nome
                        }
                    }
                })
            }else{
                setNuovaRiga({
                    ...nuovaRiga,
                    cartellino:{
                        ...nuovaRiga.cartellino,
                        varieta:{
                            id:null,
                            nome:''
                        }
                    }
                })
            }    
        }else{
            setNuovaRiga({
                ...nuovaRiga,
                cartellino:{
                    ...nuovaRiga.cartellino,
                    varieta:{
                        id:null,
                        nome:''
                    }
                }
            })
        }
        
    }

    const onExtrasChange = (event)=>{
        const value = parseInt(event.target.value);

        if(nuovaRiga.extra.includes(value)){
            console.log("eliminazione");
            setNuovaRiga({...nuovaRiga, extra: nuovaRiga.extra.filter(el => el !== value) });
        }else{
            console.log('aggiunta');
            setNuovaRiga({...nuovaRiga, extra: [...nuovaRiga.extra, value] });
        }
        
    }

    const handleFileChange = (file)=>{

        setNuovaRiga({
            ...nuovaRiga,
            cartellino:{
                ...nuovaRiga.cartellino,
                codice: nuovaRiga.cartellino.codice ? nuovaRiga.cartellino.codice : nuovaRiga.cartellinoText,
                img:file
            }
        })
    }

    const handleModify = (i) => {
        setModifyIndex(i);
        setNuovaRiga(righe[i]);
    }

    const handleDeleteRiga = (i) => {
        setRighe([
            ...righe.slice(0,i),
            ...righe.slice(i+1),
        ]);
    }

    const addSample = ()=>{
        var t = [...tickets];
        
        if(modify_index===null){
            var i = t.findIndex((el)=>el.id == nuovaRiga.extra);
            var withTicket =  false;
            if( i!== -1 ){
                if(t[i].rimanenti > 0){
                    t[i].rimanenti--;
                    withTicket = true;
                }
                setTickets(t);
            }
            setRighe([
                ...righe,
                {...nuovaRiga, withTicket: withTicket}
            ]);
            setNuovaRiga({...rigaDefault});
        }else{
            //controllo se è cambiato il tipo analisi
            var withTicket= nuovaRiga.withTicket;
            if(righe[modify_index].extra !== nuovaRiga.extra){
                //se vecchia riga aveva un ticket lo riaggiungo
                if(righe[modify_index].withTicket){
                    var i = t.findIndex((el)=>el.id == righe[modify_index].extra);
                    t[i].rimanenti++;
                }
                //cerco se esiste un ticket per la nuova tipologia analisi 
                var i = t.findIndex((el)=>el.id == nuovaRiga.extra);
                var withTicket =  false;
                if( i!== -1 && t[i].rimanenti > 0){
                    t[i].rimanenti--;
                    withTicket = true;
                }else{
                    withTicket = false;
                }
                setTickets(t);
            }

            setRighe([
                ...righe.slice(0,modify_index),
                {...nuovaRiga,withTicket: withTicket},
                ...righe.slice(modify_index+1),
            ]);
            setNuovaRiga({...rigaDefault});
            setModifyIndex(null);
        }
        
    }
      
    return(
        <Page
        className={classes.root}
        title="Nuova Analisi"
        >
            <Container maxWidth={false}>
                <Grid container spacing={1}>
                    {tickets.length>0 &&
                    <Grid item xs={12}>
                        <TicketCard tickets={tickets} />
                    </Grid>
                    }
                    
                    <Grid item xs={12} md={12} lg={6}>
                        <AddRigheStep 
                        el={nuovaRiga} 
                        handleRiga={handleChangeRiga}
                        handleCartellino = {handleChangeCartellino}
                        handleCartellinoText = {handleCartellinoTextChange}
                        handleVarieta = {handleVarietaChange}
                        user={props.user}
                        handleFile={handleFileChange}
                        addSample = {addSample}
                        analisi_extra = {analisi_extra}
                        handleExtras={onExtrasChange}
                        cartellini={cartellini}
                        varieta={varieta}
                        handleBlur={handleBlur}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={6}>
                        <CheckOutAnalisi 
                        righe={righe} 
                        user={props.user} 
                        analisi_extra = {analisi_extra}
                        handleModify = {handleModify}
                        handleDeleteRiga = {handleDeleteRiga}
                        tickets ={tickets}
                        />                        
                    </Grid>
                </Grid>
                
                
                <Divider style={{backgroundColor:"#c9ddff", margin:"24px 0"}}/>
                {/* {step === 1 && <CheckOutAnalisi righe={righe} user={props.user}/> } */}

                {/* <CheckOutAnalisi 
                righe={righe} 
                user={props.user} 
                analisi_extra = {analisi_extra}
                handleModify = {handleModify}
                handleDeleteRiga = {handleDeleteRiga}
                tickets ={tickets}
                /> */}

                <Grid container spacing={2} className={classes.buttonGroup}>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={righe.length===0}>INVIA</Button>
                </Grid>
            </Container>

            <Backdrop className={classes.backdrop} open={isSaving}>
                <CircularProgress color="primary" />
            </Backdrop>
        </Page>
    );
}

export default connect(mapStateToProps)(NuovaAnalisi);