import { loadState, saveState } from "./localStorage";

export const loading = bool => ({
    type: 'LOADING',
    isLoading: bool,
});

export const saveUser = user =>({
  type: "SET_USER",
  user
});

export const logged = bool => ({
    type: 'LOGGED',
    isLoggedin: bool,
});

export const saveSettings = settings =>({
  type:"SET_SETTINGS",
  settings
})

export const changeLang = lang =>({
  type:"SET_LANG",
  lang
})


export const setUser = (user) => dispatch => (dispatch(saveUser(user)) );

export const stopLoading = () => dispatch => (dispatch(loading(false)) );
export const startLoading = () => dispatch => (dispatch(loading(true)) );

export const userLoggedIn = () => dispatch => (dispatch(logged(true)) );
export const userLoggedOut = () => dispatch => (dispatch(logged(false)) );

export const setSettings = (settings) =>dispatch =>(dispatch(saveSettings(settings)) );
export const setLang = (lang) =>dispatch =>(dispatch(changeLang(lang)) );

export const loadSettings = () => dispatch => {
  let settings = loadState('settings');
  if(settings){
    dispatch(setSettings(settings));
    dispatch(setLang(settings.lang));
  }
}

export const updateSettings = (settings) =>dispatch => {
  if(saveState(settings,'settings')){
    dispatch(setSettings(settings));
    dispatch(setLang(settings.lang));
  }

}