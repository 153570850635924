import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {Box,Button,Container,Grid,Link,TextField,Typography, makeStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import Page from 'src/components/Page';
import apiClient from 'src/utils/apiClient';
import { logIn } from 'src/utils/auth';
import { connect } from 'react-redux';
import {setUser, stopLoading, userLoggedIn, userLoggedOut } from 'src/redux/actions.js';

const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  stopLoading: () => dispatch(stopLoading()),
  userLoggedIn: () => dispatch(userLoggedIn()),
  userLoggedOut: () => dispatch(userLoggedOut()),
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {setUser,userLoggedIn} = props;
  const [errore,setErrore] = useState("");

  const login = (value,formikBag) =>{
    const {email,password,remember} = value;
    
    apiClient.get("/sanctum/csrf-cookie")
      .then(response =>{
        apiClient.post("/login",{
          email: email,
          password: password,
          remember:remember
        }).then(response => {
          formikBag.setSubmitting(false);
          if (response.data.error) {
            setErrore(response.data.error);
          } else {
            setUser(response.data.user);
            userLoggedIn();
            logIn();
            navigate('/');
          }
        })
        .catch(err => {
          console.log(err);
          setErrore("si è verificato un problema sul server. riprova più tardi!");
          formikBag.setSubmitting(false);
        })
      });
  }

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Email non valida').max(255).required('Email richiesta'),
              password: Yup.string().max(255).required('Password richiesta')
            })}
            onSubmit={(values,formikBag) => login(values,formikBag)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Accedi
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    effettua l'accesso per per accedere all'area riservata
                  </Typography>
                </Box>
                
                
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={(e)=>{setErrore('');handleChange(e);}}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={(e)=>{setErrore('');handleChange(e);}}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <FormControlLabel
                control={
                  <Checkbox name="remember" onChange={handleChange} color="primary"/>
                }
                label="Resta connesso" />

                {errore !="" && 
                <Box my={2}>
                    <Typography color="error" variant="body2">{errore}</Typography>
                </Box>}
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Accedi
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  password dimenticata?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/auth/forgotten-password"
                    variant="h6"
                  >
                    Recupera
                  </Link>
                </Typography>

                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Non hai un account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/auth/register"
                    variant="h6"
                  >
                    Registrati
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default connect(null,mapDispatchToProps)(LoginView);
