import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer, {defaultState} from './reducers.js';

const middlewares = [ReduxThunk];

// const defaultState = {
//     isLoggedin:false,
//     isLoading:true,
//     user:{},
//     settings:{lang:"it"},
//     lang:""
//   };

const store = createStore(
     rootReducer,
     defaultState,// default state of the application
    compose(applyMiddleware(...middlewares)),
);


export default store;
