import { Typography } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Switch } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React from 'react';
import PacchettoTooltip from 'src/components/PacchettoTooltip';

const UpdateAnalisi = (props) =>{
    const {analisi,setAttivo} = props;

    return(
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Typography>Gestione analisi</Typography>
            </Grid>
            
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell variant="head">Attivo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {analisi.map(el=>
                                <TableRow key={el.id}>
                                    <TableCell>
                                        {el.nome}
                                    </TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                        control={
                                            <Switch
                                            checked={el.attivo==1}
                                            onChange={(event)=>setAttivo(event.target.checked,el.id)}
                                            color="primary"
                                            />
                                        }
                                        />
                                    </TableCell>
                                </TableRow>    
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            
        </Grid>
    );
}
export default UpdateAnalisi;