import { combineReducers } from 'redux';
import it from 'src/lang/it.json';
import en from 'src/lang/en.json';

export const langObject={
    it:it,
    en:en
}

export const defaultState = {
  isLoggedin:false,
  isLoading:true,
  user:{},
  settings:{lang:"it"},
  lang:langObject.it
};



const userReducers = (state = defaultState, action) => {
  switch (action.type) {
        
        case 'SET_USER':
            return {...state, user: action.user};
        case 'LOADING':
            return { ...state, isLoading: action.isLoading };
        case 'LOGGED':
            return { ...state, isLoggedin: action.isLoggedin };
        case 'SET_SETTINGS':
            return {...state, settings:action.settings}

        case 'SET_LANG':
            return {...state, lang: langObject[action.lang] }
        default:
            return state;
    }
};



// const appReducer = combineReducers({
// 	login:userReducers
// });

const rootReducer = (state,action)=>{
	if (action.type === "SIGNOUT_REQUEST") {
        
        console.log('SignOut');
        //localStorage.removeItem('sifLab');
        state = defaultState;
    }
    return userReducers(state, action);
}
export default rootReducer;
