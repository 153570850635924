import { TableContainer } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React from 'react';
import PacchettoTooltip from 'src/components/PacchettoTooltip';



const TicketTab = (props)=>{
    const {tickets} = props;

    return (
        <>
            <Grid item xs={8} style={{marginBottom:24}}>
                {tickets.length ?
                <TableContainer>
                    <Table>
                        <TableBody>
                            {tickets.map(t=>
                                <TableRow>
                                    <TableCell>
                                        <PacchettoTooltip pacchetto={t} />
                                    </TableCell>
                                    <TableCell>{t.disponibili+'/'+t.rimanenti}</TableCell>
                                </TableRow>    
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Typography>Nessun ticket disponibile</Typography>
                }
            </Grid>

        </>
    );
}

export default TicketTab;