import InputField from './InputField';
import CheckboxField from './CheckboxField';
import SelectField from './SelectField';

const f_s = [
    {value:"Privato",label:"Privato"},
    {value:"Persona fisica titolare di Partita IVA",label:"Persona fisica titolare di Partita IVA"},
    {value:"Soggetto diverso da persona fisica",label:"Soggetto diverso da persona fisica"},
]

export { InputField, CheckboxField, SelectField, f_s };


