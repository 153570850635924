import React from 'react';
import { Outlet } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import Logo from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    flexDirection:"column"
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    
  }
}));

const HomeLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}> 
            <Outlet />
            
    </div>
  );
};

export default HomeLayout;
