import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Button, Card, CardContent, Container, Divider, makeStyles, Typography } from '@material-ui/core';
import  React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import apiClient from 'src/utils/apiClient';

import AddVarietaForm from './AddVarietaForm';

import UpdateVarieta from './UpdateVarieta';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const VarietaView = (props)=>{
    //const {}=props;
    const [varieta,setVarieta] = useState([]);
    const [isSaving,setIsSaving] = useState(false);
    const [nome,setNome] = useState('');

    const classes = useStyles();

    useEffect(()=>{
        apiClient.get('api/varieta/all')
        .then(response=>setVarieta(response.data));
    },[]);

    const setAttivo = (val,id)=>{
        setIsSaving(true);
        var body = new URLSearchParams();
        body.append('attivo',val);

        const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        apiClient.put('api/varieta/'+id,body,config)
        .then(response => {
            setIsSaving(false);
            setVarieta(response.data)
        });
    }

    const submitVarieta = (nome) =>{
        setIsSaving(true);
        var body = new FormData();
        body.append('nome',nome);
        
        apiClient.post('api/varieta',body)
        .then(response=>{
            setIsSaving(false);
            setVarieta(response.data);
            setNome('');
        })
    }

    return(
        <Page
        className={classes.root}
        title="Gestione Varieta"
        >
            <Container maxWidth={false}>
                <AddVarietaForm setVarieta={submitVarieta} nome={nome} setNome={setNome}/>
                <UpdateVarieta varieta={varieta} setAttivo={setAttivo}/>
            </Container>

            <Backdrop className={classes.backdrop} open={isSaving}>
                <CircularProgress color="primary" />
            </Backdrop>
        </Page>
    );
}

export default VarietaView;