import React, { useEffect, useState } from 'react';
import { Card, colors, Grid, makeStyles, Typography, withWidth, Avatar, CardActions, Button} from '@material-ui/core';
import { Divider } from '@material-ui/core';
import TagInput from './TagInput';
import apiClient from 'src/utils/apiClient';
import { connect } from 'react-redux';
import { Chip } from '@material-ui/core';
import clsx from 'clsx';
import CannabinoidiTable from 'src/views/reports/CannabinoidiTable.js'

const useStyles = makeStyles((theme) => ({
    card:{
      padding:"16px 8px",
      margin:"0px 16px",
    },
    })
);




const TablesCard = (props) =>{
    const {g1,g2,setS1,setS2} = props;
    const classes = useStyles();

    const opt=(i)=>(
        i==1 ? {
        selectableRows:'multiple',
        selectToolbarPlacement:'none',
        onRowSelectionChange:(currentRowsSelected, allRowsSelected, rowsSelected)=>setS1(rowsSelected),
        elevation:2
        } 
        : 
        {
        selectableRows:'multiple',
        selectToolbarPlacement:'none',
        onRowSelectionChange:(currentRowsSelected, allRowsSelected, rowsSelected)=>setS2(rowsSelected),
        elevation:2
        }
        )

    return(
        <Card className={classes.card}>
            <Grid container spacing={3}>

                <Grid item xs={12} sm={12} md={6}>
                   <CannabinoidiTable righe={g1} opt={opt(1)}/> 
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                   <CannabinoidiTable righe={g2} opt={opt(2)}/> 
                </Grid>

            </Grid>
        </Card>
    );
}

export default TablesCard;