import React from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import { connect } from 'react-redux';
import ProfileChangePassword from './ProfileChangePassword';
import { Divider } from '@material-ui/core';
import Convenzione from './Convenzione';

const mapStateToProps = function(state){
  return {
      user:state.user
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  divider:{
    marginTop:theme.spacing(3),
    backgroundColor:"transparent"
  }
}));

const Account = (props) => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Account"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid item lg={4} md={4} xs={12}>
            <Profile user={props.user}/>
            <Divider className={classes.divider}/>
            <Convenzione user={props.user}/>
          </Grid>

          <Grid item lg={8} md={8} xs={12}>
            <ProfileDetails user={props.user}/>
            <Divider className={classes.divider}/>
            <ProfileChangePassword user={props.user}/>
          </Grid>

          {/* <Grid item lg={4} md={4} xs={12}>
            
          </Grid>

          <Grid item lg={8} md={8} xs={12}>
            <ProfileChangePassword user={props.user}/>
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};

export default connect(mapStateToProps)(Account);
