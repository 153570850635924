import React from "react";
import { Typography, Grid, makeStyles, Button } from '@material-ui/core';
import InputText from 'src/components/forms/InputText';
import theme from "src/theme";

const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFilledInput-root": {
        background: "#fff"
      }
    },
    button:{
      backgroundColor:"#fff",
      
    }
}));

const CodiceConvenzione = (props)=>{
    const {codice, setCodice, submit,err,success}=props;
    const classes = useStyles();

    return (
        <Grid container spacing={2} style={{backgroundColor:"rgba(7,170,255,0.3)",marginTop:16}} alignItems="center" justify="flex-start">
            <Grid item xs={12}>
                <Typography variant="subtitle2" >Hai un codice convenzione?</Typography>
            </Grid>
            
            <Grid item xs="auto" zeroMinWidth>
                <InputText disabled={success!==""} variant="filled" value={codice} label="Codice convenzione" handleChange={setCodice} margin="dense" className={classes.root}/>
            </Grid>

            <Grid item xs="auto" zeroMinWidth>
                {success ==="" ? 
                <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={submit}
                >
                Verifica codice
                </Button>
                :
                <Typography variant="subtitle2" style={{color:theme.palette.primary.main}}>{success}</Typography>
                }
                
            </Grid>

            <Grid item xs={12}>
                {err!=="" && <Typography variant="body2" color="error">{err}</Typography>}
            </Grid>
        </Grid>
    );
}

export default CodiceConvenzione;