export default {
    formId: 'registration',
    formField: {
      first_name: {
        name: 'first_name',
        label: 'Nome*',
        requiredErrorMsg: 'campo richiesto'
      },
      last_name: {
        name: 'last_name',
        label: 'Cognome*',
        requiredErrorMsg: 'campo richiesto'
      },
      email: {
        name: 'email',
        label: 'Email*',
        requiredErrorMsg: 'campo richiesto',
        invalidErrorMsg: 'email non valida'
      },
      azienda: {
        name: 'azienda',
        label: 'Ragione sociale',
        requiredErrorMsg: 'campo richiesto'
      },
      forma_societaria: {
        name: 'forma_societaria',
        label: 'Forma societaria*',
        requiredErrorMsg: 'campo richiesto'
      },
      phone: {
        name: 'phone',
        label: 'Telefono',
        requiredErrorMsg: 'campo richiesto'
      },
      indirizzo: {
        name: 'indirizzo',
        label: 'Indirizzo*',
        requiredErrorMsg: 'campo richiesto',
      },
      citta: {
        name: 'citta',
        label: 'Città*',
        requiredErrorMsg: 'campo richiesto'
      },
      cap: {
        name: 'cap',
        label: 'CAP'
      },
      p_iva: {
        name: 'p_iva',
        label: 'Partita IVA*',
        requiredErrorMsg: 'campo richiesto'
      },
      codice_fiscale: {
        name: 'codice_fiscale',
        label: 'CF*',
        requiredErrorMsg: 'campo richiesto'
      },
      codice_univoco: {
        name: 'codice_univoco',
        label: 'Codice Univoco*',
        requiredErrorMsg: 'campo richiesto'
      },
      pec: {
        name: 'pec',
        label: 'Pec',
        invalidErrorMsg: 'pec non valida'
      },
      password: {
        name: 'password',
        label: 'Password*',
        requiredErrorMsg: 'campo richiesto'
      },
      passwordConfirm:{
        name: 'passwordConfirm',
        label: 'Conferma Password',
        requiredErrorMsg: 'campo richiesto' ,
        invalidErrorMsg: 'le password non coincidono'
      },
      cap: {
        name: 'cap',
        label: 'CAP*',
        requiredErrorMsg: 'campo richiesto'
      },
      citta: {
        name: 'citta',
        label: 'Città*',
        requiredErrorMsg: 'campo richiesto'
      },
      indirizzo: {
        name: 'indirizzo',
        label: 'Indirizzo*',
        requiredErrorMsg: 'campo richiesto'
      },
    }
  };