import React, {useState} from "react";
import {Button} from "@material-ui/core"; 
import {Email} from "@material-ui/icons";
import apiClient from "src/utils/apiClient"
import { SimpleModaleWithLoader } from "src/modali/ModaleConferma";

const ReminderProforma =(props)=>{
    const {id_payment, id_user} = props;
    const [isSendeing,setSending]= useState(false);
    const [text,setText] = useState("");
    const [isModalOpen, openModal]=useState(false)

    const handlePress = ()=>{
        setSending(true)
        openModal(true)
        var post = new FormData();
        post.append("dest",id_user);
        post.append("payment_id",id_payment);

        
        apiClient.post("api/payment/remind",post)
        .then(response=>{
            if(response.data.error){
                setText(response.data.error)
            }else{
                setText(response.data.res);
            }
            setSending(false)
        })
        .catch(err=>{
            console.log(err);
            setSending(false);
        });
    }
    return(
        <>
        <Button onClick={handlePress} variant="contained" color="primary" startIcon={<Email/>}>Invia Proforma</Button>

        <SimpleModaleWithLoader 
        open={isModalOpen}
        close={()=>openModal(false)}
        title="Reminder proforma"
        testo={text} 
        isLoading={isSendeing}
        />
        </>
    )
}

export default ReminderProforma;