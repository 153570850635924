import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, SelectField, f_s } from './FormFields';
import { useFormikContext } from 'formik';

export default function AziendaForm(props) {
  const {values,setFieldValue} = useFormikContext();
  const {
    formField: { 
      azienda,
      forma_societaria,
      p_iva,
      codice_fiscale,
      codice_univoco,
      pec,
      cap,
      citta,
      indirizzo
     }
  } = props;

  useEffect(()=>{
    if(values.forma_societaria === f_s[0].value){
      setFieldValue(p_iva.name,"");
    }
    if(values.forma_societaria === f_s[2].value){
      console.log("1 ---- ", values.p_iva)
      setFieldValue(codice_fiscale.name,values.p_iva);
    }
  },[values.forma_societaria])

  useEffect(()=>{
    if(values.forma_societaria === f_s[2].value){
      setFieldValue(codice_fiscale.name,values.p_iva);
    }
  },[values.p_iva]);

  
  console.log(values);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dettagli Azienda
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InputField
            name={azienda.name}
            label={azienda.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            name={forma_societaria.name}
            label={forma_societaria.label}
            data={f_s}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField 
          name={codice_fiscale.name} 
          label={codice_fiscale.label} 
          fullWidth 
          disabled={!(values.forma_societaria === f_s[0].value || values.forma_societaria === f_s[1].value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputField 
          name={p_iva.name} 
          label={p_iva.label} 
          fullWidth 
          disabled = {!(values.forma_societaria === f_s[2].value || values.forma_societaria === f_s[1].value)}
          />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <InputField name={codice_univoco.name} label={codice_univoco.label} fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputField name={pec.name} label={pec.label} fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputField name={cap.name} label={cap.label} fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField name={citta.name} label={citta.label} fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField name={indirizzo.name} label={indirizzo.label} fullWidth />
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
}