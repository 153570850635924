import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import apiClient from 'src/utils/apiClient';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
    inputText:{
        minWidth:180,
        maxWidth:280
    }
}));


export default function InputAutoComplete(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  
  const loading = open && options.length === 0;
  const classes = useStyles();
  const {url,onChange,label,name,opt,margin,...other} = props;

  React.useEffect(() => {

    if (!loading) {
      return undefined;
    }
    apiClient.get(url)
    .then(response =>{
        //console.log(response);
        var options = name  === 'user' ? response.data.customer:response.data;
        console.log(options);
        setOptions(options);
        if(options.length ===0){
          setOpen(false);
        }
    })
  }, [loading]);

  React.useEffect(() => {
    if (!open && url) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(()=>{
    if(!url){
      setOptions(opt)
    }
  },[opt]);

  return (
    <Autocomplete
      style={{ minWidth: 180 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      {...other}
      
      options={options}
      loading={loading}
      //onInputChange = {(e,val,r)=>{ console.log(e);console.log("------");console.log(val);console.log("------");console.log(r);console.log("------");} }
      onChange = {(e,val,r)=>onChange(e,val,r)}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.inputText}
          margin={margin}
          label={label}
          name={name}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
InputAutoComplete.defaultProps = {
  name:"cartellinoText",
  url:false,
  opt:[],
  margin:"normal"
}