import React,{Fragment, useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import { Check, Edit3, Tag, Trash2 } from 'react-feather';
import { Divider, Grid, IconButton, Paper,Box } from '@material-ui/core';
import { Icon } from '@material-ui/core';
//import { borders,borderTop,borderLeft,borderRight,borderBottom } from '@material-ui/system';
import SampleCard from './SampleCard';

const useStyles = makeStyles((theme)=>({
  saldo:{
    display:'flex',
    flexDirection:"column",
    alignItems:'flex-end',
    paddingRight:24
  },
  row: {
    display:'flex',
    justifyContent:'space-between',
    width:300,
    padding:'4px 0',

  },
  card:{
    padding:'4px 8px'
  },
  cardSide:{
    border:0,
    borderLeft:1,
    borderColor:theme.palette.primary.main,
    borderStyle:"solid",
    
  },
  last:{
    backgroundColor: theme.palette.primary.lighter,
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center"
  },
  sample:{
    borderStyle:'solid',
    borderColor: theme.palette.primary.main,
    borderTop:0,
    borderBottom:0,
    borderLeft:5,
    borderRight:0,
    marginBottom:12
  }
}))


const   getMuiTheme = () => createMuiTheme({
    overrides: {

      MUIDataTable: {
        responsiveScrollMaxHeight: {
          maxHeight:'700px !important'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: "rgba(39, 167, 74, 0.25) !important",
          padding:8
        }
      },
      MuiChip:{
        colorPrimary:{
          backgroundColor:'rgba(39, 167, 74, 0.85)'
        },
        colorSecondary:{
          backgroundColor:'#F6BD60'
        }
      }
    }
  });

  const CheckOutTable = (props)=>{
    const {analisi_extra,user} = props;
    const [imponibile,setImponibile] = useState(0.0);
    const [prezzoConvenzione,setPrezzoConvenzione] = useState(false);
    const classes = useStyles();

      useEffect(()=>{
        var tot = 0.0;
        var scontato =0.0;
        props.righe.map((el)=>{
          if(!el.withTicket){
            var p = parseFloat(user.offerta.find((extra)=> extra.id == el.extra ).prezzo);
            var s = user.convenzione?.extras?.find(ext => ext.id == el.extra);
            tot += p;//parseFloat(user.offerta.find((extra)=> extra.id == el.extra ).prezzo);

            var convenzione_extra = s ? parseFloat(s.pivot.prezzo) : p;
            if(user.convenzione && convenzione_extra){
              scontato += convenzione_extra;
            }
          }

        });
        setImponibile(tot);
        if(user.convenzione && scontato !==0.0) setPrezzoConvenzione(scontato);
      },[props.righe]);

      return (
        <Fragment>
          
          {props.righe.map( (riga,i) =>{
            let e = user.offerta.find((extra)=> extra.id == riga.extra );//.nome;
            let c= user.convenzione?.extras?.find(extra => extra.id ==riga.extra) ? user.convenzione : null;
            return (<SampleCard 
            key={i}
            index={i} 
            riga={riga} 
            analisi={e}
            convenzione = {c} 
            handleModify={props.handleModify} 
            handleDeleteRiga={props.handleDeleteRiga} 
            />
            )
          
          })}

          {props.righe.length >0 && 
            <div className={classes.saldo}>
              <div className={classes.row}>
                <Typography variant="button">Imponibile</Typography>
                <div>
                  <Typography variant="h6" style={prezzoConvenzione ? {textDecoration:"line-through"}:{}}>{imponibile}€</Typography>
                  {prezzoConvenzione && <Typography variant="h6">{prezzoConvenzione}€</Typography>}
                </div>
                
              </div>
              <Divider style={{backgroundColor:"#ccc",width:300}}/>
              <div className={classes.row}>
              <Typography variant="button">IVA</Typography>
                <Typography variant="h6">{((prezzoConvenzione ? prezzoConvenzione : imponibile) * 0.22).toFixed(2)}€</Typography>
              </div>
              <Divider style={{backgroundColor:"#ccc",width:300}}/>
              <div className={classes.row}>
              <Typography variant="button">TOTALE</Typography>
                <Typography variant="h6">{( (prezzoConvenzione ? prezzoConvenzione : imponibile) * 1.22).toFixed(2)}€</Typography>
              </div>
              <Divider style={{backgroundColor:"#ccc",width:300}}/>
            </div>
          }
        </Fragment>
      );
  }

  CheckOutTable.defaultProps = {
    opt:{}
  }
  export default CheckOutTable;
