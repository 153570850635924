import React, {useState, useEffect,Fragment} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import {Grid,makeStyles, colors, CircularProgress} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import {cannabinoidi, cannabinoidi_tot, cannabinoidi_estesi,avg,MEDIA} from 'src/utils/const.js';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import PickerDate from 'src/components/forms/PickerDate';
import moment,{ now } from 'moment';
import InputSelect from 'src/components/forms/InputSelect';
import InputText from 'src/components/forms/InputText';
import CheckBox from 'src/components/forms/CheckBox';
import InputFile from 'src/components/forms/InputFile';
import apiClient from 'src/utils/apiClient';
import { Box } from '@material-ui/core';
import _ from 'lodash';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  appBarPre:{
    position: 'relative',
    backgroundColor:"#5AB66D"
  },
  appBarPost:{
    position: 'relative',
    backgroundColor:"#207cca"
  }
}

const useStyles = makeStyles((theme) => ({
  cannaGrid:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding:' 0 8px!important',
    height: 32
  },
  cannaBtn:{
    width:60,
    height: 32,
    border:'none'
  },
  pari:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding:' 0 8px!important',
    height: 32,
    backgroundColor:colors.grey[100]
  },
  tot:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding:' 0 8px!important',
    height: 32,
    border:"1px solid #ccc"
  },
  totValue:{
    marginRight: 30,
    fontSize: '.875rem',
    color: '#666'
  },
  dialog:{
    paddingBottom:40
  },
  bottomFixed:{
    position:"fixed",
    bottom:0,
    maxHeight:40,
    left:0,
    right:0,
    backgroundColor:"#ffffff",
    padding:'0px 8px',
    display: 'flex',
    alignItems: 'center',
    marginBottom:8
  },
  circular:{
    width:'30px !important',
    height:'30px !important',
    marginRight:8
  },
  analisiMiss:{
    paddingLeft:20,
    marginLeft:16,
    position:'relative',
    '&::before': {
      content: '""',
      display: 'block',
      position:'absolute',
      left:0,
      top:'50%',
      height: 13,
      width:13,
      backgroundColor:"#ff0000",
      borderRadius:'50%',
      transform:"translateY(-50%)",
    }
  },
  analisiOk:{
    paddingLeft:20,
    marginLeft:16,
    position:'relative',
    '&::before': {
      content: '""',
      display: 'block',
      position:'absolute',
      left:0,
      top:'50%',
      height: 13,
      width:13,
      backgroundColor:theme.palette.success.main,
      borderRadius:'50%',
      transform:"translateY(-50%)",
    }
  }
}))


const ModaleRefertoDeo =(props)=>{
  const {riga,kind}=props;
  const [refVal,setRefVal] = useState("");
  const [extendedCannabinoidi,setExtendedCannabinoidi] = useState(false);
  const [um,setUm] = useState(false);
  const [risultati,setRisultati] = useState(null);
  const [colMod,setColMod] = useState(null);
  const [tot,setTot] = useState({});
  const [dataReferto, setDataReferto] = useState(now());
  const [calcolo,setCalcolo] = useState('singolo');
  const [umidita,setUmidita]=useState('');
  const [escludiTHC, setEscludiTHC] = useState(false);
  const [note, setNote] = useState('');
  const [file, setFile] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  //const [isEng, setIsEng] = useState(false);
  const [lingua,setLingua]= useState('it');
  const [isRefV2, setRefV2] = useState(false);
  const [cartellinoQR, setCartellinoQR] = useState(false);
  const classes = useStyles();
  
  const folder = MEDIA+props.idu+"/deodorazioni/"+props.ido+"/";
  /*
  component update riga => setta i valori iniziale della riga
  */
  useEffect(() => {
    
    setRefVal("");
    setExtendedCannabinoidi(false);
    setUm(false);
    setIsSaving(false);
    //setIsEng(false);
    setLingua('it');
    setRefV2(false);
    setCartellinoQR(false);
    if(riga!==null){
      const referto = kind=="pre" ? riga.analisi_pre : riga.analisi_post;
      setDataReferto( referto ? moment(referto.data_referto) : moment() );
      setFile(referto && referto.path ? folder+referto.path : []);
      setNote(referto?.note? referto.note : "");
      setCalcolo(referto ? referto.calcolo : 'singolo');
      setEscludiTHC(referto?.escludiTHC==1? true : false);
      setUp();
      console.log(folder);
    }else{
      setRisultati(null);
      setUp();
    }
  },[riga]);

  /** calcola i totali alla modifica dei risultati */
  useEffect(() => {
    setTot(calcolaTotali())
  },[risultati]);

  /**setta i valori iniziale quando il componente viene montato */
  useEffect(() => {
    setRefVal("");
    setExtendedCannabinoidi(false);
    setUm(false);
    setUp();
    setIsSaving(false);
  },[]);

  /** setta i risultati in caso di cannabinoidi estesi
   * @v flag per estendere i cannabinoidi
   */
  const setExtended = (v)=>{
    var ris = {...risultati};

    var l = ris['CBD'].val.length;
    if(v){
      for(var i =0; i<cannabinoidi_estesi.length; i++){
        var c = cannabinoidi_estesi[i];

        if(!ris.hasOwnProperty(c)){
          ris[c]={val:[],active:null}
          for(var j=0; j<l;j++){
            ris[c].val.push(0);
          }
        }
      }

    }
    setRisultati(ris);
    setExtendedCannabinoidi(v);
  }

  const setVal = ()=>{
    let str =  refVal.replace(/,/g, ".");
    let v = str.split(" ");
    const r = {...risultati};
    var l=0;
    var c;
    for(var i=0; i< cannabinoidi.length; i++){
      c = cannabinoidi[i];
      if(v[i]== undefined || v[i]==""){
        if(colMod===null){
          r[c].val.push(0.0)
        }else{
          r[c].val[colMod]=0.0;
        }
      }else{
        if(colMod===null){
          r[c].val.push(parseFloat(v[i]) );
        }else{
          r[c].val[colMod]=parseFloat(v[i]);
        }
      }

      l= r[c].val.length;
    }

    for(var i=0; i<cannabinoidi_estesi.length; i++){
      c=cannabinoidi_estesi[i];
      if(!r.hasOwnProperty(c)){
        r[c]={
          val:[],
          active:null
        }
        for(let j=0;j<l;j++){
          r[c].val.push(0.00);
        }
      }else{
        if(r[c].val.length<l){
          for(let j=0;j<l-r[c].val.length;j++){
            r[c].val.push(0.00);
          }
        }
      }
    }
    setRefVal("");
    setRisultati(r);
    setColMod(null);
  }

  const toggleColMod= (event,value)=>{

    let str="";
    var c;
    let val=[];

    if(value != null){
        //modifica colonna
      for (var i = 0; i < cannabinoidi.length; i++) {
      c=cannabinoidi[i];
          if(c==="THCA"){
            str+=risultati[c].val[value];
          }else{
            str+=risultati[c].val[value]+" ";
          }

        }
        setColMod(value);
        setRefVal(str);
    }else{
      var cannab = [...cannabinoidi, ...cannabinoidi_estesi];//getCannabinoidi();
      var r = {...risultati};
      for (var i = 0; i < cannab.length; i++) {
        c=cannab[i];
        val=[
          ...r[c].val.slice(0,colMod),
          ...r[c].val.slice(colMod+1)
          ];
        r[c].val=val;

        if(r[c].active!=null){
          if(r[c].active>colMod){
            r[c].active -= 1
          }else if(r[c].active==colMod){
            r[c].active = null
          }
        }

      }
      setColMod(value);
      setRefVal(str);
      setRisultati(r);
      //this.setState({colMod:value,ref_val:str,referti:referti,tot:tot})
    }
  }

  const handleToggle = (val,c)=>{
    setRisultati({
      ...risultati,
      [c]:{
        ...risultati[c],
        active:val
      }
    });
  }

  const handleChangeExtended = (key,j,value)=>{
    setRisultati({
      ...risultati,
      [key]:{
        ...risultati[key],
        val:[...risultati[key].val.slice(0,j),parseFloat(value)|| '', ...risultati[key].val.slice(j+1) ]
      }
    })
  }
  /** se la riga è vuota o non ci sono risultati salvati crea un oggetto risultati e lo carica nello stato, alrimenti carica i risultati da server */
  const setUp= ()=>{

    if(riga != null){
      apiClient.get('api/refertoDeo/'+props.idu+"/"+props.ido+"/"+riga.id+"/"+kind)
      .then(response=>{
        if(response.data.ref == null){
          setRisultati( defaultRisultati() );
        }else{
          setRisultati(response.data.ref);
        }
        

      })
    }else{
      setRisultati( defaultRisultati() );
    }

  }

  const defaultRisultati = ()=>{
    var r={};
    var key;
    var cannab = [...cannabinoidi, ...cannabinoidi_estesi];
    for(var k in cannab){
      key=cannab[k];
      r[key] = {
        val:[],
        active:null,
      };
    }
    return r;
  }

  const calcolaTotali = ()=>{
    var totali={}
    var c;
    var cannab= getCannabinoidi();
    for(let i=0; i< cannab.length; i++){
      c=cannab[i];
      if(risultati== null){
        totali[c]=0.0;
      }else if(risultati[c].active==null){
        totali[c]=avg(risultati[c].val);
      }else{
        totali[c]=parseFloat(risultati[c].val[risultati[c].active] );
      }
    }

    totali["tot_CBD"]=(( parseFloat(totali['CBDA']) * 0.877) + parseFloat(totali['CBD'])).toFixed(2);
    totali["tot_CBG"]=(( parseFloat(totali['CBGA']) * 0.878) + parseFloat(totali['CBG'])).toFixed(2);
    totali["tot_THC"]=(( parseFloat(totali['THCA']) * 0.877) + parseFloat(totali['d9_THC'])).toFixed(2);

    return totali;

  }

  const getCannabinoidi =()=>{
    if (extendedCannabinoidi){
      return [...cannabinoidi, ...cannabinoidi_estesi];
    }else{
      return cannabinoidi;
    }
  }

  const handleSubmit =()=>{
    setIsSaving(true);
    var post  = new FormData();
    
    post.append('rid',riga.id);
    post.append('data_analisi', moment(dataReferto).format("DD/MM/YYYY"));
    post.append('umidita',umidita);
    post.append('note',note);
    post.append('um',um);
    post.append('extended',extendedCannabinoidi);
    post.append('escludiTHC', escludiTHC);
    post.append('tot',JSON.stringify(tot));
    post.append('calcolo',calcolo);
    post.append('risultati',JSON.stringify(risultati) );
    //post.append('isEng',isEng);
    post.append('lang',lingua);
    post.append('refV2',isRefV2);
    post.append('cartellinoQR',cartellinoQR);
    post.append('kind',kind);
    if(typeof file === "string" && file!==""){
        post.append('img_name',file.split('/').pop());
    }else if(Array.isArray(file) && file.length){
        post.append('img_name',file[0].name);
        post.append('picture', file[0]);
    }

    apiClient.post('api/salvaRefertoDeo',post)
    .then(response=>props.setAnalisi(response.data.deo))
    .then(()=>{ setIsSaving(false); props.close() } )
    
  }

  const validate = (id)=>{
  
    switch(id){
      case 1:
        return  _.isEqual(risultati, defaultRisultati());
      case 2:
        return umidita =="";
      default: 
        return false;
    }
  }

  return(
    <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition} PaperProps={{className:classes.dialog}}>
      <AppBar style={kind=="pre" ? styles.appBarPre : styles.appBarPost}>
        <Toolbar>
          <IconButton color="inherit" onClick={props.close} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" style={{display:'flex'}}>
            {props.title + " " + (riga ? riga.nome_materia :"" )}
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid container spacing={0}> 
        {/* {riga && 
        <Grid item xs={12}>
          <Typography variant="h6">Analisi richieste</Typography>
          <Box display="flex">
            {riga.extras[0].analisi.map(el=>
              <Typography variant="overline" key={el.id} className={validate(el.id)? classes.analisiMiss :classes.analisiOk}>{el.nome}</Typography>
            )}
          </Box>
          
        </Grid>
        } */}
        <Grid item sm={8} xs={12}>
          <Grid container spacing={0} alignItems="center" style={{padding:"0px 8px"}}>
            <Grid item sm={3} xs={12} >
              <TextField
                label="Valori"
                value={refVal}
                onChange={(event)=>setRefVal(event.target.value)}
                margin="normal"
                helperText="scrivi i valori separati da uno spazio"
                autoComplete="off"
                InputProps={{
                  endAdornment:
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={(e)=>setVal()}><NavigateNextRoundedIcon /></IconButton>
                  </InputAdornment>,
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FormControlLabel
              control={
                <Switch
                checked={extendedCannabinoidi}
                onChange={(event)=>setExtended(event.target.checked)}
                color="primary"
                />
              }
              label="Cannabinoidi Estesi"
              />
            </Grid>

            <Grid component="label" item container sm={3} xs={12} alignItems="center" spacing={0} style={{width:"auto"}}>
              <Grid item xs={12}><Typography variant="body1"> Unità di misura</Typography></Grid>
              <Grid item component={FormLabel}>p/p</Grid>
              <Grid item>
                <Switch
                checked={um}
                onChange={(event)=>setUm(event.target.checked)}
                color="primary"
                />
              </Grid>
              <Grid item component={FormLabel}>mg/ml</Grid>
            </Grid>

          </Grid>

          <Grid container spacing={0} style={{padding:"0px 8px"}}>
            {getCannabinoidi().map((c,i)=>(
              <Fragment key={i}>
              {i==0 && risultati ?
                <Grid item xs={12} className={classes.cannaGrid}>
                  <div style={{marginLeft:50, display:"flex"}}>
                    <ToggleButtonGroup value={colMod} exclusive onChange={(event,val)=>toggleColMod(event,val)}>
                    {risultati[c].val.map((v,j)=>(
                      colMod == j ?
                      <ToggleButton key={j} value={j} className={classes.cannaBtn}><DeleteForeverRoundedIcon fontSize="small" /></ToggleButton>
                      :
                      <ToggleButton key={j} value={j} className={classes.cannaBtn}><EditRoundedIcon fontSize="small" /></ToggleButton>
                    ))}
                    </ToggleButtonGroup>
                  </div>
                </Grid>
              :
                null
              }

              <Grid item xs={12} className={i%2 == 0 ? classes.pari: classes.cannaGrid}>
                <div style={{display:'flex',alignItems:'center'}}>
                <Typography display="inline" variant="h4" style={{fontSize:12,fontWeight:500,width:50}}>{c}</Typography>
                {risultati && risultati[c] ?
                  cannabinoidi_estesi.includes(c) ?
                  <Fragment>
                  {risultati[c].val.map((v,j)=>(
                    <TextField
                    key={j}
                    value={risultati[c].val[j]}
                    onChange={(event)=>handleChangeExtended(c,j,event.target.value)}
                    margin="normal"
                    style={{width:60,margin:0}}
                    autoComplete="off"
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start">
                        {risultati[c].active == j ? <LockRoundedIcon style={{color:"#999"}} onClick={()=>handleToggle(null,c)}/>:<LockOpenRoundedIcon style={{color:"#999"}} onClick={()=>handleToggle(j,c)} />}
                        </InputAdornment>,
                    }}
                    />
                  ))}
                  </Fragment>
                  :
                  <ToggleButtonGroup value={risultati[c].active} exclusive onChange={(event,val)=>handleToggle(val,c)}>
                    {!cannabinoidi_tot.includes(c) && risultati[c].val.map((v,j)=>(
                    <ToggleButton key={j} value={j} className={classes.cannaBtn}>
                    {v}
                    </ToggleButton>
                    ))}

                  </ToggleButtonGroup>
                :
                null
                }
                </div>
                <span className={classes.totValue}>
                  {tot[c] ? tot[c].toFixed(2) : '-'}
                </span>

              </Grid>
              </Fragment>
            ) )}
            <Grid item xs={4} className={classes.tot}>
              <Typography display="inline" variant="h6" style={{fontSize:12,fontWeight:500,width:50}}>CBD TOT</Typography>
              <span className={classes.totValue}>
                {tot['tot_CBD'] ? tot['tot_CBD'] : '-'}
              </span>
            </Grid>

            <Grid item xs={4} className={classes.tot}>
              <Typography display="inline" variant="h6" style={{fontSize:12,fontWeight:500,width:50}}>CBG TOT</Typography>
              <span className={classes.totValue}>
                {tot['tot_CBG'] ? tot['tot_CBG'] : '-'}
              </span>
            </Grid>

            <Grid item xs={4} className={classes.tot}>
              <Typography display="inline" variant="h6" style={{fontSize:12,fontWeight:500,width:50}}>THC TOT</Typography>
              <span className={classes.totValue} style={tot['tot_THC'] && tot['tot_THC']>0.6 ? {color:"#ff0000",fontWeight:"bold"} : null}>
                {tot['tot_THC'] ? tot['tot_THC'] : '-'}
              </span>
            </Grid>
          </Grid>

          
        </Grid>
        <Grid container item sm={4} xs={12} spacing={0} alignContent="flex-start">
          <Grid item xs={12} sm={6}>
            <PickerDate label="Data analisi" handleDateChange={setDataReferto} selectedDate={dataReferto}/>
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputSelect 
            label="Calcolo" 
            value={calcolo} 
            handleChange={setCalcolo} 
            items={[
              {value:"singolo",label:"Singolo"},
              {value:"media",label:"Media"},
              {value:"multiplo",label:"Multiplo"}]} 
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <InputText value={umidita} handleChange={setUmidita} label="Tasso umidità" />
          </Grid>
          
          <Grid item xs={12} sm={6} style={{alignSelf:"flex-end"}}>
            <CheckBox label="Escludi THC" value={escludiTHC} handleChange={setEscludiTHC}/>
          </Grid>

          <Grid item xs={12}>
            <InputText value={note} handleChange={setNote} label="Note" multiline rowsMax="4" style={{width:'100%'}}/>
          </Grid>

          <Grid item xs={12}>
            <InputFile value={file} onChange={setFile}/>
          </Grid>

          <Grid item xs={12}>
            {/* <CheckBox label="Crea in Inglese" value={isEng} handleChange={setIsEng}/> */}
            <InputSelect 
            label="Lingua Referto" 
            value={lingua} 
            handleChange={setLingua} 
            items={[
              {value:"it",label:"Italiano"},
              {value:"eng",label:"Inglese"},
              {value:"de",label:"Tedesco"}]} 
            />
          </Grid>

          <Grid item xs={12}>
            <CheckBox label="Versione 2" value={isRefV2} handleChange={setRefV2}/>
          </Grid>

          <Grid item xs={12}>
            <CheckBox label="QrCode cartellino" value={cartellinoQR} handleChange={setCartellinoQR} disabled={riga?.cartellino?.path? false:true }/>
          </Grid>
        </Grid>
        
      </Grid>
      
      <div className={classes.bottomFixed}>
        <Button variant="contained" color="primary" onClick={handleSubmit} >SALVA</Button>
        {isSaving &&
        <Fragment>
            <CircularProgress className={classes.circular}/>
            <Typography variant="overline" style={{color:"#aaa"}}>Salvataggio in corso</Typography>
        </Fragment>
        }
      </div>
    </Dialog>
  );
}

export default ModaleRefertoDeo;
