import React, {useEffect, useState} from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from 'react-redux';

import apiClient from 'src/utils/apiClient';
import CustomersTable from './CustomersTable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const mapStateToProps = function(state){
  return {
    login:state.login
  }
}

const CustomerList = (props) => {

  const [customers, setCustomers] = useState([]);
  const classes = useStyles();
  useEffect(()=>{
    apiClient.get('/api/users')
    .then(response =>{
      console.log(response);
      setCustomers(response.data.customer);
    })
  },[]);

  return(
    <Page
      className={classes.root}
      title="Analisi"
    >
      <Container maxWidth={false}>
        <Grid container spacing={3} >

        <Grid item xs={12} >
          <CustomersTable customers={customers} />
        </Grid>

        </Grid>
      </Container>
    </Page>
  );
}

export default connect(mapStateToProps)(CustomerList);
