import React, {useEffect, useState} from 'react';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  useTheme
} from '@material-ui/core';
import Page from 'src/components/Page';
import DashOrderTable from './DashOrderTable';
import { connect } from 'react-redux';
import apiClient from 'src/utils/apiClient'
import NuovaAnalisiCard from './NuovaAnalisiCard';
import PaymentsCard from './PaymentsCard';
import TicketsCard from './TicketsCard';
import { Tabs } from '@material-ui/core';
import { Tab } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import ContactsCard from 'src/components/ContactsCard';
import { f_s } from 'src/views/auth/registrationView/FormFields';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import DashDeoTable from './DashDeoTable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  tabsContainer:{
    overflow:'hidden'
  },
  tabs:{
    marginBottom:12,
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const mapStateToProps = function(state){

  return {
    user:state.user
  }
}

const Dashboard = (props) => {
  const classes = useStyles();
  const [attesa,setAttesa] = useState([]);
  const [inCorso,setInCorso] = useState([]);
  const [terminate,setTerminate] = useState([]);

  const [deoInCorso,setDeoInCorso] = useState([]);
  const [deoTerminate,setDeoTerminate] = useState([]);

  const [contatti,setContatti] = useState([]);
  const [querying, setQuerying] = useState(true); 
  const [tickets, setTickets] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [payment_tot, setPaymentTot] = useState(0);
  const [deo_payment_tot, setDeoPaymentTot] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabDeo, setActiveTabDeo] = useState(0);
  const [incompleteAccount, SetIncompleteAccount] = useState(false);
  const {user}= props;
  const theme = useTheme();

  useEffect(()=>{
    setIsFetching(true);
    apiClient.get('/api/dashboard')
    .then(response => {
      console.log(response);
      setPaymentTot(response.data.payment_tot);
      setDeoPaymentTot(response.data.deo_payment_tot)
      setAttesa(response.data.inAttesa);
      setInCorso(response.data.inCorso);
      setTerminate(response.data.terminate);
      setTickets(response.data.tickets);
      setContatti(response.data.contatti);
      setDeoInCorso(response.data.deoAccettate);
      setDeoTerminate(response.data.deoTerminate);
      setQuerying(false);
    })
    .then(()=>setIsFetching(false))
    .catch(err=>{console.log(err);setIsFetching(false)});
  },[]);

  useEffect(()=>{
    if(user.role_id !==1 &&
      (!user.codice_univoco ||
      !user.cap || !user.citta || !user.indirizzo ||
      !user.phone || !user.forma_societaria ||
      !f_s.some(e =>e.value===user.forma_societaria)
      )
    ){
      //console.log(f_s.some(e =>e.value===user.forma_societaria),f_s,user.forma_societaria);
      SetIncompleteAccount(true);
    }
  },[user])

  const updateOrdine = (id,stato,value)=>{
    if(stato==2){
      var index = attesa.findIndex(el => el.id ==id);

      setAttesa([
        ...attesa.slice(0,index),
        {
          ...attesa[index],
          payment:{
            ...attesa[index].payment,
            data_fatturazione:value
          }
        },
        ...attesa.slice(index+1)
      ])
    }

    if(stato==3){
      var index = inCorso.findIndex(el => el.id ==id);

      setInCorso([
        ...inCorso.slice(0,index),
        {
          ...inCorso[index],
          payment:{
            ...inCorso[index].payment,
            data_fatturazione:value
          }
        },
        ...inCorso.slice(index+1)
      ])
    }

    if(stato==4){
      var index = terminate.findIndex(el => el.id ==id);

      setTerminate([
        ...terminate.slice(0,index),
        {
          ...terminate[index],
          payment:{
            ...terminate[index].payment,
            data_fatturazione:value
          }
        },
        ...terminate.slice(index+1)
      ])
    }
  }

  const updateDeo = (id,stato,value)=>{
    if(stato==2){
      var index = deoInCorso.findIndex(el => el.id ==id);

      setDeoInCorso([
        ...deoInCorso.slice(0,index),
        {
          ...deoInCorso[index],
          payment:{
            ...deoInCorso[index].payment,
            data_fatturazione:value
          }
        },
        ...deoInCorso.slice(index+1)
      ])
    }

    if(stato==3){
      var index = deoTerminate.findIndex(el => el.id ==id);

      setDeoTerminate([
        ...deoTerminate.slice(0,index),
        {
          ...deoTerminate[index],
          payment:{
            ...deoTerminate[index].payment,
            data_fatturazione:value
          }
        },
        ...deoTerminate.slice(index+1)
      ])
    }

  }

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChangeDeo = (event, newValue) => {
    setActiveTabDeo(newValue);
  };

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Grid container spacing={3} >

          {incompleteAccount &&
            <Grid item xs={12}>
              <Alert 
              severity="warning"
              action={
                <Button color="inherit" size="small" component={Link} to="/account">
                  Completa
                </Button>
              }
              >
                <AlertTitle>Completa account</AlertTitle>
                Sembra che <strong>mancano</strong> alcune informazioni al tuo account! Inserendole renderai più efficiente la comunicazione con il laboratorio 
              </Alert>
            </Grid>
          }

          <Grid
            item
            md={user.role_id==1? 3: 3}
            sm={6}
            xs={12}
          >
            <NuovaAnalisiCard />
          </Grid>

          
          <Grid
            item
            md={user.role_id==1? 3: 3}
            sm={6}
            xs={12}
          >
            <PaymentsCard payment_tot={payment_tot} type="analisi"/>
          </Grid>

          {deo_payment_tot!= 0 && 
          <Grid
            item
            md={user.role_id==1? 3: 3}
            sm={6}
            xs={12}
          >
            <PaymentsCard payment_tot={deo_payment_tot} type="deodorazione"/>
          </Grid>
          }

          {tickets.length !==0 &&
          <Grid
            item
            md={user.role_id==1? 3: 3}
            sm={6}
            xs={12}
          >
            <TicketsCard tickets={tickets}/>
          </Grid>
          }
        </Grid>

        <Grid container spacing={3} >

          <Grid item md={user.role_id==1? 9: 12} xs={12} >
            

            <Paper className={classes.tabsContainer}>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabs}
                scrollButtons="on"
                variant="scrollable"
              >
                <Tab label="Analisi in attesa dei campioni" />
                <Tab label="Analisi in corso" />
                <Tab label="Analisi terminate" />
              </Tabs>
              
              <TabPanel value={activeTab} index={0} className={classes.tabsContainer}>
                <DashOrderTable updateOrdine={updateOrdine} isFetching={isFetching} analisi={attesa} kind="attesa" title="Analisi in attesa dei campioni" filter={2}/>
              </TabPanel>

              <TabPanel value={activeTab} index={1} >
                <DashOrderTable updateOrdine={updateOrdine} isFetching={isFetching} analisi={inCorso} kind="inCorso" title="Analisi in corso" filter={3}/>
              </TabPanel>

              <TabPanel value={activeTab} index={2} >
                <DashOrderTable  updateOrdine={updateOrdine} isFetching={isFetching} analisi={terminate} kind="terminate" title="Analisi terminate" filter={4}/>
              </TabPanel>

            
            </Paper>

            {(deoInCorso.length>0 || deoTerminate.length>0) &&

              <Paper className={classes.tabsContainer} style={{marginTop:16}}>
                <Tabs
                  value={activeTabDeo}
                  onChange={handleChangeDeo}
                  indicatorColor="primary"
                  textColor="primary"
                  className={classes.tabs}
                  scrollButtons="on"
                  variant="scrollable"
                >
                  {/* <Tab label="Deodorazioni in attesa" /> */}
                  <Tab label="Deodorazioni in corso" />
                  <Tab label="Deodorazioni terminate" />
                </Tabs>
                
                {/* <TabPanel value={activeTab} index={0} className={classes.tabsContainer}>
                  <DashOrderTable isFetching={isFetching} analisi={attesa} kind="attesa" title="Analisi in attesa dei campioni" filter={2}/>
                </TabPanel> */}

                <TabPanel value={activeTabDeo} index={0} >
                  <DashDeoTable updateDeo={updateDeo} isFetching={isFetching} analisi={deoInCorso} kind="inCorso" title="Deodorazioni in corso" filter={2}/>
                </TabPanel>

                <TabPanel value={activeTabDeo} index={1} >
                  <DashDeoTable updateDeo={updateDeo} isFetching={isFetching} analisi={deoTerminate} kind="terminate" title="Deodorazioni terminate" filter={3}/>
                </TabPanel>

              
              </Paper>
            }
          </Grid>

          
          

          {user.role_id==1 &&
          <Grid item md={3} xs={12}>
            <ContactsCard contatti={contatti}/>
          </Grid>
          }
          

        </Grid>
      </Container>
    </Page>
  );
};

export default connect(mapStateToProps)(Dashboard);
