import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import InputText from 'src/components/forms/InputText';
import apiClient from 'src/utils/apiClient';

const PacchettoForm = (props)=>{
    const {setPacchetto,nome,setNome,analisi,selected,setSelected,prezzo,setPrezzo,modifica,annullaModifiche, ...other} = props;
    

    return(
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Typography>Aggiungi una nuovo pacchetto di analisi</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <InputText label="Nome pacchetto" value={nome} handleChange={setNome} /> 
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <InputText label="Prezzo" value={prezzo} handleChange={setPrezzo} inputProps={{pattern: '[1-9][0-9]*'}}/> 
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" flexDirection="column">
                    {analisi.map(el=>
                        <FormControlLabel
                        control={
                            <Checkbox 
                            checked={selected.includes(el.id)}
                            onChange={e=>setSelected(e)}
                            value={el.id}
                            color="primary"
                            />
                        }
                        label={el.nome}
                        key={el.id}
                        {...other}
                        />
                    )}

                </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Button variant="contained" color="primary" onClick={()=>setPacchetto(nome)}>AGGIUNGI</Button>
                {modifica && <Button onClick={()=>annullaModifiche()}>ANNULLA MODIFICHE</Button>}
            </Grid>
        </Grid>
    );
}

export default PacchettoForm;