import { Box, useTheme } from '@material-ui/core';
import React from 'react';
import { Radar } from 'react-chartjs-2';
import { avg, cannabinoidi, cannabinoidi_tot } from 'src/utils/const';


const RadarAvgs = (props)=>{
    const theme = useTheme();
    const {g1,g2, isTot} = props;
    const label = cannabinoidi;
    const label_tot = cannabinoidi_tot;

    const avgCalc = (g)=>{
        let medie = [];
        const lbl = isTot ? label_tot : label;
        for (let i = 0; i < lbl.length; i++) {
            const l = lbl[i];
            medie.push( avg( g.map(r =>r.referto.totali[l]) ) );
        }
        return medie;
    }

    const data={
        labels:isTot ? label_tot : label,
        datasets:[
            {
                label:"Gruppo 1",
                data:avgCalc(g1),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },

            {
                label:"Gruppo 2",
                data:avgCalc(g2),
                backgroundColor: 'rgba(0, 99, 132, 0.2)',
                borderColor: 'rgba(0, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    }

    const options = {
        scale: {
          ticks: { beginAtZero: true },
        },
        responsive:true,
        maintainAspectRatio:false,
        tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
          }
      };

    return (
        <Box
        position="relative"
        height="400px"
        width="100%"
        >
            <Radar data={data} options={options}/>
        </Box>
        
    );
    
}

RadarAvgs.defaultProps={
    isTot:false
}

export default RadarAvgs;