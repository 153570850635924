import { makeStyles, Tooltip } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
    arrow:{
        color: '#fff',
        "&::before": {
            border: '1px solid #dadde9',
        }
    }
}))(Tooltip);

const useStyles = makeStyles(()=>({
    ul:{
      listStyle:'none'
    }
}))

const TicketTooltip = (props) =>{
    const {righe}=props;
    const classes = useStyles();

    const renderToltip = (righe) => {
        const codici = [];
        for (let i = 0; i < righe.length; i++) {
            const riga = righe[i];
            if(riga.with_ticket){
                let j = codici.findIndex(el=> el.codice === riga.with_ticket.codice)
                if(j!== -1){
                    codici[j]= {...codici[j],n: codici[j].n+1}
                }else{
                    codici.push({
                        codice: riga.with_ticket.codice,
                        n:1
                    })
                }
            }
        }

        return(
            <ul className={classes.ul}>
                {codici.map(el=>
                    <li key={el.codice}>
                        <span>{el.codice + " ("+el.n+")"}</span>
                    </li>
                )}
            </ul>
        )
    }

    return (
        <HtmlTooltip
            title={
            <React.Fragment>
                <Typography color="inherit" variant="overline">TICKET USATI</Typography>
                {renderToltip(righe)}
            </React.Fragment>
            }
            arrow={true}
        >
            <i>Tickets</i>
        </HtmlTooltip>
    );
}

TicketTooltip.defaultProps={
    righe:[]
}

export default TicketTooltip;