import * as Yup from 'yup';
import moment from 'moment';
import checkoutFormModel from './checkoutFormModel';
import { f_s } from '../FormFields';

const {
  formField: {
    first_name,
    last_name,
    email,
    azienda,
    forma_societaria,
    phone,
    p_iva,
    codice_fiscale,
    codice_univoco,
    pec,
    password,
    passwordConfirm,
    cap,
    citta,
    indirizzo
  }
} = checkoutFormModel;

//const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

export default [
  Yup.object().shape({
    [first_name.name]: Yup.string().required(`${first_name.requiredErrorMsg}`),
    [last_name.name]: Yup.string().required(`${last_name.requiredErrorMsg}`),
    [email.name]: Yup.string().email(`${email.invalidErrorMsg}`).required(`${email.requiredErrorMsg}`),
    [phone.name]: Yup.string().required(`${phone.requiredErrorMsg}`),
    [password.name]: Yup.string().max(255).required(`${password.requiredErrorMsg}`),
    [passwordConfirm.name]: Yup.string().oneOf([Yup.ref(password.name)],`${passwordConfirm.invalidErrorMsg}`),
  }),
  Yup.object().shape({
    [azienda.name]: Yup.string().required(`${azienda.requiredErrorMsg}`),
    [forma_societaria.name]: Yup.string().required(`${forma_societaria.requiredErrorMsg}`),
    [p_iva.name]: Yup.string().when(forma_societaria.name,{
      is: (val) => (val === f_s[2].value || val === f_s[1].value),
      then: Yup.string().required(`${p_iva.requiredErrorMsg}`),
    }),
    [codice_fiscale.name]: Yup.string().when(forma_societaria.name,{
      is: (val) => (val === f_s[0].value || val === f_s[1].value),
      then: Yup.string().required(`${codice_fiscale.requiredErrorMsg}`),
    }), 
    [codice_univoco.name]: Yup.string()
    .min(6,"Il codice univovo deve avere almeno 6 cifre")
    .max(7,"Il codice univoco deve avere almassimo 7 cifre")
    .required(`${codice_univoco.requiredErrorMsg}`),
    [pec.name]: Yup.string().email(`${pec.invalidErrorMsg}`),
    [cap.name]: Yup.string().required(`${cap.requiredErrorMsg}`),
    [citta.name]: Yup.string().required(`${citta.requiredErrorMsg}`),
    [indirizzo.name]: Yup.string().required(`${indirizzo.requiredErrorMsg}`),
  })
];