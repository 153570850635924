import React, { useState } from 'react';
import clsx from 'clsx';
//import moment from 'moment';
//import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
// import OrdiniTable from '../OrdiniTable.js';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Avatar,
  Divider,
  makeStyles,
  colors
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import FolderOpenRoundedIcon from '@material-ui/icons/FolderOpenRounded';
import SpeedRoundedIcon from '@material-ui/icons/SpeedRounded';
import {Link} from 'react-router-dom';
import { loading } from 'src/redux/actions.js';
import { CircularProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import DeodorazioniTable from '../DeodorazioniTable.js';

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  attesa:{
    backgroundColor:colors.red[600]
  },
  terminate:{
    backgroundColor:colors.green[600]
  },
  inCorso:{
    backgroundColor:colors.blue[600]
  },
  attesaH:{
    backgroundColor:"#f9d5d5"
  },
  terminateH:{
    backgroundColor:"#DDF1E2"
  },
  inCorsoH:{
    backgroundColor:"#d4e8fc"
  },
  title:{
    letterSpacing:"0.0201rem",
    fontSize:"1.1rem"
  },
  loader:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  flex_center:{
    display:"flex",
    alignItems:"center",
  }
}));

const TableIcon = (props) =>{
  const classes = useStyles();
  if(!props.hasOwnProperty('kind')) return null;
  return (
    <Avatar className={props.kind=="attesa" ? classes.attesa : props.kind=="terminate" ? classes.terminate : classes.inCorso}>

      {props.kind=="attesa" ?
        <HourglassEmptyRoundedIcon />
      :
        props.kind=="terminate" ?
        <FolderOpenRoundedIcon />
        :
        <SpeedRoundedIcon />
      }
    </Avatar>
  );
}

const DashDeoTable = ({ className,analisi,isFetching ,updateDeo,...rest }) => {
  const classes = useStyles();

  var to={
      pathname:"/deodorazioni/",
    };

  if(rest.hasOwnProperty('filter') ){
    const search = '?qid='+rest.filter ;
    to.search = search;
  }

  const options = {
    textLabels: {
      body: {
          noMatch: isFetching ?
              <div className={classes.loader}>
                <CircularProgress size={25} />
                <Typography component="span">Caricamento dati in corso...</Typography>
              </div>
              
              :
              
              'Nessuna analisi da mostrare!',
      },
    },
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      raised
    >
        <CardHeader 
        title={rest.title} 
        titleTypographyProps={{
          className:classes.title
        }}
        className={rest.kind=="attesa" ? classes.attesaH : rest.kind=="terminate" ? classes.terminateH : classes.inCorsoH}
        avatar={<TableIcon kind={rest.kind}/>}
        />
      <Divider />
      {/* <PerfectScrollbar> */}
        {/* <Box minWidth={800}> */}
          <DeodorazioniTable updateDeo={updateDeo} isFetching={isFetching} analisi={analisi} opt={options}/>
        {/* </Box> */}
      {/* </PerfectScrollbar> */}
      {rest.hasOwnProperty('filter') &&
        <Box
          display="flex"
          justifyContent={rest.kind=="terminate" ? "space-between":"flex-end"}
          p={2}
        >
          {rest.kind=="terminate" && 
          <Typography variant="caption" color="primary" className={classes.flex_center}>
            <Icon size="small">info</Icon>
            <span><b>Deodorazioni terminate nell'ultimo mese! </b><br/> per vedere tutte le Deodorazioni clica su VEDI TUTTE</span>
          </Typography>
          }
          
          <Button
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="text"
            component={Link}
            to={to}
          >
            Vedi tutte
          </Button>
        </Box>
      }
    </Card>
  );
};

DashDeoTable.propTypes = {
  className: PropTypes.string
};

export default DashDeoTable;
