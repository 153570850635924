import { Grid } from "@material-ui/core";
import React from "react";
import DashDeoTable from "src/views/reports/DashboardView/DashDeoTable";

const DeodorazioniTab = (props)=>{
    const {inCorso,terminate,updateDeo}=props;
    
    return (
        <>
        <Grid item xs={12} style={{marginBottom:24}}>
            <DashDeoTable updateDeo={updateDeo} isFetching={false} analisi={inCorso} kind="inCorso" title="Deodorazioni in corso"/>
        </Grid>

        <Grid item xs={12} style={{marginBottom:24}}>
            <DashDeoTable updateDeo={updateDeo} isFetching={false} analisi={terminate} kind="terminate" title="Deodorazioni terminate"/>
        </Grid>
        
        </>
    );
}

export default DeodorazioniTab;