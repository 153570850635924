import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import InputText from 'src/components/forms/InputText';
import apiClient from 'src/utils/apiClient';

const AddAnalisiForm = (props)=>{
    const {setAnalisi,nome,setNome} = props;
    

    return(
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Typography>Aggiungi una nuova tipologia di analisi</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <InputText label="Nome analisi" value={nome} handleChange={setNome} /> 
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Button variant="contained" color="primary" onClick={()=>setAnalisi(nome)}>AGGIUNGI</Button>
            </Grid>
        </Grid>
    );
}

export default AddAnalisiForm;