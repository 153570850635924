import { makeStyles, Paper, Grid, Box, Typography, Icon, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { Check, Edit3, Tag, Trash2 } from 'react-feather';
import { SimpleModale } from "src/modali/ModaleConferma";

const useStyles = makeStyles(theme => ({
    card:{
        padding:'4px 8px'
    },

    analisi:{
        padding:'4px 8px',
        borderTop:0,
        borderRight:0,
        borderBottom:0,
        borderLeft:1,
        borderColor:theme.palette.primary.main,
        borderStyle:"solid",
        '@media (max-width:599px)':{
            borderLeft:0,
            borderTop:1,
            borderColor:theme.palette.primary.main,
            borderStyle:"solid",
        }
        
    },

    last:{
        backgroundColor: theme.palette.primary.lighter,
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        borderTop:0,
        borderRight:0,
        borderBottom:0,
        borderLeft:1,
        borderColor:theme.palette.primary.main,
        borderStyle:"solid",
        '@media (max-width:599px)':{
            borderLeft:0,
            borderTop:1,
            borderColor:theme.palette.primary.main,
            borderStyle:"solid",
        }
    },
    sample:{
        borderStyle:'solid',
        borderColor: theme.palette.primary.main,
        borderTop:0,
        borderBottom:0,
        borderLeft:5,
        borderRight:0,
        marginBottom:18,
    },
    sconto:{
        textDecoration:"line-through"
    }  
}));


const SampleCard = (props)=>{
    const classes = useStyles();
    const {riga,analisi,index,convenzione} = props;
    const [elevation, setElevation] = useState(10);
    const [isNoteOpen, setNoteOpen] = useState(false);


    const mouseOver = ()=>setElevation(20);
    const mouseOut =  ()=>setElevation(10); 

    return (
        <Paper elevation ={elevation} className={classes.sample} onMouseOver={mouseOver} onMouseOut={mouseOut}>
            <Grid container spacing={0}>

                <Grid container item sm={8} xs={12}>

                    <Grid item xs={12} className={classes.card}>
                    <Box
                    color="primary.main"
                    fontFamily="h6.fontFamily"
                    fontSize="h5.fontFamily"
                    fontWeight={600}
                    borderBottom={1}
                    borderColor="#ccc"
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    p={1}
                    >
                        {riga.nome_campione + " ("+riga.grammatura +" gr.)"}
                        {riga.note!=="" && 
                        <>
                        <IconButton onClick={()=>setNoteOpen(true)} size="small">
                            <Icon style={{color:"#666"}}>note_alt</Icon>
                        </IconButton>
                        <SimpleModale open={isNoteOpen} close={()=>setNoteOpen(false)}title={"Note campione "+riga.nome_campione}testo={riga.note}/>
                        </>
                        }
                        
                    </Box>

                    
                    
                    </Grid>

                    <Grid item xs={6} sm={3} className={classes.card}>
                    <Typography variant="overline" color="secondary">Cartellino: </Typography>
                    <Typography variant="subtitle2" className="ellipsis">{riga.cartellino.codice? riga.cartellino.codice : (riga.cartellinoText ? riga.cartellinoText : "-")}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} className={classes.card}>
                    <Typography variant="overline" color="secondary">Varietà: </Typography>
                    <Typography variant="subtitle2" className="ellipsis">{riga.cartellino.varieta.nome ? riga.cartellino.varieta.nome : "-" }</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} className={classes.card}>
                    <Typography variant="overline" color="secondary">Lotto: </Typography>
                    <Typography variant="subtitle2" className="ellipsis">{riga.lotto_coltivazione? riga.lotto_coltivazione : "-"}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} className={classes.card}>
                    <Typography variant="overline" color="secondary">Tipologia: </Typography>
                    <Typography variant="subtitle2" className="ellipsis">{riga.descrizione? riga.descrizione : "-"}</Typography>
                    </Grid>
                    
                </Grid>

                <Grid item sm={3} xs={9} className={classes.analisi}>
                    <Typography variant="body2">
                    {analisi.nome}
                    </Typography>
                    {riga.withTicket ? 
                    <Icon>confirmation_number</Icon>
                    :
                    <> 
                    <Typography variant="body2" className={convenzione ? classes.sconto : null}>
                        {analisi.prezzo +"€"}
                    </Typography>

                    {convenzione && 
                    <Typography variant="body2">
                        {convenzione.extras.find(el => el.id === analisi.id ).pivot.prezzo +"€"}
                    </Typography>
                    }
                    </>
                    }
                </Grid>

                <Grid item sm={1} xs={3} className={classes.last}>
                    
                    <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e)=>props.handleModify(index)}
                    >
                        <Edit3 size={18} />
                    </IconButton>

                    <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e)=>props.handleDeleteRiga(index)}
                    >
                    <Trash2 size={18} />
                    </IconButton>

                    
                </Grid>

            </Grid>
        
        </Paper>
    );
}

export default SampleCard;