import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { Box, IconButton } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles(()=>({
  avatar: {
      height: 60,
      width: 60,
      marginRight:16
  },
  marginBot:{
      marginBottom:24
  }
}))

const AnalisiHeader =(props)=>{
  const classes = useStyles();
  return (
    <Grid container spacing={3} >
      {/* <Grid item  sm={2} xs={6} >
        <Typography variant="h6">Cliente</Typography>
      </Grid> */}
      <Grid item  sm={12} xs={6} >
        {/* {props.analisi.user &&
        <Typography variant="subtitle1" component={Link} to={`/customer/${props.analisi.user.id}`}>{props.analisi.user.azienda}</Typography>
        } */}
        {props.analisi.user &&
          <Box alignItems="center" display="flex" flexDirection="row">
            {props.analisi.user.path ? 
              <Avatar className={classes.avatar} src={props.analisi.user.path}/>
              :
              <Avatar className={classes.avatar}>
                  {props.analisi.user.cod? props.analisi.user.cod.toUpperCase(): ""}
              </Avatar>
            }
            {props.isAdmin ?
            <Typography color="textPrimary" variant="h3" component={Link} to={`/customer/${props.analisi.user.id}`}>{props.analisi.user.azienda}</Typography>
            :
            <Typography color="textPrimary"  variant="h3" > {props.analisi.user.azienda} </Typography>
            }
            
          </Box>
        }
      </Grid>

      <Grid item  sm={4} xs={12} >
        <Typography variant="h6" color="primary">Codice Interno</Typography>
        {props.analisi.codice &&
        <Typography variant="subtitle1">{props.analisi.codice}</Typography>
        }
      </Grid>
      {/* <Grid item  sm={4} xs={6} >
        {props.analisi.codice &&
        <Typography variant="subtitle1">{props.analisi.codice}</Typography>
        }
      </Grid> */}

      <Grid item  sm={4} xs={12} >
        <Typography variant="h6" color="primary">N° campioni</Typography>
        {props.analisi.righe &&
        <Typography variant="subtitle1">{props.analisi.righe.length}</Typography>
        }
      </Grid>
      <Grid item  sm={4} xs={12} >
        {props.analisi.convenzione &&
        <>
          <Typography variant="h6" color="primary">Convenzione</Typography>
          <Typography variant="subtitle1">{props.analisi.convenzione.nome_convenzione}</Typography>
        </>
        }
      </Grid>
      {/* <Grid item  sm={4} xs={6} >
        {props.analisi.righe &&
        <Typography variant="subtitle1">{props.analisi.righe.length}</Typography>
        }
      </Grid> */}

      <Grid item  sm={4} xs={12} >
        <Typography variant="h6" color="primary">Data Ordine</Typography>
        {props.analisi.data_acquisto &&
        <Typography variant="subtitle1">{moment(props.analisi.data_acquisto).format("DD/MM/YYYY")}</Typography>
        }
      </Grid>
      {/* <Grid item  sm={4} xs={6} >
        {props.analisi.data_acquisto &&
        <Typography variant="subtitle1">{moment(props.analisi.data_acquisto).format("DD/MM/YYYY")}</Typography>
        }
      </Grid> */}

      <Grid item  sm={4} xs={12} >
        <Typography variant="h6" color="primary">Data Accettazione</Typography>
        {props.analisi.data_accettazione &&
        <Typography variant="subtitle1">{moment(props.analisi.data_accettazione).format("DD/MM/YYYY")}</Typography>
        }
      </Grid>
      {/* <Grid item  sm={4} xs={6} >
        {props.analisi.data_accettazione &&
        <Typography variant="subtitle1">{moment(props.analisi.data_accettazione).format("DD/MM/YYYY")}</Typography>
        }
      </Grid> */}
      {props.analisi.payment &&
      <Grid item  sm={4} xs={12} >
        <Typography variant="h6" color="primary">Data Fatturazione</Typography>
        {props.isAdmin ? 
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label=""
          format="DD/MM/YYYY"
          value={props.dataFattura}
          onChange={props.handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          />
        </MuiPickersUtilsProvider>
          
        :
        (props.analisi.payment.data_fatturazione ?
          <Typography variant="subtitle1">{moment(props.analisi.payment.data_fatturazione).format("DD/MM/YYYY")}</Typography>
          :
          <Typography component="span" variant="subtitle1"><i>Fattura non emessa</i></Typography>
        )
        }

      </Grid>
      }
      
    </Grid>
  );
}

export default AnalisiHeader;
