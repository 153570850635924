import { makeStyles, Tooltip } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import theme from 'src/theme';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
    arrow:{
        color: '#fff',
        "&::before": {
            border: '1px solid #dadde9',
        }
    }
}))(Tooltip);

const useStyles = makeStyles(()=>({
    ul:{
      listStyle:'none'
    },
    li:{
      display:"flex",
      maxWidth:200,
      justifyContent:"space-between",
      alignItems:"center",
      marginBottom:8
    },
    nomeExtra:{
        maxWidth:140,
        textOverflow:"wrap"
    },
    circle:{
        width:15,
        height:15,
        borderRadius:50,
        backgroundColor: theme.palette.primary.main,
        boxShadow:theme.shadows[3]
    }
}))

const ConvenzioneTooltip = (props) =>{
    const {convenzione}=props;
    const classes = useStyles();

    const renderToltip = (extras) => {

        return(
            <ul className={classes.ul}>
                {extras.map(el=>
                    <li key={el.id} className={classes.li}>
                        <span className={classes.nomeExtra}>{el.nome}</span>
                        <span>{el.pivot.prezzo}</span>
                    </li>
                )}
            </ul>
        )
    }

    return (
        <HtmlTooltip
            title={
            <React.Fragment>
                <Typography color="inherit" variant="overline">{convenzione.nome_convenzione}</Typography>
                {renderToltip(convenzione.extras)}
            </React.Fragment>
            }
            arrow={true}
        >
            <div className={classes.circle}></div>
        </HtmlTooltip>
    );
}

ConvenzioneTooltip.defaultProps={
    righe:[]
}

export default ConvenzioneTooltip;