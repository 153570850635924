import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import React from 'react';
import InputText from 'src/components/forms/InputText';
import InputSelect from 'src/components/forms/InputSelect';
import { f_s } from 'src/views/auth/registrationView/FormFields';

const NuovoUtente = (props) =>{
    const {user,setUser} = props;

    return(
        <Grid container spacing={0}>
            <Grid item md={4} xs={12}>
              <InputText label="Nome *" value={user.first_name} handleChange={setUser} withEvent name="first_name"/>
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText label="Cognome *" value={user.last_name} handleChange={setUser} withEvent name="last_name"/>
            </Grid>
            <Grid item md={4} xs={12}>
              <InputText label="Tel" value={user.phone} handleChange={setUser} withEvent name="phone"/>
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText label="Email *" value={user.email} handleChange={setUser} withEvent name="email"/>
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText label="Nome Azienda *" value={user.azienda} handleChange={setUser} withEvent name="azienda"/>
            </Grid>

            {/* <Grid item md={4} xs={12}>
              <InputSelect 
              label="Forma societaria*" 
              value={user.forma_societaria }
              handleChange={setUser} 
              withEvent 
              name="forma_societaria" 
              items={[
                {value:"Az. Agricola",label:"Azienda Agricola"},
                {value:"S.r.l.",label:"S.r.l."},
                {value:"S.n.c.",label:"S.n.c."},
                {value:"S.a.s.",label:"S.a.s."},
                {value:"Privato",label:"Privato"},
                {value:"Altro",label:"Altro"},
              ]}
              />
            </Grid> */}

            <Grid item md={4} xs={12}>
              <InputSelect 
              label="Forma societaria*" 
              value={user.forma_societaria }
              handleChange={setUser} 
              withEvent 
              name="forma_societaria" 
              items={f_s}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText label="Partita IVA *" value={user.p_iva} handleChange={setUser} withEvent name="p_iva"/>
            </Grid>
            <Grid item md={4} xs={12}>
              <InputText label="Codice Fiscale *" value={user.codice_fiscale} handleChange={setUser} withEvent name="codice_fiscale"/>
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText label="Codice Univoco *" value={user.codice_univoco} handleChange={setUser} withEvent name="codice_univoco"/>
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText label="Città *" value={user.citta} handleChange={setUser} withEvent name="citta"/>
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText label="Indirizzo *" value={user.indirizzo} handleChange={setUser} withEvent name="indirizzo"/>
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText label="CAP *" value={user.cap} handleChange={setUser} withEvent name="cap"/>
            </Grid>
        </Grid>
    );
}

export default NuovoUtente;