import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  Button
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import { NavLink as RouterLink } from 'react-router-dom';
import { FilePlus } from 'react-feather';
import { Icon } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display:"flex",
    justifyContent:"flex-start",
    position:"relative",
    backgroundColor:"#d7f6df",
    '&::after':{
      content:'" "',
      position:'absolute',
      top:0,
      bottom:0,
      right:0,
      backgroundColor: "#27a769",
      borderLeft:100,
      display: "block",
      borderStyle: "solid",
      borderTop: 0,
      borderRight: 0,
      borderBottom: 0,
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      borderColor: "#27a769",
      boxShadow: "-3px 0px 16px -4px #27a769"

    }

  },
  avatar: {
    backgroundColor: "#27a769",
    height: 56,
    width: 56,
    zIndex:2,
    position:'absolute',
    top:"48%",
    transform:"translateY(-50%)",
    right:14,
  
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  button: {
    // color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    
    letterSpacing: 0,
    padding: '4px 8px',
    textTransform: 'none',
    width: '90%'
  },
  content:{
    flexBasis:"100%",
    display:"flex",
    paddingRight:100
  },
  contentContainer:{
    flexBasis:"100%",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"space-between"
  }
}));

const NuovaAnalisiCard = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent className={classes.content}>
        <div className={classes.contentContainer}>
          <Typography color="textSecondary" gutterBottom variant="h6" >
            Prenota Analisi
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            component={RouterLink}
            to="/analisi/nuova">
                Nuova Analisi
          </Button>  
        </div>
        
        <Avatar className={classes.avatar}>
          {/* <FilePlus size={48}/> */}
          <Icon style={{fontSize:48}}>science</Icon>
        </Avatar>
        
        <Box mt={2} display="flex" alignItems="center" >
          <Typography color="textSecondary" variant="caption" >
            
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

NuovaAnalisiCard.propTypes = {
  className: PropTypes.string
};

export default NuovaAnalisiCard;