import { InputAdornment, Paper, TableCell } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { Switch } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React from 'react';
import InputText from 'src/components/forms/InputText';


const OrdineDeo = (props)=>{
    const {deo, setDbCoding, setSendMail,setPesoRilevato,setPrezzo}=props;
    let tot=0;
    return (
        <Grid item xs={12}>
            <Paper elevation={4} style={{padding:"12px 8px"}}>
                
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Typography>Deodorazione accettata il {deo.data_accettazione}</Typography>
                    </Grid>

                    <Grid container item xs={12} style={{margin:"8px 0px"}}>
                        <Grid xs={12}>
                            <FormControlLabel 
                            control={
                                <Switch
                                checked={deo.useDbCoding}
                                onChange={(event)=>setDbCoding(event.target.checked)}
                                color="primary"
                                />
                            }
                            label="Usa codici da excel"
                            labelPlacement="start"
                            />
                        </Grid>

                        <Grid xs={12}>
                            <FormControlLabel 
                            control={
                                <Switch
                                checked={deo.useDbCoding}
                                onChange={(event)=>setSendMail(event.target.checked)}
                                color="primary"
                                />
                            }
                            label="Invia notifica via mail"
                            labelPlacement="start"
                            />
                        </Grid>
                    </Grid>


                    <Grid item xs={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell variant="head">Codice cliente</TableCell>
                                        <TableCell variant="head">DDT</TableCell>
                                        <TableCell variant="head">Varietà</TableCell>
                                        <TableCell variant="head">Cartellino</TableCell>
                                        <TableCell variant="head">Codice interno</TableCell>
                                        <TableCell variant="head">Peso dichiarato</TableCell>
                                        <TableCell variant="head">Peso rilevato</TableCell>
                                        <TableCell variant="head">Prezzo</TableCell>
                                    </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                    {deo.materie.map((m,i) =>{
                                        tot += (parseFloat(m.peso_rilevato ? m.peso_rilevato : m.peso_dichiarato) * parseFloat(m.prezzo));
                                        console.log((m.peso_rilevato ? m.peso_rilevato : m.peso_dichiarato) *m.prezzo);
                                        return(
                                            <TableRow key={i}>
                                                <TableCell>{m.nome_materia}</TableCell>
                                                <TableCell>{m.ddt_ingresso}</TableCell>
                                                <TableCell>{m.varieta}</TableCell>
                                                <TableCell>{m.cartellino}</TableCell>
                                                <TableCell>{m.codice}</TableCell>
                                                <TableCell>{m.peso_dichiarato} Kg</TableCell>
                                                <TableCell>
                                                    <InputText
                                                    label=""
                                                    value={m.peso_rilevato}
                                                    handleChange={e => setPesoRilevato(e,i)}
                                                    size="small"
                                                    margin="dense"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                                                    }}
                                                    />
                                                    
                                                </TableCell>

                                                <TableCell>
                                                    <InputText
                                                    label=""
                                                    value={m.prezzo}
                                                    handleChange={e => setPrezzo(e,i)}
                                                    size="small"
                                                    margin="dense"
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">€/Kg</InputAdornment>,
                                                    }}
                                                    />
                                                </TableCell>

                                            </TableRow>
                                        )
                                    })}
                                    
                                    
                                    <TableRow size="small">
                                        <TableCell size="small" colSpan={6} rowSpan={3}></TableCell>
                                        <TableCell size="small" variant="head">IMPONIBILE</TableCell>
                                        <TableCell size="small">{(tot).toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow size="small"> 
                                        <TableCell size="small" variant="head">IVA</TableCell>
                                        <TableCell size="small">{(tot * 0.22).toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow size="small">
                                        <TableCell size="small" variant="head">TOT</TableCell>
                                        <TableCell size="small">{(tot * 1.22).toFixed(2)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

                
            </Paper>
        </Grid>
    )
}

export default OrdineDeo;