import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import red from '@material-ui/core/colors/red';
import cyan from '@material-ui/core/colors/cyan';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Icon from '@material-ui/core/Icon';
import { Copy } from 'react-feather';
import ModaleConferma from 'src/modali/ModaleConferma';
import ModaleCopiaOrdine from 'src/modali/ModaleCopiaOrdine';
import ReminderProforma from 'src/components/ReminderProforma';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: red[500],
    },
    secondary:{
      main:cyan[500]
    },

  },
  textSecondary:'#fff',
});
const ActionsPanel = (props)=>{
  const {isAdmin, handleDelete,status,analisi,setResponse}= props;
  const [openDelete, setOpenDelete] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);

  const deleteAction =() =>{
    setOpenDelete(false);
    handleDelete();
  }

  return(
    <>
    <Grid container spacing={3} >
      <Grid item xs={12}>
        <Typography variant="h6">Azioni</Typography>
      </Grid>
      <Grid item xs={12}>
        <ThemeProvider theme={theme}>
          <Button onClick={()=>setOpenDelete(true)} disabled={status>2} variant="contained" color="primary" startIcon={<DeleteIcon />}>Elimina</Button>
        </ThemeProvider>
      </Grid>

      {isAdmin && 
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <Button onClick={()=>setOpenCopy(true)} variant="contained" color="secondary" startIcon={<Copy />} style={{color:'#fff'}}>Copia</Button>
          </ThemeProvider>
        </Grid>
      }
      {isAdmin && analisi.payment &&
        <Grid item xs={12}>
          <ReminderProforma id_payment={analisi.payment.id} id_user={analisi.user.id} />
        </Grid>
      }
      
    </Grid>

    <ModaleConferma 
    open={openDelete}
    close={()=>setOpenDelete(false)}
    title="Elimina Analisi"
    testo="Stai per eliminare un ordine. una volta eliminato non sarà più possibile recuperare i dati. Sei sicuro di volerlo eliminare?"
    subTesto ={<Typography>Per eliminare fare click su <span style={{color:'#27a74a'}}>Continua</span></Typography>}
    salva={deleteAction}
    />

    <ModaleCopiaOrdine 
    open={openCopy}
    close={()=>setOpenCopy(false)}
    ordine={analisi}
    setResponse={setResponse}
    />

    </>
  );
}
export default ActionsPanel;
