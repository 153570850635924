import { FormControl, InputLabel, makeStyles, MenuItem, Select,FormHelperText } from '@material-ui/core';
import React from 'react';


const useStyles = makeStyles((theme) => ({
    selectInput:{
        minWidth:180
    }
}));

const InputSelect = (props)=>{
    const {label, items, value, handleChange,withEvent,margin,helperText,error,...other} = props;
    const classes = useStyles();
    return (
        <FormControl margin={margin} size="medium" error={error}>
            <InputLabel>{label}</InputLabel>
            <Select 
            value={value} 
            onChange={withEvent ? e => handleChange(e) : e => handleChange(e.target.value)} 
            className={classes.selectInput}
            {...other}
            >
                {items.map( el => <MenuItem value={el.value} key={el.value}>{el.label}</MenuItem>)}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}

InputSelect.defaultProps = {
    withEvent:false,
    margin:"normal",
    error:false,
    helperText:""
}

export default InputSelect;