import React from 'react';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

function CheckoutSuccess() {
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Grazie per esserti registrato
      </Typography>
      <Typography variant="subtitle1">
        La tua registrazione è andata a buon fine. Ti abbiamo inviato una email di conferma all'indirizzo da te indicato.
        usa la tua email e la password scelta per accedere 
      </Typography>
      <Button component={Link} to="/auth/login" color="primary" variant="contained">Accedi</Button>
    </React.Fragment>
  );
}

export default CheckoutSuccess;
