import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: 'rgba(39, 167, 74, 0.85)',
      lighter: '#D2EDD9'
    },
    secondary: {
      main: colors.indigo[500]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
      success: 'rgba(39, 167, 74, 0.85)',
      error:colors.red[500]
    },
    alert:{
      main: colors.orange[400]
    }
  },
  shadows,
  typography
});

export default theme;
