import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField } from './FormFields';


export default function AccountForm(props) {
  const {
    formField: {
      first_name,
      last_name,
      email,
      phone,
      password,
      passwordConfirm
    }
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dettagli Account
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField name={first_name.name} label={first_name.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={last_name.name} label={last_name.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={email.name} label={email.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={phone.name} label={phone.label} fullWidth />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <InputField name={password.name} label={password.label} fullWidth type="password"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={passwordConfirm.name} label={passwordConfirm.label} fullWidth type="password"/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}