import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import apiClient from 'src/utils/apiClient';
import { connect } from 'react-redux';
import {setUser} from 'src/redux/actions.js';
import { Grid } from '@material-ui/core';
import InputText from 'src/components/forms/InputText';
import ModaleConferma from 'src/modali/ModaleConferma';

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "#fff"
    }
  },
  avatar: {
    height: 100,
    width: 100
  }
}));

const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user))
});

const Convenzione = ({ className,user ,setUser,...rest }) => {
  const classes = useStyles();
  const [codice,setCodice] = useState("");
  const [err,setError] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const handleSubmit = ()=>{
    apiClient.put('api/terminaConvenzione/'+user.id)
    .then(response=>{
      setUser(response.data.user);
    })
    .catch(err=>console.log(err));
  }

  const codiceChange = (val)=>{
    if(err!="") setError("");
    setCodice(val);
  }

  const submit = ()=>{
    var post  = new URLSearchParams();
    post.append('codice', codice );

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    apiClient.put('api/set_convenzione_user/'+user.id, post,config)
    .then(response => {
      setUser(response.data.user);
    })
    .catch(err=>{
      console.log(err);
      setError("Codice non valido!");
    });
  }

  const deleteAction =() =>{
    setOpenDelete(false);
    handleSubmit();
  }

  return (
    <>
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        {/* <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          
        </Box> */}
        {user.convenzione ? 
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Typography color="textSecondary" gutterBottom variant="subtitle2" >
            Convenzione attiva
          </Typography>

          <Typography color="textPrimary" gutterBottom variant="h4" >
            {user.convenzione.nome_convenzione +" - "+ user.convenzione.codice_convenzione}
          </Typography>
          <Typography color="textSecondary" variant="overline" >
            {"attiva dal "+ moment(user.convenzione.pivot.inizio).format("DD/MM/Y")}
          </Typography>

          <Button onClick={()=>setOpenDelete(true)} color="primary" variant="contained">Esci dalla convenzione</Button>
        </Box>
        :
        <Grid container spacing={2} alignItems="center" justify="flex-start">
            <Grid item xs={12}>
                <Typography color="textPrimary" gutterBottom variant="h4" >Hai un codice convenzione?</Typography>
            </Grid>
            
            <Grid item xs="auto" zeroMinWidth>
                <InputText variant="filled" value={codice} label="Codice convenzione" handleChange={codiceChange} margin="dense" className={classes.root}/>
            </Grid>

            <Grid item xs="auto" zeroMinWidth>
                 
                <Button
                variant="contained"
                color="primary"
                onClick={submit}
                >
                Inserisci codice
                </Button>
                
            </Grid>

            <Grid item xs={12}>
                {err!=="" && <Typography variant="body2" color="error">{err}</Typography>}
            </Grid>
        </Grid>

        }
      </CardContent>
      <Divider />
      {/* <CardActions>
        <Button
          color="primary"
          fullWidth
          variant="text"
        >
          Upload picture
        </Button>
      </CardActions> */}
    </Card>

    <ModaleConferma 
    open={openDelete}
    close={()=>setOpenDelete(false)}
    title="Abbandona Convenzione"
    testo="Stai per abbandonare la convenzione. Una volta uscito dalla convenzione potrai iscriverti ad una nuova inserendo il codice. Sei sicuro di voler continuare?"
    subTesto ={<Typography>Per abbandonare fare click su <span style={{color:'#27a74a'}}>Continua</span></Typography>}
    salva={deleteAction}
    />

    </>
  );
};

Convenzione.propTypes = {
  className: PropTypes.string
};

export default connect(null,mapDispatchToProps)(Convenzione);
