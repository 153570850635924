import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import InputText from 'src/components/forms/InputText';
import InputSelect from 'src/components/forms/InputSelect';
import apiClient from 'src/utils/apiClient';
import { connect } from 'react-redux';
import {setUser} from 'src/redux/actions.js';
import { f_s } from 'src/views/auth/registrationView/FormFields';

const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user))
});

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className,user, ...rest }) => {
  const classes = useStyles();
  const {first_name,last_name,email,phone,citta,cap,indirizzo,azienda,p_iva,codice_fiscale,codice_univoco,forma_societaria}=user
  const [values, setValues] = useState({
    first_name: first_name,
    last_name: last_name,
    email: email,
    phone: phone ? phone : "",
    citta: citta ? citta : "",
    indirizzo: indirizzo ? indirizzo : "",
    azienda:azienda,
    p_iva:p_iva,
    codice_fiscale:codice_fiscale ? codice_fiscale : "",
    codice_univoco:codice_univoco ? codice_univoco : "0000000",
    cap:cap ? cap : "",
    forma_societaria:(forma_societaria && f_s.filter(el=>el.value === forma_societaria) )? forma_societaria : ""
  });

  console.log(forma_societaria,f_s.includes(forma_societaria));


  const [error,setError]  = useState({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      citta: "",
      indirizzo: "",
      azienda:"",
      p_iva:"",
      codice_fiscale:"",
      codice_univoco:"",
      cap:"",
      forma_societaria:""
  });

  const validate = () =>{
    let cnt_err = 0;
    let err={
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      citta: "",
      indirizzo: "",
      azienda:"",
      p_iva:"",
      codice_fiscale:"",
      codice_univoco:"",
      cap:"",
      forma_societaria:""
    }
    const ERROR = "campo richiesto";
    const ERROR_LENGTH = "deve essere di 6 o 7 caratteri"

    if(values.first_name === "") {err.first_name= ERROR; cnt_err++;}//return false;
    if(values.last_name === "") {err.last_name= ERROR; cnt_err++;}//return false;
    if(values.email === "") {err.email= ERROR; cnt_err++;}//return false;
    if(values.citta === "") {err.citta= ERROR; cnt_err++;}//return false;
    if(values.indirizzo === "") {err.indirizzo= ERROR; cnt_err++;}//return false;
    if(values.azienda === "") {err.azienda= ERROR; cnt_err++;}//return false;
    
    if(values.p_iva === "" && 
    (values.forma_societaria === f_s[2].value || values.forma_societaria === f_s[1].value)) 
    {err.p_iva= ERROR; cnt_err++;}//return false;

    if(values.codice_fiscale === "" &&
    (values.forma_societaria === f_s[0].value || values.forma_societaria === f_s[1].value)) 
    {err.codice_fiscale= ERROR; cnt_err++;}//return false;

    if(values.codice_univoco === "") {err.codice_univoco= ERROR; cnt_err++;}//return false;

    if(values.codice_univoco !== "" && (values.codice_univoco.length<6 || values.codice_univoco.length>7)) {err.codice_univoco= ERROR_LENGTH; cnt_err++;}

    if(values.cap === "") {err.cap= ERROR; cnt_err++;}//return false;
    if(values.forma_societaria === "") {err.forma_societaria= ERROR; cnt_err++;}//return false;
    
    if(cnt_err>0){
      setError(err);
      return false
    };
    return true;
  }

  useEffect(()=>{
    if(values.forma_societaria === f_s[0].value){
      setError({
        ...error,
        p_iva:"",
        codice_fiscale:"",
      })
      handleChange({
        target:{
          name:"p_iva",
          value:""
        }
      })
    }
    if(values.forma_societaria === f_s[1].value){
      setError({
        ...error,
        p_iva:"",
        codice_fiscale:"",
      })
      setValues({
        ...values,
        p_iva: values.p_iva? values.p_iva : (p_iva? p_iva: "" ),
        codice_fiscale: codice_fiscale? codice_fiscale : (values.codice_fiscale? values.codice_fiscale:"" ),
      })
      
    }
    if(values.forma_societaria === f_s[2].value){
      setError({
        ...error,
        p_iva:"",
        codice_fiscale:"",
      })
      setValues({
        ...values,
        p_iva: values.p_iva? values.p_iva : (p_iva? p_iva: "" ),
        codice_fiscale: values.p_iva? values.p_iva : (p_iva? p_iva: "" ),
      })
      
    }
  },[values.forma_societaria])

  useEffect(()=>{
    if(values.forma_societaria === f_s[2].value){
      setValues({
        ...values,
        codice_fiscale: values.p_iva,
      })
    }
  },[values.p_iva]);
  
  const handleSubmit = () =>{
    if( validate() ){
      var post  = new URLSearchParams();
      post.append('data', JSON.stringify(values) );

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      apiClient.put('api/update_user/'+user.id, post,config)
      .then(response => {
        setUser(response.data.user);
      });
    }
  }

  const handleChange = (event) => {
    setError({
      ...error,
      [event.target.name]:""
    });
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
      <Card>
        <CardHeader
          subheader="Modifica le informazioni"
          title="Informazioni Profilo"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <InputText 
              label="Nome *" 
              value={values.first_name} 
              error={error.first_name!==""}
              helperText={error.first_name}
              handleChange={handleChange} 
              withEvent 
              name="first_name"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText 
              label="Cognome *" 
              value={values.last_name} 
              error={error.last_name!==""}
              helperText={error.last_name}
              handleChange={handleChange} 
              withEvent 
              name="last_name"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputText 
              label="Tel" 
              value={values.phone} 
              error={error.phone!==""}
              helperText={error.phone} 
              handleChange={handleChange} 
              withEvent 
              name="phone"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText 
              label="Email *" 
              value={values.email} 
              error={error.email!==""}
              helperText={error.email} 
              handleChange={handleChange} 
              withEvent 
              name="email"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText 
              label="Nome Azienda *" 
              value={values.azienda} 
              error={error.azienda!==""}
              helperText={error.azienda}
              handleChange={handleChange} 
              withEvent 
              name="azienda"/>
            </Grid>

            {/* <Grid item md={4} xs={12}>
              <InputSelect 
              label="Forma societaria*" 
              value={values.forma_societaria }
              handleChange={handleChange} 
              withEvent 
              name="forma_societaria" 
              items={[
                {value:"Az. Agricola",label:"Azienda Agricola"},
                {value:"S.r.l.",label:"S.r.l."},
                {value:"S.n.c.",label:"S.n.c."},
                {value:"S.a.s.",label:"S.a.s."},
                {value:"Privato",label:"Privato"},
                {value:"Altro",label:"Altro"},
              ]}
              />
            </Grid> */}
            <Grid item md={4} xs={12}>
              <InputSelect 
              label="Forma societaria*" 
              value={values.forma_societaria }
              error={error.forma_societaria!==""}
              helperText={error.forma_societaria}
              handleChange={handleChange} 
              withEvent 
              name="forma_societaria" 
              items={f_s}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText 
              label="Partita IVA *" 
              value={values.p_iva} 
              error={error.p_iva!==""}
              helperText={error.p_iva}
              handleChange={handleChange} 
              withEvent 
              name="p_iva"
              disabled = {!(values.forma_societaria === f_s[2].value || values.forma_societaria === f_s[1].value)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputText 
              label="Codice Fiscale *" 
              value={values.codice_fiscale} 
              error={error.codice_fiscale!==""}
              helperText={error.codice_fiscale}
              handleChange={handleChange} 
              withEvent 
              name="codice_fiscale"
              disabled={!(values.forma_societaria === f_s[0].value || values.forma_societaria === f_s[1].value)}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText 
              label="Codice Univoco *" 
              value={values.codice_univoco} 
              error={error.codice_univoco!==""}
              helperText={error.codice_univoco}
              handleChange={handleChange} 
              withEvent 
              name="codice_univoco"/>
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText 
              label="Città *" 
              value={values.citta} 
              error={error.citta!==""}
              helperText={error.citta}
              handleChange={handleChange} 
              withEvent 
              name="citta"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText 
              label="Indirizzo *" 
              value={values.indirizzo} 
              error={error.indirizzo!==""}
              helperText={error.indirizzo}
              handleChange={handleChange} 
              withEvent 
              name="indirizzo"/>
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText 
              label="CAP *" 
              value={values.cap} 
              error={error.cap!==""}
              helperText={error.cap}
              handleChange={handleChange} 
              withEvent 
              name="cap"/>
            </Grid>

            
          </Grid>
        </CardContent>
        <Divider />
        
        <Box display="flex" justifyContent="space-between" p={2}>
          
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Salva Modifiche
          </Button>
        </Box>
      </Card>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default connect(null,mapDispatchToProps)(ProfileDetails);
