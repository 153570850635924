import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  useTheme,
  makeStyles,
} from '@material-ui/core';
import { cannabinoidi } from 'src/utils/const';
import { connect } from 'react-redux';

const mapStateToProps = (state)=>{
  return{
    user:state.user
  }
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const CannabinoidiGraph = (props)=>{
  const classes = useStyles();
  const theme = useTheme();

  const data = ()=>{
    const {righe,user} = props;

    const label = cannabinoidi;//Object.keys(righe[0].referto.totali);
    var dataSet = [];
    for(var r in righe){
      var riga = righe[r];
      var tot = riga.referto && riga.referto.totali ? riga.referto.totali : [];
      var escludiTHC = riga.referto ? riga.referto.escludiTHC===1 : false;
      
      var obj = {
        backgroundColor: riga.color,
        label:riga.nome_campione,
        borderRadius: 5,
      }
      var data= [];
      for(var c in label){
        var l = label[c];
        if((l == "THCV" || l=="d9_THC") &&  escludiTHC && user.role_id !== 1){
          data.push(0.0);
        }else{
          data.push(parseFloat(tot[l] || 0));
        }
        
      }
      obj.data = data;
      dataSet.push(obj);
    }
    console.log(dataSet);
    return {
      datasets:dataSet,
      labels:label
    };
  }

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: true,
    responsive: true,
    scales: {
      xAxes: [
        {
         maxBarThickness: 15,
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return(
    <Box
      position="relative"
    >
      <Bar
        data={data()}
        height={100}
        options={options}
      />
    </Box>
  );


}

export default connect(mapStateToProps)(CannabinoidiGraph);
