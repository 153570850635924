import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    first_name,
    last_name,
    email,
    azienda,
    forma_societaria,
    phone,
    p_iva,
    codice_fiscale,
    codice_univoco,
    pec,
    password,
    passwordConfirm,
    cap,
    citta,
    indirizzo
  }
} = checkoutFormModel;

export default {
    [first_name.name]: '',
    [last_name.name]: '',
    [email.name]: '',
    [azienda.name]: '',
    [forma_societaria.name]: '',
    [phone.name]: '',
    [p_iva.name]: '',
    [codice_fiscale.name]: '',
    [codice_univoco.name]: '0000000',
    [pec.name]:'',
    [password.name]: '',
    [passwordConfirm.name]:'',
    [cap.name]:'',
    [citta.name]:'',
    [indirizzo.name]:'',
  };