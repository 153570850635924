import { Backdrop, makeStyles, Modal } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}))

const ModaleQr = (props)=>{
    const {open, handleClose,qr} = props;
    const classes = useStyles();
    return(
        <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        >
            <img src={qr} title="qrcode" alt="qrcode"/>
        </Modal>
    );
}

export default ModaleQr;