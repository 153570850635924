import { Card } from '@material-ui/core';
import { Tabs } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Tab } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Container, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Page from 'src/components/Page';
import UserDetails from 'src/components/UserDetails';
import apiClient from 'src/utils/apiClient';
import AnalisiTab from './AnalisiTab';
import DeodorazioniTab from './DeodorazioniTab';
import OffertaTab from './OffertaTab';
import PagamentiTab from './PagamentiTab';
import TicketTab from './TicketTab';


const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
}));


const TabPanel = (props)=>{
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
  
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
}

const CustomerPage = (props)=>{
    const classes = useStyles();
    const param = useParams();
    const id = param.id;
    const [payments,setPayments] =  useState([]);
    const [user,setUser] = useState(null);
    const [inAttesa,setInAttesa] =  useState([]);
    const [inCorso,setInCorso] = useState([]);
    const [terminate,setTerminate] = useState([]);
    const [tickets,setTickets] = useState([]);
    const [activeTabs,setActiveTabs] = useState(0);
    const [offerta,setOfferta] = useState([]);
    const [deoInCorso,setDeoInCorso] = useState([]);
    const [deoTerminate,setDeoTerminate] = useState([]);
    
    useEffect(()=>{
        apiClient('api/customer_details/'+id)
        .then(response => {
            console.log(response);
            setPayments(response.data.payments);
            setUser(response.data.user);
            setInAttesa(response.data.inAttesa);
            setTerminate(response.data.terminate);
            setInCorso(response.data.inCorso);
            setTickets(response.data.tickets);
            setOfferta(response.data.user.offerta);
            setDeoInCorso(response.data.deoInCorso);
            setDeoTerminate(response.data.deoTerminate);
        });
    },[])

    const handleChange = (event, newValue) => {
        setActiveTabs(newValue);
    };

    const onOffertaChange = (e,i) =>{
        setOfferta([
            ...offerta.slice(0,i),
            {
                ...offerta[i],
                prezzo:e
            },
            ...offerta.slice(i+1)
        ]);
    }

    const saveOfferta = ()=>{
        var body = new URLSearchParams();
        body.append('offerta', JSON.stringify(offerta));

        const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        apiClient.put('api/set_offerta/'+user.id,body,config)
        .then(response => {
            if(response.data.error){
                console.log(response);
            }
        })
    }

    const updateOrdine = (id,stato,value)=>{
        if(stato==2){
          var index = inAttesa.findIndex(el => el.id ==id);
    
          setInAttesa([
            ...inAttesa.slice(0,index),
            {
              ...inAttesa[index],
              payment:{
                ...inAttesa[index].payment,
                data_fatturazione:value
              }
            },
            ...inAttesa.slice(index+1)
          ])
        }
    
        if(stato==3){
          var index = inCorso.findIndex(el => el.id ==id);
    
          setInCorso([
            ...inCorso.slice(0,index),
            {
              ...inCorso[index],
              payment:{
                ...inCorso[index].payment,
                data_fatturazione:value
              }
            },
            ...inCorso.slice(index+1)
          ])
        }
    
        if(stato==4){
          var index = terminate.findIndex(el => el.id ==id);
    
          setTerminate([
            ...terminate.slice(0,index),
            {
              ...terminate[index],
              payment:{
                ...terminate[index].payment,
                data_fatturazione:value
              }
            },
            ...terminate.slice(index+1)
          ])
        }
    }

    const updateDeo = (id,stato,value)=>{
        if(stato==2){
          var index = deoInCorso.findIndex(el => el.id ==id);
    
          setDeoInCorso([
            ...deoInCorso.slice(0,index),
            {
              ...deoInCorso[index],
              payment:{
                ...deoInCorso[index].payment,
                data_fatturazione:value
              }
            },
            ...deoInCorso.slice(index+1)
          ])
        }
    
        if(stato==3){
          var index = deoTerminate.findIndex(el => el.id ==id);
    
          setDeoTerminate([
            ...deoTerminate.slice(0,index),
            {
              ...deoTerminate[index],
              payment:{
                ...deoTerminate[index].payment,
                data_fatturazione:value
              }
            },
            ...deoTerminate.slice(index+1)
          ])
        }
    
    }

    return(
        <Page 
        className={classes.root}
        title="Dettagli Pagamento"
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <UserDetails user={user} />
                    </Grid>

                    <Grid item xs={12}>
                        <Card>
                            <AppBar position="static">
                                <Tabs value={activeTabs} onChange={handleChange}>
                                    <Tab label="Analisi"/>
                                    <Tab label="Deodorazioni"/>
                                    <Tab label="Voucher"/>
                                    <Tab label="Pagamenti"/>
                                    <Tab label="Offerta"/>
                                </Tabs>
                            </AppBar>
                            <CardContent>
                                <Grid container spacing={0}>
                                    <TabPanel value={activeTabs} index={0} style={{width:'100%'}}>
                                        <AnalisiTab updateOrdine={updateOrdine} inAttesa={inAttesa} inCorso={inCorso} terminate={terminate} />
                                    </TabPanel>

                                    <TabPanel value={activeTabs} index={1} style={{width:'100%'}}>
                                        <DeodorazioniTab updateDeo={updateDeo} inCorso={deoInCorso} terminate={deoTerminate}/>
                                    </TabPanel>

                                    <TabPanel value={activeTabs} index={2} style={{width:'100%'}}>
                                        <TicketTab tickets={tickets} />
                                    </TabPanel>    

                                    <TabPanel value={activeTabs} index={3} style={{width:'100%'}}>
                                        <PagamentiTab payments={payments} />
                                    </TabPanel> 

                                    <TabPanel value={activeTabs} index={4} style={{width:'100%'}}>
                                        <OffertaTab offerta={offerta} handleChange={onOffertaChange} handleSave={saveOfferta}/>
                                    </TabPanel> 
                                </Grid>    
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

        </Page>
    );
}

export default CustomerPage;