import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import {Provider} from 'react-redux';
import store from './redux/store.js';
import './styles.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render((
  <Provider store={store}>
    <GoogleReCaptchaProvider reCaptchaKey="6LdszuQcAAAAAGjfbKWR5SpETvqEHfHhQLeJTuFE">
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  </Provider>
  
), document.getElementById('root'));

serviceWorker.unregister();
