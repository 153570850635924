import { createMuiTheme, Grid, makeStyles, MuiThemeProvider, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import apiClient from 'src/utils/apiClient';
import IncludiAnalisi from './IncludiAnalisi';


const AnalisiPaymentTable = (props) =>{
    const {utente,payment,setPayment, selected,setSelected} =  props;
    // const [payment,setPayment] = useState([]);
    

    useEffect(()=>{
        setPayment([]);
        setSelected({});
        if(utente!==null){
            
            apiClient.get('api/payments/user/analisi/'+utente.id)
            .then(response=>{
                console.log(response);
                if(response.data.payments){
                    setPayment(response.data.payments);
                    setSelected({});
                }
            })
        }
    },[utente])

    return(
        <Grid container spacing={0}>
            <Grid item xs={12} style={{marginBottom:16}}>
                <Typography variant="h4">Scala gli ordini dal voucher</Typography>
                <Typography variant="subtitle1">Seleziona quali ordini da saldare vuoi scalare dal voucher che stai creando</Typography>
            </Grid>
            

            {/* <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable data={payment} columns={columns} options={options} />
            </MuiThemeProvider> */}

            <IncludiAnalisi payments={payment} selected={selected} setSelected={setSelected}/>
        </Grid>
    );
}

export default AnalisiPaymentTable;