import { makeStyles } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Page from 'src/components/Page';
import GroupMaker from './GroupMaker';
import TablesCard from './TablesCard';
import GraphCard from './GraphCard';
import RadarCard from './RadarCard';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    
    
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

const Comparatore = ()=>{
    const classes = useStyles();
    const [g1,setG1] = useState([]);
    const [g2,setG2] = useState([]);
    const [s1,setS1] = useState([]);
    const [s2,setS2] = useState([]);

    const getRighe = (i)=>{
      const g = i==1 ? g1:g2;
      const s = i==1 ? s1:s2;
      if(s.length ===0 ) return g;
  
      return g.filter((el,i) => {
        return s.includes(i);
      })
    }

    return(
        <Page
        title="Comparatore"
        className={classes.root}
        >
            <GroupMaker setG1={setG1} setG2={setG2}/>

            {(g1.length>0 || g2.length>0) && 
            <>
            <TablesCard g1={g1} g2={g2}  setS1={setS1} setS2={setS2}/>

            <GraphCard g={getRighe(1)} title="Gruppo 1"/>

            <GraphCard g={getRighe(2)} title="Gruppo 2"/>

            <RadarCard g1={getRighe(1)} g2={getRighe(2)} />
            </>
            
            }
        </Page>
    );
}

export default Comparatore;