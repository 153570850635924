import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Button, Card, CardContent, Container, Divider, makeStyles, Typography } from '@material-ui/core';
import  React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import apiClient from 'src/utils/apiClient';
import AddAnalisiForm from './AddAnalisiForm';
import UpdateAnalisi from './UpdateAnalisi';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const AnalisiView = (props)=>{
    //const {}=props;
    const [analisi,setAnalisi] = useState([]);
    const [isSaving,setIsSaving] = useState(false);
    const [nome,setNome] = useState('');

    const classes = useStyles();

    useEffect(()=>{
        apiClient.get('api/tipo_analisi')
        .then(response=>setAnalisi(response.data.analisi));
    },[]);

    const setAttivo = (val,id)=>{
        setIsSaving(true);
        var body = new URLSearchParams();
        body.append('attivo',val);

        const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        apiClient.put('api/tipo_analisi/'+id,body,config)
        .then(response => {
            setIsSaving(false);
            setAnalisi(response.data.analisi)
        });
    }

    const submitAnalisi = (nome) =>{
        setIsSaving(true);
        var body = new FormData();
        body.append('nome',nome);
        
        apiClient.post('api/tipo_analisi',body)
        .then(response=>{
            setIsSaving(false);
            setAnalisi(response.data.analisi);
            setNome('');
        })
    }

    return(
        <Page
        className={classes.root}
        title="Gestione Analisi"
        >
            <Container maxWidth={false}>
                <AddAnalisiForm setAnalisi={submitAnalisi} nome={nome} setNome={setNome}/>

                <UpdateAnalisi analisi={analisi} setAttivo={setAttivo} />
            </Container>

            <Backdrop className={classes.backdrop} open={isSaving}>
                <CircularProgress color="primary" />
            </Backdrop>
        </Page>
    );
}

export default AnalisiView;