import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

function ModaleResponse(props) {
  //const [open, setOpen] = React.useState(false);
  const {open,toggle,response}=props
  const navigate = useNavigate();

  const onNavigate = ()=>{
    navigate('/analisi/'+response.analisi.id);
    toggle();
    // window.location.reload();
  }

  return (
      <Dialog
        open={open}
        onClose={toggle}
      >
        <DialogTitle id="alert-dialog-title">{"Ordine copiato con successo"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {response && "l'ordine è stato copiato con successo nell 'account di " +response.analisi.user.azienda }
          </DialogContentText>
          
          {response && 
            
            <div>
                <Typography variant="overline" display="block">Ordine creato:</Typography>
                <Button color="primary" onClick={onNavigate}>{response.analisi.codice}</Button>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle}>
            Chiudi
          </Button>
          
        </DialogActions>
      </Dialog>
  );
}
export default ModaleResponse;