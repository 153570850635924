import { Typography } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import PaymentsCard from 'src/components/PaymentsCard';
import PaymentsList from 'src/components/PaymentsList.js';
import PaymentsTable from 'src/views/pagamenti/PaymentsListView/PaymentsTable';


const PagamentiTab = (props)=>{
    const {payments} = props;
    const [selected,setSelected] = useState(null);
    const handleClick=(pay)=>{
        setSelected(pay);
    } 
    return (
        <>
            <Grid container justify="space-between" item xs={12} style={{marginBottom:24}}>
                {/* <PaymentsTable payments={payments} /> */}
                <Grid item xs={12} md={6}>
                    <PaymentsList 
                    payments={payments}
                    isButton={true}
                    handleClick={handleClick}
                    selected={selected}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    {selected && 
                    <PaymentsCard pay={selected} />
                    }
                </Grid>
            </Grid>

        </>
    );
}

export default PagamentiTab;