import { makeStyles, TableContainer } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React from 'react';
import InputText from 'src/components/forms/InputText';
import PacchettoTooltip from 'src/components/PacchettoTooltip';

const useStyles = makeStyles((theme) => ({
    margin0:{
        marginTop:0,
        marginBottom:0
    }
}));

const OffertaTab = (props)=>{
    const {offerta, handleChange,handleSave} = props;
    const classes = useStyles();

    return (
        <>
            <Grid item xs={8} style={{marginBottom:24}}>
                <TableContainer>
                    <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        
                                        <TableCell>Nome</TableCell>
                                        
                                        <TableCell>Prezzo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {offerta.map((tipo_analisi,i)=>(
                                        <TableRow key={tipo_analisi.id}>
                                            
                                            <TableCell>{tipo_analisi.nome}</TableCell>
                                            
                                            <TableCell>
                                                <InputText value={tipo_analisi.prezzo} handleChange={(e)=>handleChange(e,i)} variant="outlined" inputProps={{pattern: '[0-9][0-9]*'}} className={classes.margin0} size="small"/>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={8} style={{marginBottom:24}}>
                <Button onClick={handleSave} variant="contained" color="primary">SALVA</Button>
            </Grid>

        </>
    );
}

export default OffertaTab;