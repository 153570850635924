// export const SITE_URL = "http://localhost:8000";
// export const API = SITE_URL + "/api";
// export const SITE_URL ="https://siflab.tsantichita.it";
// export const API="https://siflab.tsantichita.it/api";

export const SITE_URL ="https://api.scienzainfiore.it";
export const API="https://api.scienzainfiore.it/api";

export const MEDIA = SITE_URL + '/storage/';

export const cannabinoidi_estesi = ["CBDVA","CBGVA","CBGV","CBCA"];
export const cannabinoidi = ["CBDV","CBDA","CBGA","CBG","CBD","THCV","CBN","d9_THC","d8_THC","CBC","THCA"];
export const cannabinoidi_tot = ["tot_CBD","tot_CBG","tot_THC"];

export function avg(a){
    let somma=0;
    if(a.length===0) return 0;
    for (var i = 0; i < a.length; i++) {
      somma += parseFloat(a[i])
    }
    
    return (somma/a.length);
}

export const arrayCompare = (_arr1, _arr2) => {
  if (
    !Array.isArray(_arr1)
    || !Array.isArray(_arr2)
    || _arr1.length !== _arr2.length
    ) {
      return false;
    }
  
  // .concat() to not mutate arguments
  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();
  
  for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
          return false;
       }
  }
  
  return true;
}

export function defaultToPrint(){
  var tp = {};
  var c = [...cannabinoidi, ...cannabinoidi_estesi,...cannabinoidi_tot];
  
  for (var k in c){
    tp[ c[k] ] = true
  }
  return tp
}
