import React, {useEffect, useState} from 'react';
import {
    Button,
    Container,
    Divider,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from 'react-redux';
import apiClient from 'src/utils/apiClient';
import SelezioneUtente from './SelezioneUtente';
import Ordine from './Ordine';
import TicketCard from '../NuovaAnalisi/TicketCard';
import { useNavigate } from 'react-router-dom';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import ModaleResponse from './ModaleResponse';

const colonne = ['pos','data_accettazione','azienda', 'descrizione', 'nome_campione', 'codice', 'varieta' ,'cartellino','grammatura', 'campionamento', 'destinazione', 'lotto'];

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    buttonGroup:{
        marginTop:24
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const setUpRiga=(riga)=>{
    return {
        nome_campione:riga.nome_campione,
        grammatura: riga.grammatura ? parseFloat(riga.grammatura):0,
        lotto_coltivazione:riga.lotto,
        descrizione:riga.descrizione,
        cartellinoText:riga.cartellino,
        extra:"",
        withTicket:false,
        posizione:riga.pos,
        codice:riga.codice,
        data_accettazione: riga.data_accettazione,
        cartellino:{
            id:null,
            codice:riga.cartellino,
            img:[],
            varieta:{
                nome:riga.varieta,
                id:null
            }
        }
    }
}

const formatClipboard = (clip => {
    var lines = clip.split('\n');
    
    var res = lines.filter(el=>el!="").map(el => {
        
        if(el!=""){
            return el.split('\t')
        }
    });
    
    res = res.map(riga => {
        var r={};
        riga.map((el,i) => {r[colonne[i]]=el} );
        return r;
    })
    res = res.reduce((r, a) => {
        r[a.azienda] = [...r[a.azienda] || [], a];
        return r;
    }, {});

    

    for (const azienda in res) {
        const gruppo = res[azienda];
        var g={};
        var az =[];
        var i=0;
        var cod = gruppo[0].codice.substr(0, gruppo[0].codice.indexOf('.'));
        var data_acc = gruppo[0].data_accettazione
        var g={
            codice: cod,
            data_accettazione:data_acc,
            useDbCoding:false,
            righe:[]
        };
        gruppo.map(el=>{
            if(el.codice.substr(0, gruppo[0].codice.indexOf('.'))  !== cod || el.data_accettazione != data_acc){
                az=[...az,g];
                cod = el.codice.substr(0, gruppo[0].codice.indexOf('.'));
                data_acc = el.data_accettazione;
                g={
                    codice: cod,
                    data_accettazione:data_acc,
                    useDbCoding:false,
                    righe:[]
                };
            }
            g={...g,righe:[...g.righe,setUpRiga(el)]};
        });
        az=[...az,g];
        res[azienda]=az; 
    }
    //
    return res;
})
const newUser = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    citta: "",
    indirizzo: "",
    azienda:"",
    p_iva:"",
    codice_fiscale:"",
    codice_univoco:"",
    cap:"",
    forma_societaria:"",
    offerta:[]
}

const ImportaAnalisi = (props)=> {
    const [tab,setTab] = useState(0);
    const [parsing,setParsing] = useState(null);
    const [saving, setSaving] = useState(null);
    const [user,setUser] = useState(null);
    const [match,setMatch] = useState([]);
    const [nuovoUtente,setNuovoUtente]=useState(newUser);
    const [ordini, setOrdini] = useState([]);
    const [tickets,setTickets] = useState([]);
    const [response, setResponse] = useState(null);
    const [bulk,setBulk] = useState([]);
    const [errore, setErrore] = useState("");
    const [offerta, setOfferta] = useState([]);
    const [sendMail, setSendMail] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(()=>{
        setTickets([]);
        if(user && tab==0){
            console.log("AAAAAAAAAAA");
            setUpOfferta(user);
            apiClient.get('api/ticket/user/'+user.id)
            .then(response => {
                var ticket_list = response.data.tickets.map(el=>({...el,usati:0}))
                setTickets(ticket_list)
            })
        }
        
        if(nuovoUtente && tab==1) setOfferta(nuovoUtente.offerta);
        
    },[user,tab]);

    useEffect(()=>{calcolaTicket()},[ordini.length,tickets.length])

    const calcolaTicket =  ()=>{
        var o = [...ordini];
        console.log(ordini);
        var t = [...tickets];
        for (let i = 0; i < o.length; i++) {
            var righe = o[i].righe;
            for (let j = 0; j < righe.length; j++) {
                const riga = righe[j];
                
                var ticket_i = t.findIndex((el)=>el.id == riga.extra);

                if(ticket_i !== -1 && t[ticket_i].rimanenti > 0){
                    t[ticket_i].rimanenti--;
                    riga.withTicket = true; 
                }else{
                    riga.withTicket=false;
                }
                
            }
        }
        
        setOrdini(o);
        setTickets(t);
    }

    const handleNuovoChange = (event) => {
        
        setNuovoUtente({
          ...nuovoUtente,
          [event.target.name]: event.target.value
        });
    };

    const setUpOfferta = (user) =>{
        var convenzione = user.convenzione;
        if(convenzione){
            var price  = user ? 
                user.offerta.map(el=>{
                    var p = convenzione.extras.find(e => e.id ==el.id);

                    return {
                        ...el,
                        'prezzo':p? p.pivot.prezzo: el.prezzo,
                        'old': p? el.prezzo : p
                    }
                }) 
            : 
                [];
            console.log("------------------------------------------------------------");
            console.log(price);
            setOfferta(price);
        }else{
            setOfferta(user? user.offerta : []);
            // console.log(user? user.offerta : []);
        }
    }

    const parseClipboard = () => {
        setParsing(true);
        setErrore("");
        navigator.clipboard.readText().then(data => {
            var res = formatClipboard(data);

            for (const key in res) {
                const ordini = res[key];
                checkAzienda(key).then(response => {
                    if(response.data.users.length===1){
                        setTab(0);
                        setUser(response.data.users[0]);
                        //setOfferta(response.data.users[0].offerta);
                        setUpOfferta(response.data.users[0]);
                    }else if(response.data.users.length>1){
                        //setMatch(response.data.users)
                        setTab(0);
                        setUser(response.data.users[0]);
                        // setOfferta(response.data.users[0].offerta);
                        setUpOfferta(response.data.users[0]);
                    }else{
                        setTab(1);
                        setOfferta(response.data.offerta_base);
                    }
                    setNuovoUtente({
                        ...nuovoUtente,
                        azienda:key,
                        email:key+'@scienzainfiore.it',
                        offerta:response.data.offerta_base
                    });
                    
                    
                })
                .then(()=>{
                    setOrdini(ordini);
                    setBulk(ordini.map(el=>""));
                })
                // .then(()=>{
                //     calcolaTicket();
                // })
            }

        })
        .then(()=>setParsing(false));
    }

    const checkAzienda = (azienda)=>{
        return apiClient.get('api/users/check',{params:{azienda:azienda}})   
    }

    const changeTab = (event, newValue)=>{
        setTab(newValue);
    }

    const handleChangeExtra = (val,i,j)=>{
        var t = [...tickets];
        var rigaOld = ordini[i].righe[j];
        var ticket_i = -1;
        var withTicket = false;

        if(rigaOld.withTicket){
            ticket_i = t.findIndex((el)=>el.id == rigaOld.extra);
            t[ticket_i].rimanenti++;
        }

        ticket_i = t.findIndex((el)=>el.id == val);

        if(ticket_i !== -1){
            if(t[ticket_i].rimanenti > 0){
                t[ticket_i].rimanenti--;
                withTicket = true;
            }
            setTickets(t);
        }




        setOrdini([
            ...ordini.slice(0,i),
            {...ordini[i],righe:[
                ...ordini[i].righe.slice(0,j),
                {...ordini[i].righe[j],extra:val,withTicket:withTicket},
                ...ordini[i].righe.slice(j+1),
            ]},
            ...ordini.slice(i+1)
        ])

        setBulk([
            ...bulk.slice(0,i),
            "",
            ...bulk.slice(i+1)
        ]);
    }

    const handleChangeAll = (val,i) =>{
        var t = [...tickets];
        var ordine = {...ordini[i]};
        for (let j = 0; j < ordine.righe.length; j++) {
            const riga = ordine.righe[j];
            var ticket_i = -1;
            var withTicket = false;

            if(riga.withTicket){
                ticket_i = t.findIndex((el)=>el.id == riga.extra);
                t[ticket_i].rimanenti++;
            }

            ticket_i = t.findIndex((el)=>el.id == val);

            if(ticket_i !== -1){
                if(t[ticket_i].rimanenti > 0){
                    t[ticket_i].rimanenti--;
                    withTicket = true;
                }
            }
            
            ordine.righe[j].extra=val;
            ordine.righe[j].withTicket=withTicket;
        }
        setTickets(t);
        setOrdini([
            ...ordini.slice(0,i),
            ordine,
            ...ordini.slice(i+1)
        ]);
        setBulk([
            ...bulk.slice(0,i),
            val,
            ...bulk.slice(i+1)
        ]);

    }

    const handleSetUser = (e,val,r)=>{
        console.log(e,val,r);
        if(r!=="clear"){
            setUser(val);
        }
        
    }

    const setDbCoding = (val,i) =>{
        setOrdini([
            ...ordini.slice(0,i),
            {...ordini[i],useDbCoding:val},
            ...ordini.slice(i+1)
        ])
    }

    const saveImport = ()=>{
        setSaving(true);
        var post = new FormData();
        if(tab==0){
            post.append('user_id',user.id);
        }else{
            post.append('nuovoUtente',JSON.stringify(nuovoUtente) );
        }
        post.append('ordini',JSON.stringify(ordini));
        post.append('sendEmail',sendMail);
        post.append('offerta',JSON.stringify(offerta));

        apiClient.post('api/analisi/importa',post)
        .then(response =>{
            setResponse(response.data);
            setSaving(false);
        })
        .catch(err=>{
            setErrore("Errore durante l'import dell'ordine");
            setSaving(false);
        });

    }

    const validate = ()=>{
        if(tab == 0 && !user) return false;
        if(tab==1 && (nuovoUtente.azienda=="" || nuovoUtente.email=="") ) return false;

        for (let i = 0; i < ordini.length; i++) {
            const righe = ordini[i].righe;
            for (let j = 0; j < righe.length; j++) {
                const riga = righe[j];
                if(riga.extra=="") return false
            }
        }

        return true;
    }

    const backdropOpen = ()=>{
        if(saving == null && parsing== null) return false;

        return saving || parsing;
    }

    const handleOffertaChange=(e,i)=>{
        setOfferta([
            ...offerta.slice(0,i),
            {...offerta[i], prezzo:e.target.value},
            ...offerta.slice(i+1)
        ])
    }
    return (
        <Page
        className={classes.root}
        title="Nuova Analisi"
        >
            <Container maxWidth={false}>

            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={parseClipboard}>IMPORTA</Button> 
                </Grid>

                {parsing!==null && !parsing && 
                <>
                    <SelezioneUtente 
                    user={user} 
                    match={match} 
                    setUser={handleSetUser} 
                    selectedTab={tab} 
                    changeTab={changeTab}
                    nuovoUtente={nuovoUtente}
                    setNuovoUtente={handleNuovoChange}
                    />

                    <Divider style={{backgroundColor:"#c9ddff", margin:"24px 0"}}/>

                    <TicketCard tickets={tickets} />

                    <Divider style={{backgroundColor:"#c9ddff", margin:"24px 0"}}/>

                    {ordini.map((ordine,i)=> 
                        <Ordine 
                        ordine={ordine} 
                        key={i} index={i} 
                        user={tab===0?user:nuovoUtente} 
                        bulk={bulk} 
                        handleChangeAll={handleChangeAll} 
                        handleChangeExtra={handleChangeExtra} 
                        setDbCoding={setDbCoding}
                        sendMail={sendMail}
                        setSendMail={setSendMail}
                        offerta={offerta}
                        handleOffertaChange={handleOffertaChange}
                        />
                    )}

                    <Button variant="contained" color="primary" onClick={saveImport} disabled={!validate()}>Salva</Button>
                    {errore!=="" && <Typography variant="caption" color="error">{errore}</Typography> }
                </>
                }
            </Grid>

            <Backdrop className={classes.backdrop} open={backdropOpen()}>
                <CircularProgress color="primary" />
            </Backdrop>

            <ModaleResponse response={response} open={response!==null} toggle={()=>setResponse(null)}/>

            </Container>
        </Page>

    );
}

export default ImportaAnalisi;