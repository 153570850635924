import React, { useEffect, useState } from 'react';
import { Card, colors, Grid, makeStyles, Typography, withWidth, Avatar, CardActions, Button} from '@material-ui/core';
import { Divider } from '@material-ui/core';
import TagInput from './TagInput';
import apiClient from 'src/utils/apiClient';
import { connect } from 'react-redux';
import { Chip } from '@material-ui/core';
import clsx from 'clsx';
import materialColor from 'src/utils/randomColor.js';

const mapStateToProps = (state)=>{
    return{
      user:state.user
    }
}

const useStyles = makeStyles((theme) => ({
    card:{
      padding:"16px 8px",
      margin:"0px 16px",
    },
    dividerContainer:{
        position:"relative",
        display:"flex",
        
    },
    vs:{
        position: "absolute",
        top: "50%",
        left: "0%",
        transform: "translate(-50%, -50%)",
        border: "1px solid",
        borderRadius: "50%",
        width: 25,
        height: 25,
        lineHeight: "25px",
        textAlign: "center",
        backgroundColor:"#fff",
        [theme.breakpoints.down("sm")]:{
            left:"50%"
        }
    },
    chipsList: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginTop:16,
        '& > *': {
          margin: theme.spacing(0.5),
        },
    },

    cAvatar:{
        color: theme.palette.getContrastText(colors.deepPurple[500]),
        backgroundColor: colors.cyan[200],
        
    },
    vAvatar:{
        color: theme.palette.getContrastText(colors.amber[500]),
        backgroundColor: colors.green[200],
        
    },
    divider:{
        backgroundColor:theme.palette.primary.main
    }
    })
);

const RenderAvatar =  (el)=>{
    const classes = useStyles();
    if (!el.group_id) return null;

    return (
        <Avatar
        className={clsx((el.group_id==3 ? classes.cAvatar: classes.vAvatar),el.className)  }
        >
            {el.group_id==3 ? "C": "V"}
        </Avatar>
    )
}

const GroupMaker = (props)=>{
    const classes = useStyles();
    const [tagList,setTagList] = useState([]);
    const [tl1,setTl1] = useState([]);
    const [tl2,setTl2] = useState([]);
    const {width,user,setG1,setG2} = props;

    const setColor = (righe)=> {
        let r = righe.map(el=>{
            return {...el,color: materialColor()}
        })

        return r;
    }

    useEffect(()=>{
        apiClient.get('api/getTagUser/'+user.id)
        .then(response=>{
            setTagList(response.data.tag_list);
        })
        .catch(err=>console.log(err))
    },[])

    const delTl1 = (i)=>{
        setTl1([
            ...tl1.slice(0,i),
            ...tl1.slice(i+1)
        ])
    }

    const delTl2 = (i)=>{
        setTl2([
            ...tl2.slice(0,i),
            ...tl2.slice(i+1)
        ])
    }

    const compare = ()=>{
        const data={
            params:{
                tl1:tl1.map(t=>t.name),
                tl2:tl2.map(t=>t.name)   
            }
        }
        
        apiClient.get("api/compare/"+user.id,data)
        .then(response=>{
            console.log(response);
            setG1( setColor(response.data.g1) );
            setG2( setColor(response.data.g2) );
        })
        .catch(err=>console.log(err));
    }
    
    return(
        <Card className={classes.card}>
            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h6">Gruppo 1</Typography>
                    <TagInput options={tagList} value={tl1} addTag={setTl1}/>

                    <div className={classes.chipsList}>
                    {tl1.map((el,i)=>{
                        return (
                        <Chip
                        key={el.id}
                        size="small"
                        avatar={ <RenderAvatar group_id={el.tag_group_id} /> }
                        label={el.name}
                        onDelete={()=>delTl1(i)}
                        />
                        )
                    })}
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} md={1} className={classes.dividerContainer} >
                    <Divider className={classes.divider} color="primary" orientation={(width=="xs" || width=="sm") ? "horizontal":"vertical"} />
                    <Typography color="primary" variant="overline" className={classes.vs}>VS</Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={5}>
                    <Typography variant="h6">Gruppo 2</Typography>
                    <TagInput options={tagList} value={tl2} addTag={setTl2}/>

                    <div className={classes.chipsList}>
                    {tl2.map((el,i)=>{
                        return (
                        <Chip
                        key={el.id}
                        size="small"
                        avatar={ <RenderAvatar group_id={el.tag_group_id} /> }
                        label={el.name}
                        onDelete={()=>delTl2(i)}
                        />
                        )
                    })}
                    </div>
                </Grid>
                
            </Grid>

            <CardActions>
                <Button onClick={compare} color="primary" variant="contained" disabled={tl1.length==0 && tl2.length==0}>
                    Compara
                </Button>
            </CardActions>
            
        </Card>
    );
}

export default connect(mapStateToProps)(withWidth()(GroupMaker));