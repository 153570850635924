import { Grid } from '@material-ui/core';
import React from 'react';
import DashOrderTable from 'src/views/reports/DashboardView/DashOrderTable';

const AnalisiTab = (props)=>{
    const {inAttesa,inCorso,terminate,updateOrdine} = props;

    return (
        <>
            <Grid item xs={12} style={{marginBottom:24}}>
                <DashOrderTable updateOrdine={updateOrdine} analisi={inAttesa} kind="attesa" title="Analisi in attesa dei campioni"/>
            </Grid>

            <Grid item xs={12} style={{marginBottom:24}}>
                <DashOrderTable updateOrdine={updateOrdine} analisi={inCorso} kind="inCorso" title="Analisi in corso"/>
            </Grid>

            <Grid item xs={12} style={{marginBottom:24}}>
                <DashOrderTable updateOrdine={updateOrdine} analisi={terminate} kind="terminate" title="Analisi terminate"/>
            </Grid>
        </>
    );
}

export default AnalisiTab