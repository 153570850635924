import { Backdrop, makeStyles } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import apiClient from 'src/utils/apiClient';
import PacchettoForm from './PacchettoForm';
import UpdatePacchetto from './UpdatePacchetto';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const PacchettiView = (props)=>{
    const [nome, setNome] =  useState('');
    const [prezzo, setPrezzo] =  useState('');
    const [selected, setSelected] =  useState([]);
    const [analisi, setAnalisi] =  useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [pacchetti, setPacchetti] =  useState([]);
    const [modify, setModify] = useState(null);
    const classes = useStyles();

    useEffect(()=>{
        apiClient.get('api/extras_settings')
        .then(response=>{
            setAnalisi(response.data.analisi);
            setPacchetti(response.data.extras);
        });
    },[]);

    const savePacchetto = () =>{
        if(modify===null){
            nuovoPacchetto();
        }else{
            updatePacchetto();
        } 
    }

    const nuovoPacchetto =()=>{
        setIsSaving(true);
        var post = new FormData();
        post.append('prezzo',prezzo);
        post.append('nome', nome);
        for (let i = 0; i < selected.length; i++) {
            post.append('analisi_extra[]',selected[i]);
        }

        apiClient.post('api/extras',post)
        .then(response =>{
            setPacchetti(response.data.extras);
            setIsSaving(false);
            setNome('');
            setPrezzo('');
            setSelected([]);
        })
    }

    const updatePacchetto =()=>{
        setIsSaving(true);
        var post = new URLSearchParams();
        post.append('prezzo',prezzo);
        post.append('nome', nome);
        for (let i = 0; i < selected.length; i++) {
            post.append('analisi_extra[]',selected[i]);
        }

        const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        apiClient.put('api/extras/'+modify,post)
        .then(response =>{
            setPacchetti(response.data.extras);
            setIsSaving(false);
            setNome('');
            setPrezzo('');
            setSelected([]);
        })
    }

    const modifica = (id) =>{
        var pacchetto = pacchetti.find(el=>el.id == id);
        var analisi_selected = pacchetto.analisi.map(el=> el.id);
        setNome(pacchetto.nome);
        setPrezzo(pacchetto.prezzo);
        setSelected(analisi_selected);
        setModify(id);
    }

    const setAttivo = (val,id)=>{
        setIsSaving(true);
        var body = new URLSearchParams();
        body.append('attivo',val);

        const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        apiClient.put('api/extras/active/'+id,body,config)
        .then(response => {
            setIsSaving(false);
            setPacchetti(response.data.extras)
        });
    }

    const addAnalisi = (e) =>{
        console.log(e.target.value, e.target.checked);
        const val = parseInt(e.target.value);
        if(e.target.checked){
            setSelected([
                ...selected,
                val
            ]);
        }else{
            var s = selected.filter(el=> el !== val);
            setSelected(s);
        }
    }

    const validatePrezzo=(val)=>{
        const regex=new RegExp('[1-9][0-9]*');
        if(val==='' || regex.test(val) ){
            setPrezzo(val);
        }
    }
    const annullaModifiche = () =>{
        setModify(null);
        setNome('');
        setPrezzo('');
        setSelected([]);
    }

    return(
        <Page
        className={classes.root}
        title="Gestione pacchetti"
        >
            <Container maxWidth={false}>
                <PacchettoForm
                setPacchetto={savePacchetto}
                nome={nome}
                setNome={setNome}
                analisi={analisi}
                selected={selected}
                setSelected={addAnalisi}
                prezzo={prezzo}
                setPrezzo={validatePrezzo}
                modifica={modify}
                annullaModifiche={annullaModifiche}
                />

                <Divider style={{backgroundColor:"#c9ddff", margin:"24px 0"}}/>

                <UpdatePacchetto pacchetti={pacchetti} setAttivo={setAttivo} modifica={modifica} annullaModifiche={annullaModifiche}/>
            </Container>

            <Backdrop className={classes.backdrop} open={isSaving}>
                <CircularProgress color="primary" />
            </Backdrop>
        </Page>
    );
}

export default PacchettiView;