import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { colors, makeStyles, Typography } from '@material-ui/core';
import apiClient from 'src/utils/apiClient';
import TagAutocomplete from 'src/components/forms/TagAutocomplete';
import { Chip } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    chipsList: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      marginTop:16,
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    cAvatar:{
        color: theme.palette.getContrastText(colors.deepPurple[500]),
        backgroundColor: colors.cyan[200],
        
    },
    vAvatar:{
        color: theme.palette.getContrastText(colors.amber[500]),
        backgroundColor: colors.green[200],
        
    },
    small: {
        
    },
}));

const RenderAvatar =  (el)=>{
    const classes = useStyles();
    if (!el.group_id) return null;

    return (
        <Avatar
        className={clsx((el.group_id==3 ? classes.cAvatar: classes.vAvatar),el.className)  }
        >
            {el.group_id==3 ? "C": "V"}
        </Avatar>
    )
}

const ModaleTag = (props)=>{
    const {open,close,riga}= props;
    const [tags,setTags] = useState([]);
    const [tagList, setTagList] = useState([]);
    const classes = useStyles();

    useEffect(()=>{
        if(open){
            
            apiClient.get('api/getTags/'+riga.id)
            .then(response => {
                // console.log(response)
                setTagList(response.data.tag_list);
                setTags(response.data.tags);
            })
            .catch(err => console.log(err));
        }else{
            setTags([]);
        }
    },[open])

    const salva =()=>{
        const data = {tags:tags.map(t=>t.name) };
        apiClient.put('api/setTags/'+riga.id,data)
        .then(response=>{
            //console.log( response.data);
            close();
        })
        .catch(err => console.log(err));
    }

    const deleteTag = (i)=>{
        setTags([
            ...tags.slice(0,i),
            ...tags.slice(i+1)
        ])
    }

    const addTag = (e,v,r)=>{
        if (e.keyCode === 13 && e.target.value) {
            e.preventDefault();
            console.log(e.target);
            console.log(v);
        }else{
            setTags(v);
        }
    }

    return (
        <Dialog
        open={open}
        onClose={close}
        >
            <DialogTitle id="alert-dialog-title">Aggiungi tag al campione</DialogTitle>
            <DialogContent>
                <DialogContentText>Aggiungendo tag al campione sara possibile confrontarlo con gli altri anche se non appartengono allo stesso ordine</DialogContentText>

                <TagAutocomplete 
                option={tagList} 
                value={tags} 
                addTag={setTags}
                // inputBaseProps={{
                //     onKeyDown: e => {
                        
                //         if (e.keyCode === 13 && e.target.value) {
                //             e.preventDefault();
                //             console.log(e.target);
                //         }
                //       }
                // }}
                />
                
                <div className={classes.chipsList}>
                    {tags.map((el,i)=>{
                        return (
                        <Chip
                        key={el.name}
                        size="small"
                        avatar={ <RenderAvatar group_id={el.tag_group_id} /> }
                        label={el.name}
                        onDelete={el.tag_group_id ? null : ()=>deleteTag(i)}
                        />
                        )
                    })}
                </div>

                <DialogActions>
                <Button onClick={salva} color="primary" variant="contained">
                    Salva
                </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export default ModaleTag;