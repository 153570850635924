import { Typography } from '@material-ui/core';
import React from 'react';

const Suggerimenti = (props) =>{

    return(
        <Typography>Seleziona utente autocomplete</Typography>
    );
}

export default Suggerimenti;