import { Container, Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Page from 'src/components/Page';
import UserDetails from 'src/components/UserDetails';
import apiClient from 'src/utils/apiClient';
import AnalisiRefer from './AnalisiRefer';
import DeodorazioneRefer from './DeodorazioneRefer';
import TicketRefer from './TicketRefer';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
}));

const mapStateToProps = function(state){
    return {
      user:state.user
    }
}


const PaymentDetailsView = (props)=>{
    const {user}=props;
    const classes = useStyles();
    const param = useParams();
    const navigate = useNavigate();
    const id = param.id;
    const [payment,setPayment] =  useState(null);
    const [refer,setRefer] =  useState(null);
    const [perc,setPerc] = useState('');

    useEffect(()=>{
        apiClient('api/payment/'+id)
        .then(response => {
            console.log(response);
            setPayment(response.data.payment);
            setRefer(response.data.refer)
        })
        .catch(err => {
            navigate('/404');
        });
    },[])

    const setPercentuale=(val)=>{
        console.log(val);
        const regex=new RegExp('[1-9][0-9]*');
        if(val==='' || regex.test(val) ){
            setPerc(val);
        }
    }

    const handleDateChange = (date)=>{
        
        var post = new URLSearchParams();
        post.append('data_fatturazione',moment(date).format("DD/MM/YYYY") );

        const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        }

        apiClient.put('api/payment/fattura/'+id, post,config)
        .then(response => {
        console.log(response);
        setPayment(response.data.payment);
        setRefer(response.data.refer)
        })
        .catch(err=>{
        console.log(err);
        
        })
    } 

    const setPagato = ()=>{
        var body = new URLSearchParams();
        body.append('perc',perc);
        const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        return apiClient.put('api/payment/salda/'+id, body, config)
        .then(response => {
            console.log(response);
            setPayment(response.data.payment);
            setRefer(response.data.refer)
        });
    }
    return(
        <Page 
        className={classes.root}
        title="Dettagli Pagamento"
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <UserDetails user={payment? payment.user : null} isAdmin={user.role_id ===1}/>
                    </Grid>

                    <Grid item xs={12}>
                        {payment ? 
                            payment.refer_type==="tickets" ?
                            <TicketRefer ticket={refer} payment={payment} handleDateChange={handleDateChange} setPagato={setPagato} perc={perc} setPerc={setPercentuale} user={user}/>
                            :
                            (payment.refer_type==="analisi" ?
                                <AnalisiRefer analisi={refer} payment={payment} handleDateChange={handleDateChange} setPagato={setPagato} perc={perc} setPerc={setPercentuale} user={user}/>
                            :
                                <DeodorazioneRefer deodorazione={refer} payment={payment} handleDateChange={handleDateChange} setPagato={setPagato} perc={perc} setPerc={setPercentuale} user={user} />
                            )
                        :
                        null
                        }
                    </Grid>
                </Grid>
            </Container>

        </Page>
    );
}

export default connect(mapStateToProps)(PaymentDetailsView);