import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LinearProgress } from '@material-ui/core';

const ModaleProgressBar = (props)=>{
    const {open,close, progress} = props;
    return(
        <Dialog
        open={open}
        onClose={close}
        >
            <DialogContent>
                <DialogContentText>creazione archivio in corso</DialogContentText>
                <LinearProgress variant="determinate" value={progress} />
            </DialogContent>
        </Dialog>
    )

}

export default ModaleProgressBar;