import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {Box,Button,Container,Grid,Link,TextField,Typography, makeStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import Page from 'src/components/Page';
import apiClient from 'src/utils/apiClient';
import { logIn } from 'src/utils/auth';

import {setUser, stopLoading, userLoggedIn, userLoggedOut } from 'src/redux/actions.js';




const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ForgottenPasswordView = (props) => {
  const classes = useStyles();
  const [message,setMessage] = useState('');
  const [errore,setErrore] = useState('');

  const resetPassword = (value,formikBag) =>{
    const {email} = value;
    
    apiClient.get("/sanctum/csrf-cookie")
      .then(response =>{
        apiClient.post("/forgot_password_request",{
          email: email,
        }).then(response => {
          setMessage(response.data.msg);
          formikBag.setSubmitting(false);
        }).catch(err => {
          console.log(err.response);
          setErrore(err.response.data.msg);
          formikBag.setSubmitting(false);
        })
      });
  }

  return (
    <Page
      className={classes.root}
      title="Password Dimenticata"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Email non valida').max(255).required('Email richiesta'),
            })}
            onSubmit={(values,formikBag) => resetPassword(values,formikBag)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Password dimenticata?
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Inserisci la tua email per ricevere un link per il reset della password
                  </Typography>
                </Box>
                
                
                
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={(e)=>{setErrore("");handleChange(e)}}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Richiedi
                  </Button>
                </Box>


                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Non hai un account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/auth/register"
                    variant="h6"
                  >
                    Registrati
                  </Link>
                </Typography>

                {errore != "" && <div className="container-errore">
                  <Typography color="error">
                    {errore}
                  </Typography>
                </div>}

                {message != "" && <div className="container-success">
                  <Typography color="primary">
                    {message}
                  </Typography>
                </div>}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default ForgottenPasswordView;
