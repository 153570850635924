import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    closeBtn:{
        position:"absolute",
        right:0
    },
}));

function NoteEditor(props) {
    const [editorState, setEditorState] = useState(()=>EditorState.createEmpty());
    const {open,close,salva,title, savingNote, init}=props
    const classes = useStyles();

    const onChange = (editorState)=>{
        setEditorState(editorState);
        //setCanSave(true);
    }

    useEffect(()=>{
        if(init){
            setEditorState(EditorState.createWithContent(
                convertFromRaw(JSON.parse(init))
            ))
        }
    },[init])

    const saveNote = ()=>{
        var content = JSON.stringify(
            convertToRaw(editorState.getCurrentContent())
        );
        salva(content)
        
    }

    return (
        <Dialog
        open={open}
        onClose={close}
        fullWidth
        maxWidth="lg"
        >
            <IconButton onClick={close} className={classes.closeBtn}>
                <Close />
            </IconButton>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

            <DialogContent style={{height:"50vh"}}>
                <Editor 
                editorState={editorState}
                onEditorStateChange = {onChange}
                editorStyle={{backgroundColor:"#efefef",padding:4,minHeight:300}}
                />
            </DialogContent>

            <DialogActions>
                {savingNote && <CircularProgress  size={24}/>}
                
                <Button onClick={saveNote} color="primary" autoFocus>
                    Salva
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default NoteEditor;