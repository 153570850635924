import { Typography } from '@material-ui/core';
import { Tab,Tabs } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React from 'react';
import NuovoUtente from './TabSelezioneUtente/NuovoUtente';
import SelectUtente from './TabSelezioneUtente/SelectUtente';
import Suggerimenti from './TabSelezioneUtente/Suggerimenti';
import UtenteTrovato from './TabSelezioneUtente/UtenteTrovato';

const TabPanel = (props)=>{
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
  
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
}


const SelezioneUtente = (props)=>{
    const {user,match,setUser,selectedTab,changeTab,nuovoUtente,setNuovoUtente} = props;

    return (
        <Grid item xs={12}>
            <Typography variant="subtitle2">Selezione utente</Typography>
            <AppBar position="static" color="transparent">
                <Tabs
                value={selectedTab}
                onChange={changeTab}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                >
                    <Tab label="Seleziona un utente"/>
                    <Tab label="Crea nuovo"/>
                    <Tab label="Utenti suggeriti" disabled={match.length<1}/>
                </Tabs>
            </AppBar>

            <TabPanel value={selectedTab} index={0} style={{width:'100%'}}>
                <SelectUtente user={user} setUser={setUser}/>
            </TabPanel>
            <TabPanel value={selectedTab} index={1} style={{width:'100%'}}>
                <NuovoUtente user={nuovoUtente} setUser={setNuovoUtente}/>
            </TabPanel>
            <TabPanel value={selectedTab} index={2} style={{width:'100%'}}>
                <Suggerimenti/>
            </TabPanel>
        </Grid>
    );
}

export default SelezioneUtente;