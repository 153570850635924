import React, {useEffect, useState} from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from 'react-redux';
// import OrdiniTable from 'src/views/reports/OrdiniTable.js';
//import {API} from 'src/utils/const.js';
import apiClient from 'src/utils/apiClient';
import { useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import DeodorazioniTable from 'src/views/reports/DeodorazioniTable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const mapStateToProps = function(state){
  return {
    login:state.login
  }
}

const DeodorazioniList = (props) => {
  let location = useLocation();
  const [analisi, setAnalisi] = useState([]);
  const [filters,setFilters] = useState({});
  const [firstFetch,setFirstFetch] = useState(true);
  const [page,setPage] = useState(0);
  const [count,setCount] = useState(1);
  const [rowsPerPage,setRowPerPage] = useState(50);
  const [isLoading,setIsLoading] = useState(false);
  const [order, setOrder]= useState(["",""]);
  const classes = useStyles();
  
  const updateDeo = (id,stato,value)=>{
    
    var index = analisi.findIndex(el => el.id ==id);

    setAnalisi([
      ...analisi.slice(0,index),
      {
        ...analisi[index],
        payment:{
          ...analisi[index].payment,
          data_fatturazione:value
        }
      },
      ...analisi.slice(index+1)
    ])

  }
  
  useEffect(()=>{
    var qid= [];
    if(location.search!=""){
      const decoded = decodeURI(location.search.substring(location.search.indexOf("?")+1) )
      const filters =decoded.split('&')
    
      qid = filters[0].substring( filters[0].indexOf("=")+1).split(',');
      
      qid = qid.map((i) => Number(i));

      
    }

    if(qid.length){
      setFilters({
        "stato": qid
      });
      
      setFirstFetch(false);
    }else{
      dataRequest();
    }
    
  },[]);

  useEffect(()=>{
    
    if(!firstFetch){
      dataRequest();
    }
  },[filters,page]);

  useEffect(()=>{
    if(!firstFetch){
      dataRequest();
    }
    
  },[order])

  const dataRequest = () => {
    const body = {};
    setIsLoading(true);
    for(const key in filters){
      body[key]= filters[key][0];
    }

    if(order[0]!=="" && order[1]!==""){
      body['orderCol']=order[1];
      body['order']=order[0];
    }

    apiClient.get('/api/deodorazioni?page='+(page+1),{params:body})
    .then(response =>{
      setAnalisi(response.data.analisi.data);
      setCount(response.data.analisi.total);
      console.log(response);
    })
    .then(()=>{
      setFirstFetch(false);
      setIsLoading(false)
    })
  }

  const handleFilterSubmit = (filterList) => {
    //let filterList = applyFilters();
    let filter= {}
    for (let i = 0; i < filterList.length; i++) {
      const element = filterList[i];
      if(element.length){
        switch (i) {
          case 1:
            filter['codice']=element;
            break;
          case 2:
            filter["azienda"] = element;
            break;
          case 4:
            filter["stato"] = element;
            break;
          default:
            break;
        }  
      }
    }
    setFilters(filter);
  };

  const opt ={
    pagination:true,
    filter:true,
    serverSide: true,
    confirmFilters: true, 
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button variant="contained" onClick={() => handleFilterSubmit(applyNewFilters())}>Filtra</Button>
        </div>
      );
    },

    onFilterChipClose: (index , removedFilter, filterList ) =>handleFilterSubmit(filterList),
    count: count,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [],
    onTableChange: (action, tableState) => {
      if(action === "changePage"){
        setPage(tableState.page);
      }
    },
    onColumnSortChange: (changedColumn, direction) => {
      if(direction==="none"){
        setOrder(["",""]);
        
      }else{
        if(changedColumn=="user"){
          changedColumn="users.azienda"
        }
        if(changedColumn=="materie"){
          changedColumn="materie_count"
        }
        setOrder([direction,changedColumn]);
        //setOrderCol(changedColumn)
      }
    },
  }

  const renderTitle = ()=>{
    return(
      <Typography variant="h6">
        Lista Deodorazioni
        {/* {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />} */}
      </Typography>
    );
  }

  return(
    <Page
      className={classes.root}
      title="Deodorazioni"
    >
      <Container maxWidth={false}>
        <Grid container spacing={3} >

        <Grid item xs={12} style={{position:'relative'}}>
          {isLoading && <CircularProgress size={48} style={{ position: 'sticky', top: '50%', left: '50%',zIndex:1000 }} />} 
          
          <DeodorazioniTable updateDeo={updateDeo} title={renderTitle()}  analisi ={analisi} opt={opt} filters={filters} />
        </Grid>

        </Grid>
      </Container>
    </Page>
  );
}

export default connect(mapStateToProps)(DeodorazioniList);
