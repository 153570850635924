import React,{Fragment} from 'react';
import MUIDataTable from "mui-datatables";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import { connect } from 'react-redux';
import { MEDIA } from 'src/utils/const';
import { Eye } from 'react-feather';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const mapStateToProps = (state)=>{
    return{
      user:state.user
    }
}

const   getMuiTheme = () => createMuiTheme({
    overrides: {

      MUIDataTable: {
        responsiveScrollMaxHeight: {
          maxHeight:'700px !important'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: "rgba(39, 167, 74, 0.25) !important",
          padding:4
        }
      },
      MUIDataTableBodyCell: {
        root: {
          padding:4
        }
      },
      MuiChip:{
        colorPrimary:{
          backgroundColor:'rgba(39, 167, 74, 0.85)'
        },
        colorSecondary:{
          backgroundColor:'#F6BD60'
        }
      }
    }
});

const MenuRiga = (props)=>{
    const {anchorEl,open,handleClose,value, setReferto,openModificaRiga}=props;
    
    return (
      <Fragment>
        <Menu
        id="long-menu"
        variant='menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '20ch',
          },
        }}
        elevation={2}
        >
          
          
            <MenuItem onClick={()=>{openModificaRiga(value);handleClose()}}>
              Modifica Materia
            </MenuItem>
          
          
            <MenuItem onClick={()=>{setReferto(value,"pre");handleClose()}}>
              Referto pre
            </MenuItem>

            <MenuItem onClick={()=>{setReferto(value,"post");handleClose()}}>
              Referto post
            </MenuItem>
          
          
        </Menu>
      </Fragment>
    );
}

const MaterieDeoTable = (props)=>{
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [menuIndex, setMenuIndex] = React.useState(null);

    const folder = MEDIA+props.idu+"/deodorazioni/"+props.ido+"/"; 

    const handleClick = (event,value) => {
        setMenuIndex(value);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuIndex(null);
        setAnchorEl(null);
    };

    const columns =[
        {
            label: "Pos.",
            name: "posizione",
        },
        {
            label: "Nome Materia",
            name: "nome_materia",
        },
        {
            label: "Varietà",
            name: "cartellino",
            options: {
                customBodyRender:(value) => (
                    <Fragment>
                        <Typography>{value.varieta.nome}</Typography>
                        {value.path ? 
                        <Typography color="textSecondary" variant="body2" component="a" target="_blank" href={MEDIA+props.idu+"/cartellini/"+value.path}>
                            {value.codice}
                            <Eye size={14} style={{marginLeft:4}}/>
                        </Typography>
                        :
                        <Typography color="textSecondary" variant="body2">{value.codice}</Typography>
                        }
                    </Fragment>
                ),
            }
        },
        {
            label: "Cod. Interno",
            name: "codice",
        },

        {
            label: "DDT ingresso",
            name: "ddt_ingresso",
        },

        {
            label: "DDT evasione",
            name: "ddt_uscita",
        },

        {
            label: "Peso Dichiarato",
            name: "peso_dichiarato",
            options: {
                customBodyRender:(value) => value + " Kg"
            }
        },

        {
            label: "Peso Rilevato",
            name: "peso_rilevato",
            options: {
                customBodyRender:(value) => value? value + " Kg" : ""
            }
        },

        {
            label: "Referto Pre",
            name: "referto_pre",
            options: {
              customBodyRender:(value) => {
                if(value===null || (props.user.role_id!==1 && props.stato!==3)){
                  return <HourglassEmptyRoundedIcon fontSize="small"/>
                }else{
                  return <IconButton size="small" href={folder + value} target="_blank" component="a">
                            <OpenInNewRoundedIcon fontSize="small"/>
                         </IconButton>
                }
              },
              filter: true,
              filterType:"textField"
            }
        },

        {
            label: "Referto Post",
            name: "referto_post",
            options: {
              customBodyRender:(value) => {
                if(value===null || (props.user.role_id!==1 && props.stato!==3)){
                  return <HourglassEmptyRoundedIcon fontSize="small"/>
                }else{
                  return <IconButton size="small" href={folder + value} target="_blank" component="a">
                            <OpenInNewRoundedIcon fontSize="small"/>
                         </IconButton>
                }
              },
              filter: true,
              filterType:"textField"
            }
        },


        
    ]; 

    const getColumns = ()=>{
        return props.user.role_id !==1 ? 
        columns 
        : 
        [
            ...columns, 
            {
                label: "Azioni",
                name: "posizione",
                options: {
                  customBodyRender:(value, tableMeta, updateValue) => {
                    {/**/}
                    return (
        
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(e)=>handleClick(e,tableMeta.rowIndex)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    );
                  },
                  filter: true,
                  filterType:"textField"
                }
              }
        ]
    }

    const options = {
        filter: false,
        responsive: 'standard',
        fixedHeaderOptions: {
          xAxis: false,
          yAxis: true
        },
        selectableRows:'none',
        // selectToolbarPlacement:'none',
        // onRowSelectionChange:(currentRowsSelected, allRowsSelected, rowsSelected)=>props.onSelect(rowsSelected),
        print:false,
        download:false,
        viewColumns:false,
        search:false,
        pagination:false,
        elevation:0,
        sort:false
    };

    return(
        <Fragment>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable data={props.materie} columns={getColumns()} options={{...options,...props.opt}} />
            </MuiThemeProvider>   

            <MenuRiga 
            anchorEl={anchorEl} 
            open={open} 
            handleClose={handleClose} 
            value={menuIndex} 
            setReferto={props.openReferto} 
            openModificaRiga={props.openModificaRiga}
            /> 
        </Fragment>
        
        
    );
}

export default connect(mapStateToProps)(MaterieDeoTable);