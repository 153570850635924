import { Button, Collapse, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { ChevronDown, ChevronUp, FileText } from 'react-feather';
import { MEDIA } from 'src/utils/const';

const useStyles = makeStyles((theme) => ({
    item: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0
    },
    button: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      justifyContent: 'flex-start',
      letterSpacing: 0,
      padding: '10px 8px',
      textTransform: 'none',
      width: '100%'
    },
    icon: {
      marginRight: theme.spacing(1)
    },
    title: {
      marginRight: 'auto'
    },
    collapseContainer:{
      marginLeft:16,
    },
    
  }));

const DocumentiPanel = (props)=>{
    const [open,setOpen] = useState(false);
    const classes = useStyles();
    const {analisi}= props
    const folder = MEDIA + analisi.id_user + "/" + analisi.id +"/";
    const handleClick = ()=>{
        setOpen(!open);
    }

    return (
        <Grid container spacing={0} >
            <Grid item xs={12}>
                <Button onClick={handleClick} variant="outlined">
                    {open ? <ChevronUp className={classes.icon} size="20" /> : <ChevronDown className={classes.icon} size="20" />}
                    Lista Documenti
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={open} timeout="auto" unmountOnExit className={classes.collapseContainer}>
                    {analisi.files && analisi.files.map((el,i)=>(
                        <List dense={false} key={i}>
                        
                        <Button size="small" href={folder + el.path} target="_blank" component="a">
                            <ListItem>
                                <ListItemIcon>
                                    <FileText size="18" />
                                </ListItemIcon>
                                <span className={classes.title}>
                                    Dichiarazione di provenienza
                                </span>
                            </ListItem>
                        </Button>
                        
                        </List>
                    ))}

                    {analisi?.payment?.src && 
                        <List dense={true}>
                        
                        <Button size="small" href={analisi.payment.src} target="_blank" component="a">
                            <ListItem>
                                <ListItemIcon>
                                    <FileText size="18" />
                                </ListItemIcon>
                                <span className={classes.title}>
                                    Proforma
                                </span>
                            </ListItem>
                        </Button>
                        
                        </List>
                    }
                    
                </Collapse>
            </Grid>
            
        </Grid>
    );
}

export default DocumentiPanel;