import React,{Fragment} from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { MEDIA } from 'src/utils/const';
import { Link } from 'react-router-dom';
import { Eye } from 'react-feather';
import { Icon } from '@material-ui/core';
import { SimpleModale } from 'src/modali/ModaleConferma';

const mapStateToProps = (state)=>{
  return{
    user:state.user
  }
}

const   getMuiTheme = () => createMuiTheme({
    overrides: {

      MUIDataTable: {
        responsiveScrollMaxHeight: {
          maxHeight:'700px !important'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: "rgba(39, 167, 74, 0.25) !important",
          padding:4
        }
      },
      MUIDataTableBodyCell: {
        root: {
          padding:4
        }
      },
      MuiChip:{
        colorPrimary:{
          backgroundColor:'rgba(39, 167, 74, 0.85)'
        },
        colorSecondary:{
          backgroundColor:'#F6BD60'
        }
      }
    }
  });

  const MenuRiga = (props)=>{
    const {anchorEl,open,handleClose,value, setReferto,openQr,openTag,openModificaRiga,user,openRefertoEsterno}=props;
    
    return (
      <Fragment>
        <Menu
        id="long-menu"
        variant='menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '20ch',
          },
        }}
        elevation={2}
        >
          
          {user.role_id==1 &&
            <MenuItem onClick={()=>{openModificaRiga(value);handleClose()}}>
              Modifica Riga
            </MenuItem>
          }
          {user.role_id==1 &&
            <MenuItem onClick={()=>{setReferto(value);handleClose()}}>
              Crea/Modifica PDF
            </MenuItem>
          }
          {user.role_id==1 &&
            <MenuItem onClick={()=>{openRefertoEsterno(value);handleClose()}}>
              Crea PDF con intestazione
            </MenuItem>
          }
          <MenuItem onClick={()=>{openQr(value);handleClose()}}>
            Qr Code
          </MenuItem>

          <MenuItem onClick={()=>{openTag(value);handleClose()}}>
            Aggiungi Tag
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }

  const RigheTable = (props)=>{

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [menuIndex, setMenuIndex] = React.useState(null);
    const [noteSelected, setNoteSelected] = React.useState(null);

    const folder = MEDIA+props.idu+"/"+props.ido+"/"; 

    const handleClick = (event,value) => {
      setMenuIndex(value);
      setAnchorEl(event.currentTarget);
    };

    const MenuItm = (props)=>(
      <MenuItem onClick={()=>{console.log(props.r);props.openReferto(props.r)}}>
        Crea/Modifica PDF
      </MenuItem>
    )

    const showNote = (i)=>{
      setNoteSelected(i);
    }


    const handleClose = () => {
      setMenuIndex(null);
      setAnchorEl(null);
    };

    const columns = [
      {
        label: "Codice",
        name: "posizione",
        options: {
          customBodyRender:(value) => props.codice+"."+(value+1),
          filter: true,
          filterType:"textField"
        }
      },
      {
        label: "Nome campione",
        name: "nome_campione",
        options: {
          filter: true,
          filterType:"textField"
        }
      },
      {
        label: "Varietà",
        name: "cartellino",
        options: {
          customBodyRender:(value) => (<Fragment>
                                        <Typography>{value.varieta.nome}</Typography>
                                        {value.path ? 
                                        <Typography color="textSecondary" variant="body2" component="a" target="_blank" href={MEDIA+props.idu+"/cartellini/"+value.path}>
                                          {value.codice}
                                          <Eye size={14} style={{marginLeft:4}}/>
                                        </Typography>
                                        :
                                        <Typography color="textSecondary" variant="body2">{value.codice}</Typography>
                                        }
                                      </Fragment>),
        }
      },
      {
        label: "gr.",
        name: "grammatura",
        options: {
          filter: true,
          filterType:"textField"
        }
      },
      {
        label: "Descrizione",
        name: "descrizione",
        options: {
          filter: true,
          filterType:"textField"
        }
      },
      {
        label: "Lotto",
        name: "lotto_coltivazione",
        options: {
          filter: true,
          filterType:"textField"
        }
      },
      {
        label: "Analisi richieste",
        name: "extras",
        options: {
          filter: true,
          customBodyRender:(value)=>value[0].nome || ''
        }
      },
      {
        label: "Note",
        name: "note",
        options: {
          filter: false,
          customBodyRender:(value,tableMeta)=>{
            //console.log(value,tableMeta, updateValue);
            if(value){
              return (
              <IconButton size="small" onClick={()=>setNoteSelected(tableMeta.rowIndex)}>
                <Icon style={{color:"#666"}}>note_alt</Icon>
              </IconButton>
              )
            }else{
              return null;
            }
          }
        }
      },
      {
        label: "Referto",
        name: "referto_path",
        options: {
          customBodyRender:(value) => {
            if(value===null || (props.user.role_id!==1 && props.stato!==4)){
              return <HourglassEmptyRoundedIcon fontSize="small"/>
            }else{
              return <IconButton size="small" href={folder + value} target="_blank" component="a">
                        <OpenInNewRoundedIcon fontSize="small"/>
                     </IconButton>
            }
          },
          filter: true,
          filterType:"textField"
        }
      },
      {
        label: "Azioni",
        name: "posizione",
        options: {
          customBodyRender:(value, tableMeta, updateValue) => {
            {/**/}
            return (

              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e)=>handleClick(e,value)}
              >
                <MoreVertIcon />
              </IconButton>
            );
          },
          filter: true,
          filterType:"textField"
        }
      }

    ];

    const options = {
        filter: false,
        responsive: 'standard',
        fixedHeaderOptions: {
          xAxis: false,
          yAxis: true
        },
        selectableRows:'multiple',
        selectToolbarPlacement:'none',
        onRowSelectionChange:(currentRowsSelected, allRowsSelected, rowsSelected)=>props.onSelect(rowsSelected),
        print:false,
        download:false,
        viewColumns:false,
        search:false,
        pagination:false,
        elevation:0,
        sort:false
      };

      return (
        <Fragment>
          <MuiThemeProvider theme={getMuiTheme()}>
    			   <MUIDataTable data={props.righe} columns={columns} options={{...options,...props.opt}} />
          </MuiThemeProvider>
          
          <MenuRiga 
          anchorEl={anchorEl} 
          open={open} 
          handleClose={handleClose} 
          value={menuIndex} 
          setReferto={props.openReferto} 
          user={props.user} 
          openQr={props.openQr}
          openModificaRiga={props.openModificaRiga}
          openRefertoEsterno={props.openRefertoEsterno}
          openTag={props.openTag}
          />

          <SimpleModale 
          open={noteSelected!==null} 
          close={()=>setNoteSelected(null)} 
          title={noteSelected!==null ? "Note campione "+props.righe[noteSelected].nome_campione : ""} 
          testo={noteSelected!==null ? props.righe[noteSelected].note : "" } 
          />
        </Fragment>
      );
  }

  RigheTable.defaultProps = {
    opt:{}
  }
  export default connect(mapStateToProps)(RigheTable);
