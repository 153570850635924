import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  Chip,
  makeStyles,
  IconButton
} from '@material-ui/core';
import moment from 'moment';
import { useLocation,useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Typography,Dialog,CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import TicketTooltip from 'src/components/TicketTooltip';
import ConvenzioneTooltip from 'src/components/ConvenzioneTooltip';
// import DataFatturazione from 'src/components/DataFatturazione';
import {CalendarToday} from "@material-ui/icons"
import {Calendar,MuiPickersUtilsProvider} from "@material-ui/pickers"
import MomentUtils from '@date-io/moment';
import "moment/locale/it";
import apiClient from "src/utils/apiClient"

moment.locale("it");

const   getMuiTheme = () => createMuiTheme({
    overrides: {

      MUIDataTable: {
        responsiveScrollMaxHeight: {
          maxHeight:'700px !important'
        }
      },

      MuiChip:{
        colorPrimary:{
          backgroundColor:'rgba(39, 167, 74, 0.85)'
        },
        colorSecondary:{
          backgroundColor:'#F6BD60'
        }
      }
    }
  });

  const useStyles = makeStyles(() => ({
    attesa:{backgroundColor:"#ff00f0"},
    footer:{
      display:"flex",
      justifyContent:"flex-start",
      padding:"0px 8px 8px 8px",
      minHeight:40
  }
  }));

  const BorderLinearProgress = withStyles((theme) => ({
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);

  const OrdiniTable = (props)=>{
    let navigate = useNavigate();
    // let location = useLocation();
    const {filters, title,isFetching,updateOrdine} = props;
    const [rowsPerPage,setRowPerPage] = useState(50);

    //stato calendario 
    const [isSendeing,setSending]= useState(false);
    const [text,setText] = useState("");
    const [isOpen, open]=useState(false)
    const [selectedDate, handleDateChange] = useState(moment());
    const [id_payment, setIdPayment] = useState(null);
    const classes = useStyles();

    const statoLabel = (v)=>{
      switch (v) {
        case 2:
          return  "in attesa";
          
        case 3:
          return  "in corso";
          
        case 4:
          return  "terminate";
        
        default:
          return v;
      }
    }

    const OpenCalendar = (event,id)=>{
      event.stopPropagation();
      event.preventDefault()
      setSending(false);
      handleDateChange(moment());
      setIdPayment(id);
      setText("");
      open(true);
    }

    const closeCalendar = ()=>{
      setSending(false);
      setIdPayment(null);
      setText("");
      open(false);
    }


    const columns = [
        {
          Label: "ID",
          name:'id',
          options: {
            filter: false,
          }
        },
        {
          label: "Codice",
          name: "codice",
          options: {
            filter: true,
            filterList:filters?.codice? filters.codice : [] ,
            filterType:"textField",
            sortCompare: (order)=>{
              return (obj1,obj2) =>{
                let str1 = obj1.data;
                let str2 = obj2.data;

                return str1.localeCompare(str2, 'en') * (order==="asc"? 1 : -1);
              }
            }
          }
        },
        {
          label: "Azienda",
          name: "user",
          options: {
            filter: true,
            filterType:"textField",
            filterList:filters?.azienda? filters.azienda : [] ,
            customBodyRender:( value, tableMeta, updateValue ) => {return value.azienda;},
            setCellProps: () => ({className: "ellipsis"}),
            sortCompare: (order)=>{
              return (obj1,obj2) =>{
                let str1 = obj1.data.azienda;
                let str2 = obj2.data.azienda;

                return str1.localeCompare(str2, 'en') * (order==="asc"? 1 : -1);
              }
            }
          }
        },
        {
          label: "N° Campioni",
          name:"righe",
          options: {
            filter: false,
            sort: true,
            customBodyRender:( value, tableMeta, updateValue ) => {
              //console.log(value, tableMeta, updateValue);
              return value.length;
            },
            sortCompare: (order)=>{
              return (obj1,obj2) =>{
                var o = order==="asc"? 1 : -1
                let v1 = obj1.data.length;
                let v2 = obj2.data.length;

                return v1>v2? 1*o : v2>v1 ? -1*o : 0;
              }
            }
          }
        },
        {
          name: "stato",
          label:"Stato",
          options: {
            filter: true,
            sort:false,
            filterList:filters?.stato? filters.stato : [] ,
            customBodyRender:( value, tableMeta, updateValue ) => {

              return <Chip
                color={value==2 ? "default": value==4 ? "primary" : "secondary"}
                label={value==2 ? "in attesa": value==4 ? "terminato" : "in corso"}
                size="small"
              />
            },
            filterOptions: {
              renderValue: v => statoLabel(v),
              
            },
            customFilterListOptions: {
              render: v => statoLabel(v),
            },
          }
        },
        {
          label: "Acquistato il",
          name:"data_acquisto",
          options: {
            filter: false,
            
            customBodyRender:( value, tableMeta, updateValue ) => {return moment(value).format("DD/MM/YYYY");}
          }
        },
        {
          label: "Accettato il",
          name:"data_accettazione",
          options: {
            filter: false,
            
            customBodyRender:( value, tableMeta, updateValue ) => { return value!=null ? moment(value).format("DD/MM/YYYY"): "";}
          }
        },

        {
          label: "Fattura del",
          name:"payment",
          options: {
            filter: false,
            sort:false,
            customBodyRender:( value, tableMeta, updateValue ) => { 
              return value?.data_fatturazione !=null ? 
              moment(value.data_fatturazione).format("DD/MM/YYYY")
              : 
              (value && value.data_fatturazione ==null) ?
                // <DataFatturazione id_payment={value?.id} updateOrdine={updateOrdine}/>
                <IconButton onClick={(e)=>OpenCalendar(e,value?.id)} size="small">
                  <CalendarToday />
                </IconButton>
                :
                "";
              
            }
          }
        },
        {
          label: " ",
          name:"convenzione",
          options: {
            filter: false,
            sort:false,
            customBodyRender:( value, tableMeta, updateValue ) => { 
              return value? <ConvenzioneTooltip convenzione={value}/> : ""
            }
          }
        },
        {
          label: "pagamento",
          name:"payment",
          options: {
            filter: false,
            sort:false,
            customBodyRender:( value, tableMeta, updateValue ) => { 
              
              if(value){
                return (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography align="center" variant="caption" color="textSecondary">{value.perc}%</Typography>
                  <BorderLinearProgress variant="determinate" value={value.perc} style={{width:"100%"}}/>
                </Box>
                )
              }else{
                let index = tableMeta.rowIndex;
                return <TicketTooltip righe={tableMeta.tableData[index][3]} />
              }
              
            }
          }
        },
    ];

    const handlePress = (date)=>{

      setSending(true);
      var post = new URLSearchParams();
      post.append('data_fatturazione',moment(selectedDate).format("DD/MM/YYYY") );

      const config = {
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
      }
      }
      if(id_payment==null) return;

      apiClient.put('api/payment/fattura/'+id_payment, post,config)
      .then(response => {
          console.log(response);
          // setDataFattura(date);
          // setIsDeleting(false);
          updateOrdine(response.data.refer.id, response.data.refer.stato, response.data.payment.data_fatturazione);
          setText("");
          setTimeout(() => {
              open(false);
              setSending(false);
              setIdPayment(null)
          }, 1000);
      
      })
      .catch(err=>{
          console.log(err);
          setText("Impossibile salvare data di fatturazione");
          setSending(false);
      })
    }

    


    const options = {
          filter: false,
          responsive: 'standard',
          fixedHeader:true,
          fixedSelectColumn:false,
          selectableRows:'none',
          print:false,
          download:false,
          viewColumns:false,
          search:false,
          pagination:false,
          elevation:0,
          onRowClick:(rowData)=>navigate("/analisi/"+rowData[0]),
          draggableColumns:{enabled:true},
    };

    return (
      <>
      <MuiThemeProvider theme={getMuiTheme()}>
			   <MUIDataTable title={title} data={props.analisi} columns={columns} options={{...options,...props.opt}} />
      </MuiThemeProvider>
        <Dialog
        open={isOpen}
        onClose={closeCalendar}
        >
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="it">
          {/* <DatePicker 
          open={isOpen}
          format="DD/MM/YYYY"
          onChange={handlePress}
          value={selectedDate}
          TextFieldComponent={()=>null}
          renderDay={renderDayInPicker}
          variant="inline"
          />     */}
          <Calendar 
          date={selectedDate}
          format="DD/MM/YYYY"
          onChange={handleDateChange}
          // renderDay={renderDayInPicker}
          allowKeyboardControl={false}
          />
          </MuiPickersUtilsProvider>
          <div className={classes.footer}>
              {isSendeing ? 
              <>
              <CircularProgress color="primary" size={24} style={{marginRight:16}}/>
              <Typography>Salvataggio in corso</Typography>
              </>
              :
              <>
              <Button variant="text" onClick={closeCalendar} color="primary">ANNULLA </Button>
              <Button variant="contained" onClick={handlePress} color="primary" style={{alignSelf:"flex-end"}}>SALVA</Button>
              </>
              }
              
          </div>
        </Dialog>
      </>
    );
  }

  OrdiniTable.defaultProps = {
    opt:{},
    filters:{},
    onFilterSubmit:null,
    title:"",
  }
  export default OrdiniTable;
