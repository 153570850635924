import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  Button
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { DollarSign} from 'react-feather';
import { Icon } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display:"flex",
    justifyContent:"flex-start",
    position:"relative",
    backgroundColor:"#ffe7c3",
    '&::after':{
      content:'" "',
      position:'absolute',
      top:0,
      bottom:0,
      right:0,
      backgroundColor: "#ffa726",
      borderLeft:100,
      display: "block",
      borderStyle: "solid",
      borderTop: 0,
      borderRight: 0,
      borderBottom: 0,
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      borderColor: "#ffa726",
      boxShadow: "-3px 0px 16px -4px #ffa726"

    }

  },
  avatar: {
    backgroundColor: colors.orange[400],
    height: 56,
    width: 56,
    zIndex:2,
    position:'absolute',
    top:"48%",
    transform:"translateY(-50%)",
    right:14,
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  button: {
    color: "#ffa726",
    borderColor:"#ffa726",
    color:"#ffa726",
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0,
    padding: '4px 8px',
    textTransform: 'none',
    width: '90%'
  },
  content:{
    flexBasis:"100%",
    display:"flex",
    paddingRight:100
  },
  contentContainer:{
    flexBasis:"100%",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"space-between"
  }
}));

const PaymentsCard = ({ className, payment_tot,type,...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent className={classes.content}>
        <div className={classes.contentContainer}>
          <Typography color="textSecondary" gutterBottom variant="h6" >
            {type==="analisi"? "Pagamenti analisi" : "Pagamenti deodorazioni" }
          </Typography>
          <Typography color="textPrimary" variant="h3" >
            {payment_tot.toFixed(2) + '€'}
          </Typography>
          <Button
          variant="outlined"
          size="small"
          className={classes.button}
          component={RouterLink}
          to={{
            pathname: "/pagamenti",
            search: "?p=0&&refer_type="+(type==="analisi"? "analisi,tickets" : "deodorazione")
          }}
          >    
              Vedi
          </Button>


        </div>

        <Avatar className={classes.avatar}>
          {/* <DollarSign size={48}/> */}
          <Icon style={{fontSize:48}}>euro_symbol</Icon>
        </Avatar>
        {/* <Grid container justify="space-between" spacing={3} >
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6" >
              Pagamenti 
            </Typography>
            <Typography color="textPrimary" variant="h3" >
              {payment_tot.toFixed(2) + '€'}
            </Typography>
            <Button
            color="primary"
            size="small"
            
            component={RouterLink}
            to={{
              pathname: "/pagamenti",
              search: "?p=0"
            }}
            >    
                Vedi
            </Button>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <DollarSign />
            </Avatar>
          </Grid>
        </Grid> */}
        <Box mt={2} display="flex" alignItems="center" >
          <Typography color="textSecondary" variant="caption" >
            
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

PaymentsCard.propTypes = {
  className: PropTypes.string
};

export default PaymentsCard;