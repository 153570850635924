import React,{Fragment} from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import { MEDIA } from 'src/utils/const';




const   getMuiTheme = () => createMuiTheme({
    overrides: {

      MUIDataTable: {
        responsiveScrollMaxHeight: {
          maxHeight:'700px !important'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: "rgba(39, 167, 74, 0.25) !important",
          padding:4
        }
      },
      MUIDataTableBodyCell:{
          root:{
              padding:4
          }
      },
      MuiChip:{
        colorPrimary:{
          backgroundColor:'rgba(39, 167, 74, 0.85)'
        },
        colorSecondary:{
          backgroundColor:'#F6BD60'
        }
      }
    }
  });

  const DettagliRigaTicket = (props)=>{

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [menuIndex, setMenuIndex] = React.useState(null);
    const folder = MEDIA + props.idu +"/"

    const handleClick = (event,value) => {
      setMenuIndex(value);
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setMenuIndex(null);
      setAnchorEl(null);
    };

    const columns = [
      {
        label: "Codice",
        name: "posizione",
        options:{
          customBodyRender:(value,tableMeta) => {
            return props.righe[tableMeta.rowIndex].codice + "." + (value+1);
          }
        }
      },
      {
        label: "Nome campione",
        name: "nome_campione",
        options: {
          filter: true,
          filterType:"textField"
        }
      },
      {
        label: "Varietà",
        name: "cartellino",
        options: {
          customBodyRender:(value) => (<Fragment>
                                        <Typography>{value.varieta.nome}</Typography>
                                        <Typography color="textSecondary" variant="body2">{value.codice}</Typography>
                                      </Fragment>),
        }
      },
      {
        label: "gr.",
        name: "grammatura",
        options: {
          filter: true,
          filterType:"textField"
        }
      },
      {
        label: "Tipo",
        name: "extras",
        options: {
          customBodyRender: (value) =>(value[0].nome),
          filter: true,
          filterType:"textField"
        }
      },
      {
        label: "Referto",
        name: "referto_path",
        options: {
          customBodyRender:(value,tableMeta) => {
            if(value===null){
              return <HourglassEmptyRoundedIcon fontSize="small"/>
            }else{
              let path = folder+ props.righe[tableMeta.rowIndex].id_ordine+"/"+value;
              return <IconButton size="small" href={path} target="_blank" component="a">
                        <OpenInNewRoundedIcon fontSize="small"/>
                     </IconButton>
            }
          },
          filter: true,
          filterType:"textField"
        }
      }

    ];

    const options = {
        filter: false,
        responsive: 'standard',
        fixedHeaderOptions: {
          xAxis: false,
          yAxis: true
        },
        selectableRows:'none',
        selectToolbarPlacement:'none',
        print:false,
        download:false,
        viewColumns:false,
        search:false,
        pagination:false,
        elevation:0
      };
      console.log(props.righe);
      return (
        <Fragment>
          <MuiThemeProvider theme={getMuiTheme()}>
    			   <MUIDataTable data={props.righe} columns={columns} options={{...options,...props.opt}} />
          </MuiThemeProvider>
        </Fragment>
      );
  }

  DettagliRigaTicket.defaultProps = {
    opt:{}
  }
  export default DettagliRigaTicket;
