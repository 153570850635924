import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  List as ListAnalisi,
  ChevronDown,
  ChevronUp,
  Users
} from 'react-feather';
import InputIcon from '@material-ui/icons/Input';
import NavItem from './NavItem';
import NavCollapsItem from './NavCollapsItem';
import { Compare, DynamicFeedOutlined, GroupWorkOutlined } from '@material-ui/icons';
import apiClient from 'src/utils/apiClient';
import { logOut } from 'src/utils/auth';
import { connect } from 'react-redux';
import {setUser, userLoggedOut } from 'src/redux/actions.js';



const items = (user) => {
  if(user.role_id===1){
    ///MENU ADMIN
    return [{
        href: '/dashboard',
        icon: BarChartIcon,
        title: 'Dashboard'
      },
      {
        title:'Analisi',
        iconClose: ChevronDown,
        iconOpen: ChevronUp ,
        children:[
          {
            href: '/analisi',
            icon: ListAnalisi,
            title: 'Lista'
          },
          {
            href: '/analisi/nuova',
            icon: "science",
            title: 'Nuova Analisi'
          },
        ]
      },
      {
        title:'Lavorazioni',
        iconClose: ChevronDown,
        iconOpen: ChevronUp ,
        children:[
          {
            href: '/deodorazione/nuova',
            icon: "fiber_new",
            title: 'Nuova Deodorazione'
          },
          {
            href: '/deodorazioni',
            icon: ListAnalisi,
            title: 'Lista Deodorazioni'
          },
        ]
      },
      {
        title:'Voucher',
        iconClose: ChevronDown,
        iconOpen: ChevronUp ,
        children:[
          {
            href: '/voucher/nuovo',
            icon: "confirmation_number",
            title: 'Nuovo Voucher'
          },
          {
            href: '/vouchers',
            icon: ListAnalisi,
            title: 'Lista'
          },
          
        ]
      },
      {
        title:"Lista Clienti",
        icon: Users,
        href:"/customers"
      },
      {
        title:"Tabella Referti",
        icon: ListAnalisi,
        href:"/referti"
      },
      {
        title:'Settings',
        iconClose: ChevronDown,
        iconOpen: ChevronUp ,
        children:[
          {
            href: '/settings/analisi',
            icon: GroupWorkOutlined,
            title: 'Gestione analisi'
          },
          {
            href: '/settings/pacchetti',
            icon: DynamicFeedOutlined,
            title: 'Gestione Pacchetti'
          },
          {
            href: '/settings/varieta',
            icon: DynamicFeedOutlined,
            title: 'Gestione Varieta'
          },
        ]
      },
    ];
  }else{
    /// MENU UTENTE
    return [{
        href: '/dashboard',
        icon: BarChartIcon,
        title: 'Dashboard'
      },
      {
        title:'Analisi',
        iconClose: ChevronDown,
        iconOpen: ChevronUp ,
        children:[
          {
            href: '/analisi',
            icon: ListAnalisi,
            title: 'Lista'
          },
          {
            href: '/analisi/nuova',
            icon: "science",
            title: 'Nuova Analisi'
          },
        ]
      },
      {
        title:'Lavorazioni',
        iconClose: ChevronDown,
        iconOpen: ChevronUp ,
        children:[
          // {
          //   href: '/deodorazione/nuova',
          //   icon: "fiber_new",
          //   title: 'Nuova Deodorazione'
          // },
          {
            href: '/deodorazioni',
            icon: ListAnalisi,
            title: 'Lista Deodorazioni'
          },
        ]
      },
      {
        href: '/comparatore',
        icon: Compare,
        title: 'Comparatore'
      }
    ];
  }
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
}));

const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  userLoggedOut: () => dispatch(userLoggedOut()),
});

const NavBar = ({ onMobileClose, openMobile, user,...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const {userLoggedOut,setUser} = rest;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const logout = () =>{
    apiClient.post("/logout")
    .then(response => {
      userLoggedOut();
      logOut();
    }).then(()=>{
      setUser({});
    })

  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          to="/account"
        >
        {user.cod.toUpperCase()}
        </Avatar>
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.azienda}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items(user).map((item) => {
            if(item.hasOwnProperty('children')){
              return <NavCollapsItem
                    children={item.children}
                    key={item.title}
                    title={item.title}
                    iconOpen={item.iconOpen}
                    iconClose={item.iconClose}
                    />
            }else{
              return <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              />
            }    
          })}

          <Hidden lgUp>
            <Divider />
            <ListItem
              className={classes.item}
              disableGutters
            >
              <Button
                className={classes.button}
                onClick={logout}
              >
                <InputIcon className={classes.icon}/>
                <span className={classes.title}>
                  Logout
                </span>
              </Button>
            </ListItem>
          </Hidden>

        </List>
      </Box>

    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default connect(null,mapDispatchToProps)(NavBar);
