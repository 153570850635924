import { TableContainer } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { Fragment } from "react";

const TC = withStyles((theme)=>({
    head:{
        backgroundColor:"rgba(39, 167, 74, 0.25) !important",
    }
}))(TableCell);

const TCpre = withStyles((theme)=>({
    body:{
        backgroundColor:theme.palette.action.hover,
    }
}))(TableCell);

const TCpost = withStyles((theme)=>({
    body:{
        backgroundColor:"#DBE5EC",
    }
}))(TableCell);

const TCdelta = withStyles((theme)=>({
    body:{
        backgroundColor:"#ece2db",
    }
}))(TableCell);



const TabellaCannabinoidi =(props)=>{
    const {deo} = props;

    const calcolaDiff = (i,value)=>{
        var materia = deo.materie[i];
        if(materia.analisi_pre && materia.analisi_post){
            if(value==="CBD/THC"){
                var v1 = (parseFloat(materia.analisi_pre.totali.tot_CBD)/parseFloat(materia.analisi_pre.totali.tot_THC));
                var v2 = (parseFloat(materia.analisi_post.totali.tot_CBD)/parseFloat(materia.analisi_post.totali.tot_THC));
            }else{
                var v1 = parseFloat(materia.analisi_pre.totali[value]);
                var v2 = parseFloat(materia.analisi_post.totali[value]); 
                console.log(v1,v2)
            }
            
            
            return (v1-v2).toFixed(2);

        }else{
            return "-";
        }
    }

    return(
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TC size="small">Campione</TC>
                        <TC size="small">Tipo</TC>
                        <TC size="small">CBD TOTALE</TC>
                        <TC size="small">THC TOTALE</TC>
                        <TC size="small">CBG TOTALE</TC>
                        <TC size="small">CBD/THC</TC>
                        <TC size="small">CBDV</TC>
                        <TC size="small">CBDA</TC>
                        <TC size="small">CBGA</TC>
                        <TC size="small">CBG</TC>
                        <TC size="small">CBD</TC>
                        <TC size="small">THCV</TC>
                        <TC size="small">CBN</TC>
                        <TC size="small">d9_THC</TC>
                        <TC size="small">d8_THC</TC>
                        <TC size="small">CBC</TC>
                        <TC size="small">THCA</TC>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {deo?.materie?.map((materia,i) =>
                    <Fragment key={i}>
                    <TableRow>
                        <TableCell size="small" rowSpan={3}>{materia.nome_materia}</TableCell>
                        <TCpre size="small">pre</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.tot_CBD : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.tot_THC : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.tot_CBG : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? (parseFloat(materia.analisi_pre.totali.tot_CBD)/parseFloat(materia.analisi_pre.totali.tot_THC)).toFixed(2) : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.CBDV : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.CBDA : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.CBGA : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.CBG : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.CBD : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.THCV : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.CBN : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.d9_THC : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.d8_THC : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.CBC : "-"}</TCpre>
                        <TCpre size="small">{materia.analisi_pre ? materia.analisi_pre.totali.THCA : "-"}</TCpre>
                    </TableRow>

                    <TableRow>
                        <TCpost size="small">post</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.tot_CBD : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.tot_THC : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.tot_CBG : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? (parseFloat(materia.analisi_post.totali.tot_CBD)/parseFloat(materia.analisi_post.totali.tot_THC)).toFixed(2) : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.CBDV : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.CBDA : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.CBGA : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.CBG : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.CBD : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.THCV : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.CBN : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.d9_THC : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.d8_THC : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.CBC : "-"}</TCpost>
                        <TCpost size="small">{materia.analisi_post ? materia.analisi_post.totali.THCA : "-"}</TCpost>
                    </TableRow>

                    <TableRow>
                        <TCdelta size="small"><b>∆</b></TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'tot_CBD')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'tot_THC')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'tot_CBG')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'CBD/THC')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'CBDV')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'CBDA')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'CBGA')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'CBG')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'CBD')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'THCV')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'CBN')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'d9_THC')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'d8_THC')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'CBC')}</TCdelta>
                        <TCdelta size="small">{calcolaDiff(i,'THCA')}</TCdelta>
                    </TableRow>
                    </Fragment>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TabellaCannabinoidi;