import { InputAdornment, makeStyles, TableHead } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import { Grid, Table, TableCell, TableRow,TableBody,TableContainer, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import React from 'react';
import CheckBox from 'src/components/forms/CheckBox';
import InputAutoComplete from 'src/components/forms/InputAutoComplete';
import InputText from 'src/components/forms/InputText';
import PacchettoTooltip from 'src/components/PacchettoTooltip';

const StyledTableContainer = withStyles((theme) => ({
    root: {
      width: "max-content"
    }
}))(TableContainer);

const useStyles = makeStyles((theme)=>({
    
    text:{flexGrow:1},
    subText:{color: theme.palette.text.secondary, fontSize:14},
}))

const VoucherForm = (props) =>{
    const {onTicketPriceChange,user,onTicketChange, onUserChange,totale, senzaIva,analisi_extra} = props;
    console.log(user);
    const classes = useStyles();
    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Typography variant="h4">Creazione Voucher</Typography>
                <Typography variant="subtitle1">inserisci i dati del voucher</Typography>
            </Grid>

            <Grid item md={4} xs={12}>
                <InputAutoComplete 
                label="Utente"
                url="api/users"
                name="user"
                onChange={onUserChange}
                value={user}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.azienda}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <div className={classes.text}>
                        {option.azienda}
                        <br />
                        <span className={classes.subText}><i>{"Referente: "}</i></span><span className={classes.subText}>{option.first_name+" " +option.last_name}</span>
                      </div>
                    </React.Fragment>
                )}
                />
                {user?.convenzione && 
                    <Chip color="primary" label={user.convenzione.nome_convenzione}/>
                }
            </Grid>

            

            {/* <Grid item md={4} xs={12}>
                <InputText 
                value={totale} 
                handleChange={onTotaleChange} 
                label={"Prezzo (senza IVA)"}
                inputProps={{pattern: '[1-9][0-9]*'}}
                />
            </Grid> */}

            <Grid item xs={12}>
                <StyledTableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell variant="head">Quantità</TableCell>
                            <TableCell variant="head">Rimanenti</TableCell>
                            <TableCell variant="head">Prezzo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {analisi_extra.filter(el=>el.attivo ===1).map((el,i)=>(
                        <TableRow key={el.id}>
                            <TableCell size="small">
                                {/* <CheckBox value={el.id} onChange={(e)=>console.log(e)} label={el.nome}/> */}
                                <PacchettoTooltip pacchetto={el} />
                            </TableCell>
                            <TableCell size="small">
                                <InputText 
                                value={el.quantita} 
                                handleChange={(e)=>onTicketChange(e,i)} 
                                variant="outlined" 
                                InputProps={{
                                    pattern: '[0-9][0-9]*'
                                }} 
                                size="small"/>
                                
                            </TableCell>
                            <TableCell size="small">
                                ({el.rimanenti})
                            </TableCell>

                            <TableCell size="small">
                                <InputText value={el.prezzo} handleChange={(e)=>onTicketPriceChange(e,i)} variant="outlined" inputProps={{pattern: '[0-9][0-9]*'}} size="small"/>
                                
                            </TableCell>
                        </TableRow>
                    ))}

                    <TableRow>
                        <TableCell colSpan={2} rowSpan={3}></TableCell>
                        <TableCell variant="head">IMPONIBILE</TableCell>
                        <TableCell>{totale}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">IVA</TableCell>
                        <TableCell>{senzaIva ? <em>Regime fiscale senza iva</em>: totale * 0.22}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">TOT</TableCell>
                        <TableCell>{senzaIva ? totale : totale * 1.22}</TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
                </StyledTableContainer>
            </Grid>

        </Grid>

    );
}

export default VoucherForm;