import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress } from '@material-ui/core';

function ModaleConferma(props) {
  //const [open, setOpen] = React.useState(false);
  const {open,close,title,testo,salva,subTesto}=props

  return (
      <Dialog
        open={open}
        onClose={close}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {testo}
          </DialogContentText>
          {subTesto}
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>
            Annulla
          </Button>
          <Button onClick={salva} color="primary" autoFocus>
            Continua
          </Button>
        </DialogActions>
      </Dialog>
  );
}
export default ModaleConferma;

export function SimpleModale(props){
  const {open, close,title,testo} = props;

  return(
    <Dialog
        open={open}
        onClose={close}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {testo}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export function SimpleModaleWithLoader(props){
  const {open, close,title,testo,isLoading} = props;

  return(
    <Dialog
        open={open}
        onClose={close}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent >
          {isLoading ? 
          <div style={{display:"flex",justifyContent:"center"}}>
          <CircularProgress color="primary" size={36} />
          </div>
          :
          
          <DialogContentText id="alert-dialog-description">
            {testo}
          </DialogContentText>
         
          }
          
        </DialogContent>
        <DialogActions>
          {!isLoading && 
          <Button onClick={close}>
            Chiudi
          </Button>
          }
          
        </DialogActions>
      </Dialog>
  );
}