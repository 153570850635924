import React from "react";
import { Card, colors, Grid, makeStyles, Typography, withWidth, Avatar, CardActions, Button} from '@material-ui/core';
import CannabinoidiGraph from "../analisi/AnalisiPageView/CannabinoidiGraph";
import RadarAvgs from "./RadarAvgs";
import TotaliBar from "./TotaliBar";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    card:{
      padding:"16px 8px",
      margin:"24px 16px",
    },
    })
);


const GraphCard = (props)=>{
    const {g,title} = props;
    const classes = useStyles();

    return(
        <Card className={classes.card}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6">{title}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                   <CannabinoidiGraph righe={g}/>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                    
                    <TotaliBar righe={g}/>
                </Grid>

            </Grid>
        </Card>
    )
}

export default GraphCard;