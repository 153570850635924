import { Card, CardContent, CardMedia, IconButton, makeStyles } from '@material-ui/core';
import { DeleteRounded, EditRounded } from '@material-ui/icons';
import React, {useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import ImageEdit from '../ImageEdit';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const useStyles = makeStyles(theme=>({
    card:{width:130},
    cardMedia:{
        height:130,
        width:130,
        backgroundSize:"contain !important"
    },
    cardContent:{
        paddingTop:8,
        paddingBottom:'8px !important',
        paddingLeft:4,
        paddingRight:4
    }
}));

function InputFile(props) {

    const {value,onChange} = props;
    const classes = useStyles();
    const [open,setOpen] = useState(false);
    
    // const onDrop = useCallback(acceptedFiles => {
    //     //console.log(acceptedFiles);
    //     onChange(acceptedFiles);
    //   }, [context])

    const onDrop = acceptedFiles => onChange(acceptedFiles);
    
    
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({accept: 'image/*',onDrop});

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);
    
    return (
        <div className="">
            {value.length === 0 ? 
            <div {...getRootProps({style})} >
                <input {...getInputProps()} capture="camera"/>
                <p>Trascina un immagine o fai click per selezionarne una</p>
            </div>
            :
            <>
            <Card className={classes.card}>
                <CardMedia
                className={classes.cardMedia}
                image= {typeof value ==="string" ? value :  URL.createObjectURL(value[0])}
                />
                <CardContent className={classes.cardContent}>
                    <IconButton size="small" onClick={()=>onChange([])}>
                        <DeleteRounded fontSize="small"/>
                    </IconButton>
                    <IconButton size="small"onClick={()=>setOpen(true)}>
                        <EditRounded fontSize="small"/>
                    </IconButton>
                </CardContent>
            </Card>
            <ImageEdit open={open} handleClose={()=>setOpen(false)} handleChange={onChange} path={typeof value ==="string" ? value :  value[0]} />
            </>
            }
            

            
        </div>
    );
}

InputFile.defaultProps = {
    context:null,
    value:[]
}
// import React from 'react'
// import Dropzone from 'react-dropzone'

// const InputFile = (props)=>{

//     return(
//         <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
//         {({getRootProps, getInputProps}) => (
//             <section>
//             <div {...getRootProps()}>
//                 <input {...getInputProps()} />
//                 <p>Drag 'n' drop some files here, or click to select files</p>
//             </div>
//             </section>
//         )}
//         </Dropzone>
//     );
// }

export default InputFile;