import { Divider } from '@material-ui/core';
import { colors, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Card, Container } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import MaterieDeoTable from 'src/components/MaterieDeoTable';
import Page from 'src/components/Page';
import ModaleModificaMateria from 'src/modali/ModaleModificaMateria';
import ModaleProformaDeo from 'src/modali/ModaleProformaDeo';
import ModaleRefertoDeo from 'src/modali/ModaleRefertoDeo';
import apiClient from 'src/utils/apiClient';
import materialColor from 'src/utils/randomColor';
import DeodorazioneHeader from './DeodorazioneHeader';
import DocumentiPanel from './DocumentiPanel';
import StateStepper from './StateStepper';
import DeleteIcon from '@material-ui/icons/Delete';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import ModaleConferma from 'src/modali/ModaleConferma';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import cyan from '@material-ui/core/colors/cyan';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import TabellaCannabinoidi from './TabellaCannabinoidi';

const mapStateToProps = (state)=>{
    return{
      user:state.user
    }
}

const MyAlert = (props) => {
  return <Alert elevation={6} variant="filled" {...props} />;
}

const themeBtn = createMuiTheme({
  palette: {
    primary: {
      main: red[500],
    },
    secondary:{
      main:cyan[500]
    },

  },
  textSecondary:'#fff',
});

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    card:{
      padding:"16px 0px",
      margin:"0px 16px",
  
    },
    lightBack:{
      backgroundColor:colors.grey[100]
    },
    refCardList:{
      display:"flex",
      flexWrap:"wrap"
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    actionPanel:{
      display:"flex",
      flexDirection:"column",
    }
}));

const DeodorazionePageView = (props)=>{
    const classes = useStyles();
    let param = useParams();
    const navigate = useNavigate();
    const id = parseInt(param.id);
    const [deodorazione, setDeodorazione] = useState({});
    const [dataFattura,setDataFattura] = useState(null);
    const [isDeleting,setIsDeleting] =  useState(false);
    const [modificaRigaOpen, setModificaRigaOpen] = useState(false);
    const [modificaRiga, setModificaRiga] =  useState(null);
    const [referto, setReferto] = useState(null);
    const [refertoKind, setRefertoKind] = useState(null);
    const [makeProforma, setMakeProforma] = useState(false);
    const [isSavingProforma, setIsSavingProforma] = useState(false);
    const [erroreProforma, setErroreProforma] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteComplete,setDeleteComplete] = useState(null);
    const [snackVariant, setSnackVariant] = useState('info');

    const openReferto=(i,k)=>{
      setReferto(deodorazione.materie[i]);
      setRefertoKind(k);
    }

    const setColor = (a)=>{
    
        for( var i in a.materie ){
          a.materie[i].color = materialColor();
        }
        setDeodorazione(a);
    }

    const openModificaRiga = (i) =>{
      setModificaRiga(deodorazione.materie[i])
      setModificaRigaOpen(true);
    }
  
    const closeModificaRiga = ()=>{
      setModificaRiga(null)
      setModificaRigaOpen(false);
    }

    useEffect(()=>{
        apiClient.get('api/deodorazione/'+id)
        .then(response => {
          console.log(response);
          setColor(response.data.deo);
          setDataFattura(response.data.deo.payment?.data_fatturazione? response.data.deo.payment.data_fatturazione : null);
        }).catch(err=>{
          navigate('/404');
        });
    
      },[param.id]);

    const handleDateChange = (date)=>{
        setIsDeleting(true);
        var post = new URLSearchParams();
        post.append('data_fatturazione',moment(date).format("DD/MM/YYYY") );
    
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
    
        apiClient.put('api/payment/fattura/'+deodorazione.payment.id, post,config)
        .then(response => {
          console.log(response);
          setDataFattura(date);
          setIsDeleting(false);
        })
        .catch(err=>{
          console.log(err);
          setIsDeleting(false);
        })
    }

    const disableProforma = ()=>{
      if(!deodorazione.materie) return true;

      const c = deodorazione.materie.filter(el=> el.peso_rilevato ==null); //el.analisi_pre === null ||
      return c.length>0;
    }

    const saveProforma = (materie,tot)=>{
      setIsSavingProforma(true);
      setErroreProforma("");

      const post = new FormData();
      post.append('materie',JSON.stringify(materie));
      post.append('tot',tot);

      apiClient.post('api/proformaDeo/'+deodorazione.id,post)
      .then(response=>{
        setDeodorazione(response.data.deo);
        setIsSavingProforma(false);
        setMakeProforma(false);
      })
      .catch(err=>{
        console.log(err)
        setIsSavingProforma(false);
        setErroreProforma("OPS, qualcosa è andato storto");
      })
      
    }

    const deleteAction = ()=>{
      setOpenDelete(false);
      setIsDeleting(true);

      apiClient.post('api/deodorazione/elimina/'+deodorazione.id)
      .then(response =>{
        if(response.data.error){
          setDeleteComplete(false);
          console.log(response.data.error);
          setIsDeleting(false)
        }else{
          setDeleteComplete(true);
          setIsDeleting(false)
          navigate('/');
        }
      })
      .catch(err=>{
        setIsDeleting(false);
        setDeleteComplete(false);
      })
    }

    const handleCloseDelete= ()=>{
      setDeleteComplete(null);
    }

    return (
        <Page
        title="deodorazione"
        className= {classes.root}
        >
            <Card className={classes.card}>
                <Container maxWidth={false}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={9}>
                            <DeodorazioneHeader 
                            deodorazione={deodorazione} 
                            isAdmin={props.user.role_id === 1} 
                            dataFattura={dataFattura}
                            handleDateChange={handleDateChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          
                          

                          {props.user.role_id === 1 && 
                            <>
                            <Grid item xs={12} style={{marginBottom:24}}>
                              <ThemeProvider theme={themeBtn}>
                                <Button onClick={()=>setOpenDelete(true)} variant="contained" color="primary" startIcon={<DeleteIcon />}>Elimina</Button>
                              </ThemeProvider>
                            </Grid>

                            <Grid item xs={12} >
                              <Button variant="contained" color="primary" disabled={disableProforma()} onClick={()=>setMakeProforma(true)}>Genera proforma</Button>
                            </Grid>
                            </>
                          } 
                        </Grid>

                        <Grid item xs={12} style={{marginTop:32}}>
                            <DocumentiPanel deodorazione={deodorazione}/>
                        </Grid>

                        <Grid item xs={12} style={{marginTop:32}}>
                            <Divider style={{backgroundColor:"#274AA7"}}/>
                            <Typography variant="button">Stato delle Analisi</Typography>
                        </Grid>

                        <Grid item xs={12} >
                            <StateStepper deodorazione={deodorazione} setDeodorazione = {setColor}/>
                        </Grid>

                        <Grid item xs={12} style={{marginTop:32}}>
                            <Divider style={{backgroundColor:"#274AA7"}}/>
                            <Typography variant="button">Materie</Typography>
                        </Grid>

                        <Grid item xs={12} >
                            <MaterieDeoTable 
                            materie={deodorazione.materie} 
                            stato={deodorazione.stato}
                            idu={deodorazione.id_user ? deodorazione.id_user : null}
                            ido={deodorazione.id}
                            openModificaRiga = {(i)=>openModificaRiga(i)}
                            openReferto={(i,k)=>openReferto(i,k)}
                            />
                        </Grid>

                        {props.user.role_id === 1 && 
                        <>
                        <Grid item xs={12} style={{marginTop:32}}>
                            <Divider style={{backgroundColor:"#274AA7"}}/>
                            <Typography variant="button">Reports</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TabellaCannabinoidi deo={deodorazione} />
                        </Grid>
                        </>
                        }
                        
                    </Grid>
                </Container>
            </Card>

            <ModaleModificaMateria 
            open={modificaRigaOpen}
            close={closeModificaRiga}
            materia={modificaRiga}
            uid={deodorazione.id_user ? deodorazione.id_user : null}
            setAnalisi = {setColor}
            />

            <ModaleRefertoDeo 
            open={referto!==null} 
            close={()=>setReferto(null)} 
            title={"Carica Referto " + refertoKind} 
            kind={refertoKind}
            riga={referto} 
            idu={deodorazione.id_user ? deodorazione.id_user : null}
            ido={deodorazione.id }
            setAnalisi = {setColor}
            />

            <ModaleProformaDeo 
            m={deodorazione?.materie? deodorazione.materie : []}
            open = {makeProforma}
            handleClose={()=>setMakeProforma(false)}
            save={saveProforma}
            isSaving={isSavingProforma}
            errore={erroreProforma}
            />

            <ModaleConferma 
            open={openDelete}
            close={()=>setOpenDelete(false)}
            title="Elimina Lavorazione"
            testo="Stai per eliminare una lavorazione. Una volta eliminata non sarà più possibile recuperare i dati. Sei sicuro di volerlo eliminare?"
            subTesto ={<Typography>Per eliminare fare click su <span style={{color:'#27a74a'}}>Continua</span></Typography>}
            salva={deleteAction}
            />


            <Snackbar 
            open={deleteComplete !== null} 
            autoHideDuration={3000} 
            onClose={handleCloseDelete} 
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
              <MyAlert onClose={handleCloseDelete} severity={deleteComplete? "succes" : deleteComplete===null?"info":"warning"}>
                {deleteComplete && "Ordine Eliminato con successo"}
                {deleteComplete===false && "Ops,quancosa è andato storto"}
                {snackVariant===null && ""}
              </MyAlert>
            </Snackbar>

            <Backdrop className={classes.backdrop} open={isDeleting}>
              <CircularProgress color="primary" />
            </Backdrop>
        </Page>
    );
}

export default connect(mapStateToProps)(DeodorazionePageView);