import React, {useState, useEffect,Fragment} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import InputAutoComplete from 'src/components/forms/InputAutoComplete';
import InputText from 'src/components/forms/InputText';
import InputSelect from 'src/components/forms/InputSelect';
import apiClient from 'src/utils/apiClient';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const styles = {
    appBar:{
      position: 'relative',
      backgroundColor:"#5AB66D"
    }
  }

  const useStyles = makeStyles((theme) => ({
    dialog:{
        paddingBottom:40
    },
    bottomFixed:{
        position:"fixed",
        bottom:0,
        maxHeight:40,
        left:0,
        right:0,
        backgroundColor:"#ffffff",
        padding:'0px 8px',
        display: 'flex',
        alignItems: 'center',
        marginBottom:8
    },
    circular:{
        width:'30px !important',
        height:'30px !important',
        marginRight:8
    },
    grid:{
        padding:'8px 16px'
    }

  }))
  const defaultCartellino = {
        id:null,
        codice:'',
        varieta:{
            nome:"",
            id:null
        }
    };

  const ModaleModificaRiga = (props)=>{
    const {open,close,riga} = props;
    const classes = useStyles();
    const [nome_campione, setNomeCampione] = useState('');
    const [cartellino, setCartellino] = useState( defaultCartellino ); 
    const [cartellinoText, setCartellinoText] = useState(''); 
    const [isSaving, setIsSaving] = useState(false);
    const [grammatura, setGrammatura] = useState('');
    const [lotto, setLotto] = useState('');
    const [descrizione, setDescrizione] = useState('');

    

    const onChange = (e,val,r)=>{
        if(r!=="clear"){
            if(typeof val === "string" ){
                setCartellino({
                    id:null,
                    codice:val,
                    varieta:{
                        nome:"",
                        id:null
                    }
                });
            }else if(typeof val == "object"&& val !== null){
                setCartellino({
                    id:val.id,
                    codice:val.codice,
                    varieta:{
                        nome:val.varieta.nome,
                        id:val.varieta.id
                    }
                })
            }else{
                setCartellino(defaultCartellino);
            }     
        }else{
            setCartellino(defaultCartellino);
        }
         
    }

    const onVarietaChange =(e,val,r)=>{
        if(r!=="clear"){
            if(val){
                setCartellino({
                    id:cartellinoText!==cartellino.codice ? null:cartellino.id,
                    codice:cartellinoText,
                    varieta:{
                        nome:val.nome,
                        id: val.id
                    }
                }) 
            }else{
                setCartellino({
                    ...cartellino,
                    varieta:{
                        nome:'',
                        id: null
                    }
                })
            }    
        }
        
        

    }
    

    useEffect(()=>{
        if(riga){
            console.log(riga);
            setNomeCampione(riga.nome_campione);
            setCartellino({
                id: riga.cartellino.id,
                codice:riga.cartellino.codice,
                varieta:{
                    nome:riga.cartellino.varieta.nome,
                    id: riga.cartellino.varieta.id
                }
            });

            setCartellinoText(riga.cartellino.codice);
            
            setGrammatura(riga.grammatura);
            setLotto(riga.lotto_coltivazione);
            setDescrizione(riga.descrizione);  
        }
        
    },[riga]);

    const handleSubmit = ()=>{
        setIsSaving(true);
        var post  = new URLSearchParams();
        post.append('nome_campione',nome_campione);
        post.append('grammatura', grammatura);
        post.append('lotto_coltivazione',lotto);
        post.append('descrizione',descrizione);

        var c = {...cartellino};
        if(cartellinoText !== cartellino.codice){
            c={
                ...c,
                codice:cartellinoText,
                id:null
            }
        }
        
        post.append('cartellino',JSON.stringify(c) );
        
        const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        apiClient.put('api/modifica_riga/'+riga.id, post,config)
        .then(response=>{
            props.setAnalisi(response.data.analisi)
            //console.log(response);
        })
        .then(()=>{ setIsSaving(false); close() } )
    }

    return(
        <Dialog fullScreen open={open} onClose={close} TransitionComponent={Transition} PaperProps={{className:classes.dialog}}>
            <AppBar style={styles.appBar}>
                <Toolbar>
                <IconButton color="inherit" onClick={close} aria-label="Close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" style={{display:'flex'}}>
                    {"Modifica Riga "}
                </Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={0} className={classes.grid}>
                <Grid item sm={4} xs={12}>
                    <InputText value={nome_campione} label={"Nome Campione"} handleChange={setNomeCampione} />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <InputAutoComplete 
                    onChange={onChange} 
                    value={cartellino} 
                    inputValue={cartellinoText} 
                    onInputChange={(e,val)=>setCartellinoText(val)} 
                    url={'api/cartellini/'+props.uid} 
                    label={"Cartellino"}
                    getOptionSelected={(option, value) => option.codice === value.codice}
                    getOptionLabel={(option) => option.codice}
                    freeSolo
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    {/* <InputText value={varieta} label={"Varietà"} handleChange={setVarieta} /> */}
                    <InputAutoComplete 
                    onChange={onVarietaChange} 
                    value={cartellino.varieta} 
                    url={'api/varieta'} 
                    label={"Varietà"}
                    getOptionSelected={(option, value) => option.nome === value.nome}
                    getOptionLabel={(option) => option.nome}
                    disabled={cartellino.id!==null && cartellino.codice===cartellinoText}
                    />
                </Grid>

                <Grid item sm={4} xs={12}>
                    <InputText value={grammatura} label={"gr"} handleChange={setGrammatura} />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <InputText value={lotto} label={"Lotto"} handleChange={setLotto} />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <InputSelect 
                    value={descrizione} 
                    handleChange={setDescrizione} 
                    label="Tipologia" 
                    items={[
                        {label:"Infiorescenza", value:"infiorescenza"},
                        {label:"Semilavorato",value:"semilavorato"},
                        {label:"Bio massa",value:"bio massa"}]} />
                </Grid>
            </Grid>
            <div className={classes.bottomFixed}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>SALVA</Button>
                {isSaving &&
                <Fragment>
                    <CircularProgress className={classes.circular}/>
                    <Typography variant="overline" style={{color:"#aaa"}}>Salvataggio in corso</Typography>
                </Fragment>
                }
            </div>
        </Dialog>
    );
  }

  export default ModaleModificaRiga;