import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import { FileText } from 'react-feather';
import { DeleteForeverRounded, OpenInNewRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems:'center',
    marginRight:14,
    minWidth:300
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function RefertoEsternoCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {referto, handleDelete,codice, canDelete} = props;

  return (
    <Card className={classes.root}>
      <div>
          <FileText size={48} color="#ccc"/>
      </div>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {referto.committente}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {codice}
          </Typography>
        </CardContent>
        <div className={classes.controls}>
          
          <IconButton aria-label="open" size="small" href={referto.referto_path} target="_blank" component="a">
            <OpenInNewRounded fontSize="small"/>
          </IconButton>
          
          {canDelete && 
          <IconButton aria-label="open" size="small" onClick={()=>handleDelete(referto.id)}>
            <DeleteForeverRounded fontSize="small"/>
          </IconButton>
          }
          
        </div>
      </div>
      
    </Card>
  );
}
