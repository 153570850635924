import React from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  makeStyles
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const   getMuiTheme = () => createMuiTheme({
    overrides: {

      MUIDataTable: {
        responsiveScrollMaxHeight: {
          maxHeight:'700px !important'
        }
      },

      MuiChip:{
        colorPrimary:{
          backgroundColor:'rgba(39, 167, 74, 0.85)'
        },
        colorSecondary:{
          backgroundColor:'#F6BD60'
        }
      }
    }
  });

  const useStyles = makeStyles(() => ({
    attesa:{backgroundColor:"#ff00f0"}
  }));


  const CustomersTable = (props)=>{
    let navigate = useNavigate();

    const columns = [
        {
          Label: "ID",
          name:'id',
          options: {
            filter: false,
          }
        },
        {
          label: "Codice",
          name: "cod",
          options: {
            filter: true,
            filterType:"textField"
          }
        },
        {
          label: "Azienda",
          name: "azienda",
          options: {
            filter: true,
            filterType:"textField",
            setCellProps: () => ({className: "ellipsis"})
          }
        },
        {
          label:"N° Ordini",
          name: 'ordine_count'
        },
        {
          label: "Telefono",
          name: "phone",
          
        },
        {
          label: "Email",
          name:"email",
          options: {
            filter: false,
          }
        },
        {
          name: "citta",
          label:"Citta",
          options: {
            filter: true,
          }
        },
        {
          label: "Indirizzo",
          name:"indirizzo",
          options: {
            filter: true,
            filterType:"textField",
          }
        },
        {
          label: "Codice Univoco",
          name:"codice_univoco",
          options: {
            filter: true,
            filterType:"textField",
          }
        },
      ];

      const options = {
          filter: true,
          responsive: 'standard',
          tableBodyMaxHeight:"700px",
          fixedHeaderOptions: {
            xAxis: false,
            yAxis: true
          },
          draggableColumns:{enabled:true},
          fixedSelectColumn:false,
          selectableRows:'none',
          print:false,
          download:false,
          viewColumns:false,
          search:true,
          pagination:false,
          elevation:0,
          onRowClick:(rowData)=>navigate("/customer/"+rowData[0])
        };


    return (
      <MuiThemeProvider theme={getMuiTheme()}>
			   <MUIDataTable data={props.customers} columns={columns} options={{...options,...props.opt}} />
      </MuiThemeProvider>
    );
  }

  CustomersTable.defaultProps = {
    opt:{}
  }
  export default CustomersTable;
