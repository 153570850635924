import { CircularProgress } from '@material-ui/core';
import { Backdrop } from '@material-ui/core';
import { Button, Card, CardContent, Container, Divider, makeStyles, Typography } from '@material-ui/core';

import  React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckBox from 'src/components/forms/CheckBox';
import Page from 'src/components/Page';
import apiClient from 'src/utils/apiClient';
import AnalisiPaymentTable from './AnalisiPaymentTable';
import VoucherForm from './VoucherForm';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    buttonGroup:{
        marginTop:24
    },
    card:{
        width:280,
        marginTop:16,
    },
    cardContent:{
        display:'flex',
        justifyContent:'space-between'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const CreaTicketView = (props)=>{
    const classes = useStyles();
    const [totale, setTotale] = useState('');
    const [utente, setUser] = useState(null);
    // const [selectedPayment, setSelectedPayment]= useState([]);
    const [selected, setSelected]= useState({});
    const [payment,setPayment] = useState([]);
    const [analisi_extra,setAnalisiExtra] = useState([]);
    const [extra,setExtra] = useState([]);
    const [senzaIva,setSenzaIva] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        apiClient.get('api/extras')
        .then(response => {
            setExtra(response.data.extras);
            var extras = [];
            for (let i = 0; i < response.data.extras.length; i++) {
                const element = response.data.extras[i];
                extras = [
                    ...extras,
                    {
                        ...element,
                        quantita:0,
                        rimanenti:0,
                        
                    }
                ]
                
            }
            setAnalisiExtra(extras)
        });
    },[]);

    useEffect(()=>{
        var extras = [...analisi_extra];
        
        extras = extras.map(el=>{
            var convenzione_extra = utente?.convenzione?.extras.find(e => e.id ==el.id);
            return {
                ...el,
                rimanenti: getRimanenti(el.quantita,el.id),
                prezzo: utente ? 
                        (convenzione_extra ? convenzione_extra.pivot.prezzo : utente.offerta.find(e => e.id == el.id).prezzo) 
                        : 
                        el.prezzo
            }
        });
        
        setAnalisiExtra(extras);

    },[utente]);

    useEffect(()=>{
        var extras = [...analisi_extra];
        extras = extras.map(el=>{
            return {
                ...el,
                rimanenti: getRimanenti(el.quantita,el.id)
            }
        });
        
        setAnalisiExtra(extras);

    },[selected]);

    const handleNumberField = (val,i)=>{
        const regex=new RegExp('[1-9][0-9]*');
        if(val==='' || regex.test(val) ){
            setAnalisiExtra([
                ...analisi_extra.slice(0,i),
                {
                    ...analisi_extra[i],
                    quantita:val,
                    rimanenti: getRimanenti(val,analisi_extra[i].id)
                },
                ...analisi_extra.slice(i+1)
            ])
        }
    }

    const handlesetUser = (e,val,r) =>{
            console.log(r);
            setUser(val);
        
    }

    const onTicketPriceChange = (val,i)=>{
        const regex=new RegExp('[1-9][0-9]*[.]?[0-9]*$');
        console.log(regex.test(val));
        if(val==='' || regex.test(val) ){
            setAnalisiExtra([
                ...analisi_extra.slice(0,i),
                {
                    ...analisi_extra[i],
                    prezzo:val,
                },
                ...analisi_extra.slice(i+1)
            ])
        }
    }

    const getRimanenti=(quantita, type)=>{
        var rimanenti= quantita ==='' ? 0 : quantita;
        
        Object.keys(selected).map(id_ordine =>{
            var righe_selezionate = selected[id_ordine];
            var ordine = payment.find(p => p.refer.id == id_ordine).refer;
            var righe_ordine = ordine.righe.filter(r => r.extras[0].id===type).map(r=>r.id);
            
            rimanenti -= righe_selezionate.filter(id_riga => righe_ordine.includes(id_riga)).length;
        })
        return rimanenti;
    }

    const handleSubmit = () =>{
        var post = new FormData();
        //post.append('numero_ticket',numero_ticket);
        post.append('id_user',utente.id);
        post.append('totale',calcolaTotale());

        var analisi = {};
        for(let i=0;i<analisi_extra.length; i++){
            if(parseInt(analisi_extra[i].quantita)>0){
                analisi[analisi_extra[i].id] = {
                    'numero_ticket':analisi_extra[i].quantita,
                    'rimanenti':analisi_extra[i].rimanenti,
                    'prezzo':analisi_extra[i].prezzo
                }
            }
            
            
        }
        post.append('analisi_extra',JSON.stringify(analisi));
        if(Object.keys(selected).length){
            post.append('selected', JSON.stringify(selected));
        }
        
        // for (let i = 0; i < selectedPayment.length; i++) {
        //     var j = selectedPayment[i];
        //     //payments_id.push( payment[j].id);
        //     post.append('payments_id[]',payment[j].id);
        // }
        post.append('senzaIva',senzaIva);

        apiClient.post('api/ticket/create',post)
        .then(response =>{
            if(response.data.error){
                console.log(response.data.error)
            }else{
                navigate('/')
                //console.log(response.data);
            }
        })
    }

    const calcolaTotale = () => {
        var tot =0;
        for (let i = 0; i< analisi_extra.length; i++) {
            const element = analisi_extra[i];
            tot+=element.prezzo * element.quantita;
        }
        console.log(tot);
        return tot;
    }

    return (
        <Page
        className={classes.root}
        title="Crea Voucher"
        >
            <Container maxWidth={false}>

                <VoucherForm 
                onTicketPriceChange={onTicketPriceChange} 
                user={utente} 
                onTicketChange={handleNumberField} 
                onUserChange={handlesetUser}
                totale={calcolaTotale()}
                analisi_extra={analisi_extra}
                senzaIva = {senzaIva}
                />
                <CheckBox label="Regime Fiscale senza IVA" value={senzaIva} handleChange={setSenzaIva} />

                <Divider style={{backgroundColor:"#c9ddff", margin:"24px 0"}}/>

                <AnalisiPaymentTable 
                utente={utente} 
                payment={payment} 
                setPayment={setPayment}
                selected={selected}
                setSelected={setSelected}
                />

                <Divider style={{backgroundColor:"#c9ddff", margin:"24px 0"}}/>

                

                <Button variant="contained" color="primary" onClick={handleSubmit}>SALVA</Button>    
            </Container>
            <Backdrop className={classes.backdrop} open={isSaving}>
                <CircularProgress color="primary" />
            </Backdrop>
        </Page>
    );
}
export default CreaTicketView;