import { Button, Card, CardContent, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useState } from 'react';
import PacchettoTooltip from 'src/components/PacchettoTooltip';
import ModaleSaldaPagamento from 'src/modali/ModaleSaldaPagamento';
import DettagliRigaTicket from './DettagliRigaTicket';
import PagamentoProgress from './PagamentoProgress';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Divider } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import ReminderProforma from 'src/components/ReminderProforma';


const MuiTableCell = withStyles({
    root: {
      borderBottom: "none"
    }
})(TableCell);

const useStyles = makeStyles((theme)=>({
    avatar: {
        height: 60,
        width: 60,
        marginRight:16
    },
    marginBot:{
        marginBottom:24
    },
    ticketTot:{
        fontSize:16
    },
    saldato:{
        // backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.main,
        textAlign:"center"
    },
    daSaldare:{
        // backgroundColor:theme.palette.alert.main,
        color:theme.palette.alert.main,
        textAlign:"right"
    },
    dataElem:{
        [theme.breakpoints.up('md')]:{
            width:300
        },
        [theme.breakpoints.down('sm')]:{
            marginBottom:4
        }
    },
    dataList:{
        display:"flex",
        marginBottom:36,
        [theme.breakpoints.down('sm')]:{
            flexDirection:"column"
        }
    },
    scontato:{
        textDecoration:"line-through"
    }
}))


const TicketRefer = (props) =>{
    const {ticket,payment,setPagato,perc,setPerc,user,handleDateChange}= props;
    const classes = useStyles()
    const [paga,setPaga] = useState(false);

    
    var imponibile= parseFloat(payment.totale);
    var iva = payment.senzaIVA ? 0 : imponibile * 0.22;
    var tot = imponibile+iva;

    const togglePaga = ()=>{
        setPaga(!paga);
        setPerc('');
    }
    const salva = ()=>{
        setPagato()
        .then(()=>{
            togglePaga()
        })
    }
    return (
        <Card>
            <CardContent>
                <Grid container spacing={0}>
                    {ticket && 
                    <>
                        <Grid item xs={12} className={classes.marginBot}>
                            <Typography variant="h5">Riferimento: Acquisto Voucher {ticket.codice}</Typography>
                        </Grid>

                        <Grid item xs={12}  className={classes.dataList}>
                            <div className={classes.dataElem}>
                               <Typography variant="h6" color="primary">Creato il</Typography>
                                <Typography variant="subtitle1">{moment(ticket.created_at).format("DD/MM/YYYY")}</Typography> 
                            </div>

                            <div className={classes.dataElem}>
                                <Typography variant="h6" color="primary">Fattura del</Typography>

                                {user.role_id ===1 ? 
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label=""
                                format="DD/MM/YYYY"
                                value={payment.data_fatturazione}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                />
                                </MuiPickersUtilsProvider>
                                :
                                <Typography variant="subtitle1">{payment.data_fatturazione ?  moment(payment.data_fatturazione).format("DD/MM/YYYY"): <i>nessuna fattura emessa</i>}</Typography>
                                }
                                
                            </div>

                            {payment.refer.convenzione && 
                            <div className={classes.dataElem}>
                                <Typography variant="h6">Pagamento Convenzionato</Typography>
                                <Chip color="primary" label={payment.refer.convenzione.nome_convenzione} />
                            </div>
                            }

                            {payment.proforma && 
                            <div className={classes.dataElem}>
                                <ReminderProforma id_user={payment.user.id} id_payment={payment.id} />
                            </div>
                            }
                            
                        </Grid>

                        {payment.proforma && 
                        <Grid item xs={12} className={classes.marginBot}>
                            <Button size="small" href={payment.src} target="_blank" component="a" color="primary" variant="outlined">
                                Vedi proforma
                            </Button>
                        </Grid>
                        }
                        <Grid item xs={12}>
                            <Divider color="primary"/>
                        </Grid>
                        

                        <Grid item sm={5} xs={12}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow style={{backgroundColor:"rgba(0,0,0,0.12)"}}>
                                            <TableCell>Tipo</TableCell>
                                            <TableCell>Rimanenti</TableCell>
                                            <TableCell>Totali</TableCell>
                                            <TableCell>Prezzo</TableCell>
                                            {ticket.convenzione && 
                                            <TableCell>Convenzione</TableCell>
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ticket.extras.map((tipo)=>
                                            <TableRow key={tipo.id}>
                                                <TableCell>
                                                    <PacchettoTooltip pacchetto={tipo} />
                                                </TableCell>
                                                <TableCell>
                                                    {/* {tipo.pivot.rimanenti}<span className={classes.ticketTot}>{"/"+tipo.pivot.numero_ticket}</span> */}
                                                    {tipo.pivot.rimanenti}
                                                </TableCell>
                                                <TableCell>
                                                    {tipo.pivot.numero_ticket}
                                                </TableCell>
                                                <TableCell>
                                                    <span className={ticket.convenzione ? classes.scontato : null}>
                                                        {(ticket.convenzione ? tipo.prezzo : tipo.pivot.prezzo)+ "€/Cad"}
                                                    </span>
                                                    
                                                </TableCell>
                                                {ticket.convenzione && 
                                                <TableCell>
                                                    {tipo.pivot.prezzo + "€/Cad"}
                                                </TableCell>
                                                }
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>  

                        <Grid item sm={2} xs={12}></Grid>
                        <Grid item sm={5} xs={12}>
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        
                                        <TableRow >
                                            <MuiTableCell variant="head" align="right">Imponibile</MuiTableCell>
                                            <MuiTableCell align="right">
                                                {imponibile}€
                                            </MuiTableCell>
                                        </TableRow>

                                        <TableRow >
                                            <MuiTableCell variant="head" align="right">
                                                IVA<br/>
                                                {payment.senzaIVA==1 && <Typography variant="caption"><em>*Regime fiscale senza IVA</em></Typography>}
                                            </MuiTableCell>
                                            <MuiTableCell align="right">
                                                {iva}€
                                            </MuiTableCell>
                                        </TableRow>

                                        <TableRow >
                                            <MuiTableCell variant="head" align="right">TOTALE</MuiTableCell>
                                            <MuiTableCell align="right">
                                                {tot}€
                                            </MuiTableCell>
                                        </TableRow>
                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <PagamentoProgress value={payment.perc} />
                            {payment.pagato ? 
                            
                            <Typography variant="h4" className={classes.saldato}>SALDATO</Typography>
                            : 
                            <>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell><Typography variant="h4" className={classes.daSaldare}>DA SALDARE</Typography></TableCell>
                                                <TableCell><Typography variant="h4" className={classes.daSaldare}>{(tot*(1-payment.perc/100)).toFixed(2)}€</Typography></TableCell>
                                            </TableRow>
                                        </TableBody>    
                                    </Table>
                                </TableContainer>
                                {user.role_id===1 && 
                                <>
                                <Button onClick={togglePaga}>Imposta come pagato</Button>
                                <ModaleSaldaPagamento open={paga} toggle={togglePaga} salva={salva} payment={payment} perc={perc} setPerc={setPerc}/>
                                </>
                                }
                                
                            </>
                            }
                        </Grid>   

                        
                        <Grid item xs={12} className={classes.marginBot}></Grid>
                        
                        <Grid item xs={12}>
                            <DettagliRigaTicket righe={ticket.riga} idu={ticket.id_user}/>
                        </Grid>
                    </>
                    }
                </Grid>
            </CardContent>
        </Card>

    );
}

export default TicketRefer;