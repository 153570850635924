import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import InputText from 'src/components/forms/InputText';
import InputSelect from 'src/components/forms/InputSelect';
import apiClient from 'src/utils/apiClient';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileChangePassword = ({user, ...rest }) => {
  const classes = useStyles();
  
  const [values, setValues] = useState({
    password_old:"",
    password_new:"",
    password_confirm:""
  });
  const [error,setError]  = useState(false);
  const [errorMsg, setErrorMsg] =  useState('');

  const validate = () =>{
    if(values.password_old === "") {setErrorMsg('inserisci la tua vecchia password');return false;}
    if(values.password_new === "") {setErrorMsg('inserisci una nuova password');return false;}
    if(values.password_confirm === "") {setErrorMsg('conferma la nuova password');return false;}
    if(values.password_new !== values.password_confirm) {setErrorMsg('la nuova password non coincide con la conferma');return false;}
    
    return true;
  }
  
  const handleSubmit = () =>{
    if( validate() ){
      var post  = new URLSearchParams();
      post.append('data', JSON.stringify(values) );

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }

      apiClient.put('api/change_password/'+user.id, post,config)
      .then(response => {
        setValues({
          password_old:"",
          password_new:"",
          password_confirm:""
        })
      })
      .catch(err =>{ 
        setErrorMsg(err.response.data.msg);
        setError(true);
      });
    }else{
      setError(true);
    }
  }

  const handleChange = (event) => {
    setError(false);
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  return (
      <Card>
        <CardHeader
          title="Modifica Password"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <InputText label="Vecchia Password" value={values.password_old} handleChange={handleChange} withEvent name="password_old" type="password"/>
            </Grid>

            <Grid item md={4} xs={12}>
              <InputText label="Nuova Password" value={values.password_new} handleChange={handleChange} withEvent name="password_new" type="password"/>
            </Grid>
            <Grid item md={4} xs={12}>
              <InputText label="Conferma Password" value={values.password_confirm} handleChange={handleChange} withEvent name="password_confirm" type="password"/>
            </Grid>
            
          </Grid>
        </CardContent>
        <Divider />
        
        <Box display="flex" justifyContent="space-between" p={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Salva Password
          </Button>
          <div>
            {error && <Typography color="error">{errorMsg}</Typography>}
          </div>
          
        </Box>
      </Card>
  );
};

export default ProfileChangePassword;
