import React, { useEffect, useState } from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import urltoFile from 'src/utils/urlToFile';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Icon } from '@material-ui/core';

const useStyles = makeStyles({
    commands:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
        
    }
})

const ImageEdit = (props)=>{
    const {open, handleClose,path,handleChange} = props;
    const [image, setImage] = useState(null);
    const [cropper, setCropper] = useState();

    const classes = useStyles();

    useEffect(()=>{
        console.log(typeof path);
        if(typeof path==="string"){
            setImage('https://cors-anywhere.herokuapp.com/'+path);
        }else{
           setImage(URL.createObjectURL(path)); 
        }
        
    },[path])

    // const onChange = (e) => {
    //     e.preventDefault();
    //     let files;
    //     if (e.dataTransfer) {
    //       files = e.dataTransfer.files;
    //     } else if (e.target) {
    //       files = e.target.files;
    //     }
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //       setImage(reader.result);
    //     };
    //     reader.readAsDataURL(files[0]);
    // };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
          //setCropData(cropper.getCroppedCanvas().toDataURL());
          const img =cropper.getCroppedCanvas().toDataURL();
          //handleChange([cropper.getCroppedCanvas().toDataURL()]);
          urltoFile(img,'ref_'+Date.now()+'.png',"image/png" ).then(file=>handleChange([file])).then(()=>handleClose());
        }
    };
    
    return(
        <Dialog  maxWidth={false} open={open} onClose={handleClose}>
            <Cropper
            style={{ minHeight: 400, minWidth: 300 }}
            initialAspectRatio={1}
            aspectRatio={1}
            src={image}
            viewMode={1}
            guides={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
                setCropper(instance);
            }}
            />
            <div className={classes.commands}>
                <Icon onClick={()=>cropper.rotate(-90)}>rotate_left</Icon>
                <Icon onClick={()=>cropper.rotate(90)}>rotate_right</Icon>
            </div>
            <Button onClick = {getCropData}>SALVA</Button>
        </Dialog>
    );

}

export default ImageEdit;