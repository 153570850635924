import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { Box, IconButton } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles(()=>({
  avatar: {
      height: 60,
      width: 60,
      marginRight:16
  },
  marginBot:{
      marginBottom:24
  }
}))

const DeodorazioneHeader =(props)=>{
  const classes = useStyles();
  return (
    <Grid container spacing={3} >
      
      <Grid item  sm={12} xs={6} >
        
        {props.deodorazione.user &&
          <Box alignItems="center" display="flex" flexDirection="row">
            {props.deodorazione.user.path ? 
              <Avatar className={classes.avatar} src={props.deodorazione.user.path}/>
              :
              <Avatar className={classes.avatar}>
                  {props.deodorazione.user.cod? props.deodorazione.user.cod.toUpperCase(): ""}
              </Avatar>
            }
            {props.isAdmin ?
            <Typography color="textPrimary" variant="h3" component={Link} to={`/customer/${props.deodorazione.user.id}`}>{props.deodorazione.user.azienda}</Typography>
            :
            <Typography color="textPrimary"  variant="h3" > {props.deodorazione.user.azienda} </Typography>
            }
            
          </Box>
        }
      </Grid>

      <Grid item  sm={4} xs={12} >
        <Typography variant="h6" color="primary">Codice Interno</Typography>
        {props.deodorazione.codice &&
        <Typography variant="subtitle1">{props.deodorazione.codice}</Typography>
        }
      </Grid>

      <Grid item  sm={4} xs={12} >
        <Typography variant="h6" color="primary">N° Genetiche</Typography>
        {props.deodorazione.materie &&
        <Typography variant="subtitle1">{props.deodorazione.materie.length}</Typography>
        }
      </Grid>
      <Grid item  sm={4} xs={12} ></Grid>
      

      <Grid item  sm={4} xs={12} >
        <Typography variant="h6" color="primary">Data Ordine</Typography>
        {props.deodorazione.data_acquisto &&
        <Typography variant="subtitle1">{moment(props.deodorazione.data_acquisto).format("DD/MM/YYYY")}</Typography>
        }
      </Grid>
      

      <Grid item  sm={4} xs={12} >
        <Typography variant="h6" color="primary">Data Accettazione</Typography>
        {props.deodorazione.data_accettazione &&
        <Typography variant="subtitle1">{moment(props.deodorazione.data_accettazione).format("DD/MM/YYYY")}</Typography>
        }
      </Grid>
      
      {props.deodorazione.payment &&
      <Grid item  sm={4} xs={12} >
        <Typography variant="h6" color="primary">Data Fatturazione</Typography>
        {props.isAdmin ? 
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label=""
          format="DD/MM/YYYY"
          value={props.dataFattura}
          onChange={props.handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          />
        </MuiPickersUtilsProvider>
          
        :
        (props.deodorazione.payment.data_fatturazione ?
          <Typography variant="subtitle1">{moment(props.deodorazione.payment.data_fatturazione).format("DD/MM/YYYY")}</Typography>
          :
          <Typography component="span" variant="subtitle1"><i>Fattura non emessa</i></Typography>
        )
        }

      </Grid>
      }
      
    </Grid>
  );
}

export default DeodorazioneHeader;
