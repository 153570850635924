import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import styles from './styles';

function AziendaDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { azienda, forma_societaria, p_iva, codice_fiscale, codice_univoco,pec,cap,citta,indirizzo } = formValues;
  return (
    <>
        {/* <Grid item xs={6} sm={6}>
            <Typography variant="caption" gutterBottom>Azienda</Typography> 
        </Grid>
        <Grid item xs={6} sm={6}>
            <Typography gutterBottom>{`${azienda} ${forma_societaria}`}</Typography> 
        </Grid> */}

        <Grid item xs={12} sm={6} className={classes.details}>
            <Typography variant="overline" color="secondary">Ragione Sociale: </Typography>
            <Typography variant="subtitle2" className="ellipsis">{`${azienda}`}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.details}>
            <Typography variant="overline" color="secondary">Forma Societaria: </Typography>
            <Typography variant="subtitle2" className="ellipsis">{`${forma_societaria}`}</Typography>
        </Grid>

        {/* <Grid item xs={6} sm={6}>
            <Typography variant="caption" gutterBottom>Partita Iva</Typography> 
        </Grid>
        <Grid item xs={6} sm={6}>
            <Typography gutterBottom>{`${p_iva}`}</Typography> 
        </Grid> */}

        <Grid item xs={12} sm={6} className={classes.details}>
            <Typography variant="overline" color="secondary">Partita IVA: </Typography>
            <Typography variant="subtitle2" className="ellipsis">{`${p_iva}`}</Typography>
        </Grid>

        {/* <Grid item xs={6} sm={6}>
            <Typography variant="caption" gutterBottom>CF</Typography> 
        </Grid>
        <Grid item xs={6} sm={6}>
            <Typography gutterBottom>{`${codice_fiscale}`}</Typography> 
        </Grid> */}
        <Grid item xs={12} sm={6} className={classes.details}>
            <Typography variant="overline" color="secondary">CF: </Typography>
            <Typography variant="subtitle2" className="ellipsis">{`${codice_fiscale}`}</Typography>
        </Grid>

        {/* <Grid item xs={6} sm={6}>
            <Typography variant="caption" gutterBottom>Codice univoco</Typography> 
        </Grid>
        <Grid item xs={6} sm={6}>
            <Typography gutterBottom>{`${codice_univoco}`}</Typography> 
        </Grid> */}
        <Grid item xs={12} sm={6} className={classes.details}>
            <Typography variant="overline" color="secondary">Codice Univoco: </Typography>
            <Typography variant="subtitle2" className="ellipsis">{`${codice_univoco}`}</Typography>
        </Grid>

        {/* <Grid item xs={6} sm={6}>
            <Typography variant="caption" gutterBottom>Pec</Typography> 
        </Grid>
        <Grid item xs={6} sm={6}>
            <Typography gutterBottom>{`${pec}`}</Typography> 
        </Grid> */}
        <Grid item xs={12} sm={6} className={classes.details}>
            <Typography variant="overline" color="secondary">Pec: </Typography>
            <Typography variant="subtitle2" className="ellipsis">{`${pec}`}</Typography>
        </Grid>

        {/* <Grid item xs={6} sm={6}>
            <Typography variant="caption" gutterBottom>Cap</Typography> 
        </Grid>
        <Grid item xs={6} sm={6}>
            <Typography gutterBottom>{`${cap}`}</Typography> 
        </Grid> */}

        {/* <Grid item xs={6} sm={6}>
            <Typography variant="caption" gutterBottom>Citta</Typography> 
        </Grid>
        <Grid item xs={6} sm={6}>
            <Typography gutterBottom>{`${citta}`}</Typography> 
        </Grid> */}

        {/* <Grid item xs={6} sm={6}>
            <Typography variant="caption" gutterBottom>Indirizzo</Typography> 
        </Grid>
        <Grid item xs={6} sm={6}>
            <Typography gutterBottom>{`${indirizzo}`}</Typography> 
        </Grid> */}
        <Grid item xs={12} sm={12} className={classes.details}>
            <Typography variant="overline" color="secondary">Indirizzo: </Typography>
            <Typography variant="subtitle2" >{`${citta}, ${cap}, ${indirizzo}`}</Typography>
        </Grid>
    </>
  );
}

export default AziendaDetails;