import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import { SaveOutlined } from '@material-ui/icons';
import apiClient from 'src/utils/apiClient';
import { connect } from 'react-redux';
import ModaleProforma from 'src/modali/ModaleProforma';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import FolderOpenRoundedIcon from '@material-ui/icons/FolderOpenRounded';
import SpeedRoundedIcon from '@material-ui/icons/SpeedRounded';

const mapStateToProps = (state)=>{
  return{
    user:state.user
  }
}

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: 'rgb(39, 167, 74)',
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(246, 189, 96) 0%,rgb(39, 167, 74) 100%)',
    },
  },
  completed: {
    '& $line': {
      borderColor: 'rgb(39, 167, 74)',
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(39, 167, 74) 0%, rgb(39, 167, 74) 100%)',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
    // height: 3,
    // border: 0,
    // backgroundColor: '#eaeaf0',
    // borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: 'rgb(39, 167, 74)',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: 'rgb(39, 167, 74)',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

function getSteps() {
  return [ 'In attesa dei campioni', 'Analisi in Corso','Analisi terminate'];//'Ordine ricevuto',
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor:'#fff',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    marginLeft:24,
  },
  step:{
    backgroundColor:"#eaeaf0",
    position:"relative",
    height:30,
    marginRight:30,
    marginLeft:6,
    
    "&:after":{
      content:'" "',
      position:"absolute",
      top:0,
      right:-30,
      // bottom:0,
      borderStyle:"solid",
      borderWidth:"15px 0 15px 30px",
      borderColor:"transparent transparent transparent #eaeaf0"
    },
    "&:before":{
      content:'" "',
      position:"absolute",
      top:0,
      left:-30,
      borderStyle:"solid",
      borderWidth:"15px 0 15px 30px",
      borderColor:"#eaeaf0 transparent #eaeaf0 transparent"
    }
  },
  step_first:{
    backgroundColor:"#eaeaf0",
    position:"relative",
    height:30,
    marginRight:30,
    "&:after":{
      content:'" "',
      position:"absolute",
      top:0,
      right:-30,
      // bottom:0,
      borderStyle:"solid",
      borderWidth:"15px 0 15px 30px",
      borderColor:"transparent transparent transparent #eaeaf0"
    },
  },
  stepActive:{
    backgroundColor:"#4FB365",
    "&:after":{
      content:'" "',
      borderColor:"transparent transparent transparent #4FB365"
    },
    "&:before":{
      content:'" "',
      borderColor:"#4FB365 transparent #4FB365 transparent"
    }
  }
}));

const StateStepper = (props)=>{
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [proforma,setProforma] = React.useState(false);
  const steps = getSteps();
  const [isSaving, setIsSaving]= React.useState(false);
  const [error, setError]= React.useState("");

  React.useEffect(()=>{
    if(typeof props.analisi.stato!= 'undefined'){
      setActiveStep(props.analisi.stato);
    }
  },[props.analisi.stato])

  const handleChange = (event) => {
    setActiveStep(event.target.value);
  };

  const save= ()=>{
    var post = new URLSearchParams();
    post.append('stato',activeStep);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    apiClient.put('api/stato_analisi/'+props.analisi.id, post, config)
    .then(response=>{
      props.setAnalisi(response.data.analisi);
    })
    .then(()=>{
      setProforma(false);
    })
    .catch(err=>{
      setIsSaving(false);
      setError('errore durante il salvataggio...');
    })
  }

  const handleSave = () =>{
    switch (activeStep) {
      case 3:
        setProforma(true);
        break;
      default:
        save();
        break;
    }
  }

  const saveWithProforma = (tot,offerta,savePrezzo)=>{
    setIsSaving(true);
    setError('');
    var post = new URLSearchParams();
    post.append('stato',activeStep);
    post.append('proforma', true);
    post.append('tot',tot);
    post.append('offerta',JSON.stringify(offerta));
    post.append('savePrezzo',savePrezzo);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    apiClient.put('api/stato_analisi/'+props.analisi.id, post, config)
    .then(response=>{
      props.setAnalisi(response.data.analisi);
      setIsSaving(false);
    })
    .then(()=>{
      setProforma(false);
    })
    .catch(err=>{
      setIsSaving(false);
      setError('errore durante il salvataggio...');
    })
  }

  const stepperStyle = (step,index)=>{
    if(index==0){
      return step>=(index+2) ? "step_first active" :  "step_first";
    }else{
      return step>=(index+2) ?  "step active" :  "step";
    }
  }


  return (
    <Grid container spacing={3} >
      <Grid container item  sm={props.user.role_id==1 ? 9 : 12} xs={12} >
      {/* <Stepper alternativeLabel activeStep={props.analisi.stato-1} connector={<QontoConnector />} >
        {steps.map((label,index) => (
          <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> */}
      {steps.map((label,index) => (
        <Grid item xs={4} key={index}>
          <div className={ stepperStyle(props.analisi.stato,index) }>
            {index == 0 && <HourglassEmptyRoundedIcon />}
            {index == 1 && <SpeedRoundedIcon  />}
            {index == 2 && <FolderOpenRoundedIcon />}
          </div>
          <Typography variant="subtitle2">{label}</Typography>
        </Grid>
      ))}
      </Grid>

      {props.user.role_id==1 && 
        <Grid item sm={3} xs={12} >
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Seleziona stato analisi</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={activeStep}
            onChange={handleChange}
          >
            {steps.map((label,index) => (
              <MenuItem key={index} value={index+2}>{label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton onClick={handleSave}><SaveOutlined/> </IconButton>
        </Grid>
      }
      
      <ModaleProforma 
      open={proforma} 
      handleClose={()=>{setError("");setProforma(false)}} 
      user={props.analisi? props.analisi.user : {}}
      payment={props.analisi?.payment? props.analisi.payment : null}
      tickets={props.analisi?.tickets? props.analisi.tickets : []}
      righe={props.analisi?.righe? props.analisi.righe : [] }
      simpleSave={save}
      proformaSave={saveWithProforma}
      codice={props.analisi?.codice? props.analisi.codice : ''}
      isSaving={isSaving}
      errore={error}
      convenzione={props.analisi?.convenzione? props.analisi.convenzione:null}
      />
    </Grid>
  );
}
export default connect(mapStateToProps)(StateStepper);
