import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

const PTabs = withStyles({
  indicator: {
    display: 'none',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const PTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    backgroundColor:theme.palette.primary.light,
    borderRadius:30,

    
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const PillTabs = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  return (
    <PTabs
      
      value={tabIndex}
      onChange={(e, index) => setTabIndex(index)}
    >
      <PTab label={'Esistente'} />
      <PTab label={'Nuovo'} />
      
    </PTabs>
  );
};

export default PillTabs;