import 'react-perfect-scrollbar/dist/css/styles.css';
import React, {useEffect} from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import {connect} from 'react-redux';
import {setUser, stopLoading, userLoggedIn, userLoggedOut,loadSettings } from 'src/redux/actions.js';
import apiClient from 'src/utils/apiClient';
import { logIn } from 'src/utils/auth';
import CookieBanner from './components/CookieBanner';


const mapStateToProps = function(state){
  return {
    isLoggedin:state.isLoggedin,
    isLoading:state.isLoading,
    user:state.user
  };
}

const mapDispatchToProps = dispatch => ({
    setUser: (user) => dispatch(setUser(user)),
    stopLoading: () => dispatch(stopLoading()),
    userLoggedIn: () => dispatch(userLoggedIn()),
    userLoggedOut: () => dispatch(userLoggedOut()),
    loadSettings:() => dispatch(loadSettings())
});


const App = (props) => {
  
  

  const routing = useRoutes(routes(props.isLoading,props.isLoggedin,props.user));

  useEffect(() => {
     apiClient.get('remember')
     .then(response => {
        if(response.data.user){
          console.log(response.data.user);
          props.setUser(response.data.user);
          props.userLoggedIn();
          logIn();
        }
     })
     .then(()=>{
        props.loadSettings();
     })
     .then( ()=> props.stopLoading()) 
  },[]);

  //check for remember me
  console.log(props.isLoading,props.isLoggedin);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}

      <CookieBanner />
    </ThemeProvider>
  );
};

export default connect(mapStateToProps,mapDispatchToProps)(App);
