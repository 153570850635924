import React from "react";
import Logo from "src/components/Logo";
import "./topBar.css"

const TopBar = ({children, navStyle,navRef})=>{

    return(
        <nav className="nav" style={navStyle} ref={navRef}>
            <div className="toolbar">
                <Logo className="logo"/>
                {children}
            </div>
        </nav>
    );
}

export default TopBar;