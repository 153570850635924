import React from 'react';
import {Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, makeStyles,withStyles, Typography, colors} from '@material-ui/core'
import InputText from 'src/components/forms/InputText';
import InputSelect from 'src/components/forms/InputSelect';
import InputAutoComplete from 'src/components/forms/InputAutoComplete';
import { Plus } from 'react-feather';
import InputFile from 'src/components/forms/InputFile';
import { Paper } from '@material-ui/core';
import PillTabs from 'src/components/PillTabs/indexs';
// import CartelliniSelect from 'src/components/CartelliniSelect';



const useStyles = makeStyles((theme)=>({
    indice:{
        marginRight:16,
        marginBottom:16,
        alignSelf:'flex-end'
    },
    riga:{
        
        borderBottom:2,
        borderBottomStyle:"dashed",
        borderBottomColor:theme.palette.primary.lighter
    },
    sticky:{
        position: "sticky",
        top: "1rem",
    },
    text:{flexGrow:1},
    subText:{color: theme.palette.text.secondary},
    textField:{width:280}
}))

const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(colors.indigo[300]),
      backgroundColor: colors.indigo[300],
      '&:hover': {
        backgroundColor: colors.indigo[400],
      },
    },
  }))(Button);

const AddRigheStep = (props)=>{
    const{el,handleRiga,handleCartellino,handleBlur,handleCartellinoText,handleVarieta,addSample,handleFile,cartellini,varieta,user } = props;
    const classes = useStyles();
    
    const isDisabled = ()=>{
        if(el.nome_campione=="" || el.grammatura=="" || el.extra=="") return true;
        return false;
    }
    return(
        <Paper elevation={8} style={{padding:"16px 8px"}} className={classes.sticky}>
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Typography variant="h4">Nuovo Campione</Typography>
                <Typography variant="body2" color="textSecondary">Inserisci i dati relativi ad un campione, seleziona quale analisi svolgere e premi <b>AGGIUNGI CAMPIONE</b> per inserirlo nell'ordine.</Typography>
                <br/>
                <Typography variant="caption" color="textSecondary" ><i>I campi contrassegnati con * sono obbligatori</i></Typography>
            </Grid>

            
            <Grid container spacing={1}  alignItems="center" >
                {/* <Typography color="primary" className={classes.indice}>{i+1}</Typography> */}
                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        1) inserisci i dati del campione
                    </Typography>
                </Grid>
                <Grid item md={6} xs>
                    <InputText 
                    label="Nome Campione*" 
                    value={el.nome_campione} 
                    name="nome_campione" 
                    handleChange={(e)=>handleRiga(e)}
                    withEvent
                    margin="dense"
                    />
                </Grid>

                <Grid item md={6} xs>
                    <InputText 
                    label="Gr.*" 
                    value={el.grammatura} 
                    name="grammatura" 
                    handleChange={(e)=>handleRiga(e)}
                    withEvent
                    margin="dense"
                    />
                </Grid>

                 <Grid item md={6} xs>
                    <InputText 
                    label="Lotto" 
                    value={el.lotto_coltivazione} 
                    name="lotto_coltivazione" 
                    handleChange={(e)=>handleRiga(e)}
                    withEvent
                    margin="dense"
                    />
                </Grid>

                 <Grid item md={6} xs>
                    <InputSelect
                    value={el.descrizione} 
                    handleChange={(e)=>handleRiga(e)} 
                    label="Tipologia" 
                    name="descrizione"
                    margin="dense"
                    withEvent
                    items={[
                        {label:"Infiorescenza", value:"infiorescenza"},
                        {label:"Semilavorato",value:"semilavorato"},
                        {label:"Bio massa",value:"bio massa"}
                    ]} 
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        2) inserisci varieta e cartellino sementi
                    </Typography>
                </Grid>

                <Grid item md={6} xs>
                    <InputAutoComplete 
                    onChange={(e,val,r)=>handleVarieta(e,val,r)} 
                    value={el.cartellino.varieta} 
                    // url={'api/varieta'} 
                    margin="dense"
                    opt={varieta}
                    label={"Varietà"}
                    getOptionSelected={(option, value) => option.nome === value.nome}
                    getOptionLabel={(option) => option.nome}
                    disabled={el.cartellino.id!==null && el.cartellino.codice===el.cartellinoText && el.cartellinoText!==""}
                    />
                </Grid>

                <Grid item md={6} xs>
                    <InputAutoComplete
                    onChange={(e,val,r)=>handleCartellino(e,val,r)} 
                    value={el.cartellino} 
                    inputValue={el.cartellinoText} 
                    onInputChange={(e,val,r)=>handleCartellinoText(e,val,r)} 
                    onBlur={()=>handleBlur()}
                    margin="dense"
                    opt={cartellini}
                    label={"N° cartellino sementi"}
                    getOptionSelected={(option, value) => option.codice === value.codice}
                    getOptionLabel={(option) => option.codice}
                    freeSolo
                    name="cartellinoText"
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <div className={classes.text}>
                            {option.codice}
                            <br />
                            <span className={classes.subText}>{option.varieta.nome}</span>
                          </div>
                        </React.Fragment>
                    )}
                    />
                </Grid>

                 

                 <Grid item md={12} xs>
                    <InputFile value={el.cartellino.img!==null? el.cartellino.img : []} onChange={handleFile} context={el.cartellino.img}/>
                </Grid>
            </Grid>

            <Grid container spacing={0}  style={{marginTop:16}}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        3) seleziona la tipologia analisi
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    
                    <InputSelect
                        value={el.extra} 
                        handleChange={(e)=>handleRiga(e)} 
                        label="Tipologia analisi*" 
                        name="extra"
                        margin="dense"
                        withEvent
                        items={user.offerta.filter(el=> el.attivo ===1).map(extra_elem=>{
                            var convenzione_extra = user.convenzione ? user.convenzione.extras.find(el => el.id === extra_elem.id) : undefined;
                            if( convenzione_extra ){
                                return {label: extra_elem.nome+"["+user.convenzione.codice_convenzione+"] (+"+convenzione_extra.pivot.prezzo+"€/cad.)", value: extra_elem.id}
                            }else{
                                return {label: extra_elem.nome+" (+"+extra_elem.prezzo+"€/cad.)", value: extra_elem.id}
                            }
                            
                        })} 
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <InputText 
                    label="Note" 
                    value={el.note} 
                    name="note" 
                    handleChange={(e)=>handleRiga(e)}
                    withEvent
                    margin="dense"
                    multiline
                    rows={3}
                    className={classes.textField}
                    />
                </Grid>

            </Grid>
            
            
            <Grid item xs={12} style={{marginTop:16}}>
                <Button variant="outlined" color="primary" onClick={addSample} disabled={isDisabled()}>
                    <Plus size={16}/>
                    Aggiungi campione
                </Button>
            </Grid>
        </Grid>
        </Paper>
    );

}

export default AddRigheStep;