import React,{Fragment, useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import { connect } from 'react-redux';
import { useLocation,useNavigate } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import moment from 'moment';
import { format } from 'prettier';
import ConvenzioneTooltip from 'src/components/ConvenzioneTooltip';

const BorderLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const mapStateToProps = (state)=>{
  return{
    user:state.user
  }
}

const   getMuiTheme = () => createMuiTheme({
    overrides: {

      MUIDataTable: {
        responsiveScrollMaxHeight: {
          maxHeight:'700px !important'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: "rgba(39, 167, 74, 0.25) !important"
        }
      },
      MuiChip:{
        colorPrimary:{
          backgroundColor:'rgba(39, 167, 74, 0.85)'
        },
        colorSecondary:{
          backgroundColor:'#F6BD60'
        }
      }
    }
  });
  

  const PaymentsTable = (props)=>{
    const [data,setData] = useState([]);
    let navigate = useNavigate();
    const {filters, title} = props;
    console.log(props.payments);

    useEffect(()=>{
      setData(props.payments);
    },[props.payments])

    const statoLabel = (v)=>{
      if(v>=1) return "Saldato";
      return "Da saldare"
      
    }

    const columns = [
      {
        label: "ID",
        name: "id",
        options: {
          filter: false,
          sort:true
        }
      },
      {
        label: "Riferimento",
        name: "refer_type",
        options: {
          filter: true,
          filterList:filters?.refer_type? filters.refer_type : [] ,
          sortThirdClickReset:true,
          sort:true,
          filterType:"custom",
          filterOptions: {
            fullWidth:true,
            renderValue: v => statoLabel(v),
            display: (filterList, onChange, index, column) => {
              const optionValues = ['analisi', 'tickets','deodorazione'];
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    Riferimento
                  </InputLabel>
                  <Select
                    multiple
                    value={ filterList[index] }
                    renderValue={selected => selected.join(', ')}
                    onChange={event => {
                      filterList[index] = event.target.value;
                      console.log(filterList);
                      onChange(filterList[index], index, column);
                    }}
                  >
                    
                    {optionValues.map((item, i) => (
                      <MenuItem key={item} value={item}>
                        
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          },
        }
      },
      {
        label: "Azienda",
        name: "user",
        options: {
          customBodyRender: (value) => value.azienda,
          filter: props.user.role_id===1,
          filterType:"textField",
          filterList:filters?.azienda? filters.azienda : [] ,
          sort:true
        }
      },
      {
        label: "Importo",
        name: "totale",
        options: {
          customBodyRender:(value) => value + "€",
          filter: false,
          sort:true
        }
      },
      {
        label: "Saldo",
        name: "saldo",
        options: {
          customBodyRender:(value) => value.toFixed(2) + "€",
          filter: false,
          sort:false
        }
      },
      {
        label: "Fattura del",
        name: "data_fatturazione",
        options: {
          customBodyRender:(value) => (value ? moment(value).format("DD/MM/YYYY") : ""),
          filter: false,
          sort:true
        }
      },
      {
        label:" ",
        name:"refer",
        options: {
          filter: false,
          sort:false,
          customBodyRender:( value, tableMeta, updateValue ) => { 
            return value.convenzione? <ConvenzioneTooltip convenzione={value.convenzione}/> : ""
          }
        }
      },
      {
        label: "Stato pagamento",
        name: "perc",
        options: {
          filter: false,
          sort:false,
          customBodyRender:( value, tableMeta, updateValue ) => {

            return  <Box display="flex" flexDirection="column" alignItems="center">
                      <Typography align="center" variant="caption" color="textSecondary">{value}%</Typography>
                      <BorderLinearProgress variant="determinate" value={value} style={{width:"100%"}}/>
                    </Box>
          },
          
        }
      },
      {
        label: "Pagato",
        name: "pagato",
        options: {
          display:false,
          filter: true,
          filterList:filters?.pagato? filters.pagato : [] ,
          customBodyRender:( value, tableMeta, updateValue ) => {

            return  <Box display="flex" flexDirection="column" alignItems="center">
                      <Typography align="center" variant="caption" color="textSecondary">{value}%</Typography>
                      <BorderLinearProgress variant="determinate" value={value} style={{width:"100%"}}/>
                    </Box>
          },
          sort:false,
          filterType: 'custom',
          filterOptions: {
            fullWidth:true,
            renderValue: v => statoLabel(v),
            display: (filterList, onChange, index, column) => {
              const optionValues = ['Da saldare', 'Saldato'];
              return (
                <FormControl>
                  <InputLabel htmlFor='select-multiple-chip'>
                    Stato Pagamento
                  </InputLabel>
                  <Select
                    value={ filterList[index] }
                    renderValue={selected => optionValues[selected[0]]}
                    onChange={event => {
                      filterList[index][0] = event.target.value;
                      console.log(filterList);
                      onChange(filterList[index], index, column);
                    }}
                  >
                    <MenuItem value="">
                      <ListItemText primary="Tutti" />
                    </MenuItem>
                    {optionValues.map((item, i) => (
                      <MenuItem key={item} value={i}>
                        
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
          },
          customFilterListOptions: {
            render: v => statoLabel(v),
          },
        }
      },
      {
        label: "Proforma",
        name: "src",
        
        options: {
          sort:false,
          customBodyRender:(value) => {
            if(value===null){
              return <HourglassEmptyRoundedIcon fontSize="small"/>
            }else{
              return <IconButton size="small" href={value} target="_blank" component="a">
                        <OpenInNewRoundedIcon fontSize="small"/>
                     </IconButton>
            }
          },
          filter: false,
          filterType:"textField"
        }
      },
      

    ];

    const options = {
        filter: false,
        responsive: 'standard',
        fixedHeaderOptions: {
          xAxis: false,
          yAxis: true
        },
        selectableRows:'none',
        selectToolbarPlacement:'none',
        print:false,
        download:false,
        viewColumns:false,
        search:false,
        pagination:false,
        elevation:0,
        onRowClick:(rowData)=>navigate("/pagamento/"+rowData[0])
      };

      return (
        <Fragment>
          <MuiThemeProvider theme={getMuiTheme()}>
    			   <MUIDataTable data={data} columns={columns} options={{...options,...props.opt}} />
          </MuiThemeProvider>
          
          
        </Fragment>
      );
  }

  PaymentsTable.defaultProps = {
    opt:{},
    filters:{},
    onFilterSubmit:null,
    title:"",
  }
  export default connect(mapStateToProps)(PaymentsTable);
