import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {Box,Button,Container,Grid,Link,TextField,Typography, makeStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import Page from 'src/components/Page';
import apiClient from 'src/utils/apiClient';
import { logIn } from 'src/utils/auth';
import { connect } from 'react-redux';
import {setUser, stopLoading, userLoggedIn, userLoggedOut } from 'src/redux/actions.js';
import { useParams } from 'react-router-dom';

const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  stopLoading: () => dispatch(stopLoading()),
  userLoggedIn: () => dispatch(userLoggedIn()),
  userLoggedOut: () => dispatch(userLoggedOut()),
});


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ResetPasswordView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {setUser,userLoggedIn} = props;
  let param = useParams();
  const [messaggio, setMessaggio] = useState('');
  const [errore, setErrore] = useState('');

  const reset = (value) =>{
    const {email,password} = value;
    
    apiClient.get("/sanctum/csrf-cookie")
      .then(response =>{
        apiClient.post("/reset_password",{
          email: email,
          password: password,
          token:param.token
        }).then(response => {
          if(response.data.error){
              setErrore(response.data.error);
          }else{
              setMessaggio(response.data.msg);
          }
        })
      });
  }

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: '',
              passwordConfirm:''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Email non valida').max(255).required('Email richiesta'),
              password: Yup.string().max(255).required('Password richiesta'),
              passwordConfirm :Yup.string().oneOf([Yup.ref('password')],'Le password non coincidono'),
            })}
            onSubmit={(values) => reset(values)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Password reset
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Inserisci la nuova password 
                  </Typography>
                </Box>
                
                
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                
                <TextField
                  error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                  fullWidth
                  helperText={touched.passwordConfirm && errors.passwordConfirm}
                  label="Conferma Password"
                  margin="normal"
                  name="passwordConfirm"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.passwordConfirm}
                  variant="outlined"
                />
                
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    INVIA
                  </Button>
                </Box>
                {errore!=='' && 
                <div className="container-errore">
                  <Typography color="error">{errore}</Typography>
                </div>
                }

                {messaggio!=='' && 
                <div className="container-success">
                  <Typography color="primary">
                      {messaggio + '  '}
                      <Link
                      component={RouterLink}
                      to="/auth/login"
                      variant="h6"
                      >
                          ACCEDI
                      </Link>
                  </Typography>  
                </div>
                }                
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default connect(null,mapDispatchToProps)(ResetPasswordView);
