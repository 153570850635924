import React from 'react';
import {Box,Typography,makeStyles} from '@material-ui/core';
import Page from 'src/components/Page';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(180deg, #D2EDD9 8%, #fff 90%)',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  logo:{
    width:200
  },
  LinearProgressContainer:{
    width:350,
    marginTop:24
  }
}));

const SplashView = () =>{

  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title=""
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" gutterBottom>Benvenuto su scienzainfiore.it</Typography>
        <img
          alt="Logo"
          src="/static/siflab_logo.svg"
          className={classes.logo}
        />

        <div className={classes.LinearProgressContainer}>
          <LinearProgress />
        </div>

      </Box>
    </Page>
  );
}

export default SplashView;
