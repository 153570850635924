import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  Button
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {Tag} from 'react-feather';
import { Icon } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display:"flex",
    justifyContent:"flex-start",
    position:"relative",
    backgroundColor:"#c4e3fc",
    '&::after':{
      content:'" "',
      position:'absolute',
      top:0,
      bottom:0,
      right:0,
      backgroundColor: "#42a5f5",
      borderLeft:100,
      display: "block",
      borderStyle: "solid",
      borderTop: 0,
      borderRight: 0,
      borderBottom: 0,
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      borderColor: "#42a5f5",
      boxShadow: "-3px 0px 16px -4px #42a5f5"

    }

  },
  avatar: {
    backgroundColor: "#42a5f5",
    height: 56,
    width: 56,
    zIndex:2,
    position:'absolute',
    top:"48%",
    transform:"translateY(-50%)",
    right:14,
  },
  differenceIcon: {
    color: colors.red[900]
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  container:{
    position:'relative',
    width:220,
    alignItems:"center",
    padding:'0px 0 16px 8px',
    display:'flex',
    justifyContent:'space-between',
    marginRight:16
  },
  content:{
    flexBasis:"100%",
    display:"flex",
    paddingRight:100
  },
  contentContainer:{
    flexBasis:"100%",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"space-between"
  }
}));

const TicketsCard = ({ className, tickets,...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent className={classes.content}>
        <div className={classes.contentContainer}>
          <Typography color="textSecondary" gutterBottom variant="h6" >
              Voucher
          </Typography>
          {tickets.map(el=>(
            <Box className={classes.container} key={el.id}>
                    <Typography variant="caption" >{el.nome}</Typography> 
                    <div>
                        <Typography display="inline" variant="h6" color="textSecondary">{el.disponibili}</Typography>
                    </div>
            </Box> 
          ))}
        </div>
        <Avatar className={classes.avatar}>
          {/* <Tag size={48}/> */}
          <Icon style={{fontSize:48}}>confirmation_number</Icon>
        </Avatar>
        
        <Box mt={2} display="flex" alignItems="center" >
          <Typography color="textSecondary" variant="caption" >
            
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

TicketsCard.propTypes = {
  className: PropTypes.string
};

export default TicketsCard;