import React, {useEffect, useState} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Divider,
  Typography,
  Card,
  Button,
  colors,
  ThemeProvider,
  createMuiTheme
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useNavigate, useParams } from 'react-router-dom';
import StateStepper from './StateStepper';
import AnalisiHeader from './AnalisiHeader.js';
import ActionsPanel from './ActionsPanel.js';
import {API, MEDIA} from 'src/utils/const.js';
import RigheTable from 'src/views/reports/RigheTable.js';
import ReportTabs from './ReportTabs.js';
import materialColor from 'src/utils/randomColor.js';
import apiClient from 'src/utils/apiClient';
import ModaleReferto from 'src/modali/ModaleReferto.js';
import ModaleQr from 'src/modali/ModaleQr';
import ModaleModificaRiga from 'src/modali/ModaleModificaRiga';
import { connect } from 'react-redux';
import DocumentiPanel from './DocumentiPanel';
import { Download,Edit } from 'react-feather';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import RefertoEsternoCard from './RefertoEsternoCard';
import ModaleRefertoEsterno from 'src/modali/ModaleRefertoEsterno';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Fragment } from 'react';
import ModaleResponse from './ModaleResponse';
import ModaleTag from 'src/modali/ModaleTag';
import moment from 'moment';
import cyan from '@material-ui/core/colors/cyan';
import ModaleProgressBar from 'src/modali/ModaleProgressBar';
import NoteEditor from 'src/modali/NoteEditor';


const mapStateToProps = (state)=>{
  return{
    user:state.user
  }
}
const theme = createMuiTheme({
  palette: {
    secondary:{
      main:cyan[500]
    },

  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card:{
    padding:"16px 0px",
    margin:"0px 16px",

  },
  lightBack:{
    backgroundColor:colors.grey[100]
  },
  refCardList:{
    display:"flex",
    flexWrap:"wrap"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const MyAlert = (props) => {
  return <Alert elevation={6} variant="filled" {...props} />;
}


const AnalisiPage = (props)=>{

  const classes = useStyles();
  let param = useParams();
  const navigate = useNavigate();
  const [selected, setSelected]=useState([]);
  const id = parseInt(param.id);
  const [analisi, setAnalisi] = useState({});
  const [referto, setReferto] = useState(null);
  const [qrSrc,setQrSrc] = useState(null);
  const [qrOpen, setQrOpen] = useState(false);
  const [modificaRigaOpen, setModificaRigaOpen] = useState(false);
  const [modificaRiga, setModificaRiga] =  useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackVariant, setSnackVariant] = useState('info');
  const [refertoEsternoOpen, setRefertoEsternoOpen] = useState(false);
  const [refertoEsternoRiga, setRefertoEsternoRiga] = useState(null);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagRiga, setTagRiga] = useState(null);
  const [isDeleting,setIsDeleting] =  useState(false);
  const [deleteComplete,setDeleteComplete] = useState(null);
  const [response,setResponse] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [downloading, setDownloading] = useState(null);
  const [editNote, setEditNote] = useState(false);
  const [savingNote, setSavingNote] = useState(false);

  const [dataFattura,setDataFattura] = useState(null);

  const folder = MEDIA+ analisi?.id_user +"/"+analisi.id+"/"; 

  const handleQrToggle =()=>{
    setQrOpen(!qrOpen);
  }

  const setColor = (a)=>{
    
    for( var i in a.righe ){
      a.righe[i].color = materialColor();
    }
    setAnalisi(a);
  }

  // useEffect(()=>{
  //   apiClient.get('api/analisi/'+id)
  //   .then(response => {
  //     console.log(response);
  //     setColor(response.data.analisi);
  //   });

  // },[]);

  useEffect(()=>{
    apiClient.get('api/analisi/'+id)
    .then(response => {
      console.log(response);
      setColor(response.data.analisi);
      setDataFattura(response.data.analisi.payment?.data_fatturazione? response.data.analisi.payment.data_fatturazione : null);
    }).catch(err=>{
      navigate('/404');
    });

  },[param.id]);

  const openReferto=(i)=>{
    setReferto(analisi.righe[i]);
  }

  const openQr=(i)=>{
    setQrSrc( folder + analisi.righe[i].qrcode );
    handleQrToggle();
  }

  const handleQrClose = ()=>{
    setQrSrc(null);
    handleQrToggle();
  }

  const openModificaRiga = (i) =>{
    setModificaRiga(analisi.righe[i])
    setModificaRigaOpen(true);
  }

  const closeModificaRiga = ()=>{
    setModificaRiga(null)
    setModificaRigaOpen(false);
  }

  const openRefertoEsterno =(i)=>{
    setRefertoEsternoRiga(analisi.righe[i]);
    setRefertoEsternoOpen(true);
  }

  const closeRefertoEsterno =()=>{
    setRefertoEsternoRiga(null);
    setRefertoEsternoOpen(false);
  }

  const openTagModal = (i)=>{
    setTagRiga(analisi.righe[i]);
    setTagModalOpen(true);
  }

  const closeTagModal = (i)=>{
    setTagRiga(null);
    setTagModalOpen(false);
  }

  const copyToClipboard = () =>{
    let str="";
    analisi.righe.map((riga,i)=>{
      str+=(riga.posizione+1);
      str+=",";
      str+=analisi.data_accettazione;
      str+=",";
      str+=analisi.user.azienda;
      str+=",";
      str+=riga.descrizione;
      str+=",";
      str+=riga.nome_campione;
      str+=",";
      str+=analisi.codice +"."+ (riga.posizione+1);
      str+=",";
      str+=riga.cartellino.varieta.nome;
      str+=",";
      str+=riga.cartellino.codice;
      str+=",";
      str+=riga.grammatura+"g";
      str+=",";
      str+="a cura del cliente";
      str+=",";
      //destinazione lavorazione assente
      str+=",";
      str+=riga.lotto_coltivazione;
      str+="\n";
    });
    
    navigator.clipboard.writeText(str).then(
      ()=>{
        setSnackOpen(true);
        setSnackVariant('success');
      },
      ()=>{
        setSnackOpen(true);
        setSnackVariant('warning');
      }
    );

  }

  const handleCloseSnack = () =>{
    setSnackOpen(false);
    setSnackVariant('info');
  }

  const deleteRefEsterno = (id)=>{
    var post = new FormData();
    post.append('id',id );
    post.append('id_ordine',analisi.id);

    apiClient.post('api/delete_referto_esterno',post)
    .then(response =>{
      if(response.data.error){
        console.log(response.data.error);
      }else{
        setColor(response.data.analisi);
      }
    })
  }

  const deleteAnalisi = ()=>{
    setIsDeleting(true);
    var post = new FormData();
    post.append('id',analisi.id );

    apiClient.post('api/analisi/elimina',post)
    .then(response =>{
      if(response.data.error){
        setDeleteComplete(false);
        console.log(response.data.error);
        setIsDeleting(false)
      }else{
        setDeleteComplete(true);
        setIsDeleting(false)
        navigate('/');
      }
    })
    .catch(err=>{
      setIsDeleting(false);
      setDeleteComplete(false);
    })
    
  }

  const handleCloseDelete= ()=>{
    setDeleteComplete(null);
  }

  const handleDateChange = (date)=>{
    setIsDeleting(true);
    var post = new URLSearchParams();
    post.append('data_fatturazione',moment(date).format("DD/MM/YYYY") );

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    apiClient.put('api/payment/fattura/'+analisi.payment.id, post,config)
    .then(response => {
      console.log(response);
      setDataFattura(date);
      setIsDeleting(false);
    })
    .catch(err=>{
      console.log(err);
      setIsDeleting(false);
    })
  }

  const downloadArchivio = ()=>{
    setPercentage(0);
    setDownloading(true);
    apiClient.get('api/downloadArchivio/'+ analisi.id,{
      responseType: 'blob',
      onDownloadProgress(progressEvent) {
        let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

        setPercentage(progress);
      }
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', "archivio_" + analisi.codice+'.zip'); //or any other extension
      document.body.appendChild(link);
      link.click();
   })
   .then(()=>{
    setDownloading(false);
   })
   .catch(err=>{
     console.log(err);
     setDownloading(false);
   });
  }

  const salvaNote = (content) =>{
    setSavingNote(true);
    var post = new FormData();
    post.append('content', content);

    apiClient.post("api/analisi/"+analisi.id+"/saveNote",post)
    .then((response)=>{
      setColor(response.data.analisi);
      setSavingNote(false);
      return(true);
    })
    .catch(err=>{
      console.log(err);
      setSavingNote(false);
      return(false);
    })
  }

  return(
    <Page
      className={classes.root}
      title="Analisi"
    >
    <Card className={classes.card}>
      <Container maxWidth={false}>
        <Grid container spacing={3} >

        <Grid item sm={9} xs={12} >
          <AnalisiHeader 
          analisi={analisi} 
          isAdmin={props.user.role_id === 1} 
          dataFattura={dataFattura}
          handleDateChange={handleDateChange}
          />
        </Grid>

        <Grid item sm={3} xs={12} >
          <ActionsPanel 
          isAdmin={(props.user.role_id===1)} 
          handleDelete={deleteAnalisi} 
          status={analisi.stato} 
          analisi={analisi}
          setResponse={setResponse}
          />
        </Grid>

        <Grid item xs={12} style={{marginTop:32}}>
          
          <DocumentiPanel analisi={analisi}/>
        </Grid>
        <Grid item xs={12} style={{marginTop:32}}>
          <Divider style={{backgroundColor:"#274AA7"}}/>
          <Typography variant="button">Stato delle Analisi</Typography>
        </Grid>

        <Grid item xs={12} >
          <StateStepper analisi={analisi} setAnalisi = {setColor}/>
        </Grid>

        <Grid item xs={12} style={{marginTop:32}}>
          <Divider style={{backgroundColor:"#274AA7"}}/>
          <Typography variant="button">Campioni</Typography>
        </Grid>
        <Grid item xs={12} >
          {analisi.stato ===4 &&
          <Grid item xs={3} style={{marginBottom:16,marginTop:8}}>
            <ThemeProvider theme={theme}>
              <Button
              variant="contained"
              color="secondary"
              onClick={downloadArchivio}
              startIcon={<Download />}
              style={{color:"#fff"}}
              >
                Scarica Archivio
              </Button>
            </ThemeProvider>
            
          </Grid>
          }
          {props.user.role_id === 1 &&
          <Grid item xs={8} style={{marginBottom:16,marginTop:8}}>
            <Button
            variant="contained"
            color="primary"
            onClick={copyToClipboard}
            startIcon={<Download />}
            style={{marginRight:8}}
            >
              Esporta in excel
            </Button>

            <Button
            variant="contained"
            color="primary"
            onClick={()=>setEditNote(true)}
            startIcon={<Edit />}
            >
              Appunti
            </Button>
          </Grid>
          }
          <RigheTable
          righe={analisi.righe}
          codice={analisi.codice}
          idu={analisi.id_user ? analisi.id_user : null}
          ido={analisi.id }
          stato = {analisi.stato}
          onSelect={setSelected}
          openReferto={(r)=>openReferto(r)}
          openQr={(i)=>openQr(i)}
          openModificaRiga = {(i)=>openModificaRiga(i)}
          openRefertoEsterno={(i)=>openRefertoEsterno(i)}
          openTag={i=>openTagModal(i)}
          />
        </Grid>

        <Grid item xs={12} style={{marginTop:32}}>
          <Divider style={{backgroundColor:"#274AA7"}}/>
          <Typography variant="button">Reports</Typography>
        </Grid>

        <Grid item xs={12} >
          {(props.user.role_id!==1 && analisi.stato !==4)?
            <Typography variant="caption" color="textSecondary"> I Report non sono ancora disponibili. Riceverai una email che ti avviserà quando le analisi saranno terminate e i report disponibili</Typography>
          :
            <ReportTabs righe={analisi.righe} selected={selected} codice={analisi.codice}/>
          }
          
        </Grid>

        {(props.user.role_id === 1 || (props.user.role_id!==1 && analisi.stato ===4) ) &&
          <>
            <Grid item xs={12} style={{marginTop:32}}>
              <Divider style={{backgroundColor:"#274AA7"}}/>
              <Typography variant="button">Referti Esterni</Typography>
            </Grid>
            <Grid item xs={12} style={{marginBottom:16,marginTop:8}}>
              {analisi.righe && analisi.righe.map(riga => riga.referto_esterno.length ?
                <Fragment key={riga.id}>
                  <Typography variant="caption">{analisi.codice +"."+(riga.posizione+1)+ " ("+riga.nome_campione+")"}</Typography>
                  <div className={classes.refCardList}>
                    {riga.referto_esterno.map(r => 
                      <RefertoEsternoCard 
                      key={r.id} 
                      referto={r} 
                      codice={r.codice? r.codice : analisi.codice+"."+(riga.posizione+1)} 
                      handleDelete={(id)=>deleteRefEsterno(id)}
                      canDelete={props.user.role_id === 1} 
                      /> 
                      
                      )}
                  </div>
                  
                </Fragment>
                :
                null
              )}
            </Grid>
          </>
        }

        </Grid>
      </Container>
    </Card>
    
    {props.user.role_id === 1 && 
    <>
    <ModaleReferto 
    open={referto!==null} 
    close={()=>setReferto(null)} 
    title={"Carica Referto"} 
    riga={referto} 
    idu={analisi.id_user ? analisi.id_user : null}
    ido={analisi.id }
    setAnalisi = {setColor}
    />

    <ModaleModificaRiga 
    open={modificaRigaOpen}
    close={closeModificaRiga}
    riga={modificaRiga}
    uid={analisi.id_user ? analisi.id_user : null}
    setAnalisi = {setColor}
    />

    <ModaleRefertoEsterno 
    open={refertoEsternoOpen}
    close={closeRefertoEsterno}
    riga={refertoEsternoRiga}
    ordine={analisi}
    setAnalisi={setColor}
    />

    </>
    }
    

    <ModaleQr 
    qr={qrSrc} 
    open={qrOpen} 
    handleClose={handleQrClose}
    />

    <ModaleTag
    open={tagModalOpen}
    close={closeTagModal}
    riga={tagRiga}
    />

    <ModaleProgressBar 
    open={downloading} 
    close={()=>setDownloading(false)} 
    progress={percentage} 
    />

    <NoteEditor 
    open={editNote}
    close={()=>setEditNote(false)}
    title="CICOICOICOI"
    salva={salvaNote}
    savingNote={savingNote}
    init={analisi?.appunti}
    />

    <ModaleResponse response={response} open={response!==null} toggle={()=>setResponse(null)} />

      <Snackbar 
      open={snackOpen} 
      autoHideDuration={3000} 
      onClose={handleCloseSnack} 
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
        <MyAlert onClose={handleCloseSnack} severity={snackVariant}>
          {snackVariant=="success" && "Copiato negli appunti"}
          {snackVariant=="warning" && "Ops,quancosa è andato storto"}
          {snackVariant=="" && ""}
        </MyAlert>
      </Snackbar>

      <Snackbar 
      open={deleteComplete !== null} 
      autoHideDuration={3000} 
      onClose={handleCloseDelete} 
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
        <MyAlert onClose={handleCloseDelete} severity={deleteComplete? "succes" : deleteComplete===null?"info":"warning"}>
          {deleteComplete && "Ordine Eliminato con successo"}
          {deleteComplete===false && "Ops,quancosa è andato storto"}
          {snackVariant===null && ""}
        </MyAlert>
      </Snackbar>

      <Backdrop className={classes.backdrop} open={isDeleting}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Page>
  );
}

export default connect(mapStateToProps)(AnalisiPage);
