import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import { connect } from 'react-redux';
import {setUser, stopLoading,startLoading, userLoggedIn, userLoggedOut } from 'src/redux/actions.js';
import apiClient from 'src/utils/apiClient';
import { logOut } from 'src/utils/auth';

const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user)),
  stopLoading: () => dispatch(stopLoading()),
  startLoading: () => dispatch(startLoading()),
  userLoggedIn: () => dispatch(userLoggedIn()),
  userLoggedOut: () => dispatch(userLoggedOut()),
});

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  logoHeader:{
    height:45,
  }
}));

const TopBar = (props) => {
  const classes = useStyles();
  const {startLoading,userLoggedOut,setUser,className,onMobileNavOpen,stopLoading,userLoggedIn,...rest} = props;
  

  const logout = () =>{
    apiClient.post("/logout")
    .then(response => {
      userLoggedOut();
      logOut();
    }).then(()=>{
      setUser({});
    })

  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo className={classes.logoHeader}/>
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton color="inherit" onClick={logout}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default connect(null,mapDispatchToProps)(TopBar);
