import React from 'react';
import { Typography } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Switch } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import PacchettoTooltip from 'src/components/PacchettoTooltip';
import { Delete, Edit } from 'react-feather';
import { IconButton } from '@material-ui/core';

const UpdatePacchetto = (props) => {
    const {pacchetti,setAttivo, modifica} = props;

    return(
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Typography>Gestione analisi</Typography>
            </Grid>
            
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell variant="head">Prezzo</TableCell>
                                <TableCell variant="head">Attivo</TableCell>
                                <TableCell variant="head">Azioni</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pacchetti.map(el=>
                                <TableRow>
                                    <TableCell>
                                        <PacchettoTooltip pacchetto={el} />
                                    </TableCell>
                                    <TableCell>
                                        {el.prezzo}
                                    </TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                        control={
                                            <Switch
                                            checked={el.attivo}
                                            onChange={(event)=>setAttivo(event.target.checked,el.id)}
                                            color="primary"
                                            />
                                        }
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton size="small" onClick={()=>modifica(el.id)}>
                                            <Edit />
                                        </IconButton>
                                        
                                    </TableCell>

                                </TableRow>    
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            
        </Grid>
    );
}

export default UpdatePacchetto;