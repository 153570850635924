import React,{useState,useEffect, useRef} from "react";
import Page from 'src/components/Page';
import {Button, makeStyles,Menu,MenuItem,Typography,IconButton,Icon, Divider, Card, Grid} from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import makeCarousel from "react-reveal/makeCarousel";
import Slide from "react-reveal/Slide";
import "src/home.css";
import TopBar from "./TopBar";
import MoreIcon from '@material-ui/icons/MoreVert';
import clsx from "clsx";
import ContactForm from "./ContactForm";
import { connect } from "react-redux";
import { setSettings, updateSettings } from "src/redux/actions";
import { saveState } from "src/redux/localStorage";
import { Link } from "react-router-dom";
import Scroll, {Element,animateScroll, scroller} from "react-scroll";
import { Facebook, Instagram } from "react-feather";

const useStyles = makeStyles((theme) => ({
    root: {
    //   backgroundColor: theme.palette.background.dark,
      height: '100%',
      flex: '1 1 auto',
      overflow: 'auto',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
        
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
    },
    headline:{
        backgroundImage:"url('/static/images/seeds.jpeg')",
        backgroundPositionY:"bottom",
        backgroundSize:"cover",
        position: "relative",
        height: "100vh",
        color: "#fff",
        textAlign: "center"
    },
    inner:{
        position:"absolute",
        top:"50%",
        left:"50%",
        zIndex:7,
        transform:"translateX(-50%) translateY(-50%)"
    },
    logoHeadline:{
        width:"100%",
        maxWidth:400,
    },
    logoFooter:{
        width:250
    },
    sl:{
        height:"100vh",
        [theme.breakpoints.down('sm')]: {
            height:"100%",
          },
    },
    // slide:{
    //     display: "flex",
	// 	alignItems: "center",
	//   	justifyContent: "center",
	//   	textAlign: "center",
	// 	height: "100vh",
	// 	backgroundColor:"#f43",
	// 	lineHeight: 1.5,
    // },
    // slide1:{
    //     backgroundImage:"url('/static/images/slide1.jpeg')",
    //     backgroundSize:"cover",
    //     height:700,
    //     backgroundPositionY:"bottom",
    //     position:"relative",
    //     display:"flex",
    //     justifyContent:"center",
    //     alignItems:"center"
    // },
    // layer:{
    //     position:"absolute",
    //     top:0,
    //     bottom:0,
    //     left:0,
    //     right:0,
    //     backgroundColor:"#000",
    //     opacity:0.4
    // },
    title:{
        fontFamily:"'Poppins', sans-serif",
        textTransform:"uppercase",
        color:"#fff",
        fontSize:"2em",
        color:"rgba(39, 167, 74, 1)",
        fontWeight:"bold",
        letterSpacing:"-1px",
        wordSpacing:5
    },
    // logo:{
    //     width:300,

    // },
    // captionContainer:{
    //     zIndex:2
    // },
    // sectionContainer:{
    //     width:"100%",
    //     maxWidth:1200,
    //     backgroundColor:"#fff",
    //     height:600,
    //     margin:"0 auto",
    //     boxShadow: "0px 0px 18px 5px rgba(0,0,0,0.39)"
    // },
    // sectionTitle:{
    //     fontFamily:"'Oswald', sans-serif",
    //     fontiSize: 25,
    //     letterSpacing: 2,
    //     wordSpacing: 2,
    //     color: "#000000",
    //     fontWeight: "normal",
    //     textDecoration: "overline solid rgb(68, 68, 68)",
    //     fontStyle: "normal",
    //     fontVariant: "small-caps",
    //     textTransform: "none"
    // },
    // sectionParagraph:{
    //     fontFamily: "'Cardo', serif"
    // },
    // sectionContent:{
    //     padding:"32px 16px" 
    // }
    formContainer:{
        width:"50%",
        [theme.breakpoints.down('sm')]: {
            width:"100%",
        },
    },
    infoContainer:{
        width:"50%",
        [theme.breakpoints.down('sm')]: {
            width:"100%",
        },
    },
    slAnalisi:{
        width:850,
        [theme.breakpoints.down('sm')]: {
            width:"auto",
        },
    },
    slCard:{
        [theme.breakpoints.down('sm')]: {
            flexDirection:"column",
        },
    },
    slCardContent:{
        width:"70%",
        [theme.breakpoints.down('sm')]: {
            width:"100%",
        },
    },
    slideBg:{
        backgroundImage:"url('/static/images/mol_bg_grey.png')",
        backgroundSize:"cover"
    },
    sl1_card_media:{
        backgroundImage:"url('/static/images/analisi_portrait.jpeg')",
        backgroundSize:"cover",
        backgroundRepeat:"no-repeat",
        height:622,
        backgroundPositionY:"40%",
        width:350,
        [theme.breakpoints.down('sm')]: {
            width:"100%",
            height:"calc(0.5625 * 100vw)",
            backgroundImage:"url('/static/images/analisi_landscape.jpeg')",
        },
    },
    sl3_card_media:{
        backgroundImage:"url('/static/images/Heavy_Metals.jpeg')",
        backgroundSize:"cover",
        backgroundRepeat:"no-repeat",
        height:"calc(900px / 3.26)",
        backgroundPositionY:"40%",
        width:"100%"
    },
    footerContainer:{
        width:"100%",
        maxWidth:1200,
        [theme.breakpoints.down(1200)]: {
            maxWidth:"100%",
        },
    }
}));

// const CarouselUI = ({ children }) => <div className="slideContainer">{children}</div>;
// const Carousel = makeCarousel(CarouselUI);

const Home = (props) =>{
    const {settings,setSettings,updateSettings,lang}=props;
    const classes = useStyles();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const navRef = useRef(null);
    const headerRef= useRef(null);
    const innerRef= useRef(null);
    const carouselRef = useRef(null);
    const [headerOpacity,setHeaderOpacity]=useState(0);
    const [scrollTop,setScrollTop]=useState(0);
    const [scrolled,setScrolled]=useState(false);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleScroll = e =>{
        let element = e.target;
        let scrollTop = element.scrollTop;
        let headLineHeight = headerRef.current.clientHeight -75;
        // console.log("******************************************");
        // console.log("scrollHeight -> "+element.scrollHeight);
        // console.log("scrollTop -> "+element.scrollTop);
        // console.log("clientHeight -> "+element.clientHeight);
        // console.log("******************************************");
        // console.log("nav -> "+navRef.current.clientHeight);
        // console.log("header -> "+headerRef.current.clientHeight);
        // console.log("innerRef -> "+innerRef.current.clientHeight);
        setHeaderOpacity(scrollTop / headLineHeight);
        setScrollTop(scrollTop);
    }

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = (i) => {
        goTo(i);
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const goTo = (i)=>{
        var promise = new Promise((resolve,reject)=>{
            setTimeout(() => {
                carouselRef.current.listRef.scrollIntoView({ behavior: 'smooth',block:"center"});
                resolve();
            }, 300);
        })
        
        // scroller.scrollTo('carousel',{
        //     duration:1500,
        //     delay:100,
        //     smooth:true,
        //     containerId:"carouselWrap"
        // })
        promise.then(()=>{
            console.log(i);
            setCurrentSlide(i);
        });
        
    }
    const scrollHome = ()=>{
        headerRef.current.scrollIntoView({ behavior: 'smooth'});
    }

    const changeLang=()=>{
        let obj = {
            ...settings,
            lang:settings.lang=="it"? "en" : "it"
        };
        console.log(obj)

        // if(saveState(obj,'settings')){
        //     setSettings(obj);
        // }
        updateSettings(obj);
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isMenuOpen}
        getContentAnchorEl={null}
        onClose={handleMenuClose}
        >
            <MenuItem onClick={()=>handleMenuClose(1)}>{lang.landing.analisiCannabMenu}</MenuItem>
            <MenuItem onClick={()=>handleMenuClose(2)}>{lang.landing.analisiSpecialMenu}</MenuItem>
            <MenuItem onClick={()=>handleMenuClose(3)}>{lang.landing.heavyMetalMenu}</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        >   
            <MenuItem onClick={scrollHome}>{lang.landing.homeMenu}</MenuItem>
            <MenuItem onClick={()=>handleMenuClose(0)}>{lang.landing.aboutUsMenu}</MenuItem>
            <MenuItem onClick={()=>handleMenuClose(1)}>{lang.landing.analisiCannabMenu}</MenuItem>
            <MenuItem onClick={()=>handleMenuClose(2)}>{lang.landing.analisiSpecialMenu}</MenuItem>
            <MenuItem onClick={()=>handleMenuClose(3)}>{lang.landing.heavyMetalMenu}</MenuItem>
            <MenuItem onClick={()=>handleMenuClose(4)}>{lang.landing.contactMenu}</MenuItem>
            <MenuItem onClick={changeLang}>{settings.lang=="it"?"EN":"IT"}</MenuItem>
        </Menu>
    );

    return (
        <>
            <TopBar navStyle={{backgroundColor:'rgba(39, 167, 74, '+(0+headerOpacity)+')'}} navRef={navRef}>

                <div className={classes.sectionDesktop}>
                    <Button color="inherit" onClick={scrollHome}>{lang.landing.homeMenu}</Button>
                    <Button color="inherit" onClick={()=>goTo(0)}>{lang.landing.aboutUsMenu} </Button>
                    <Button 
                    color="inherit" 
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}>
                        {lang.landing.analisiMenu}
                    </Button>
                    <Button color="inherit" onClick={()=>goTo(4)}>{lang.landing.contactMenu}</Button>
                    <Button color="inherit" component={Link} to="/auth/login">{lang.landing.loginMenu}</Button>
                    <Divider orientation="vertical" flexItem className="topBar-divider"/>
                    <Button color="inherit" onClick={changeLang}>{settings.lang=="it"?"EN":"IT"}</Button>

                </div>
                <div className={classes.sectionMobile}>
                <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
                >
                    <MoreIcon />
                </IconButton>
                </div>
            </TopBar>
            <Page
            title="Scienza In Fiore"
            className={classes.root}
            onScroll={handleScroll}
            >
                <div className="header" >
                    <div style={{opacity:(headerOpacity<=0.1? 0.9 : 1-headerOpacity)}} ref={headerRef} className={classes.headline}>
                        <div className={classes.inner} ref={innerRef}>
                            <div style={{transform:'translateY('+ scrollTop * 0.4 +'px)'}}>
                                <img src="static/siflab.png" className={classes.logoHeadline}/>
                                <p className={classes.title}>{lang.landing.homeHeader} </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div id="carouselWrap">
                <Element name="carousel"> */}
                <Carousel 
                autoFocus={false} 
                showThumbs={false} 
                showStatus={false} 
                showIndicators={false} 
                useKeyboardArrows 
                className="presentation-mode"
                showArrows={false}
                selectedItem={currentSlide}
                ref={carouselRef}
                autoPlay={false}
                interval={0}
                swipeable={false}
                >
                    {/*++++++++++++++++ CHI SIAMO  +++++++++++++++++++++++++++*/}
                    <div key="content-0" className={clsx(classes.slideBg,"my-slide",classes.sl)}>

                        
                        <div className={clsx(classes.slCard,"sl1-card")}>
                            <div className={clsx(classes.slCardContent,"sl1-card-content")}>
                                <h1 className="aboutUs-title">{lang.landing.slide1_title}</h1>
                                <hr className="divider"/>
                                <div className="aboutUs-text">
                                    <p>{lang.landing.about_p_1}</p>
                                    <p>{lang.landing.about_p_2}</p>
                                    <p>{lang.landing.about_p_3}</p>
                                    <p>{lang.landing.about_p_4}</p>
                                    <p>{lang.landing.about_p_5}</p>
                                    <br/>
                                    <p>{lang.landing.about_p_6}</p>
                                </div>
                            </div>
                            {/* <div className={clsx(classes.formContainer,"contact-form-container")}>
                                
                            </div> */}
                            <div className={clsx(classes.infoContainer,"contact-info")}>
                                <ContactForm />
                                <div>
                                    <p style={{fontWeight:"500"}}>Scienza In Fiore S.r.l.</p>
                                    <p>Via sabotino, 64027, Pineto (Te)</p>
                                    <br/>
                                    <ul className="contact-list">
                                        <li className="contact-item">
                                            <Icon style={{fontSize:20,color:"#666"}}>phone</Icon>
                                            <p className="contact-text">123213123</p>
                                        </li>
                                        <li className="contact-item">
                                            <Icon style={{fontSize:20,color:"#666"}}>email</Icon>
                                            <p className="contact-text">info@scienzainfiore.it</p>
                                        </li>
                                        <li className="contact-item">
                                            <Icon style={{fontSize:20,color:"#666"}}>email</Icon>
                                            <p className="contact-text">scienzainfiore@pec.it</p>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                        
                        
                    </div>

                    {/*++++++++++++++++ ANALISI CANNABINOIDI  +++++++++++++++++++++++++++*/}
                    <div key="content-1" className={clsx(classes.slideBg,"my-slide",classes.sl)}>
                        <div className={clsx(classes.slCard,"sl1-card")}>
                            <div className={classes.sl1_card_media}></div>
                            <div className={clsx(classes.slAnalisi,"analisi")}>
                                <h2 className="aboutUs-title">{lang.landing.slide2_title}</h2>
                                <hr className="divider"/>
                                <div className="aboutUs-text">
                                    <p>{lang.landing.cannabinoids_p_1}</p>
                                    <p>{lang.landing.cannabinoids_p_2}</p>
                                    <p>{lang.landing.cannabinoids_p_3}</p>
                                    <p>{lang.landing.cannabinoids_p_4}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*++++++++++++++++ ANALISI SPECIAL  +++++++++++++++++++++++++++*/}
                    <div key="content-2" className={clsx(classes.slideBg,"my-slide",classes.sl)}>
                        <div className={clsx(classes.slCard,"sl1-card")}>
                            <div className={classes.sl1_card_media}></div>
                            <div className="analisi">
                                <h2 className="aboutUs-title">{lang.landing.slide3_title}</h2>
                                <hr className="divider"/>
                                <div className="aboutUs-text">
                                    <p>{lang.landing.advanced_p_1}</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                    {/*++++++++++++++++ ANALISI METALLI PESANTI  +++++++++++++++++++++++++++*/}
                    <div key="content-3" className={clsx(classes.slideBg,"my-slide",classes.sl)}>
                    <div className={clsx(classes.slCard,"sl1-card","column-flex")}>
                            <div className={classes.sl3_card_media}></div>
                            <div className="analisi">
                                <h2 className="aboutUs-title">{lang.landing.slide4_title}</h2>
                                <hr className="divider"/>
                                <div className="aboutUs-text">
                                    <p>{lang.landing.metals_p_1}</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>


                    {/*++++++++++++++++ CONTATTI  +++++++++++++++++++++++++++*/}
                    <div key="content-4" className={clsx(classes.slideBg,"my-slide",classes.sl)}>

                            

                        
                    </div>

                    
                </Carousel>

                <div className="footer">
                    <Grid container className={classes.footerContainer} spacing={0}>
                        <Grid item sm={4} xs={12} className="footerCol">
                            <img src="static/siflab.png" className={classes.logoFooter}/>
                        </Grid>
                        <Grid item sm={4} xs={12} className="footerCol">
                            <div className="contact-info">
                                <p style={{fontWeight:"500"}}>Scienza In Fiore S.r.l.</p>
                                <p>Via sabotino, 64027, Pineto (Te)</p>
                                <br/>
                                <ul className="contact-list">
                                    <li className="contact-item">
                                        <Icon style={{fontSize:20,color:"#eee"}}>phone</Icon>
                                        <p className="text-footer">123213123</p>
                                    </li>
                                    <li className="contact-item">
                                        <Icon style={{fontSize:20,color:"#eee"}}>email</Icon>
                                        <p className="text-footer">info@scienzainfiore.it</p>
                                    </li>
                                    <li className="contact-item">
                                        <Icon style={{fontSize:20,color:"#eee"}}>email</Icon>
                                        <p className="text-footer">scienzainfiore@pec.it</p>
                                    </li>
                                </ul>
                                
                                
                                
                            </div>
                        </Grid>
                        <Grid item sm={4} xs={12} className="footerCol">
                            <div className="contact-info">
                                <p style={{fontWeight:"500"}}>Social</p>

                                <ul className="soclial-list">
                                    <li className="soclial-item">
                                        <IconButton target="_blank" href="https://www.facebook.com/pages/category/Consulting-Agency/Scienza-In-Fiore-2161347094077559/">
                                            <Facebook color="#eee"/>
                                        </IconButton>
                                    </li>
                                    <li className="soclial-item">
                                    <IconButton target="_blank" href="https://www.instagram.com/scienza_in_fiore">
                                        <Instagram color="#eee"/>
                                    </IconButton>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                    </Grid>   
                </div>
                
                {/* </Element>
                </div> */}
                {renderMenu}
                {renderMobileMenu}
            </Page>    
        
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    setSettings:(settings)=>dispatch(setSettings(settings)),
    updateSettings:(settings)=>dispatch(updateSettings(settings))
});

const mapStateToProps = function(state){
    return {
        settings:state.settings,
        lang:state.lang
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Home);