import React from 'react';
import { withStyles } from '@material-ui/styles';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    
  },
}))(LinearProgress);
const useStyles = makeStyles(()=>({
    label:{
        position:'absolute',
        left:'50%',
        transform: "translateX(-50%)",
        top:0,
    }
}));
const PagamentoProgress = (props)=>{
    const {value} = props;
    const classes= useStyles();

    return (
        <div style={{position:'relative'}}>
            <BorderLinearProgress value={value} variant="determinate"/>
            <Typography className={classes.label}>{value+'%'}</Typography>
        </div>
        
    );
}

export default PagamentoProgress;