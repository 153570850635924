import { Chip } from '@material-ui/core';
import { Avatar, Box, Card, CardContent, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(()=>({
    avatar: {
        height: 60,
        width: 60,
        marginRight:16
    },
    marginBot:{
        marginBottom:24
    }
}))
const MuiTableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(TableCell );


const UserDetails = (props) => {
    const {user,isAdmin} = props;
    const classes  = useStyles();

    return (
        <Card>
        <CardContent>
        
        <Grid container spacing={0}>
            {user && 
            <>
            <Grid item xs={12} className={classes.marginBot}>
                <Box alignItems="center" display="flex" flexDirection="row">
                {user.path ? 
                    <Avatar className={classes.avatar} src={user.path}/>
                    :
                    <Avatar className={classes.avatar}>
                        {user.cod? user.cod.toUpperCase(): ""}
                    </Avatar>
                }
                <Typography color="textPrimary"  variant="h3" > {user.azienda} </Typography>
                {isAdmin ?
                <Typography color="textPrimary" variant="h3" component={Link} to={`/customer/${user.id}`}>{user.azienda}</Typography>
                :
                <Typography color="textPrimary"  variant="h3" > {user.azienda} </Typography>
                }
                </Box>
            </Grid>
            
            <Grid item sm={5} xs={12}>
                <Typography gutterBottom variant="overline">CONTATTO</Typography>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <MuiTableCell variant="head">Referente</MuiTableCell>
                                <MuiTableCell>{user.first_name+" "+user.last_name}</MuiTableCell>
                            </TableRow>
                            <TableRow>
                                <MuiTableCell variant="head">Indirizzo</MuiTableCell>
                                <MuiTableCell>{user.indirizzo+", "+user.citta+" "+user.cap}</MuiTableCell>
                            </TableRow>
                            <TableRow>
                                <MuiTableCell variant="head">email</MuiTableCell>
                                <MuiTableCell>{user.email}</MuiTableCell>
                            </TableRow>
                            <TableRow>
                                <MuiTableCell variant="head">Telefono</MuiTableCell>
                                <MuiTableCell>{user.phone}</MuiTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item sm={5} xs={12}>
                <Typography gutterBottom variant="overline">FISCALE</Typography>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <MuiTableCell variant="head">Forma societaria</MuiTableCell>
                                <MuiTableCell>{user.forma_societaria}</MuiTableCell>
                            </TableRow>
                            <TableRow>
                                <MuiTableCell variant="head">Partita IVA</MuiTableCell>
                                <MuiTableCell>{user.p_iva}</MuiTableCell>
                            </TableRow>
                            <TableRow>
                                <MuiTableCell variant="head">C.F.</MuiTableCell>
                                <MuiTableCell>{user.codice_fiscale}</MuiTableCell>
                            </TableRow>
                            <TableRow>
                                <MuiTableCell variant="head">Codice Univoco</MuiTableCell>
                                <MuiTableCell>{user.codice_univoco}</MuiTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            
            {user.convenzione && 
            <Grid item sm={2} xs={12}>
                <Typography gutterBottom variant="overline">CONVENZIONE ATTIVA</Typography>
                <Chip color="primary" label={user.convenzione.nome_convenzione} style={{marginLeft:16}}/>
            </Grid>
            }
            
            </>}
        </Grid>
        </CardContent>
        </Card>
    );
}
UserDetails.defaultProps = {
    isAdmin:false,
}

export default UserDetails;