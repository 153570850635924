import React,{Fragment} from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { colors } from '@material-ui/core';
import { connect } from 'react-redux';
import clsx from 'clsx';

const mapStateToProps = (state)=>{
  return{
    user:state.user
  }
}

const   getMuiTheme = () => createMuiTheme({
    overrides: {

      MUIDataTable: {
        responsiveScrollMaxHeight: {
          maxHeight:'700px !important'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: "rgba(39, 167, 74, 0.25) !important",
          padding:4,
          ['@media (min-width:780px)']:{
            color:"#0f0"
          }
        }
      },
      MUIDataTableBodyCell: {
        root: {
          padding:4
        }
      },
      MuiChip:{
        colorPrimary:{
          backgroundColor:'rgba(39, 167, 74, 0.85)'
        },
        colorSecondary:{
          backgroundColor:'#F6BD60'
        }
      },
      MuiButton:{
        label:{
          ['@media (min-width:780px)']:{
            fontSize:"0.74rem"
          }
        }
      },
      MUIDataTableSelectCell:{
        root:{
          backgroundColor:"#fff"
        }
      }
    }
  });

  const useStyles = makeStyles((theme)=>({
    goodBg:{
      backgroundColor: theme.palette.primary.light
    },
    tooHigh:{
      backgroundColor:colors.red[400]
    },
    hidden:{color:'#ccc'}
  }))

const CannabinoidiTable = (props)=>{
  //console.log(props);

  const classes = useStyles();
  const options = {
      filter: false,
      responsive: 'standard',
      fixedHeaderOptions: {
        xAxis: false,
        yAxis: true
      },
      selectableRows:'none',
      selectToolbarPlacement:'none',
      print:false,
      download:false,
      viewColumns:false,
      search:false,
      pagination:false,
      elevation:0
    };

  const codiceColumn = ()=>{
    if(props.codice){
      return ({
        label: "Codice",
        name: "posizione",
        options: {
          customBodyRender:(value) => props.codice+"."+(value+1),
          filter: true,
          filterType:"textField"
        }
      })
    }else{
      return ({
        label: "Codice",
        name: "posizione",
        options: {
          customBodyRender:(value,tableMeta) => {
            console.log(tableMeta);
            return props.righe[tableMeta.rowIndex].ordine.codice+"."+(value+1)
          },
          filter: true,
          filterType:"textField"
        }
      })
    }
  }

  const columns = [
    // {
    //   label: "Codice",
    //   name: "posizione",
    //   options: {
    //     customBodyRender:(value) => props.codice+"."+(value+1),
    //     filter: true,
    //     filterType:"textField"
    //   }
    // },
    codiceColumn(),
    {
      label: "Nome campione",
      name: "nome_campione",
      options: {
        filter: true,
        filterType:"textField"
      }
    },
    {
      label: "Descrizione",
      name: "descrizione",
      options: {
        filter: true,
      }
    },
    {
        label: "CBD TOTALE",
        name:"referto",
        options: {
          filter: true,
          sort: true,
          filterType:'textField',
          customBodyRender:(value) => value!==null ? parseFloat(value.totali.tot_CBD) : '-',
          filterOptions:{
          	logic:(location, filters)=>{
          		console.log(location);
              
          		if (location && filters.length){
                var val = location.totali.tot_CBD;
          			if(val=="-"){
          				return true;
          			}else{
          				return parseFloat(filters[0])>parseFloat(val);
          			}

          		}
          		return false;
          	}
          },
        }
      },
      {
        label: "THC TOTALE",
        name:"referto",
        options: {
          filter: true,
          sort: true,
          customBodyRender:(value) => {
            
            if(value!==null){
              
              if(value.escludiTHC===1  && props.user.role_id !==1){
                return '-';
              }else if(value.escludiTHC===1  && props.user.role_id ===1){
                return <span className={classes.hidden}>{parseFloat(value.totali.tot_THC)}</span>
              }else{
                return parseFloat(value.totali.tot_THC)
              }
            }else{
              return '-';
            }
            //value!==null ? parseFloat(value.totali.tot_THC) : '-'
          },
          filterType:'textField',
          filterOptions:{
          	logic:(location, filters)=>{

          		if (location && filters.length){
                var val = location.totali.tot_THC;
          			if(val=="-"){
          				return true;
          			}else{
          				return parseFloat(filters[0])>parseFloat(val);
          			}

          		}
          		return false;
          	}
          },
          setCellProps:(value) =>{
            if(value.hasOwnProperty('props')){
              return{
                className: value.props.children>=0.6 ? classes.tooHigh : value.props.children<0.2? classes.goodBg :null
              }
            }else{
              return{
          		  className: value>=0.6 ? classes.tooHigh : value<0.2? classes.goodBg :null
          	  }
            }
          	
          }
        }
      },
      {
        label: "CBG TOTALE",
        name:"referto",
        options: {
          filter: true,
          sort: true,
          customBodyRender:(value) => value!==null ? parseFloat(value.totali.tot_CBG) : '-',
          filterType:'textField',
          filterOptions:{
          	logic:(location, filters)=>{
          		if (location && filters.length){
                var val = location.totali.tot_CBG;
          			if(val=="-"){
          				return true;
          			}else{
          				return parseFloat(filters[0])>parseFloat(val);
          			}

          		}
          		return false;
          	}
          },

        }
      },
      {
        label: "CBD/THC",
        name:"referto",
        options: {
          customBodyRender:(value) =>{
            if(value!==null){
              
              if(value.escludiTHC===1  && props.user.role_id !==1){
                return '-';
              }else if(value.escludiTHC===1  && props.user.role_id ===1){
                return <span className={classes.hidden}>{(parseFloat(value.totali.tot_CBD)/parseFloat(value.totali.tot_THC)).toFixed(2)}</span>
              }else{
                return (parseFloat(value.totali.tot_CBD)/parseFloat(value.totali.tot_THC)).toFixed(2)
              }
            }else{
              return '-';
            }
            
          }, 
          filter: false,
          sort: true,
          // setCellProps:(value) =>{
            
          // 	return{
          // 		className: value>=30 ? classes.goodBg :null
          // 	}
          // }
          setCellProps:(value) =>{
            if(value.hasOwnProperty('props')){
              return{
                className: value.props.children>=30 ? classes.goodBg :null
              }
            }else{
              return{
          		  className: value>=30 ? classes.goodBg :null
          	  }
            }
          	
          }
        }
      },
      {
        label: "CBDV",
        name:"referto",
        options: {
          customBodyRender:(value) => value!==null ? parseFloat(value.totali.CBDV) : '-',
          filter: false,
          sort: true
        }
      },
      {
        label: "CBDA",
        name:"referto",
        options: {
          customBodyRender:(value) => value!==null ? parseFloat(value.totali.CBDA) : '-',
          filter: false,
          sort: true
        }
      },
      {
        label: "CBGA",
        name:"referto",
        options: {
          customBodyRender:(value) => value!==null ? parseFloat(value.totali.CBGA) : '-',
          filter: false,
          sort: true
        }
      },
      {
        label: "CBG",
        name:"referto",
        options: {
          customBodyRender:(value) => value!==null ? parseFloat(value.totali.CBG) : '-',
          filter: false,
          sort: true
        }
      },

      {
        label: "CBD",
        name:"referto",
        options: {
          customBodyRender:(value) => value!==null ? parseFloat(value.totali.CBD) : '-',
          filter: false,
          sort: true
        }
      },
      {
        label: "THCV",
        name:"referto",
        options: {
          customBodyRender:(value) => value!==null ? parseFloat(value.totali.THCV) : '-',
          filter: false,
          sort: true
        }
      },
      {
        label: "CBN",
        name:"referto",
        options: {
          customBodyRender:(value) => value!==null ? parseFloat(value.totali.CBN) : '-',
          filter: false,
          sort: true
        }
      },
      {
        label: "d9_THC",
        name:"referto",
        options: {
          customBodyRender:(value) => {
            if(value!==null){
              
              if(value.escludiTHC===1  && props.user.role_id !==1){
                return '-';
              }else if(value.escludiTHC===1  && props.user.role_id ===1){
                return <span className={classes.hidden}>{parseFloat(value.totali.d9_THC)}</span>
              }else{
                return parseFloat(value.totali.d9_THC)
              }
            }else{
              return '-';
            }
            
          },//value!==null ? parseFloat(value.totali.d9_THC) : '-',
          filter: false,
          sort: true
        }
      },
      {
        label: "d8_THC",
        name:"referto",
        options: {
          customBodyRender:(value) => value!==null ? parseFloat(value.totali.d8_THC) : '-',
          filter: false,
          sort: true
        }
      },
      {
        label: "CBC",
        name:"referto",
        options: {
          customBodyRender:(value) => value!==null ? parseFloat(value.totali.CBC) : '-',
          filter: false,
          sort: true
        }
      },
      {
        label: "THCA",
        name:"referto",
        options: {
          // customBodyRender:(value) => value!==null && value.totali!==null ? parseFloat(value.totali.THCA) : '-',
          customBodyRender:(value) => {
            if(value!==null){
              
              if(value.escludiTHC===1  && props.user.role_id !==1){
                return '-';
              }else if(value.escludiTHC===1  && props.user.role_id ===1){
                return <span className={classes.hidden}>{parseFloat(value.totali.THCA)}</span>
              }else{
                return parseFloat(value.totali.THCA)
              }
            }else{
              return '-';
            }
            
          },
          filter: false,
          sort: true
        }
      },
  ];

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
       <MUIDataTable data={props.righe} columns={columns} options={{...options,...props.opt}} />
    </MuiThemeProvider>
  );
}

CannabinoidiTable.defaultProps = {
  opt:{},
  codice:false
}

export default connect(mapStateToProps)(CannabinoidiTable);
