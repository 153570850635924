import { CardContent } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { ListItemAvatar } from "@material-ui/core";
import { List } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Card, makeStyles } from "@material-ui/core";
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import theme from "src/theme";
import { Box } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { Search } from "react-feather";
const useStyles = makeStyles({
    root: {
        position: "sticky",
        top: "1rem",
    },
    title: {
        fontSize: 14
    },
    list:{
        height:350,
        overflow:"auto",
    },
    link:{
        color:theme.palette.text.primary,
        "&:visited":{
            color:theme.palette.text.primary,

        }
    },
    noBorder:{
        border:"none"
    },
    search:{
        backgroundColor:"#eee",
        borderRadius:5,
        marginBottom:10
    }
});


const ContactsCard = (props)=> {
    const [search, setSearch]=useState("");
    const [list, setList] = useState([]);
    const classes = useStyles();
    const {contatti} = props;

    useEffect(()=>{
        setList(contatti);
    },[contatti]);

    useEffect(()=>{
        if(search){
             var filter  =  contatti.filter(el => el.azienda.toLowerCase().includes(search.toLowerCase()) || (el.first_name + " "+ el.last_name).toLowerCase().includes(search.toLowerCase()));
             setList(filter);
        }else{
            setList(contatti);
        }
    },[search]);

    return (
        <Card className={classes.root} elevation={8}>
            <CardContent>
                <Box display="flex" justifyContent="space-between">
                    <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    >
                    Contatti
                    </Typography>  

                    <TextField
                    size="small"
                    variant="outlined"
                    value={search}
                    onChange={(e)=>setSearch(e.target.value)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Search /></InputAdornment>,
                        classes:{notchedOutline:classes.noBorder}
                    }}
                    margin="none"
                    placeholder="Cerca..."
                    className={classes.search}
                    />  
                </Box>
                

                <Divider color="secondary"/>

                <PerfectScrollbar>
                    <List className={classes.list}>
                        {list.map(contatto =>(
                            <Fragment key={contatto.id}>
                            <ListItem alignItems="flex-start" className={classes.link} component={Link} to={"/customer/"+contatto.id}>
                                <ListItemAvatar>
                                    <Avatar>
                                        {contatto.cod.toUpperCase()}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                primary={contatto.azienda}
                                secondary={
                                    contatto.first_name + " "+ contatto.last_name
                                }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            </Fragment>
                        ))}
                    </List>
                </PerfectScrollbar>
                
            </CardContent>
        </Card>
    );
}

export default ContactsCard;