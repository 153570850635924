import React, {useState, useEffect,Fragment} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import InputAutoComplete from 'src/components/forms/InputAutoComplete';
import apiClient from 'src/utils/apiClient';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { TableContainer } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { Table } from '@material-ui/core';
import CheckBox from 'src/components/forms/CheckBox';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const styles = {
    appBar:{
      position: 'relative',
      backgroundColor:"#5AB66D"
    }
  }

  const useStyles = makeStyles((theme) => ({
    dialog:{
        paddingBottom:40
    },
    bottomFixed:{
        position:"fixed",
        bottom:0,
        maxHeight:40,
        left:0,
        right:0,
        backgroundColor:"#ffffff",
        padding:'0px 8px',
        display: 'flex',
        alignItems: 'center',
        marginBottom:8
    },
    circular:{
        width:'30px !important',
        height:'30px !important',
        marginRight:8
    },
    grid:{
        padding:'8px 16px'
    },
    text:{flexGrow:1},
    subText:{color: theme.palette.text.secondary, fontSize:14},
  }))

  const ModaleCopiaOrdine = (props)=>{
    const {open,close,ordine,setResponse} = props;
    const classes = useStyles();
    const [to, setTo] = useState(null);
    const [isSaving,setIsSaving] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [payment, setPayment] = useState(0);
    const [totale,setTotale] = useState(0);
    const [with_ticket, setWithTicket] = useState(false);
    const [senzaIva, setSenzaIva] = useState(false);
    const [errore, setErrore] = useState("");
    const [selectedRow,setSelectedRow] = useState([]);

    useEffect(()=>{
        setTickets([]);
        if(to){
           apiClient.get('api/ticket/user/'+to.id)
            .then(response => {
                var ticket_list = response.data.tickets.map(el=>({...el,usati:0}))
                setTickets(ticket_list)
            })
        }
        
    },[to]);

    useEffect(()=>{
        if(open){
            setSelectedRow(ordine.righe.map(r=>r.id));
        }
    },[open])

    const onClose = ()=>{
        setTo(null);
        setIsSaving(false);
        setTickets([]);
        setPayment(0);
        setTotale(0);
        setWithTicket(false);
        setSenzaIva(false);
        setErrore("");
        close();
    }

    useEffect(()=>{
        var tot = 0;
        if(!ordine) return;
        if(!to) return;

        if(payment === 1){
            setTotale(0);
            return;
        }

        ordine.righe
        .filter(el=>selectedRow.indexOf(el.id)!==-1)
        .map(riga=>{
            tot += parseFloat(to.offerta.find(el=> el.id === riga.extras[0].id).prezzo);
            return riga;
        });
        setTotale(tot);

    },[to,payment,selectedRow]);

    const handleSubmit = ()=>{
        setIsSaving(true);

        var post  = new FormData();
        post.append('id_ordine',ordine.id);
        post.append('id_utente',to.id);
        post.append('payment',payment);
        post.append('with_ticket',with_ticket);
        post.append('senzaIva',senzaIva);
        for (let i = 0; i < selectedRow.length; i++) {
            post.append('selectedRow[]',selectedRow[i]);
        }
        //post.append('selectedRow[]',selectedRow);

        apiClient.post('api/analisi/copia',post)
        .then(response=>{
            
            setIsSaving(false);
            if(response.data.error){
                setErrore("si è verificato un errore nella copia dell'ordine");
            }else{
                setResponse(response.data);
                onClose();
            }
        })
        .catch(err=>{
            console.log(err.response);
            setIsSaving(false);
            setErrore("si è verificato un errore nella copia dell'ordine");
        })

        
    }

    const calcolaPrezzoRiga = (riga)=>{
        var prezzo =0;
        if(payment === 0){
            prezzo = to ? 
            to.offerta.find(el => el.id === riga.extras[0].id).prezzo
            : 
            0;
        }else if(payment === 1){
            prezzo=0;
        }else{
            prezzo = 0;
        }

        return prezzo;
    }

    const onSelectAllClick = (event) => {
        if (event) {
          const newSelecteds = ordine.righe.map((n) => n.id);
          setSelectedRow(newSelecteds);
          return;
        }
        setSelectedRow([]);
    };

    const onSelectRow = (event,id) => {
        if (event) {
            setSelectedRow([...selectedRow,id]);
        }else{
            setSelectedRow(selectedRow.filter(r => r!==id));
        }
        
    };

    return(
        <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition} PaperProps={{className:classes.dialog}}>
            <AppBar style={styles.appBar}>
                <Toolbar>
                <IconButton color="inherit" onClick={onClose} aria-label="Close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" style={{display:'flex'}}>
                    {"Copia Ordine "}
                </Typography>
                </Toolbar>
            </AppBar>

            <Grid container spacing={0} className={classes.grid}>
                <Grid item xs={12}>
                    <InputAutoComplete 
                    label="Utente"
                    url="api/users"
                    name="user"
                    onChange={(e,val,r)=>setTo(val)}
                    value={to}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.azienda}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <div className={classes.text}>
                            {option.azienda}
                            <br />
                            <span className={classes.subText}><i>{"Referente: "}</i></span><span className={classes.subText}>{option.first_name+" " +option.last_name}</span>
                          </div>
                        </React.Fragment>
                    )}
                    /> 
                </Grid>

                <Grid item xs={12}>
                    <ToggleButtonGroup
                    value={payment}
                    exclusive
                    onChange={(event, newVal)=> setPayment(newVal)}
                    aria-label="pagamento"
                    >
                        <ToggleButton value={0} aria-label="Genera pagamento" color="primary">
                            Con pagamento
                        </ToggleButton>
                        
                        <ToggleButton value={1} aria-label="Genera pagamento">
                            Senza pagamento
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <CheckBox
                                        checked={selectedRow.length > 0 && selectedRow.length === ordine?.righe?.length}
                                        handleChange={event=>onSelectAllClick(event)}
                                        />
                                    </TableCell>
                                    <TableCell variant="head">Nome Campione</TableCell>
                                    <TableCell variant="head">Varietà</TableCell>
                                    <TableCell variant="head">Gr.</TableCell>
                                    <TableCell variant="head">Descrizione</TableCell>
                                    <TableCell variant="head">Analisi Richieste</TableCell>
                                    <TableCell variant="head">Prezzo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ordine?.righe?.map(riga=>{
                                    var prezzo = calcolaPrezzoRiga(riga);
                                    const isItemSelected = selectedRow.indexOf(riga.id)!==-1;
                                    return(
                                    <TableRow key={riga.id}>
                                        <TableCell padding="checkbox">
                                            <CheckBox
                                            checked={isItemSelected}
                                            handleChange={(event)=>onSelectRow(event,riga.id)}
                                            />
                                        </TableCell>
                                        <TableCell>{riga.nome_campione}</TableCell>
                                        <TableCell>
                                            <Typography>{riga.cartellino.varieta.nome}</Typography>
                                            <Typography color="textSecondary" variant="body2">{riga.cartellino.codice}</Typography>
                                        </TableCell>
                                        <TableCell>{riga.grammatura}</TableCell>
                                        <TableCell>{riga.descrizione}</TableCell>
                                        <TableCell>{riga.extras[0].nome}</TableCell>
                                        <TableCell>{prezzo}</TableCell>
                                    </TableRow>
                                    )
                                })}
                                <TableRow>
                                    <TableCell colSpan={5} rowSpan={3}></TableCell>
                                    <TableCell variant="head">Imponibile</TableCell>
                                    <TableCell>{totale.toFixed(2)}€</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">
                                        IVA 
                                    </TableCell>
                                    <TableCell>{senzaIva? 0.00: (totale * 0.22).toFixed(2)+"€"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">TOTALE</TableCell>
                                    <TableCell>{senzaIva? totale.toFixed(2)+"€" : (totale * 1.22).toFixed(2)+"€"}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12}>
                    <CheckBox
                    value={with_ticket}
                    label= "utilizza voucher se disponibile"
                    handleChange={setWithTicket} 
                    />
                </Grid>

                <Grid item xs={12}>
                    <CheckBox
                    value={senzaIva}
                    label= "Regime fiscale senza IVA"
                    handleChange={setSenzaIva} 
                    />
                </Grid>

            </Grid>
            <div className={classes.bottomFixed}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>SALVA</Button>
                {isSaving &&
                <Fragment>
                    <CircularProgress className={classes.circular}/>
                    <Typography variant="overline" style={{color:"#aaa"}}>Salvataggio in corso</Typography>
                </Fragment>
                }
                {errore !== "" && 
                <Typography variant="overline" style={{color:"#ff0000"}}>{errore}</Typography>
                }
            </div>
        </Dialog>
    );
  }

  export default ModaleCopiaOrdine;