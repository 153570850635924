
import { List } from "@material-ui/core";
import MuiListItem from "@material-ui/core/ListItem";
import { Avatar } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";

import { Divider } from "@material-ui/core";
import { Icon } from "@material-ui/core";
import { ListItemAvatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import theme from "src/theme";


const useStyles = makeStyles((theme)=>({
    root: {
        position: "sticky",
        top: "1rem",
    },
    title: {
        fontSize: 14
    },
    list:{
        height:350,
        overflow:"auto",
    },
    
    noBorder:{
        border:"none"
    },
    search:{
        backgroundColor:"#eee",
        borderRadius:5,
        marginBottom:10
    }
}));
// const style = {
//     root:{
//         "&:hover":{
//             backgroundColor:"#e7e7e7"
//         }
//     }
// }

const ListItem = withStyles({
    root: {
      "&$selected": {
        backgroundColor: theme.palette.primary.lighter,
        
      },
      "&$selected:hover": {
        backgroundColor: "#e7e7e7",
        
      },
      "&:hover": {
        backgroundColor: "#e7e7e7",
        
      }
    },
    selected: {}
  })(MuiListItem);

const PaymentsList =( props )=>{
    const classes = useStyles();
    const {payments,listItemProps,isButton,handleClick,selected} = props;
    return(
        
            <List className={classes.list}>
                {payments.map(pay =>(
                    <Fragment key={pay.id}>
                    <ListItem 
                    alignItems="flex-start" 
                    button={isButton}
                    onClick={isButton ? () => handleClick(pay) : null}
                    {...listItemProps}
                    selected={selected ? pay.id===selected.id: false }
                    >
                        <ListItemAvatar>
                            <Avatar className={pay.refer_type==="analisi" ? "science" :(pay.refer_type==="deodorazione"? "deo": "ticket" ) }>
                                {pay.refer_type==="analisi" ?
                                <Icon>science</Icon>
                                :
                                (pay.refer_type==="deodorazione" ?
                                <Icon>trending_down</Icon>
                                :
                                <Icon>confirmation_number</Icon>
                                )
                                
                                }
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                        primary={pay.refer.codice}
                        secondary={
                            pay.totale+"€"
                        }
                        />
                        <div className={pay.perc==0 ? "chip chip-0" : pay.perc==100 ? "chip chip-100": " chip chip-x"}>
                            <span className="chip-label">{pay.perc==100 ? "Saldato" : "Da saldare"}</span>
                        </div>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    </Fragment>
                ))}
            </List>
        
    );

}

PaymentsList.defaultProps= {
    listItemProps:{},
    isButton:false,
  }

export default PaymentsList;