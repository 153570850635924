import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import { colors } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      // padding: 8,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    cAvatar:{
      color: theme.palette.getContrastText(colors.deepPurple[500]),
      backgroundColor: colors.cyan[200],
      
    },
    vAvatar:{
        color: theme.palette.getContrastText(colors.amber[500]),
        backgroundColor: colors.green[200],
        
    },
  })
);

const TagAutocomplete = (props)=>{
    const {option,value, addTag} = props;
    //const [value, setValue] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const classes=useStyles();

    const addNewTag= ()=>{
        
        addTag([
            ...value,
            {
            id:null,
            name:inputValue,
            tag_group_id:null
            }
        ])
    }
    
    return(
        <Autocomplete
        value={value}
        onChange={(e, newValue) => {
          if(e.keyCode ===13 && e.target.value){
            addNewTag();
          }else{
            addTag(newValue);
          }
          
        }}
        multiple
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        freeSolo
        id="controllable-states-demo"
        options={[...option].sort((a,b)=>{
            let ai = value.findIndex(i=>i.id===a.id);
            ai = ai === -1 ? value.length + option.indexOf(a) : ai;
            let bi = value.findIndex(i=>i.id===b.id);
            bi = bi === -1 ? value.length + option.indexOf(b) : bi;
            return ai - bi;
        })}
        style={{ width: 300 }}
        renderOption={(option, { selected, ...other }) => {
            return(
            <React.Fragment>
              <DoneIcon
                className={classes.iconSelected}
                style={{ visibility: selected ? 'visible' : 'hidden' }}
              />
              <span 
              className={clsx(classes.color,(option.tag_group_id==3 ? classes.cAvatar: classes.vAvatar) )} 
              style={{ visibility: option.tag_group_id ? 'visible' : 'hidden' }}
              />
              <div className={classes.text}>
                {option.name}
                {option.tag_group_id &&
                <>
                    <br />
                    {option.tag_group_id==3 ? "Cartellino": "Varietà"}
                </>
                }
              </div>
            </React.Fragment>
        )}}
        getOptionLabel={(option) => { return option.name}}
        getOptionSelected={(option, value)=>{ return value.id===option.id;}} 
        renderInput={(params) => {

            return(<Paper ref={params.InputProps.ref} className={classes.root}>
                <InputBase
                className={classes.input}
                placeholder="Aggiungi un tag"
                {...params.inputProps}
                // {...props.inputBaseProps}
                />
                <Divider className={classes.divider} orientation="vertical" />
                <Button size="small" color="primary" variant="contained" className={classes.iconButton} onClick={()=>addNewTag()}>
                Aggiungi
                </Button>
            </Paper>)
        }
        
      }
        />
    );
}


TagAutocomplete.defaultProps={
    option:[]
}
export default TagAutocomplete;