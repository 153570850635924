import React from 'react';
import CookieConsent from "react-cookie-consent";

const CookieBanner = (props)=>{
    return (
    <CookieConsent
    location="bottom"
    buttonText="Ho capito!"
    cookieName="siflabGdpr"
    style={{ background: "rgba(39, 167, 74, 1)", zIndex:2000 }}
    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
    containerClasses="bannerShadow"
    >
    Questo sito fa uso di soli cookies di sistema necessari per il corretto funzionameto del sito.{" "}
    
    </CookieConsent>)
}

export default CookieBanner;

