import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
// import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
// import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
//import SettingsView from 'src/views/settings/SettingsView';
import SplashView from 'src/views/splash/SplashView';
import AnalisiList from 'src/views/analisi/AnalisiListView';
import AnalisiPage from 'src/views/analisi/AnalisiPageView';
import SubPage from 'src/layouts/DashboardLayout/SubPage.js';
import NuovaAnalisi from './views/analisi/NuovaAnalisi';
import CreaTicketView from './views/ticket/CreaTicketView';
import PaymentsListView from './views/pagamenti/PaymentsListView';
import PaymentDetailsView from './views/pagamenti/PaymentDetailsView';
import CustomerList from './views/costumer/CustomerList';
import CustomerPage from './views/costumer/CustomerPage';
import CannabinoidiList from './views/reports/CannabinoidiList';
import AnalisiView from './views/settings/AnalisiView';
import PacchettiView from './views/settings/PacchettiView';
import ImportaAnalisi from './views/analisi/ImportaAnalisi.js';
import ForgottenPasswordView from './views/auth/ForgottenPaswordView';
import ResetPasswordView from './views/auth/ResetPasswordView';
import Comparatore from './views/Comparatore';
import TicketListView from './views/ticket/TicketListView';
import ImportaDeodorazioniView from './views/deodorazioni/ImportaDeodorazioniView';
import DeodorazionePageView from './views/deodorazioni/DeodorazionePageView';
import DeodorazioniListView from './views/deodorazioni/DeodorazioniListView';
import Home from './views/Home';
import HomeLayout from './layouts/HomeLayout';
import Landing from './views/Home/Landing';
import VarietaView from './views/settings/varietaView';


const routes = (isLoading,isLoggedIn,user)=> {
  return isLoading ? 
  [
    {
      path:'/',
      element: <SplashView />,
      children: [
            { path: '*', element: <Navigate to="/" /> }
          ]
    }
  ] 
  
  :
  
  [
    {
      path: '/',
      element: isLoggedIn ? <DashboardLayout /> : <HomeLayout/>,
      children: isLoggedIn ? [
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: 'dashboard', element: <DashboardView /> },
        { path: 'analisi', element: <SubPage />, children:[
          { path: '/', element: <AnalisiList />},
          { path: ':id', element: <AnalisiPage /> },
          {path: '/nuova', element: user.role_id == 1 ?<ImportaAnalisi /> : <NuovaAnalisi />}
        ]},
        { path: 'voucher', element: <SubPage />, children:[
          {path: '/nuovo', element: user.role_id == 1 ? <CreaTicketView /> : <Navigate to="/"/> }
        ]},
        
        { path: 'vouchers', element: user.role_id == 1 ? <TicketListView />:<Navigate to="/"/> },

        { path: 'pagamenti', element: <SubPage />, //user.role_id == 1 ?  <SubPage />:<Navigate to="/"/>
        children:[
          {path: '/', element: <PaymentsListView /> }
        ]},

        { path: 'deodorazione', element: <SubPage />, children:[
          {path: '/nuova', element: user.role_id == 1 ?<ImportaDeodorazioniView /> : <Navigate to="/"/>},
          { path: ':id', element: <DeodorazionePageView /> },
        ]},

        { path: 'deodorazioni', element: <DeodorazioniListView />},

        { path: 'pagamento', element: <SubPage />, 
        children:[
          {path: ':id', element: <PaymentDetailsView /> }
        ]},

        { path: 'customers', element: user.role_id == 1 ? <CustomerList />:<Navigate to="/"/> },

        { path: 'referti', element: user.role_id == 1 ? <CannabinoidiList />:<Navigate to="/"/> },
    
        { path: 'customer', element: user.role_id == 1 ?  <SubPage />:<Navigate to="/"/>, 
        children:[
          {path: ':id', element: <CustomerPage /> },
          {path: '/', element: <Navigate to="/customers"/> }
        ]},

        { path: 'settings', element: user.role_id == 1 ?  <SubPage />:<Navigate to="/"/>, 
        children:[
          {path: '/analisi', element: <AnalisiView /> },
          {path: '/pacchetti', element: <PacchettiView /> },
          {path: '/varieta', element: <VarietaView />}
        ]},

        { path: 'comparatore', element: <Comparatore/>},

        { path: 'account', element: <AccountView /> },
        { path: '/auth/*', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
      :
      [
        { path: '/', element: <Landing /> },
        { path: '*', element: <Navigate to="/" /> }
      ]
    },
    {
      path: '/auth',
      element: !isLoggedIn ? <MainLayout /> : <Navigate to="/dashboard" />,
      children: [
        { path: 'login', element: <LoginView /> },
        { path: 'register', element: <RegisterView /> },
        { path: 'forgotten-password', element: <ForgottenPasswordView />},
        { path: 'password-reset/:token', element: <ResetPasswordView />},
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '/404', element: <NotFoundView /> },
  ];
}


export default routes;

// { path: 'account', element: <AccountView /> },
          // { path: 'customers', element: <CustomerListView /> },
          // { path: '/', element: <AnalisiList />},
          // { path: ':id', element: <AnalisiPage /> },
        // { path: 'products', element: <ProductListView /> },
        // { path: 'settings', element: <SettingsView /> },