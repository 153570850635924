import React from 'react';
import MomentUtils from '@date-io/moment';
import { DatePicker,MuiPickersUtilsProvider } from '@material-ui/pickers';


const PickerDate = (props) =>{
    const {selectedDate,handleDateChange,label} = props;
    
    return(
        <MuiPickersUtilsProvider utils={MomentUtils} >
            <DatePicker
                value={selectedDate}
                onChange={date => handleDateChange(date)}
                format="DD-MM-YYYY"
                
                margin="normal"
                label={label}
            />
        </MuiPickersUtilsProvider>
    );
}

export default PickerDate;