import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import InputText from 'src/components/forms/InputText';

function ModaleSaldaPagamento(props) {
  //const [open, setOpen] = React.useState(false);
  const {open,toggle,payment,perc,setPerc,salva}=props

  return (
      <Dialog
        open={open}
        onClose={toggle}
      >
        <DialogTitle id="alert-dialog-title">{"Imposta fattura pagata"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Imposta la percentuale pagata
          </DialogContentText>
          <div>
            <Typography variant="caption">ATTUALMENTE SALDATO</Typography>
            <Typography>{payment.perc+'%'}</Typography>
          </div>
          <div>
            <InputText label="percentuale saldata" value={perc} handleChange={setPerc} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle}>
            Chiudi
          </Button>
          <Button onClick={salva} color="primary" autoFocus>
            Continua
          </Button>
        </DialogActions>
      </Dialog>
  );
}
export default ModaleSaldaPagamento;