import { Icon } from '@material-ui/core';
import { Button, Card, CardContent, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { Tag } from 'react-feather';
import ModaleSaldaPagamento from 'src/modali/ModaleSaldaPagamento';
import PagamentoProgress from './PagamentoProgress';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Divider } from '@material-ui/core';
import ReminderProforma from 'src/components/ReminderProforma';


const useStyles = makeStyles((theme)=>({
    avatar: {
        height: 60,
        width: 60,
        marginRight:16
    },
    marginBot:{
        marginBottom:24
    },
    ticketTot:{
        fontSize:16
    },
    saldato:{
        //backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.main,
        textAlign:"center"
    },
    daSaldare:{
        //backgroundColor:theme.palette.alert.main,
        color:theme.palette.alert.main,
        textAlign:"right"
    },
    wt:{color:'#ccc'},
    dataElem:{
        [theme.breakpoints.up('md')]:{
            width:300
        },
        [theme.breakpoints.down('sm')]:{
            marginBottom:4
        }
    },
    dataList:{
        display:"flex",
        marginBottom:36,
        [theme.breakpoints.down('sm')]:{
            flexDirection:"column"
        }
    }
}))

const DeodorazioneRefer = (props) =>{
    const {deodorazione,payment,setPagato,perc,setPerc,user,handleDateChange} = props;
    const classes = useStyles();
    const [paga,setPaga] = useState(false);

    var imponibile= parseFloat(payment.totale);
    var iva = payment.senzaIVA ? 0 : imponibile * 0.22;
    var tot = imponibile+iva;

    const togglePaga = ()=>{
        setPaga(!paga);
        setPerc('');
    }
    const salva = ()=>{
        setPagato()
        .then(()=>{
            togglePaga()
        })
    }
    return (
        <Card>
            <CardContent>
                <Grid container spacing={0}>
                    {deodorazione && 
                    <>
                        <Grid item xs={12} className={classes.marginBot}>
                            <Typography variant="h5">Riferimento: Deodorazione {deodorazione.codice}</Typography>
                            {/* <Typography variant="caption"><em>{'ordinato il '+ moment(analisi.data_acquisto).format("DD/MM/YYYY")}</em></Typography>
                            <br/>
                            <Typography variant="caption"><em>{'accettato il '+ (analisi.data_accettazione? moment(analisi.data_accettazione).format("DD/MM/YYYY"): "in attesa" )}</em></Typography> */}
                        </Grid>

                        <Grid item xs={12}  className={classes.dataList}>
                            <div className={classes.dataElem}>
                               <Typography variant="h6" color="primary">Ordinato il</Typography>
                                <Typography variant="subtitle1">{moment(deodorazione.data_acquisto).format("DD/MM/YYYY")}</Typography> 
                            </div>

                            <div className={classes.dataElem}>
                                <Typography variant="h6" color="primary">Accettato il</Typography>
                                <Typography variant="subtitle1">{deodorazione.data_accettazione? moment(deodorazione.data_accettazione).format("DD/MM/YYYY"): <i>in attesa</i>}</Typography>
                            </div>

                            <div className={classes.dataElem}>
                                <Typography variant="h6" color="primary">Fattura del</Typography>

                                {user.role_id ===1 ? 
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label=""
                                format="DD/MM/YYYY"
                                value={payment.data_fatturazione}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                />
                                </MuiPickersUtilsProvider>
                                :
                                <Typography variant="subtitle1">{payment.data_fatturazione ?  moment(payment.data_fatturazione).format("DD/MM/YYYY"): <i>nessuna fattura emessa</i>}</Typography>
                                }
                                
                            </div>

                            {payment.proforma && 
                            <div className={classes.dataElem}>
                                <ReminderProforma id_user={payment.user.id} id_payment={payment.id} />
                            </div>
                            }
                            
                        </Grid>

                        

                        

                        {payment.proforma && 
                        <Grid item xs={12} className={classes.marginBot}>
                            <Button size="small" href={payment.src} target="_blank" component="a" color="primary" variant="outlined">
                                Vedi proforma
                            </Button>
                        </Grid>
                        }

                        <Grid item xs={12}>
                            <Divider color="primary"/>
                        </Grid>

                        <Grid item xs={12}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow style={{backgroundColor:"rgba(0,0,0,0.12)"}}>
                                            <TableCell variant="head">Codice</TableCell>
                                            <TableCell variant="head">Nome campione</TableCell>
                                            <TableCell variant="head">Varietà</TableCell>
                                            <TableCell variant="head">Peso Dichiarato</TableCell>
                                            <TableCell variant="head">Peso Rilevato</TableCell>
                                            <TableCell variant="head">Prezzo</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {deodorazione.materie.map((materia)=>
                                            <TableRow key={materia.id}>
                                                <TableCell>{materia.codice }</TableCell>
                                                <TableCell>{materia.nome_materia}</TableCell>
                                                <TableCell>
                                                    <Typography>{materia.cartellino.varieta.nome}</Typography>
                                                    <Typography variant="body2">{materia.cartellino.codice}</Typography>
                                                </TableCell>
                                                <TableCell>{materia.peso_dichiarato}</TableCell>
                                                <TableCell>{materia.peso_rilevato}</TableCell>
                                                <TableCell>{materia.prezzo}</TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell colSpan={4} rowSpan={3}></TableCell>
                                            <TableCell variant="head">Imponibile</TableCell>
                                            <TableCell>{imponibile}€</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">
                                                IVA
                                                {payment.senzaIVA==1 && <Typography variant="caption"><em>*Regime fiscale senza IVA</em></Typography>}
                                            </TableCell>
                                            <TableCell>{iva.toFixed(2)}€</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell variant="head">TOTALE</TableCell>
                                            <TableCell>{tot}€</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            
                            
                        </Grid>
                        <Grid item sm={6} xs={12}></Grid>
                        <Grid item sm={6} xs={12}>
                            <PagamentoProgress value={payment.perc} />
                            {payment.pagato ? 
                                <Typography variant="h4" className={classes.saldato}>SALDATO</Typography>
                                : 
                                <>  
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell><Typography variant="h4" className={classes.daSaldare}>DA SALDARE</Typography></TableCell>
                                                    <TableCell><Typography variant="h4" className={classes.daSaldare}>{(tot*(1-payment.perc/100).toFixed(2))}</Typography></TableCell>
                                                </TableRow>
                                            </TableBody>    
                                        </Table>
                                        
                                    </TableContainer>
                                    {user.role_id ===1 && 
                                    <>
                                    <Button onClick={togglePaga}>Imposta come pagato</Button>
                                    <ModaleSaldaPagamento open={paga} toggle={togglePaga} salva={salva} payment={payment} perc={perc} setPerc={setPerc}/>
                                    </>
                                    }
                                    
                                </>
                            }
                        </Grid>
                    </>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
}

export default DeodorazioneRefer;