import React, { useState } from 'react';
import { Backdrop, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import apiClient from 'src/utils/apiClient';
import SelezioneUtente from 'src/components/SelezioneUtente';
import OrdineDeo from './OrdineDeo';
import ModaleResponse from './ModaleResponse';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
  }));

  const colonne = ["operatore","cliente","data_accettazione","ddt_ingresso","varieta","cartellino","nome_materia","codice","peso_dichiarato"]

  const formatClipboard = (clip)=>{
    var lines = clip.split('\n');
    
    var res = lines.filter(el=>el!="").map(el => {
        
        if(el!=""){
            return el.split('\t')
        }
    });

    var deo ={
        azienda:res[0][1],
        data_accettazione: res[0][2],
        
        materie: res.map((riga,i) => {
            console.log(riga);
            var cod = riga[7].split(".");
            var r={
                ddt_ingresso: (riga[3]==="non fornito"|| riga[3]==="")? "NA" : riga[3] ,
                varieta:riga[4],
                cartellino:riga[5],
                nome_materia:riga[6],
                codice:riga[7],
                peso_dichiarato: parseFloat(riga[8].replace(",",".")),
                posizione:i+1,
                stato:cod[1],
                prezzo: 100.0,
                peso_rilevato: riga[9].trim()? parseFloat(riga[9].replace(",",".")) : ""
            };
            return r;
        })
    }
    return deo;
}

const newUser = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    citta: "",
    indirizzo: "",
    azienda:"",
    p_iva:"",
    codice_fiscale:"",
    codice_univoco:"",
    cap:"",
    forma_societaria:"",
    offerta:[]
}

const ImportaDeodorazioniView =  (props)=>{
    const classes  = useStyles();
    const [parsing ,setParsing] = useState(null);
    const [saving, setSaving] = useState(null);
    const [error,setError] = useState("");
    const [response, setResponse] = useState(null);
    const [tab,setTab] = useState(0);
    const [user,setUser] = useState(null);
    const [nuovoUtente,setNuovoUtente]=useState(newUser);
    const [ordine, setOrdine] = useState(null);
    const [codeFromExcel, setCodeFromExcel] = useState(false);
    const [sendMail,setSendMail]= useState(false);

    const checkAzienda = (azienda)=>{
        return apiClient.get('api/users/check',{params:{azienda:azienda}})   
    }

    const handleSetUser = (e,val,r)=>{
        console.log(e,val,r);
        if(r!=="clear"){
            setUser(val);
        }
        
    }
    const changeTab = (event, newValue)=>{
        setTab(newValue);
    }

    const handleNuovoChange = (event) => {
        setNuovoUtente({
          ...nuovoUtente,
          [event.target.name]: event.target.value
        });
    };

    const handlePesoRilevatoChange = (val,i)=>{
        setOrdine({
            ...ordine,
            materie:[
                ...ordine.materie.slice(0,i),
                {...ordine.materie[i],
                peso_rilevato:val
                },
                ...ordine.materie.slice(i+1),
            ]
        })
    }

    const handlePrezzoChange =(val,i) =>{
        setOrdine({
            ...ordine,
            materie:[
                ...ordine.materie.slice(0,i),
                {...ordine.materie[i],
                prezzo:val
                },
                ...ordine.materie.slice(i+1),
            ]
        })
    }

    const backdropOpen = ()=>{
        if(saving == null && parsing== null) return false;

        return saving || parsing;
    }

    const handleSave = ()=>{
        setSaving(true);
        var post = new FormData();
        post.append('deo', JSON.stringify(ordine));
        post.append('codeFromExcel', codeFromExcel);
        post.append('sendMail', sendMail);
        if(tab==0){
            post.append('user_id',user.id);
        }else{
            post.append('nuovoUtente',JSON.stringify(nuovoUtente) );
        }

        apiClient.post('api/deodorazione/importa',post)
        .then(response=>{
            setResponse(response.data);
            setSaving(false);
        })
        .catch(err=>{
            console.log(err);
            setSaving(false);
        });
    }

    const parseClipboard = ()=>{
        setParsing(true);
        setError("");

        navigator.clipboard.readText().then(data =>{
            const deo  = formatClipboard(data);
            setOrdine(deo);
            checkAzienda(deo.azienda)
            .then(response=>{
                if(response.data.users.length===1){
                    setTab(0);
                    setUser(response.data.users[0]);
                }else if(response.data.users.length>1){
                    setTab(0);
                    setUser(response.data.users[0]);
                }else{
                    setTab(1);
                }
                setNuovoUtente({
                    ...nuovoUtente,
                    azienda:deo.azienda,
                    email:deo.azienda+'@scienzainfiore.it',
                });
            })
            .catch(err=>console.log(err));
        })
        .then(()=>setParsing(false));
    }
    return (
        <Page
        title="Importa deodorazione"
        className = {classes.root}
        >
            <Container maxWidth={false}>
                <Grid container spacing={0}>
                    <Grid item xs ={12}>
                        <Button variant="contained" color="primary" onClick={parseClipboard}>IMPORTA</Button>
                    </Grid>


                    {parsing!==null && !parsing && 
                    <>
                    <SelezioneUtente 
                    user={user}
                    setUser={handleSetUser} 
                    selectedTab={tab} 
                    changeTab={changeTab}
                    nuovoUtente={nuovoUtente}
                    setNuovoUtente={handleNuovoChange}
                    />


                    <OrdineDeo 
                    deo={ordine}
                    setDbCoding={setCodeFromExcel}
                    setSendMail={setSendMail}
                    setPesoRilevato={handlePesoRilevatoChange}
                    setPrezzo={handlePrezzoChange}
                    />

                    <Grid item xs ={12} style={{marginTop:18}}>
                        <Button variant="contained" color="primary" onClick={handleSave}>SALVA</Button>
                    </Grid>
                    </>
                    }
                </Grid>

                <Backdrop className={classes.backdrop} open={backdropOpen()}>
                    <CircularProgress color="primary" />
                </Backdrop>

                <ModaleResponse response={response} open={response!==null} toggle={()=>setResponse(null)}/>
            </Container>
        </Page>
    );
}

export default ImportaDeodorazioniView;