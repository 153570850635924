import { Grid,makeStyles } from '@material-ui/core';
import React from 'react';
import InputAutoComplete from 'src/components/forms/InputAutoComplete';

const useStyles = makeStyles((theme)=>({
    
    text:{flexGrow:1},
    subText:{color: theme.palette.text.secondary, fontSize:14},
}))

const SelectUtente = (props) =>{
    const {user,setUser} = props
    const classes = useStyles();
    return(
        <Grid item md={4} xs={12}>
            <InputAutoComplete 
            label="Utente"
            url="api/users"
            name="user"
            onChange={setUser}
            value={user}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.azienda}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                  <div className={classes.text}>
                    {option.azienda}
                    <br />
                    <span className={classes.subText}><i>{"Referente: "}</i></span><span className={classes.subText}>{option.first_name+" " +option.last_name}</span>
                  </div>
                </React.Fragment>
            )}
            />
        </Grid>
    );
}

export default SelectUtente;