import { makeStyles, Tooltip } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
    arrow:{
        color: '#fff',
        "&::before": {
            border: '1px solid #dadde9',
        }
    }
}))(Tooltip);

const useStyles = makeStyles(()=>({
    ul:{
      listStyle:'none'
    }
}))

const PacchettoTooltip = (props) =>{
    const {pacchetto,variant}=props;
    const classes = useStyles();

    const renderToltip = (analisi) => {
        
        return(
            <ul className={classes.ul}>
                {analisi.map(el=>
                    <li key={el.id}>
                        <span>{el.nome}</span>
                    </li>
                )}
            </ul>
        )
    }

    return (
        <HtmlTooltip
            title={
            <React.Fragment>
                <Typography color="inherit" variant="overline">Analisi comprese</Typography>
                {renderToltip(pacchetto.analisi)}
            </React.Fragment>
            }
            arrow={true}
        >
            <Typography variant={variant}>{pacchetto.nome}</Typography>
        </HtmlTooltip>
    );
}

PacchettoTooltip.defaultProps = {
    variant:"subtitle2"
};

export default PacchettoTooltip;