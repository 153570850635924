import { Button, makeStyles } from "@material-ui/core";
import React, {useState, useEffect, useCallback} from "react";
import { connect } from "react-redux";
import InputText from "src/components/forms/InputText";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from "axios";
import { API, SITE_URL } from "src/utils/const";
import clsx from "clsx";
import apiClient from "src/utils/apiClient";

const useStyles = makeStyles((theme) => ({
    success:{
        color:theme.palette.text.success,
    },
    error:{
        color:theme.palette.text.error,
    },
    margin:{
        margin:"5px 0",
        fontFamily:"'poppins', sans-serif",
    },
    formWrap:{
        display:"flex",
        flexDirection:"column",
        borderRightWidth:1,
        borderRightStyle:"solid",
        borderColor:"#666",
        paddingRight:16,
        [theme.breakpoints.down('sm')]: {
            borderRightWidth:0,
            borderBottomWidth:1,
            borderRightStyle:"solid",
            borderColor:"#666",
            
            borderBottomStyle:"solid"
        },
    }
}));

const ContactForm =(props)=>{
    const {lang} = props;
    const [nome,setNome] = useState("");
    const [email,setEmail] = useState("");
    const [messaggio,setMessaggio] = useState("");
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const classes = useStyles();

    const handleSubmit = useCallback(async ()=>{
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const token = await executeRecaptcha('homepage');
        
        apiClient.get("/sanctum/csrf-cookie")
        .then(response=>{
            apiClient.post(API+"/contact",{
                token:token,
                fullname:nome,
                email:email,
                msg:messaggio
            })
            .then(response=>{
                setSuccess(true);
            })
            .catch(err=>{
                setSuccess(false);
                setError("qualcosa è andato storto, riprova piu tardi");
            });
        })
        .catch(err=>{
            setSuccess(false);
            setError("qualcosa è andato storto, riprova piu tardi");
        });
    })
    useEffect(()=>{
        setError("");
        setSuccess(false);
    }, [nome,messaggio,email])
    
    return(
        <div className="column-flex contact-wrap">
            <h2 className="aboutUs-title">{lang.landing.slide5_title}</h2>
            <div className="aboutUs-text">
                <p style={{fontSize:15}}>{lang.landing.slide5_subtitle}</p>
            </div>

            <div className={classes.formWrap}>
                <InputText variant="filled" label={lang.landing.fullNameLabel} value={nome} handleChange={setNome} />
                <InputText variant="filled" label={lang.landing.emailLabel} value={email} handleChange={setEmail} />
                <InputText variant="filled" rows={4} multiline label={lang.landing.messageLabel} value={messaggio} handleChange={setMessaggio} />

                <Button className="contact-btn"  variant="contained" color="primary" onClick={handleSubmit}>{lang.landing.submitBtn}</Button>

                <div>
                    {success ? 
                    <p className={clsx(classes.success, classes.margin)}>Grazie per averci contattato. ti risponderemo il prima possibile</p>
                    :
                    error!==""?
                        <p className={clsx(classes.error, classes.margin)}>Ops si è verificato un errore, riprova pu tardi o scrivi a <a href="mailto:info@scienzainfiore.it">info@scienzainfiore.it</a></p>
                        :  
                        <p className={clsx(classes.error, classes.margin)}> </p>
                    }
                </div>
            </div>
            
        </div>
    );
}

const mapStateToProps = function(state){
    return {
        settings:state.settings,
        lang:state.lang
    }
  }

export default connect(mapStateToProps,null)(ContactForm);