import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { Typography, Grid, colors, makeStyles, Button } from '@material-ui/core';
import AccountDetails from './AccountDetails';
import AziendaDetails from './AziendaDetails';
import InputText from 'src/components/forms/InputText';
import apiClient from 'src/utils/apiClient';
import CodiceConvenzione from 'src/components/CodiceConvenzione';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& .MuiFilledInput-root": {
//       background: "#fff"
//     }
//   },
//   button:{
//     backgroundColor:"#fff",
    
//   }
// }));

export default function ReviewOrder(props) {
  const {setCod,cod} = props;
  const { values: formValues } = useFormikContext();
  const [codice,setCodice]= useState("");
  const [errore,setErrore]= useState("");
  const [success,setSuccess]= useState("");

  const handleChangeCod = (val)=>{
    if(cod != "") setCod("");
    if(errore!="") setErrore("");
    setCodice(val);
  }

  const checkCodice = ()=>{
    apiClient.get("api/checkConvenzione/"+codice)
    .then(response=>{
      setCod(codice);
      setSuccess("Codice Convenzione valido!")
    })
    .catch(err=>{
      console.log(err.response);
      setErrore("Codice non valido!")
    })
  }
  return (
    <React.Fragment>
      <Typography variant="h6" >
        Account
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Controlla i dati inseriti prima di continuare
      </Typography>

      <Grid container spacing={2}>
        <AccountDetails formValues={formValues} />
        <AziendaDetails formValues={formValues} />
      </Grid>

      <CodiceConvenzione codice={codice} err={errore} success={success} setCodice={handleChangeCod} submit={checkCodice} />
      
    </React.Fragment>
  );
}