import { makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';


const useStyles = makeStyles((theme)=>({
    inputText:{
        minWidth:180
    },
    inputSmall:{
        width:100
    }
}));

const InputText = (props)=>{
    const{label,value,handleChange,withEvent,margin,size,className,...other} = props;
    const classes = useStyles();
    return(
        <TextField 
        label={label}
        value={value}
        onChange={withEvent? e => handleChange(e) : e => handleChange(e.target.value)}
        margin={margin}
        className={clsx((size==="small" ? classes.inputSmall : classes.inputText),className)}
        {...other}
        />
    )

}

InputText.defaultProps = {
    withEvent:false,
    margin:"normal",
    size:"normal",
    className:{}
}
export default InputText;