import { Grid } from '@material-ui/core';
import React,{Fragment, useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Bold, Tag} from 'react-feather';
import { Divider, IconButton } from '@material-ui/core';
import InputSelect from 'src/components/forms/InputSelect';
import CheckBox from 'src/components/forms/CheckBox';
import { withStyles } from '@material-ui/styles';
import { TableRow } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Switch } from '@material-ui/core';
import InputText from 'src/components/forms/InputText';
import { Chip } from '@material-ui/core';


const useStyles = makeStyles(()=>({
  saldo:{
    display:'flex',
    flexDirection:"column",
    alignItems:'flex-end',
    paddingRight:24
  },
  cardContent: {
    display:'flex',
    justifyContent:'space-between',
    alignItems:"center"
  },
  label:{
    flexBasis:"30%",
    fontWeight:"bold",
  }
}))

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgba(39, 167, 74, 0.25)",
    color: theme.palette.text.primary,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);



const Ordine = (props) =>{
    const {ordine,index,user,handleChangeExtra,handleChangeAll,setDbCoding,bulk,sendMail,setSendMail,offerta,handleOffertaChange} = props;
    const [expanded , setExpanded ] = useState(true);
    const classes = useStyles();

    const filterPrice = (id) =>{
      for (let i = 0; i < ordine.righe.length; i++) {
          const riga = ordine.righe[i];
          if(riga.extra === id && !riga.with_ticket) return true;
      }
      return false;
    }
    return (
        <Grid container spacing={0} style={{marginTop:16}}>
            <Grid item xs={12} style={{marginBottom:8}}>
            <Accordion expanded={expanded} onChange={(e,exp)=>setExpanded(exp)}>
                <AccordionSummary expandIcon={<Icon>tune</Icon>}>
                  <Typography>Ordine accettato il {ordine.data_accettazione}</Typography>
                </AccordionSummary>
                  
                <AccordionDetails>
                  <Grid container spacing={1} item xs={12} md={7}>
                    <Grid item xs={12} md={6}>
                      <Card elevation={8}>
                        <CardContent className={classes.cardContent}>
                          <Typography color="textSecondary" variant="overline">{"usa codice da excel ("+ordine.codice+")"}</Typography>
                          {/* <CheckBox label={"usa codice da excel ("+ordine.codice+")"} value={ordine.useDbCoding} handleChange={(e)=>setDbCoding(e,index)} /> */}
                          <Switch
                          checked={ordine.useDbCoding}
                          onChange={(event)=>setDbCoding(event.target.checked,index)}
                          color="primary"
                          />
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Card elevation={8}>
                        <CardContent className={classes.cardContent}>
                          <Typography color="textSecondary" variant="overline">Analisi</Typography>
                          <InputSelect
                          value={bulk[index] || ""}
                          handleChange={(e)=>handleChangeAll(e,index)} 
                          label="Applica a tutti" 
                          name="extra"
                          margin="none"
                          items={user ? user.offerta.filter(el => el.attivo === 1).map(extra_elem=>{
                              return {label: extra_elem.nome, value: extra_elem.id}
                          }):[]} 
                          />  
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Card elevation={8}>
                        <CardContent className={classes.cardContent}>
                          <Typography color="textSecondary" variant="overline">Invia notifica via email</Typography>
                          
                          <Switch
                          checked={sendMail}
                          onChange={(event)=>setSendMail(event.target.checked,index)}
                          color="primary"
                          />
                        </CardContent>
                      </Card>
                    </Grid>

                  </Grid>
                  

                  
                  <Grid container style={{padding:"0px 8px"}} item xs={12} md={5} alignItems="stretch">
                    <Grid item xs={12}>
                      <Card elevation={8}>
                        <CardContent>
                          <Typography color="textSecondary" variant="overline">Offerta</Typography>
                          {user?.convenzione && 
                            <Chip label={user.convenzione.nome_convenzione} color="primary" style={{marginLeft:8}}/>
                          }
                          <Grid container>
                            {offerta.filter(el=>filterPrice(el.id)).map((el,i)=>(
                              <Grid container item xs={12} key={i} display="flex" justify="space-between">
                                <Typography>{el.nome}</Typography>
                                <InputText 
                                value={el.prezzo}
                                label=""
                                margin="none"
                                name={el.nome}
                                withEvent={true}
                                handleChange={(e)=>handleOffertaChange(e,i)}
                                size="small"
                                />
                              </Grid>
                            ))}  
                          </Grid>
                          
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <Typography>Ordine accettato il {ordine.data_accettazione}</Typography>
              
              <CheckBox label={"usa codice da excel ("+ordine.codice+")"} value={ordine.useDbCoding} handleChange={(e)=>setDbCoding(e,index)} />
            </Grid>

            <Grid item md={6} xs={12} style={{display:"flex",justifyContent:"flex-end"}}>
              <InputSelect
                value={bulk[index] || ""}
                handleChange={(e)=>handleChangeAll(e,index)} 
                label="Applica a tutti" 
                name="extra"
                items={user ? user.offerta.filter(el => el.attivo === 1).map(extra_elem=>{
                    return {label: extra_elem.nome, value: extra_elem.id}
                }):[]} 
              />
            </Grid> */}

            <Grid item xs={12}>

              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Nome campione</StyledTableCell>
                      <StyledTableCell align="right">Codice</StyledTableCell>
                      <StyledTableCell align="right">Varietà</StyledTableCell>
                      <StyledTableCell align="right">gr.</StyledTableCell>
                      <StyledTableCell align="right">Lotto</StyledTableCell>
                      <StyledTableCell align="right">Descrizione</StyledTableCell>
                      <StyledTableCell align="right">Analisi</StyledTableCell>
                      <StyledTableCell align="right">Prezzo</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {ordine.righe.map((riga,i)=>(
                     <StyledTableRow key={i}>
                        <StyledTableCell component="th" scope="row">
                          {riga.nome_campione}
                        </StyledTableCell>

                        <StyledTableCell align="right">{riga.codice}</StyledTableCell>
                        
                        <StyledTableCell align="right">
                          <Typography>{riga.cartellino.varieta.nome}</Typography>
                          <Typography color="textSecondary" variant="body2">{riga.cartellino.codice}</Typography>
                        </StyledTableCell>
                        
                        <StyledTableCell align="right">{riga.grammatura}</StyledTableCell>
                        <StyledTableCell align="right">{riga.lotto_coltivazione}</StyledTableCell>
                        <StyledTableCell align="right">{riga.descrizione}</StyledTableCell>
                        
                        <StyledTableCell align="right">
                        <InputSelect
                          value={riga.extra} 
                          handleChange={(e)=>handleChangeExtra(e,index,i)} 
                          label="" 
                          name="extra"
                          margin="none"
                          items={user ? user.offerta.filter(el => el.attivo === 1).map(extra_elem=>{
                              return {label: extra_elem.nome, value: extra_elem.id}
                          }):[]} 
                        />
                        </StyledTableCell>
                        
                        <StyledTableCell align="right">
                          {riga.withTicket ? 
                          <Icon>confirmation_number</Icon>
                          : 
                          (riga.extra ? offerta.find((extra)=> extra.id == riga.extra ).prezzo : "") }
                        
                        </StyledTableCell>
                        
                     </StyledTableRow> 
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>
        </Grid>
    );
}

export default Ordine;