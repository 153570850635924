export const loadState = (item ='sifLab') => {
	try{
		const serializedState = localStorage.getItem(item);//sifLab_state
		if(serializedState === null){
			return undefined;
		}
		return JSON.parse(serializedState);
	}catch(err){
		console.log(err);
		return undefined;
	}
}
export const saveState = (state,item ='sifLab') => {
	try{
		const serializedState = JSON.stringify(state);
		localStorage.setItem(item, serializedState);
		return true;
	}catch (err){
		console.log(err);
		return false;
	}
}
