import { Paper } from '@material-ui/core';
import { Avatar, Box, Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

const useStyles=makeStyles(()=>({
    row: {
        marginBottom:24
    },
    
    container:{
        position:'relative',
        width:250,
        backgroundColor:"#fff",
        padding:'16px 0 16px 30px',
        borderStyle:'solid',
        borderColor:'#f5cb04',
        borderTop:0,
        borderBottom:0,
        borderLeft:4,
        borderRight:0,
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        marginRight:16
    },
}));

const TicketCard = (props) =>{
    const {tickets} = props;
    const classes = useStyles()

    useEffect(()=>{
        
    },[tickets]);

    return(
        <Paper elevation={8} className={classes.row} style={{padding:"16px 8px"}}>
        <Grid container spacing={0} >
                <Grid item xs={12}>
                    <Typography variant="h5" >Ticket disponibili</Typography> 
                    <Typography variant="body2" color="textSecondary">Qui vengono mostrati i Ticket di cui si è in possesso.<br/>Se si possiedono i ticket relativi alle analisi selezionate non verrà applicato nessun costo al campione ma verrà scalato un ticket</Typography>
                    <br/>
                </Grid>
                
                {tickets.map(el=>(
                    <Box component={Grid} boxShadow={3} className={classes.container} key={el.id}>
                        
                        
                            <Typography variant="caption" >{el.nome}</Typography> 
                            <div>
                                <Typography display="inline" >{el.rimanenti}</Typography>      
                                <Typography display="inline" variant="caption" color="textSecondary">{"/"+el.disponibili}</Typography>
                            </div>
                            
                    </Box> 
                ))

                } 
        </Grid>
        </Paper>
    );
}

export default TicketCard;