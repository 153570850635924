import { Grid } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { classes } from 'istanbul-lib-coverage';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import Page from 'src/components/Page';
import apiClient from 'src/utils/apiClient';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    rowWhite: {
        backgroundColor:"#fff"
    },
    rowGrey:{
        backgroundColor:"#DAE4F0"
    },
    thead:{
        backgroundColor:"rgba(39, 167, 74, 0.25)"
    },
  }));

const BorderLinearProgress = withStyles((theme) => ({
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

const TicketListView = (props)=>{
    const classes = useStyles();
    const [tickets,setTickets] = useState([]);

    useEffect(()=>{
        apiClient.get("api/tickets")
        .then(response=>{
        setTickets(response.data.tickets);
        })
        .catch(err=> console.log(err));
    },[])
    return (
        <Page
        className={classes.root}
        title="Ticket"
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.thead}>
                                        <TableCell variant="head">Codice</TableCell>
                                        <TableCell variant="head">Cliente</TableCell>
                                        <TableCell variant="head">Analisi</TableCell>
                                        <TableCell variant="head">N° Ticket</TableCell>
                                        <TableCell variant="head">Rimanenti</TableCell>
                                        <TableCell variant="head">€/cad</TableCell>
                                        <TableCell variant="head">Pagamento</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    
                                        {tickets.map((el,j)=>{
                                            var rowSpan = el.extras.length;
                                            var cN = j%2==0 ? classes.rowWhite : classes.rowGrey;
                                            return(
                                                el.extras.map((ext,i)=>{
                                                    return (
                                                        i===0 ? 
                                                        <TableRow key={el.codice} className={cN}>
                                                            <TableCell rowSpan={rowSpan}>{el.codice}</TableCell>
                                                            <TableCell rowSpan={rowSpan}>{el.user.azienda}</TableCell>
                                                            <TableCell>{ext.nome}</TableCell>
                                                            <TableCell>{ext.pivot.numero_ticket}</TableCell>
                                                            <TableCell>{ext.pivot.rimanenti}</TableCell>
                                                            <TableCell>{ext.pivot.prezzo}</TableCell>
                                                            <TableCell rowSpan={rowSpan}>
                                                                {el.payment && 
                                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                                    <Typography align="center" variant="caption" color="textSecondary">{el.payment.perc}%</Typography>
                                                                    <BorderLinearProgress variant="determinate" value={el.payment.perc} style={{width:"100%"}}/>
                                                                </Box>    
                                                                }
                                                            
                                                            </TableCell>
                                                        </TableRow>
                                                        :
                                                        <TableRow key={el.codice+"-"+ext.nome} className={cN}>
                                                            <TableCell>{ext.nome}</TableCell>
                                                            <TableCell>{ext.pivot.numero_ticket}</TableCell>
                                                            <TableCell>{ext.pivot.rimanenti}</TableCell>
                                                            <TableCell>{ext.pivot.prezzo}</TableCell>
                                                        </TableRow>
                                                        
                                                    )
                                                })

                                                // <TableRow key={el.codice}>
                                                //     <TableCell rowSpan={rowSpan}>{el.codice}</TableCell>
                                                //     <TableCell rowSpan={rowSpan}>{el.user.azienda}</TableCell>
                                                //     {el.extras.map((ext,i) => {
                                                //         return (
                                                //         <Fragment key={ext.id}>
                                                            
                                                //             <TableCell>{ext.nome}</TableCell>
                                                //             <TableCell>{ext.pivot.numero_ticket}</TableCell>
                                                //             <TableCell>{ext.pivot.rimanenti}</TableCell>
                                                //             <TableCell>{ext.pivot.prezzo}</TableCell>
                                                //             {i===0 && <TableCell rowSpan={rowSpan}>{el.payment.perc}</TableCell>}

                                                //         </Fragment>
                                                //         )
                                                //     })}
                                                    
                                                // </TableRow>
                                            )
                                        })}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

export default TicketListView;