import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';

function AccountDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { first_name, last_name, phone, email } = formValues;
  return (
    <>
        {/* <Grid item xs={6} sm={6}>
            <Typography variant="caption" gutterBottom>Referente</Typography> 
        </Grid>
        <Grid item xs={6} sm={6}>
            <Typography gutterBottom>{`${first_name} ${last_name}`}</Typography> 
        </Grid> */}
        <Grid item xs={12} sm={12} className={classes.details}>
            <Typography variant="overline" color="secondary">Referente: </Typography>
            <Typography variant="subtitle2" className="ellipsis">{`${first_name} ${last_name}`}</Typography>
        </Grid>

        {/* <Grid item xs={6} sm={6}>
            <Typography variant="caption" gutterBottom>Telefono</Typography> 
        </Grid>
        <Grid item xs={6} sm={6}>
            <Typography gutterBottom>{`${phone}`}</Typography> 
        </Grid> */}
        <Grid item xs={12} sm={6} className={classes.details}>
            <Typography variant="overline" color="secondary">Telefono: </Typography>
            <Typography variant="subtitle2" className="ellipsis">{`${phone}`}</Typography>
        </Grid>

        {/* <Grid item xs={6} sm={6}>
            <Typography variant="caption" gutterBottom>email</Typography> 
        </Grid>
        <Grid item xs={6} sm={6}>
            <Typography gutterBottom>{`${email}`}</Typography> 
        </Grid> */}
        <Grid item xs={12} sm={6} className={classes.details}>
            <Typography variant="overline" color="secondary">Email: </Typography>
            <Typography variant="subtitle2" className="ellipsis">{`${email}`}</Typography>
        </Grid>
    </>
  );
}

export default AccountDetails;