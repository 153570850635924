import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CannabinoidiGraph from './CannabinoidiGraph.js'
import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded';
import BorderAllRoundedIcon from '@material-ui/icons/BorderAllRounded';
import CannabinoidiTable from 'src/views/reports/CannabinoidiTable.js'

const RTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: 'rgba(39, 167, 74, 0.85)',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const RTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#000',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = (props)=>{
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"

      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ReportTabs =(props)=>{
  //console.log(props);
  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const getRighe = ()=>{
    if(props.selected.length ===0 ) return props.righe;

    return props.righe.filter((el,i) => {
      return props.selected.includes(i);
    })
  }

  return (
    <div>
      
        <RTabs value={tab} onChange={handleChange} aria-label="styled tabs example">
          <RTab label="Tabella" icon={<BorderAllRoundedIcon />}/>
          <RTab label="Grafico" icon={<BarChartRoundedIcon />}/>

        </RTabs>
      

      <TabPanel value={tab} index={0}>
        <CannabinoidiTable codice={props.codice} righe={getRighe()} />
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <CannabinoidiGraph righe={getRighe()}/>
      </TabPanel>

    </div>
  )
}

export default ReportTabs;
